<template>
  <waste-identification-templates-view-user v-if="template?.documentType === documentType.WASTE_IDENTIFICATION" :template="template" @reload="load()" />
  <layout v-else>
    <spinner />
  </layout>
</template>

<script>

import backend from '@/util/backend'
import {ref} from 'vue'
import WasteIdentificationTemplatesViewUser
  from '@/views/transport-document-templates/waste-identification/View-User'
import documentType from '@/types/documentType'
import Layout from '@/components/layout'
import Spinner from '@/components/spinner'

export default {
  name: 'TransportDocumentTemplatesViewUser',
  computed: {
    documentType() {
      return documentType
    }
  },
  components: {
    Spinner,
    Layout,
    WasteIdentificationTemplatesViewUser
  },
  props: {
    id: {
      type: String,
      required: true,
    }
  },
  setup: (props) => {
    const template = ref(null)
    const load = () => {
      backend.get(`api/transportDocumentTemplates/${props.id}`).then((result) => {
        template.value = result.data
      })
    }
    load()

    return {
      template,
      load,
    }
  }
}
</script>
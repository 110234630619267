<template>
  <layout>
    <div class="display-3 mt-5">Page not found.</div>
    <div class="grey--text lighten-5">
      The page you are trying to get to does not exist.
    </div>
    <div class="paragraph-text mt-2">
      <br />
      <br />Try going back to home page and repeating your action. Or,
      contact helpdesk for support.
    </div>
  </layout>
</template>

<script>

import Layout from '@/components/layout'
export default {
  name: 'NotFound',
  components: {Layout},
  setup: () => {
    return []
  }
}

</script>

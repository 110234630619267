<template>
  <ui-modal id="finishModal" ref="modal" :callback="cb" :no-confirm="demo">
    <template #title>
      {{ $t('forms.finish_modal.title') }}
    </template>
    <template #default v-if="demo">
      <div class="pb-2">
        <div class="mb-4" style="color:red">
          {{ $t('forms.finish_modal.demo_warning') }}
        </div>
        <div>
          {{ $t('forms.finish_modal.information') }}
        </div>
      </div>
    </template>
    <template #default v-else>
      <div class="pb-2">
        <template v-if="amount>1">
          {{ $t('forms.finish_modal.finish_forms', {amount: amount}) }}
        </template>
        <template v-else>
          {{ $t('forms.finish_modal.finish_form') }}
        </template>
      </div>
    </template>
  </ui-modal>
</template>

<script>

import {ref} from 'vue'
import UiModal from '@/components/ui-modal'

export default {
  name:       'FormFinishModal',
  components: {
    UiModal,
  },
  props:      {
    callback: {
      type:     Function,
      required: true,
    },
    amount:   {
      type:    Number,
      default: 1,
    },
    demo:     {
      type:    Boolean,
      default: false,
    },
  },
  setup:      (props) => {
    const modal = ref('modal')

    const cb = async () => {
      await props.callback()
    }

    return {
      modal,
      cb,
    }
  },
}
</script>

<template>
  <ui-modal
      ref="modal"
      :callback="callback"
      :cancel-button="failedFormCheck.state !== FormCheckState.OK ? 'modal.go_complete' : 'modal.cancel'"
      :confirm-button="failedFormCheck.state !== FormCheckState.OK ? 'modal.sign_anyway' : 'modal.confirm'"
  >
    <template #title>
      {{ $t('forms.sign_modal.title') }}
    </template>
    <template #default v-if="failedFormCheck.state === FormCheckState.NO_UPLOAD">
      {{ $t('forms.sign_modal.sign_form') }}
      <br/>
      <br/>
      <span style="color: darkorange">
        {{ $t(`forms.sign_modal.matis_validation.no_upload`) }}
        <br/>
        <br/>
      </span>
    </template>
    <template #default v-else-if="failedFormCheck.state === FormCheckState.BAD_FIELDS">
      {{ $t('forms.sign_modal.sign_form') }}
      <br/>
      <br/>
      <span style="color: darkorange">
        {{ $t(`forms.sign_modal.matis_validation.bad_fields`) }}
        <br/>
        <br/>
      </span>
      <ul style="color: darkorange">
        <li v-for="field in failedFormCheck.fields" :key="field">
          {{ $t(`forms.sign_modal.matis_validation.${field}`) }}
        </li>
      </ul>
    </template>
    <template #default v-else>
      {{ $t('forms.sign_modal.sign_form') }}
    </template>
  </ui-modal>
</template>

<script>

import {inject, ref} from 'vue'
import UiModal from '@/components/ui-modal'
import documentType from '@/types/documentType'
import WasteIdentificationModel from '@/models/WasteIdentificationModel'
import FormCheckState from '@/types/formCheckState'

export default {
  name:       'FormSignModal',
  computed:   {
    FormCheckState() {
      return FormCheckState
    }
  },
  components: {UiModal},
  props:      {
    callback: {
      type:     Function,
      required: true,
    },
    form:     {
      type:     Object,
      required: true,
    },
  },
  setup:      (props) => {
    const modal = ref('modal')
    const failedFormCheck = ref({
      state:  FormCheckState.OK,
    })

    const flag = inject('$flag')
    // eslint-disable-next-line
    if (flag('MATIS') && flag('MATIS_CHECK')) {
      switch (props.form.type) {
        case documentType.WASTE_IDENTIFICATION: {
          failedFormCheck.value = WasteIdentificationModel.matisCheck(props.form)
          break
        }
      }
    }
    return {
      modal,
      failedFormCheck,
    }
  },
}
</script>

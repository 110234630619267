<template>
  <layout :key="language">
    <template #header>
      <ui-header :title="(user.firstName ?? '') + ' ' + (user.lastName ?? '')" />
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs" />
    <ui-content class="pb-1">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <ui-view-card :title="$t('user.title.user_information')">
            <template #actions>
              <button type="button" class="btn btn-outline-secondary" @click="router.push({name: 'ProfileEdit'})"><i class="bi-save" />&nbsp;{{$t('general.edit')}}</button>
            </template>
            {{ $t('user.labels.email') }}: {{user.email}}
            <br />
            {{ $t('user.labels.first_name') }}: {{ user.firstName }}
            <br />
            {{ $t('user.labels.last_name') }}: {{ user.lastName }}
            <br />
            {{ $t('user.labels.language') }}: {{ languages.getLanguageSelf(user.language) }}
            <br />
            {{ $t('user.labels.roles') }}: {{roles.getRoleText(currentOrg.roles)}}
          </ui-view-card>
        </div>
      </div>
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import {ref} from 'vue'
import UiViewCard from '@/components/ui-view-card'
import roles from '@/util/roles'
import session from '@/util/session'
import {useRouter} from 'vue-router'
import languages from '@/types/languages'

export default {
  name: 'Profile',
  components: {
    UiViewCard,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout
  },
  setup: () => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.PROFILE]
    const user = ref(session.getUser())
    const language = ref(user.value.language)
    const router = useRouter()

    const currentOrg = session.getCurrentOrganisation()

    return {
      breadcrumbs,
      router,
      user,
      currentOrg,
      roles,
      language,
      languages,
    }
  }
}
</script>

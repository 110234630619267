<template>
  <div class="container-fluid">
    <form id="waste-identification-template-form" class="row g-2 justify-content-center">
      <ui-view-card :title="$t('waste_identification_template.form.title')">
        <template #subtitle>
          {{ $t('form.waste_identification_subtitle') }}&nbsp;<a
            href="https://ovam.vlaanderen.be/eural-handleiding-en-eural-wizard"
            target="blank">{{ $t('form.here') }}</a>.
        </template>
        <div class="row">
          <div class="col-lg-6">
            <ui-view-card-field data-cy="template-description" :label="$t('waste_identification_template.form.description')" v-model="itemRef.description" :required="true" />
          </div>
          <div class="col-lg-6">
            <ui-view-card-field data-cy="template-external-id" :label="$t('waste_identification_template.form.external_id')" v-model="itemRef.externalId" />
          </div>
          <div class="col-lg-6">
            <ui-view-card-field data-cy="template-eural-code" :label="$t('waste_identification_template.form.EURAL-code')" v-model="itemRef.euralCode" :required="true" v-if="euralCodesLoaded">
              <v-select :options="euralCodes" v-model="itemRef.euralCode" :reduce="code => code.value" :filter="EuralCodes.ignoreSpaceSearch">
                <template #search="{attributes, events}">
                  <input
                    class="vs__search"
                    :required="!itemRef.euralCode"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
                <template #no-options>
                  {{ $t('general.no_options') }}
                </template>
              </v-select>
            </ui-view-card-field>
          </div>
          <div class="col-lg-6">
            <ui-view-card-field data-cy="template-dangerous" :label="$t('waste_identification_template.form.dangerous')" v-model="itemRef.dangerous">
              <div class="form-check d-flex justify-content-center">
                <input class="form-check-input" type="checkbox" disabled v-model="itemRef.dangerous">
              </div>
            </ui-view-card-field>
          </div>
          <div class="col-lg-6">
            <ui-view-card-field :label="$t('waste_identification_template.form.removal_operation')" :required="true">
              <v-select :key="removalOperationsLoaded" :options="removalOperations" v-model="itemRef.removalOperation" :reduce="operation => operation.value">
                <template #selected-option="option">
                  {{ option.value }}
                </template>
                <template #search="{attributes, events}">
                  <input
                    class="vs__search"
                    :required="!itemRef.removalOperation"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
                <template #no-options>
                  {{ $t('general.no_options') }}
                </template>
              </v-select>
            </ui-view-card-field>
          </div>
          <div class="col-lg-6">
            <ui-view-card-field :label="$t('waste_identification_template.form.processing_technique')" :required="true">
              <v-select :key="processingTechniquesLoaded" :options="processingTechniques" v-model="itemRef.processingTechnique" :reduce="operation => operation.value">
                <template #search="{attributes, events}">
                  <input
                    class="vs__search"
                    :required="!itemRef.processingTechnique"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
                <template #no-options>
                  {{ $t('general.no_options') }}
                </template>
              </v-select>
            </ui-view-card-field>
          </div>
          <template v-if="itemRef.dangerous">
            <div class="col-lg-4">
              <ui-view-card-field data-cy="template-physical" :label="$t('waste_identification_template.form.physical')" v-model="itemRef.physical" :required="true" />
            </div>
            <div class="col-lg-4">
              <ui-view-card-field data-cy="template-chemical" :label="$t('waste_identification_template.form.chemical')" v-model="itemRef.chemical" :required="true" />
            </div>
            <div class="col-lg-4">
              <ui-view-card-field data-cy="template-packaging-type" :label="$t('waste_identification_template.form.packaging_type')" v-model="itemRef.packagingType" :required="true" />
            </div>
          </template>
        </div>
      </ui-view-card>
    </form>
  </div>
</template>

<script>

import {ref, watch} from 'vue'
import UiViewCard from '@/components/ui-view-card'
import UiViewCardField from '@/components/ui-view-card-field'
import EuralCodes from '@/models/EuralCodes'
import RemovalOperations from "@/models/RemovalOperations";
import ProcessingTechniques from "@/models/ProcessingTechniques";

export default {
  name: 'WasteIdentificationTemplateForm',
  computed: {
    EuralCodes() {
      return EuralCodes
    }
  },
  components: {
    UiViewCardField,
    UiViewCard,
  },
  props: {
    item: {
      type:     Object,
      required: true,
    },
  },
  setup: (props) => {
    let itemRef = ref(props.item)

    const euralCodes = ref([])
    const euralCodesLoaded = ref(false)
    const getEuralCodes = async () => {
      euralCodes.value = await EuralCodes.get()
      euralCodesLoaded.value = true
      checkDangerous()
    }
    getEuralCodes()

    const getEural = (ec) => {
      for (let i = 0; i < euralCodes.value.length; i++) {
        if (euralCodes.value[i].value === ec) {
          return euralCodes.value[i]
        }
      }
      return null
    }

    const checkDangerous = () => {
      itemRef.value.dangerous = getEural(itemRef.value.euralCode)?.dangerous
    }
    watch(() => itemRef.value.euralCode, () => {
      checkDangerous()
    })

    const removalOperations = ref([])
    const removalOperationsLoaded = ref(false)
    const getRemovalOperations = async () => {
      removalOperations.value = await RemovalOperations.get()
      removalOperationsLoaded.value = true
    }
    getRemovalOperations()

    const processingTechniques = ref([])
    const processingTechniquesLoaded = ref(false)
    const getProcessingTechniques = async () => {
      processingTechniques.value = await ProcessingTechniques.get()
      processingTechniquesLoaded.value = true
    }
    getProcessingTechniques()

    return {
      itemRef,
      euralCodes,
      euralCodesLoaded,
      removalOperations,
      removalOperationsLoaded,
      processingTechniques,
      processingTechniquesLoaded,
    }
  }
}
</script>

const BREADCRUMBS = {
  HOME: {
    name: 'breadcrumbs.home',
    url: '#'
  },
  MAILS: {
    name: 'breadcrumbs.mails',
  },
  ANNEX_7: {
    name: 'breadcrumbs.annex_7',
    url: '#/annex-7/list'
  },
  WASTE_IDENTIFICATION: {
    name: 'breadcrumbs.waste_identification',
    url: '#/waste-identification/list'
  },
  WASTE_IDENTIFICATION_TEMPLATES: {
    name: 'breadcrumbs.waste_identification_templates',
    url: '#/waste-identification/list?tab=templates'
  },
  ECMR: {
    name: 'breadcrumbs.ecmr',
    url: '#/ecmr/list'
  },
  GRONDBANK: {
    name: 'breadcrumbs.grondbank',
    url: '#/grondbank/list'
  },
  GRONDWIJZER: {
    name: 'breadcrumbs.grondwijzer',
    url: '#/grondwijzer/list'
  },
  VIEW: {
    name: 'breadcrumbs.view',
  },
  EDIT: {
    name: 'breadcrumbs.edit',
  },
  VIEW_TEMPLATE: {
    name: 'breadcrumbs.view_template',
  },
  EDIT_TEMPLATE: {
    name: 'breadcrumbs.edit_template',
  },
  CREATE_FORM: {
    name: 'breadcrumbs.create_form',
  },
  NEW: {
    name: 'breadcrumbs.new',
  },
  USERS: {
    name: 'breadcrumbs.users',
    url: '#/users/list'
  },
  TEMPLATES: {
    name: 'breadcrumbs.templates'
  },
  ORGANISATIONS: {
    name: 'breadcrumbs.organisations',
    url: '#/organisations/list'
  },
  DRIVERS: {
    name: 'breadcrumbs.drivers',
    url: '#/drivers/list'
  },
  WASTES: {
    name: 'breadcrumbs.wastes',
    url: '#/waste-identification-templates/list'
  },
  PROFILE: {
    name: 'breadcrumbs.profile',
    url: '#/profile'
  },
  MY_ORGANISATION: {
    name: 'breadcrumbs.my_organisation',
    url: '#/my-organisation'
  },
  MY_ORGANISATION_LINKED_ORGANISATIONS: {
    name: 'breadcrumbs.linked_organisations',
    url: '#/my-organisation/linked-organisations'
  },
  DASHBOARD: {
    name: 'breadcrumbs.dashboard',
    url: '#/admin/dashboard'
  },
  SYSORGANISATIONS: {
    name: 'breadcrumbs.sys_organisation_list',
    url: '#/admin/organisations/list'
  },
  SYSORGANISATIONDETAIL: {
    name: 'breadcrumbs.sys_organisation_detail',
  },
  MATIS: {
    name: 'breadcrumbs.matis',
    url: '#/matis/list'
  },
}

export default BREADCRUMBS
import {reactive} from 'vue'

const message = reactive({
    msg:     '',
    type:    'message',
    options: []
})

export const notifier = () => {
    // Notify for messages from frontend
    const notify = (m, options) => {
        message.msg  = m
        message.type = 'message'
        message.options = options
    }

    const success = (m, options) => {
        message.msg = m
        message.type = 'success'
        message.options = options
    }

    const error = (m, options) => {
        message.msg = m
        message.type = 'error'
        message.options = options
    }

    const reset = () => {
        message.msg = ''
        message.type = ''
        message.options = []
    }

    return {
        message,
        success,
        error,
        reset,
        notify
    }
}

export default notifier

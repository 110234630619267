import {inject} from 'vue'

const languages = {
  languages:       [
    {
      label: 'languages.dutch',
      value: 'NL'
    },
    {
      label: 'languages.english',
      value: 'EN'
    },
    {
      label: 'languages.french',
      value: 'FR'
    },
    {
      label: 'languages.german',
      value: 'DE'
    },
  ],
  getLanguage:     (language, t) => {
    if (!language) {
      return ''
    }
    let $t = inject('$t')
    // stupid fix when deleting chauffeur and reloading, $t injection is empty?
    if (t) {
      $t = t
    }
    for (let i = 0; i < languages.languages.length; i++) {
      if (languages.languages[i].value === language.toUpperCase()) {
        return $t(languages.languages[i].label)
      }
    }
    return ''
  },
  getLanguageSelf: (language) => {
    const $tget = inject('$tget')
    for (let i = 0; i < languages.languages.length; i++) {
      if (languages.languages[i].value === language.toUpperCase()) {
        return $tget(language.toUpperCase())(languages.languages[i].label)
      }
    }
  },
}

export default languages

const formatDate = (date) => {
    if (!date) {
        return ''
    }
    const options = {
        year: 'numeric', month: 'numeric', day: 'numeric',
    }

    date = new Date(date)
    return (date instanceof Date && isFinite(date.getTime())) ? new Intl.DateTimeFormat('nl', options).format(date) : ''
}

const formatDateTime = (date) => {
    if (!date) {
        return ''
    }
    const options = {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
    }

    date = new Date(date)
    return (date instanceof Date && isFinite(date.getTime())) ? new Intl.DateTimeFormat('nl', options).format(date) : ''

}

export const DateFormatter = {
    formatDate,
    formatDateTime,
}

<template>
  <div v-if="$roles([roles.OVERVIEWER, roles.USER])" class="btn-group">
    <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="actionsDropdown" data-bs-toggle="dropdown" :disabled="selection.length === 0">
      {{$t('general.actions')}}
    </button>
    <ul data-cy="waste-identification-actions" class="dropdown-menu" aria-labelledby="actionsDropdown">
      <li v-if="$roles([roles.USER])">
        <button :disabled="selection.length === 0" class="dropdown-item" @click="showDuplicate()">
          {{ $t('waste_identification.list.actions.duplicate') }}
        </button>
        <button :disabled="selection.length === 0" class="dropdown-item" @click="showRemove()">
          {{ $t('waste_identification.list.actions.delete') }}
        </button>
      </li>
    </ul>
  </div>
  <button v-if="$roles([roles.USER])" data-cy="create-template" type="button" class="btn btn-outline-primary" @click="addTemplate()"><i class="bi-plus" />&nbsp;{{ $t('general.add_template') }}</button>
  <template-delete-modal ref="removeModal" :amount="selection.length" :callback="remove" /> <!-- TODO fix btn-group rounding visual -->
  <template-duplicate-modal ref="duplicateModal" :amount="selection.length" :callback="duplicate" />
</template>

<script>

import {ref} from 'vue'
import backend from '@/util/backend'
import Notifier from '@/util/notifier'
import '@vuepic/vue-datepicker/dist/main.css'
import router from '@/router/router'
import TransportDocumentTemplateModel from '@/models/TransportDocumentTemplateModel'
import DocumentType from '@/types/documentType'
import TemplateDeleteModal from '@/views/transport-document-templates/Delete-modal'
import TemplateDuplicateModal from '@/views/transport-document-templates/Duplicate-modal.vue'

export default {
  name: 'WasteIdentificationListUserTemplatesActions',
  computed: {
    DocumentType() {
      return DocumentType
    }
  },
  components: {
    TemplateDuplicateModal,
    TemplateDeleteModal,
  },
  props: {
    selection: {
      type:     Array,
      required: true,
    }
  },
  emits: ['reload'],
  setup: (props, {emit}) => {
    const notifier = Notifier()

    const addTemplate = async () => {
      const result = await backend.post('api/transportDocumentTemplates', JSON.parse(JSON.stringify(TransportDocumentTemplateModel.wasteIdentification)))
      if (result.status === 200) {
        router.push(`/transport-document-templates/edit/${result.data.id}`)
      } else {
        notifier.error('toast.form_create_failed')
      }
    }

    const removeModal = ref('removeModal')
    const showRemove = () => {
      removeModal.value.modal.open()
    }
    const remove = () => {
      const ids = props.selection.map((template) => { return template.id })
      if (ids.length > 0) {
        backend.post('api/transportDocumentTemplates/batch', {
          'action': 'DELETE',
          'ids':    ids,
        }).then((result) => {
          if (result.status === 200) {
            notifier.success('toast.deleting_successful')
          } else {
            notifier.error('toast.deleting_failed')
          }
        }).finally(() => {
          removeModal.value.modal.close()
          emit('reload')
        })
      } else {
        removeModal.value.modal.close()
      }
    }

    const duplicateModal = ref('duplicateModal')
    const showDuplicate = () => {
      duplicateModal.value.modal.open()
    }
    const duplicate = () => {
      const ids = props.selection.map((template) => {
        return template.id
      })
      if (ids.length > 0) {
        backend.post('api/transportDocumentTemplates/batch', {
          'action': 'COPY',
          'ids': ids,
        }).then((result) => {
          if (result.status === 200) {
            notifier.success('toast.duplication_successful')
          } else {
            notifier.error('toast.duplication_failed')
          }
        }).finally(() => {
          duplicateModal.value.modal.close()
          emit('reload')
        })
      }
    }

    return {
      addTemplate,
      removeModal,
      showRemove,
      remove,
      duplicateModal,
      showDuplicate,
      duplicate,
    }
  }
}
</script>

import proj4 from 'proj4'
import Notifier from '@/util/notifier'
import {languages} from '@/util/i18n'
import session from '@/util/session'

let language = null

const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0
};

const optionsWatch = {
  enableHighAccuracy: true,
  maximumAge: 0
};

// WGS 84
proj4.defs('WGS84', "+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees");
// Belgian Lambert 08
proj4.defs("EPSG:3812", "+proj=lcc +lat_0=50.797815 +lon_0=4.35921583333333 +lat_1=49.8333333333333 +lat_2=51.1666666666667 +x_0=649328 +y_0=665262 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs");

const notifier = Notifier()

let retryCounter = 0
const error = () => {
  if (++retryCounter === 5) {
    let url
    const user = session.getUser()
    switch (language ?? user?.language ?? languages.EN) {
      case languages.NL: {
        url = 'https://digiform.be/hulp-documentatie/locatie-bepaling/'
        break
      }
      case languages.FR: {
        url = 'https://digiform.be/fr/videos-dinstruction-et-documentation/message-derreur-erreur-de-recuperation-de-votre-position/'
        break
      }
      default: {
        url = 'https://digiform.be/en/help-documentation/error-message-retrieving-location-failed/'
        break
      }
    }
    window.open(url, '_blank')
  }
  if (retryCounter >= 5) {
    notifier.error('toast.location_failed_with_link')
  } else {
    notifier.error('toast.location_failed')
  }
}

const geolocation = {
  location: null,
  setLocation(pos) {
    this.location = pos
  },
  getLocation() {
    if (!this.location) {
      this.getPosition()
    } else {
      const lambert = proj4('EPSG:3812', [this.location.coords.longitude, this.location.coords.latitude])
      return lambert.join(',')
    }
  },
  watchPosition() {
    // The geographic position information is provided in terms of World Geodetic System coordinates [WGS84].
    navigator.geolocation.watchPosition((pos) => {
      this.setLocation(pos)
    }, () => {
    }, optionsWatch)
  },
  getPosition() {
    navigator.geolocation.getCurrentPosition((pos) => {
      this.setLocation(pos)
    }, error, options)
  },
  reverse(coordinates) {
    const parts = coordinates.split(',')
    const long = +parts[0]
    const lat = +parts[1]
    return proj4('EPSG:3812', 'WGS84', [long, lat])
  },
  setLanguage: (l) => {
    language = l
  }
}

export default geolocation
import DocumentType from '@/types/documentType'

const checkAlways = () => {
  const formElement = $('#grondbank-form')[0]
  if (!formElement) {
    return 'toast.form_missing'
  }
  if (!formElement.checkValidity()) {
    // something in the form is not valid
    return 'toast.form_incomplete'
  }
}
const checkToSign = (form) => {
  const localOffset = new Date().getTimezoneOffset()/-60 // offset to UTC in hours
  let date = new Date(form.transportDate)
  date.setHours(date.getHours()+localOffset)
  date = date.setUTCHours(0, 0, 0, 0)
  const today = new Date().setUTCHours(0, 0, 0, 0)
  // if date is in past
  if (date < today) {
    return 'toast.date_in_past'
  }
}

const GrondbankModel = {
  soilRide: {
    startOfTransport:     null,
    volume:               null,
    description:          '',
    endOfTransport:       null,
    organiserSignature:   null,
    transporterSignature: null,
    processorSignature:   null,
  },
  empty:     {
    type:                 DocumentType.GRONDBANK,
    id:                   null,
    soilReferenceId:      null,
    permissionId:         null,
    externalId:           null,
    licensePlate:         null,
    state:                'DRAFT',
    transportDate:        null,
    notes:                '',
    soilOrigin:           {
      name:    null,
      address: {},
    },
    soilOriginContractor: {
      name: null,
    },
    soilDestination:      {
      name:    null,
      address: {},
    },
    soilTransporter:      {},
    organiserSignature:   null,
    processorSignature:   null,
    transporterSignature: null,
    soilRides:            [self.soilRide],
  },
  formCheck: (form, allChecks = false) => {
    let r = checkAlways(form)
    if (r) {
      return r
    }
    if (allChecks) {
      r = checkToSign(form)
      if (r) {
        return r
      }
    }
  },
}

export default GrondbankModel

<template>
  <ui-modal ref="modal" :callback="cb" :can-confirm="isValid">
    <template #title>
      {{ $t('waste_identification.export_modal.title') }}
    </template>
    <template #default>
      <div class="pb-2">
        {{ $t('waste_identification.export_modal.export_forms', {amount}) }}
      </div>
      <label>{{ $t('waste_identification.export_modal.export_type') }}</label>
      <v-select data-cy="export_type" :options="types ?? ExportType.getAll()" v-model="exportType">
        <template #search="{attributes, events}">
          <input
              class="vs__search"
              :required="!exportType"
              v-bind="attributes"
              v-on="events"
          />
        </template>
        <template #no-options>
          {{ $t('general.no_options') }}
        </template>
      </v-select>
      <template v-if="exportType === 'PDF'">
        <br/>
        <label>{{ $t('user.labels.language') }}:</label>
        <language-selector v-model="language" :required="true"/>
      </template>
    </template>
  </ui-modal>
</template>

<script>

import {ref} from 'vue'
import UiModal from '@/components/ui-modal'
import session from '@/util/session'
import LanguageSelector from '@/components/LanguageSelector'
import ExportType from '@/types/exportType'

export default {
  name:       'FormExportModal',
  computed:   {
    ExportType() {
      return ExportType
    },
  },
  components: {LanguageSelector, UiModal},
  props:      {
    amount:   {
      type:     Number,
      required: true,
    },
    callback: {
      type:     Function,
      required: true,
    },
    types:    {
      type:    Array,
      default: null,
    },
  },
  setup:      (props) => {
    const modal    = ref('modal')
    const language = ref(session.getLanguage())

    const isValid = () => {
      return validAmount() && validLanguage() && validType()
    }

    const validAmount = () => {
      return props.amount > 0
    }

    const validLanguage = () => {
      return !!language.value
    }

    const validType = () => {
      return !!exportType.value
    }

    const cb = async () => {
      await props.callback(language.value, exportType.value)
    }

    const exportType = ref(ExportType.PDF)

    return {
      modal,
      isValid,
      language,
      cb,
      exportType,
    }
  },
}
</script>

<template>
  <layout>
    <template #header>
      <ui-header :title="$t('waste_identification.list.waste_identification')" />
    </template>
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
      </div>
      <div>
        <button class="btn btn-sm btn-outline-secondary" @click="resetGrid()">{{ $t('general.reset_filters') }}</button>
      </div>
    </div>
    <ui-content>
      <ag-grid-vue
        :serverSideDatasource="grid.datasource('api/documents/list?documentType=WASTE_IDENTIFICATION')"
        :grid-options="grid.defaultOptions"
        :columnDefs="columnDefs"
        class="ag-theme-quartz"
        style="min-height:100%"
        @row-clicked="rowClick"
        @grid-ready="onGridReady"
        @grid-pre-destroyed="onGridPreDestroyed"
        @filter-changed="onFilterChanged"
        :initialState="initialState"
      />
    </ui-content>
  </layout>
</template>

<script>

import {inject, onBeforeUnmount, ref} from 'vue'
import Layout from '@/components/layout'
import {AgGridVue} from 'ag-grid-vue3'
import {useRouter} from 'vue-router'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import UiHeader from '@/components/ui-header'
import UiContent from '@/components/ui-content'
import {DateFormatter} from '@/services/dateFormatter'
import FormState from '@/types/formState'
import '@vuepic/vue-datepicker/dist/main.css'
import DocumentType from '@/types/documentType'
import stateColorRenderer from '@/util/stateColorRenderer'
import checkboxRenderer from '@/util/checkboxRenderer'
import grid from '@/util/grid'
import session from '@/util/session'

export default {
  name: 'WasteIdentificationListOverviewer',
  computed: {
    grid() {
      return grid
    },
    DocumentType() {
      return DocumentType
    },
  },
  components: {
    UiContent,
    UiHeader,
    Layout,
    UiBreadcrumbs,
    AgGridVue,
    // eslint-disable-next-line
    stateColorRenderer,
    // eslint-disable-next-line
    checkboxRenderer,
  },
  setup: () => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.WASTE_IDENTIFICATION]
    const $t = inject('$t')

    const columnDefs = [
      {
        headerName: $t('waste_identification.list.table.key'),
        field: 'id',
        valueGetter: (params) => {
          return 'DGF-' + params.data.id
        },
      },
      {
        headerName: $t('waste_identification.list.table.date'),
        field: 'transportDate',
        filter: 'agDateColumnFilter',
        filterParams: {
          minValidYear: 2000,
          maxValidYear: 2099,
          filterOptions: ['inRange'],
        },
        valueGetter: (params) => {
          return new Date(params.data.transportDate)
        },
        valueFormatter: (params) => {
          return DateFormatter.formatDate(params.data.transportDate)
        }
      },
      { headerName: $t('waste_identification.list.table.waste_description'), field: 'wasteDescription' },
      {
        headerName: $t('waste_identification.list.table.amount'),
        field: 'amount',
        maxWidth: 200,
        valueFormatter: (params) => {
          if (params.value) {
            return params.value + ' ' + $t('form.table.tonne')
          } else {
            return ''
          }
        }
      },
      { headerName: $t('waste_identification.list.table.from'), field: 'from' },
      { headerName: $t('waste_identification.list.table.to'), field: 'to' },
      {
        headerName: $t('waste_identification.list.table.dangerous'),
        field: 'dangerous',
        maxWidth: 200,
        suppressSizeToFit: true,
        width: 100,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['WASTE_TRANSPORT_DANGEROUS', 'WASTE_TRANSPORT_NON_DANGEROUS'],
          valueFormatter: (val) => {
            return $t('form.type.'+val.value)
          }
        },
        cellRenderer: 'checkboxRenderer',
        cellRendererParams: {
          callback: (data) => {
            return data.dangerous
          }
        }
      },
      {
        headerName: $t('waste_identification.list.table.state'),
        field: 'state',
        cellRenderer: 'stateColorRenderer',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [
            FormState.ON_THE_ROAD,
            FormState.ARRIVED,
            FormState.FINISHED,
            FormState.STOPPED,
          ],
          valueFormatter: (val) => {
            return $t('form.state.'+val.value)
          },
        },
        comparator: grid.caseInsensitiveStateComparator,
      },
    ]

    const gridKey = 'waste-identification-list-overviewer'
    const initialState = ref(session.loadFormState(gridKey))
    const onGridPreDestroyed = (params) => {
      session.saveFormState(gridKey, params.state)
    }
    const onFilterChanged = (event) => {
      session.saveFormState(gridKey, event.api.getState())
    }

    let api = null
    const onGridReady = (params) => {
      api = params.api
      api.deselectAll() // if we can correctly get selection from saved state we can re-evaluate this
      api.sizeColumnsToFit({'defaultMinWidth': 200})
    }
    onBeforeUnmount(() => {
      api = null
    })

    const router = useRouter()
    const rowClick = (event) => {
      if (event.data) {
        router.push(`/waste-identification/view/${event.data.id}`)
      }
    }

    const resetGrid = () => {
      api?.setFilterModel(null)
      api?.onFilterChanged()
      session.saveFormState(gridKey, null)
    }

    return {
      breadcrumbs,
      columnDefs,
      rowClick,
      onGridReady,
      onGridPreDestroyed,
      onFilterChanged,
      initialState,
      resetGrid,
    }
  }
}
</script>

import session from '@/util/session'
import i18n, {languages} from '@/util/i18n'

const $t = () => {
	const user = session.getUser()
	return i18n(user?.language ?? languages.NL)
}

const Annex7WasteType = {
    BASEL_ANNEX_IX: 'BASEL_ANNEX_IX',
	OECD:          'OECD',
  ANNEX_IIIA:      'ANNEX_IIIA',
  ANNEX_IIIB:      'ANNEX_IIIB',
	EC:            'EC',
	NATIONAL_CODE: 'NATIONAL_CODE',
	OTHER:         'OTHER',

	getAll: () => {
		return [
			{
				label:  $t()('form.table.waste_codes.BASEL_ANNEX_IX'),
				value: Annex7WasteType.BASEL_ANNEX_IX,
			},
			{
				label:  $t()('form.table.waste_codes.OECD'),
				value: Annex7WasteType.OECD,
			},
			{
				label:  $t()('form.table.waste_codes.ANNEX_IIIA'),
				value: Annex7WasteType.ANNEX_IIIA,
			},
			{
				label:  $t()('form.table.waste_codes.ANNEX_IIIB'),
				value: Annex7WasteType.ANNEX_IIIB,
			},
			{
				label:  $t()('form.table.waste_codes.EC'),
				value: Annex7WasteType.EC,
			},
			{
				label:  $t()('form.table.waste_codes.NATIONAL_CODE'),
				value: Annex7WasteType.NATIONAL_CODE,
			},
			{
				label:  $t()('form.table.waste_codes.OTHER'),
				value: Annex7WasteType.OTHER,
			},
		]
	},
}

export default Annex7WasteType

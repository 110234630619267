const ContactOrganisationType = {
    PROCESSOR: "PROCESSOR",
    PRODUCER: "PRODUCER",
    ORGANISER: "ORGANISER",
    TRANSPORTER: "TRANSPORTER",
    INDIVIDUAL: "INDIVIDUAL",
    getAll: () => {
        return [
            ContactOrganisationType.PROCESSOR,
            ContactOrganisationType.PRODUCER,
            ContactOrganisationType.ORGANISER,
            ContactOrganisationType.TRANSPORTER,
            ContactOrganisationType.INDIVIDUAL,
        ]
    }
}

export default ContactOrganisationType

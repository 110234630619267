<template>
  <layout>
    <template #header>
      <ui-header :title="link.documentId ? $t('waste_identification.view.title', {id: link.documentId}) : ''" />
    </template>
    <ui-content class="pb-1" :key="language">
      <div class="container-fluid">
        <form id="user-form" class="row g-2 justify-content-center">
          <ui-view-card :title="$t('general.select_language')">
            <template #actions>
              <button :disabled="!language" type="button" class="btn" :class="'btn-outline-primary'" @click="confirmLanguage()" form="user-form"><i class="bi-save" />&nbsp;{{$t('shared.export_PDF')}}</button>
            </template>
            <ui-view-card-language v-model="language" required own-language />
          </ui-view-card>
        </form>
      </div>
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout'
import UiContent from '@/components/ui-content'
import {inject, ref, watch} from 'vue'
import UiHeader from '@/components/ui-header'
import UiViewCard from '@/components/ui-view-card'
import UiViewCardLanguage from '@/components/ui-view-card-language'

export default {
  name: 'SharedLinkControl',
  components: {
    UiViewCardLanguage,
    UiViewCard,
    UiHeader,
    UiContent,
    Layout
  },
  props: {
    link: {
      type:     Object,
      required: true,
    }
  },
  setup: (props) => {
    const $tset = inject('$tset')
    const language = ref(props.link.language ?? '')
    watch(() => language.value, (l) => {
      $tset(l)
    })

    const confirmLanguage = () => {
      const url = `api/sharedlinks/${props.link.id}/pdf?language=${language.value.toUpperCase()}`
      window.open(url, '_blank')
    }

    return {
      language,
      confirmLanguage,
    }
  }
}
</script>

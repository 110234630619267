<template>
  <waste-identification-view-driver :id="id" v-if="roles.hasOnlyRole(roles.DRIVER)" />
  <waste-identification-view-user :id="id" v-else />
</template>

<script>

import WasteIdentificationViewDriver from '@/views/forms/waste-identification/View-Driver'
import WasteIdentificationViewUser from '@/views/forms/waste-identification/View-User'

export default {
  name: 'WasteIdentificationView',
  components: {
    WasteIdentificationViewDriver,
    WasteIdentificationViewUser,
  },
  props: {
    id: {
      type: String,
      required: true,
    }
  },
  setup: () => {
    return {}
  }
}
</script>

<template>
  <ui-modal id="editOrganisationModal" ref="modal" :callback="cb" :on-open="init" size="lg" :confirm-button="'modal.save'">
    <template #title>
      {{ $t('transport_document_templates.waste_identification.edit_organisation_modal.title', {type: $t('organisations.types.' + organisationType)})}}
    </template>
    <template #default>
      <form id="organisation-form">
        <ui-view-card-producer v-if="organisationType === ContactOrganisationType.PRODUCER" :model-value="organisationRef" :shipment-address="true" />
        <ui-view-card-processor v-else-if="organisationType === ContactOrganisationType.PROCESSOR" :model-value="organisationRef" :shipment-address="true" />
        <ui-view-card-organiser v-else-if="organisationType === ContactOrganisationType.ORGANISER" :model-value="organisationRef" />
        <ui-view-card-transporter v-else-if="organisationType === ContactOrganisationType.TRANSPORTER" :model-value="organisationRef" />
      </form>
    </template>
  </ui-modal>
</template>

<script>

import UiModal from '@/components/ui-modal'
import UiViewCardProducer from '@/components/ui-view-card-producer'
import ContactOrganisationType from '@/types/contactOrganisationType'
import {ref} from 'vue'
import UiViewCardProcessor from '@/components/ui-view-card-processor'
import UiViewCardTransporter from '@/components/ui-view-card-transporter'
import UiViewCardOrganiser from '@/components/ui-view-card-organiser.vue'
import OrganisationModel from '@/models/OrganisationModel'

export default {
  name: 'EditOrganisationModal',
  computed: {
    ContactOrganisationType() {
      return ContactOrganisationType
    }
  },
  components: {
    UiViewCardOrganiser,
    UiViewCardTransporter,
    UiViewCardProcessor,
    UiViewCardProducer,
    UiModal
  },
  props: {
    organisation: {
      type:     Object,
      required: true,
    },
    organisationType: {
      type:     String,
      required: true,
    },
    callback: {
      type:     Function,
      required: true,
    }
  },
  setup: (props) => {
    const modal = ref('modal')

    const organisationRef = ref(JSON.parse(JSON.stringify(OrganisationModel.empty)))
    const init = () => {
      organisationRef.value = JSON.parse(JSON.stringify(props.organisation))
    }

    const cb = () => {
      // perform validation on e-mail and VAT
      const formElement = $('#organisation-form')[0]
      if (!formElement.checkValidity()) {
        // something in the form is not valid so don't allow signing
        formElement.reportValidity()
        return false
      }

      return props.callback(organisationRef.value)
    }

    return {
      modal,
      organisationRef,
      cb,
      init,
    }
  }
}
</script>

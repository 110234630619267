<template>
  <annex7-list-user />
</template>

<script>

import Annex7ListUser from '@/views/forms/annex-7/List-User.vue'
import session from '@/util/session'

export default {
  name: 'Annex7List',
  components: {
    Annex7ListUser,
  },
  setup: () => {
    session.setActiveForm('annex-7')
    return {}
  }
}
</script>

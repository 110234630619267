const TransportDocumentTemplateState = {
  DRAFT: "DRAFT",
  SIGNED: "SIGNED",

  getAll: () => {
    return [
      TransportDocumentTemplateState.DRAFT,
      TransportDocumentTemplateState.SIGNED
    ]
  },
}

export default TransportDocumentTemplateState

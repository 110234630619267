<template>
  <shared-link-driver-waste-identification v-if="link.documentType === DocumentType.WASTE_IDENTIFICATION" :link="link" />
  <shared-link-driver-grondbank v-if="link.documentType === DocumentType.GRONDBANK" :link="link" />
</template>

<script>

import SharedLinkDriverWasteIdentification
  from '@/views/shared-links/waste-identification/Shared-Link-Driver-Waste-Identification'
import SharedLinkDriverGrondbank from '@/views/shared-links/grondbank/Shared-Link-Driver-Grondbank'
import DocumentType from '@/types/documentType'

export default {
  name:  'SharedLinkDriver',
  computed: {
    DocumentType() {
      return DocumentType
    }
  },
  components: {
    SharedLinkDriverGrondbank,
    SharedLinkDriverWasteIdentification
  },
  props: {
    link: {
      type:     Object,
      required: true,
    }
  },
  setup: () => {
    return {}
  }
}
</script>

<template>
  <div class="container-fluid">
    <form id="annex-7-form" class="row g-2">
      <ui-view-card :title="$t('annex_7.title.basic_information')" type="col-lg-12">
        <div class="row">
          <div class="col-lg-6">
            <ui-view-card-field :label="$t('annex_7.labels.unique_identifier')"
                                :model-value="itemRef.id ? 'DGF-A7-'+itemRef.id : ''" :readonly="true"/>
            <ui-view-card-field :label="$t('annex_7.labels.external_identifier')" v-model="itemRef.externalId"/>
            <ui-view-card-field :label="$t('annex_7.labels.last_update')" :model-value="updateDate" :readonly="true"/>
            <ui-view-card-field :label="$t('annex_7.labels.updated_by')" :model-value="itemRef.modifiedBy"
                                :readonly="true"/>
          </div>
          <div class="col-lg-6">
            <ui-view-card-field :label="$t('annex_7.labels.status')" :model-value="$t('form.state.'+itemRef.state)"
                                :readonly="true"/>
            <ui-view-card-field data-cy="a7-date" :label="$t('annex_7.labels.transport_date')" :required="true">
              <datepicker v-model="itemRef.transportDate" :enableTimePicker="false" :autoApply="true"
                          :minDate="new Date()" format="dd/MM/yyyy" :required="true"/>
            </ui-view-card-field>
          </div>
        </div>
      </ui-view-card>
      <ui-view-card :title="$t('annex_7.title.producer')">
        <ui-view-card-organisation
            cy="producer"
            v-model="itemRef.producer"
            :fields="[
            OrganisationField.NAME,
            OrganisationField.ADDRESS,
            OrganisationField.CONTACTPERSON,
            OrganisationField.PHONE,
            OrganisationField.FAX,
            OrganisationField.EMAIL,
            OrganisationField.LANGUAGE,
          ]"
            :required-fields="[
            OrganisationField.NAME,
            OrganisationField.ADDRESS,
            OrganisationField.CONTACTPERSON,
            OrganisationField.EMAIL,
            OrganisationField.LANGUAGE,
          ]"
        />
      </ui-view-card>
      <ui-view-card :title="$t('annex_7.title.processor')">
        <ui-view-card-organisation
            cy="processor"
            v-model="itemRef.processor"
            :fields="[
            OrganisationField.NAME,
            OrganisationField.ADDRESS,
            OrganisationField.CONTACTPERSON,
            OrganisationField.PHONE,
            OrganisationField.FAX,
            OrganisationField.EMAIL,
            OrganisationField.LANGUAGE,
          ]"
            :required-fields="[
            OrganisationField.NAME,
            OrganisationField.ADDRESS,
            OrganisationField.CONTACTPERSON,
            OrganisationField.EMAIL,
            OrganisationField.LANGUAGE,
          ]"
        />
      </ui-view-card>
      <ui-view-card :title="$t('annex_7.title.organiser')">
        <ui-view-card-organisation
            cy="organiser"
            v-model="itemRef.organiser"
            :fields="[
            OrganisationField.NAME,
            OrganisationField.ADDRESS,
            OrganisationField.CONTACTPERSON,
            OrganisationField.PHONE,
            OrganisationField.FAX,
            OrganisationField.EMAIL,
            OrganisationField.LANGUAGE,
          ]"
            :required-fields="[
            OrganisationField.NAME,
            OrganisationField.ADDRESS,
            OrganisationField.CONTACTPERSON,
            OrganisationField.EMAIL,
            OrganisationField.LANGUAGE,
          ]"
        />
      </ui-view-card>
      <template v-for="(_, index) in itemRef.transporters" :key="index">
        <ui-view-card
            :title="index === 0 ? $t('annex_7.title.transporter') : $t('annex_7.title.transporter_count', {count: index+1})">
          <template #actions>
            <button data-cy="a7-add-transporter" v-if="index === 0" type="button" class="btn btn-sm btn-outline-primary"
                    @click="addTransporter()"><i class="bi-plus"/>&nbsp;{{ $t('annex_7.table.add_transporter') }}
            </button>
            <button v-else type="button" class="btn btn-sm btn-outline-danger" @click="removeTransporter(index)"><i
                class="bi-minus"/>&nbsp;{{ $t('annex_7.table.remove_transporter') }}
            </button>
          </template>
          <ui-view-card-organisation
              :cy="'transporter-'+index"
              :document-type="DocumentType.ANNEX_7"
              v-model="itemRef.transporters[index]"
              :fields="[
            OrganisationField.NAME,
            OrganisationField.ADDRESS,
            OrganisationField.CONTACTPERSON,
            OrganisationField.PHONE,
            OrganisationField.FAX,
            OrganisationField.EMAIL,
            OrganisationField.LANGUAGE,
            OrganisationField.MEANSOFTRANSPORT,
            OrganisationField.TRANSPORTDATE,
            OrganisationField.SIGNATURE,
          ]"
              :required-fields="[
            OrganisationField.NAME,
            OrganisationField.ADDRESS,
            OrganisationField.CONTACTPERSON,
            OrganisationField.EMAIL,
            OrganisationField.LANGUAGE,
            OrganisationField.MEANSOFTRANSPORT,
            OrganisationField.TRANSPORTDATE,
            OrganisationField.SIGNATURE,
          ]"
          />
        </ui-view-card>
      </template>
      <ui-view-card :title="$t('annex_7.title.recovery_facility')">
        <ui-view-card-organisation
            cy="recovery-facility"
            v-model="itemRef.recoveryFacility"
            :fields="[
            OrganisationField.NAME,
            OrganisationField.ADDRESS,
            OrganisationField.CONTACTPERSON,
            OrganisationField.PHONE,
            OrganisationField.FAX,
            OrganisationField.EMAIL,
            OrganisationField.ANNEX7RECOVERYDESTINATIONTYPE,
          ]"
            :required-fields="[
            OrganisationField.NAME,
            OrganisationField.ADDRESS,
            OrganisationField.CONTACTPERSON,
            OrganisationField.EMAIL,
            OrganisationField.LANGUAGE,
            OrganisationField.ANNEX7RECOVERYDESTINATIONTYPE,
          ]"
        />
      </ui-view-card>
      <ui-view-card :title="$t('annex_7.title.waste_identification')" type="col-lg-6">
        <template #actions>
          <button data-cy="a7-add-identification" type="button" class="btn btn-sm btn-outline-primary"
                  @click="addRowIdentification()"><i class="bi-plus"/>&nbsp;{{ $t('annex_7.table.add_row') }}
          </button>
        </template>
        <template #subtitle>
          <div class="mb-2">
            {{ $t('annex_7.table.waste_codes.fill_in_relevant_codes') }}
          </div>
        </template>
        <table class="table table-bordered table-striped">
          <thead>
          <tr>
            <th style="width: 250px">{{ $t('annex_7.table.type') }}</th>
            <th>{{ $t('annex_7.table.value') }}</th>
            <th style="width: 60px"/>
          </tr>
          </thead>
          <tbody>
          <template v-for="(row, index) in itemRef.wasteIdentifications" :key="index">
            <tr>
              <td>
                <v-select :options="Annex7WasteType.getAll()" v-model="row.type" :reduce="type => type.value">
                  <template #search="{attributes, events}">
                    <input
                        :data-cy="'a7-waste-type-'+index"
                        class="vs__search"
                        :required="!row.type"
                        v-bind="attributes"
                        v-on="events"
                    />
                  </template>
                  <template #no-options>
                    {{ $t('general.no_options') }}
                  </template>
                </v-select>
              </td>
              <td>
                <input :data-cy="'a7-waste-value-'+index" class="form-control" v-model="row.value" required/>
              </td>
              <td>
                <div :data-cy="'a7-waste-remove-'+index" class="btn btn-danger" @click="deleteRowIdentification(index)">
                  <i class="bi-trash"/>
                </div>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
        <ui-view-card-field data-cy="a7-waste-desc" :label="$t('annex_7.labels.waste_description')"
                            v-model="itemRef.description" :required="true" :maxlength="250"/>
        <div class="row">
          <div class="col-lg-6">
            <ui-view-card-number data-cy="a7-waste-amount-ton" :label="$t('annex_7.labels.waste_amount_ton')"
                                 v-model="itemRef.amountTon" :maxlength="5"/>
          </div>
          <div class="col-lg-6">
            <ui-view-card-number data-cy="a7-waste-amount-m3" :label="$t('annex_7.labels.waste_amount_m3')"
                                 v-model="itemRef.amountM3" :maxlength="5"/>
          </div>
        </div>
        <ui-view-card-field data-cy="a7-removal-operation"
                            :label="$t('waste_identification_template.form.removal_operation')"
                            :required="true">
          <v-select :key="removalOperationsLoaded" :options="removalOperations" v-model="itemRef.removalOperation"
                    :reduce="operation => operation.value">
            <template #search="{attributes, events}">
              <input
                  class="vs__search"
                  :required="!itemRef.removalOperation"
                  v-bind="attributes"
                  v-on="events"
              />
            </template>
            <template #no-options>
              {{ $t('general.no_options') }}
            </template>
          </v-select>
        </ui-view-card-field>
      </ui-view-card>
      <ui-view-card :title="$t('annex_7.title.countries')">
        <template #actions>
          <button data-cy="a7-add-country" type="button" class="btn btn-sm btn-outline-primary"
                  @click="addTransitCountry()"><i class="bi-plus"/>&nbsp;{{ $t('annex_7.table.add_transit_country') }}
          </button>
        </template>
        <table class="table table-bordered table-striped">
          <thead style="height: 48px">
          <tr>
            <th>{{ $t('general.export').toUpperCase() }}<span :title="$t('general.required')"
                                                              class="text-danger">*</span></th>
            <th v-for="(country, index) in transitCountries" :key="country.id">
              <div class="d-flex justify-content-between align-items-end">
                <span>
                  {{ $t('general.transit').toUpperCase() }}<span :title="$t('general.required')"
                                                                 class="text-danger">*</span>
                </span>
                <div data-cy="a7-remove-country" class="btn btn-sm btn-danger" @click="removeTransitCountry(index)">
                  <i class="bi-trash"/>
                </div>
              </div>
            </th>
            <th>{{ $t('general.destination').toUpperCase() }}<span :title="$t('general.required')"
                                                                   class="text-danger">*</span></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <country-selector data-cy="a7-export" v-model="exportCountry.value" required/>
            </td>
            <td v-for="(country, index) in transitCountries" :key="country.id">
              <country-selector :data-cy="'a7-transit-'+index" v-model="country.value" required/>
            </td>
            <td>
              <country-selector data-cy="a7-destination" v-model="destinationCountry.value" required/>
            </td>
          </tr>
          </tbody>
        </table>
      </ui-view-card>
      <ui-view-card :title="$t('annex_7.title.notes')">
        <textarea data-cy="a7-notes" v-model="itemRef.notes" class="form-control" maxlength="1000" rows="4"
                  style="width: 100%"/>
      </ui-view-card>
    </form>
  </div>
</template>

<script>

import {computed, inject, ref, watch} from 'vue'
import UiViewCard from '@/components/ui-view-card'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import UiViewCardField from '@/components/ui-view-card-field'
import {DateFormatter} from '@/services/dateFormatter'
import RemovalOperations from '@/models/RemovalOperations'
import OrganisationModel from '@/models/OrganisationModel'
import UiViewCardOrganisation from '@/components/ui-view-card-organisation.vue'
import OrganisationField from '@/types/organisationField'
import Annex7WasteType from '@/types/annex7WasteType'
import CountryType from '@/types/countryType'
import CountrySelector from '@/components/CountrySelector.vue'
import DocumentType from '@/types/documentType'
import UiViewCardNumber from '@/components/ui-view-card-number.vue'

export default {
  name:       'Annex7Form',
  computed:   {
    DocumentType() {
      return DocumentType
    },
    Annex7WasteType() {
      return Annex7WasteType
    },
    OrganisationField() {
      return OrganisationField
    },
  },
  components: {
    UiViewCardNumber,
    CountrySelector,
    UiViewCardOrganisation,
    UiViewCardField,
    Datepicker,
    UiViewCard,
  },
  props:      {
    item: {
      type:     Object,
      required: true,
    },
  },
  setup:      (props) => {
    const $t = inject('$t')

    let itemRef = ref(props.item)
    if (itemRef.value.producer === null) {
      itemRef.value.producer = JSON.parse(JSON.stringify(OrganisationModel.empty))
    }
    if (itemRef.value.processor === null) {
      itemRef.value.processor = JSON.parse(JSON.stringify(OrganisationModel.empty))
    }
    if (itemRef.value.organiser === null) {
      itemRef.value.organiser = JSON.parse(JSON.stringify(OrganisationModel.empty))
    }
    if (itemRef.value.transporters.length === 0) {
      itemRef.value.transporters.push(JSON.parse(JSON.stringify(OrganisationModel.empty)))
    }
    if (itemRef.value.countriesConcerned.length === 0) {
      itemRef.value.countriesConcerned.push({value: '', type: CountryType.EXPORT})
      itemRef.value.countriesConcerned.push({value: '', type: CountryType.DESTINATION})
    }
    const state = $t('form.state.' + itemRef.value.state)

    watch(() => itemRef.value.producer.address.country, (val) => {
      if (val) {
        const exportCountry = itemRef.value.countriesConcerned.find((c) => {
          return c.type === CountryType.EXPORT
        })
        if (!exportCountry.value) {
          exportCountry.value = val
        }
      }
    })

    watch(() => itemRef.value.processor.address.country, (val) => {
      if (val) {
        const destinationCountry = itemRef.value.countriesConcerned.find((c) => {
          return c.type === CountryType.DESTINATION
        })
        if (!destinationCountry.value) {
          destinationCountry.value = val
        }
      }
    })

    watch(() => itemRef.value.transportDate, (val) => {
      if (val) {
        if (!itemRef.value.transporters[0].transportDate) {
          itemRef.value.transporters[0].transportDate = val
        }
      }
    })

    const addRowIdentification = () => {
      itemRef.value.wasteIdentifications.push({})
    }

    const deleteRowIdentification = (index) => {
      itemRef.value.wasteIdentifications.splice(index, 1)
    }

    const removalOperations       = ref([])
    const removalOperationsLoaded = ref(false)
    const getRemovalOperations    = async () => {
      removalOperations.value       = await RemovalOperations.get()
      removalOperationsLoaded.value = true
    }
    getRemovalOperations()

    const addTransporter = () => {
      itemRef.value.transporters.push({})
    }

    const removeTransporter = (index) => {
      if (index !== 0) {
        itemRef.value.transporters.splice(index, 1)
      }
    }

    const updateDate = computed(() => {
      return DateFormatter.formatDateTime(itemRef.value.lastUpdate)
    })

    const exportCountry = computed(() => {
      return itemRef.value.countriesConcerned.filter((country) => {
        return country.type === CountryType.EXPORT
      })[0]
    })

    const destinationCountry = computed(() => {
      return itemRef.value.countriesConcerned.filter((country) => {
        return country.type === CountryType.DESTINATION
      })[0]
    })

    const transitCountries = computed(() => {
      return itemRef.value.countriesConcerned.filter((country) => {
        return country.type === CountryType.TRANSIT
      })
    })

    const addTransitCountry = () => {
      itemRef.value.countriesConcerned.splice(itemRef.value.countriesConcerned.length, 0, {
        value: null,
        type:  CountryType.TRANSIT,
      })
    }

    const removeTransitCountry = (index) => {
      itemRef.value.countriesConcerned.splice(index + 2, 1)
    }

    return {
      itemRef,
      state,
      removalOperations,
      removalOperationsLoaded,
      addRowIdentification,
      deleteRowIdentification,
      addTransporter,
      removeTransporter,
      updateDate,
      exportCountry,
      destinationCountry,
      transitCountries,
      addTransitCountry,
      removeTransitCountry,
    }
  },
}
</script>

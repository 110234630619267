<template>
  <ui-modal id="confirmModal" ref="modal" :callback="cb" >
    <template #title>
      {{ $t('forms.confirm_modal.title') }}
    </template>
    <template #default>
      <div class="pb-2">
        {{ $t('forms.confirm_modal.confirm_form') }}
      </div>
    </template>
  </ui-modal>
</template>

<script>

import {ref} from 'vue'
import UiModal from '@/components/ui-modal'

export default {
  name: 'FormConfirmModal',
  components: {
    UiModal
  },
  props: {
    callback: {
      type:     Function,
      required: true,
    }
  },
  setup: (props) => {
    const modal = ref('modal')

    const cb = () => {
      props.callback()
    }

    return {
      modal,
      cb,
    }
  }
}
</script>

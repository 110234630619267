<template>
  <layout>
    <template #header>
      <ui-header :title="$t('my_organisation.linked_organisations.title')" />
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs" />
    <ui-content class="pb-1">
      <template v-if="!$isMobile">
        <div class="p-1 m-1 h-50">
          <h4>{{$t('my_organisation.linked_organisations.active_links')}}</h4>
          <div class="h-75">
            <ag-grid-vue
                :columnDefs="linksColumnDefs"
                :grid-options="grid.defaultOptions"
                :initialState="linksInitialState"
                :serverSideDatasource="grid.datasource('api/linkedOrganisations/list')"
                class="ag-theme-quartz"
                style="height:100%"
                @grid-ready="linksOnGridReady"
                @grid-pre-destroyed="linksOnGridPreDestroyed"
                @filter-changed="linksOnFilterChanged"
            />
          </div>
        </div>
        <div class="p-1 m-1 h-50">
          <h4>{{$t('my_organisation.linked_organisations.invites')}}</h4>
          <div class="h-75">
            <ag-grid-vue
                :columnDefs="invitesColumnDefs"
                :grid-options="grid.defaultOptions"
                :initialState="invitesInitialState"
                :serverSideDatasource="grid.datasource('api/linkRequests/list')"
                class="ag-theme-quartz"
                style="height:100%"
                @grid-ready="invitesOnGridReady"
                @grid-pre-destroyed="invitesOnGridPreDestroyed"
                @filter-changed="invitesOnFilterChanged"
            />
          </div>
        </div>
      </template>
      <template v-else>
        Mobile view currently not supported.
      </template>
    </ui-content>
  </layout>
</template>

<script>
import Layout from '@/components/layout.vue'
import UiBreadcrumbs from '@/components/ui-breadcrumbs.vue'
import UiHeader from '@/components/ui-header.vue'
import UiContent from '@/components/ui-content.vue'
import BREADCRUMBS from '@/util/breadcrumbs'
import {AgGridVue} from 'ag-grid-vue3'
import grid from '@/util/grid'
import {inject, onBeforeUnmount, ref} from 'vue'
import session from '@/util/session'

export default {
  name:       'MyOrganisationLinkedOrganisations',
  computed:   {
    grid() {
      return grid
    }
  },
  components: {
    AgGridVue,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout
  },
  setup:      () => {
    const breadcrumbs = [BREADCRUMBS.MY_ORGANISATION_LINKED_ORGANISATIONS]
    const $t = inject('$t')

    const linksGridKey = 'linked-organisations-links'
    const linksInitialState = ref(session.loadFormState(linksGridKey))
    const linksOnGridPreDestroyed = (params) => {
      session.saveFormState(linksGridKey, params.state)
    }
    const linksOnFilterChanged = (event) => {
      session.saveFormState(linksGridKey, event.api.getState())
    }

    let linksApi = null
    const linksOnGridReady = (params) => {
      linksApi = params.api
      linksApi.deselectAll() // if we can correctly get selection from saved state we can re-evaluate this
      linksApi.sizeColumnsToFit({'defaultMinWidth': 200})
    }
    onBeforeUnmount(() => {
      linksApi = null
    })

    const linksColumnDefs = [
      {
        headerName: $t('my_organisation.linked_organisations.list.table.key'),
        field: 'id',
      },
    ]

    const invitesGridKey = 'linked-organisations-invites'
    const invitesInitialState = ref(session.loadFormState(invitesGridKey))
    const invitesOnGridPreDestroyed = (params) => {
      session.saveFormState(invitesGridKey, params.state)
    }
    const invitesOnFilterChanged = (event) => {
      session.saveFormState(invitesGridKey, event.api.getState())
    }

    let invitesApi = null
    const invitesOnGridReady = (params) => {
      invitesApi = params.api
      invitesApi.deselectAll() // if we can correctly get selection from saved state we can re-evaluate this
      invitesApi.sizeColumnsToFit({'defaultMinWidth': 200})
    }
    onBeforeUnmount(() => {
      invitesApi = null
    })

    const invitesColumnDefs = [
      {
        headerName: $t('my_organisation.linked_organisations.list.table.key'),
        field: 'id',
      },
    ]

    return {
      breadcrumbs,
      linksInitialState,
      linksOnFilterChanged,
      linksOnGridPreDestroyed,
      linksOnGridReady,
      linksColumnDefs,
      invitesInitialState,
      invitesOnFilterChanged,
      invitesOnGridPreDestroyed,
      invitesOnGridReady,
      invitesColumnDefs,
    }
  }
}
</script>


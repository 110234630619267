<template>
  <layout>
    <template #header>
      <ui-header
        title="Forms"
      />
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs" />
    <div id="forms">
      <ul>
        <li>
          <router-link :to="{ name: 'WasteList'}">Waste identification</router-link>
        </li>
      </ul>
    </div>
  </layout>
</template>

<script>

import Layout from '@/components/layout'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import UiHeader from '@/components/ui-header'
import {useRouter} from 'vue-router'
import session from '@/util/session'

export default {
  name: 'Home',
  components: {
    UiHeader,
    Layout,
    UiBreadcrumbs,
  },
  setup: () => {
    const breadcrumbs = [BREADCRUMBS.HOME]

    const key = 'redirectUrl'
    const url = session.getSessionStorage(key)
    session.setSessionStorage(key, null)
    if (url && url.indexOf('logout') === -1) {
      window.location = url
    } else {
      const router = useRouter()
      const type = session.getActiveForm() ?? 'waste-identification'
      router.push(`/${type}/list`)
    }

    return {
      breadcrumbs
    }
  }
}
</script>

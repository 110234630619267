const DriverModel = {
    empty: {
        id: null,
        language: 'NL',
        firstName: '',
        lastName: '',
        email: '',
        organisation: '',
    }
}

export default DriverModel

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-quartz.css"
import "ag-grid-enterprise"
import {LicenseManager} from "ag-grid-enterprise"
import {createApp, ref} from 'vue/dist/vue.esm-bundler'
import App from './App'
import router from './router/router.js'
import backend from '@/util/backend'
import "@/../styles/main.css"
import vSelect from 'vue-select'
import Popper from 'vue3-popper'
import 'vue-select/dist/vue-select.css'
import i18n, {languages} from '@/util/i18n'
import session from '@/util/session'
import roles from '@/util/roles'
import flag from '@/util/flag'

const app = createApp(App)
app.component('v-select', vSelect)
app.component('Popper', Popper)

app.use(router)

app.mount('#app')

backend.initBackend(router)

LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-052635}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{DigiForm}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{DigiForm}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{DigiForm}_need_to_be_licensed___{DigiForm}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{28_December_2024}____[v3]_[01]_MTczNTM0NDAwMDAwMA==3fb8b23bf9aaa39531673414ec715484")

const getTranslate = (l) => {
    const user = session.getUser()
    if (!user) {
        app.config.globalProperties.rerender = true
    }
    return i18n(l ?? user?.language ?? languages.NL)
}

Object.defineProperty(app.config.globalProperties, '$t', {
    language: languages.NL,
    get: function () {
        return getTranslate(this.language)
    },
    set: function (l) {
        this.language = l
    }
})

Object.defineProperty(app.config.globalProperties, 'roles', {
    get: () => {
        return roles
    }
})
Object.defineProperty(app.config.globalProperties, '$roles', {
    get: () => {
        return roles.hasOneOfRoles
    }
})

Object.defineProperty(app.config.globalProperties, '$orgType', {
    get: () => {
        return isOrgType
    }
})

let isMobile = ref(window.innerWidth < 992)
window.addEventListener('resize', () => {
    isMobile.value = window.innerWidth < 992
})
Object.defineProperty(app.config.globalProperties, '$isMobile', {
    get: () => {
        return isMobile.value
    }
})

const isOrgType = (orgType) => {
    const org = session.getOrganisation()
    return org?.organisationType === orgType ?? false
}
app.provide('$orgType', (type) => isOrgType(type))

app.provide('$t', getTranslate())
app.provide('$tget', (l) => getTranslate(l))
app.provide('$tset', (l) => {
    app.config.globalProperties.$t = l
    app.provide('$t', getTranslate(l))
})

app.directive('click-outside', {
    beforeMount(el, binding) {
        el.clickOutsideEvent = function (event) {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event, el)
            }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unmounted(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    }
})

Object.defineProperty(app.config.globalProperties, '$flag', {
    get: () => {
        return flag
    }
})
app.provide('$flag', (f) => flag(f))

const isSafariBrowser = () => navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1
app.provide('$isSafari', () => isSafariBrowser())

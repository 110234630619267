<template>
  <grondbank-list-driver v-if="roles.hasOnlyRole(roles.DRIVER)" />
  <grondbank-list-user v-else />
</template>

<script>
import roles from '@/util/roles'
import GrondbankListUser from '@/views/forms/grondbank/List-User.vue'
import GrondbankListDriver from '@/views/forms/grondbank/List-Driver.vue'
import session from '@/util/session'

export default {
  name: 'GrondbankList',
  computed: {
    roles() {
      return roles
    }
  },
  components: {
    GrondbankListDriver,
    GrondbankListUser,
  },
  setup: () => {
    session.setActiveForm('grondbank')
    return {}
  }
}
</script>

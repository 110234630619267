import session from '@/util/session'
import i18n, {languages} from '@/util/i18n'

const getTranslate = () => {
  const user = session.getUser()
  return i18n(user?.language ?? languages.NL)
}

const CountryCodes = {
  top: [
    {'code': "BE", 'label': getTranslate()('countries.BE')},
    {'code': "NL", 'label': getTranslate()('countries.NL')},
    {'code': "FR", 'label': getTranslate()('countries.FR')},
    {'code': "DE", 'label': getTranslate()('countries.DE')},
    {'code': "LU", 'label': getTranslate()('countries.LU')},
  ],
  rest: [
    {'code': "AF", 'label': getTranslate()('countries.AF')},
    {'code': "AX", 'label': getTranslate()('countries.AX')},
    {'code': "AL", 'label': getTranslate()('countries.AL')},
    {'code': "DZ", 'label': getTranslate()('countries.DZ')},
    {'code': "AS", 'label': getTranslate()('countries.AS')},
    {'code': "AD", 'label': getTranslate()('countries.AD')},
    {'code': "AO", 'label': getTranslate()('countries.AO')},
    {'code': "AI", 'label': getTranslate()('countries.AI')},
    {'code': "AQ", 'label': getTranslate()('countries.AQ')},
    {'code': "AG", 'label': getTranslate()('countries.AG')},
    {'code': "AR", 'label': getTranslate()('countries.AR')},
    {'code': "AM", 'label': getTranslate()('countries.AM')},
    {'code': "AW", 'label': getTranslate()('countries.AW')},
    {'code': "AU", 'label': getTranslate()('countries.AU')},
    {'code': "AT", 'label': getTranslate()('countries.AT')},
    {'code': "AZ", 'label': getTranslate()('countries.AZ')},
    {'code': "BS", 'label': getTranslate()('countries.BS')},
    {'code': "BH", 'label': getTranslate()('countries.BH')},
    {'code': "BD", 'label': getTranslate()('countries.BD')},
    {'code': "BB", 'label': getTranslate()('countries.BB')},
    {'code': "BY", 'label': getTranslate()('countries.BY')},
    {'code': "BZ", 'label': getTranslate()('countries.BZ')},
    {'code': "BJ", 'label': getTranslate()('countries.BJ')},
    {'code': "BM", 'label': getTranslate()('countries.BM')},
    {'code': "BT", 'label': getTranslate()('countries.BT')},
    {'code': "BO", 'label': getTranslate()('countries.BO')},
    {'code': "BA", 'label': getTranslate()('countries.BA')},
    {'code': "BW", 'label': getTranslate()('countries.BW')},
    {'code': "BV", 'label': getTranslate()('countries.BV')},
    {'code': "BR", 'label': getTranslate()('countries.BR')},
    {'code': "IO", 'label': getTranslate()('countries.IO')},
    {'code': "BN", 'label': getTranslate()('countries.BN')},
    {'code': "BG", 'label': getTranslate()('countries.BG')},
    {'code': "BF", 'label': getTranslate()('countries.BF')},
    {'code': "BI", 'label': getTranslate()('countries.BI')},
    {'code': "KH", 'label': getTranslate()('countries.KH')},
    {'code': "CM", 'label': getTranslate()('countries.CM')},
    {'code': "CA", 'label': getTranslate()('countries.CA')},
    {'code': "CV", 'label': getTranslate()('countries.CV')},
    {'code': "KY", 'label': getTranslate()('countries.KY')},
    {'code': "CF", 'label': getTranslate()('countries.CF')},
    {'code': "TD", 'label': getTranslate()('countries.TD')},
    {'code': "CL", 'label': getTranslate()('countries.CL')},
    {'code': "CN", 'label': getTranslate()('countries.CN')},
    {'code': "CX", 'label': getTranslate()('countries.CX')},
    {'code': "CC", 'label': getTranslate()('countries.CC')},
    {'code': "CO", 'label': getTranslate()('countries.CO')},
    {'code': "KM", 'label': getTranslate()('countries.KM')},
    {'code': "CG", 'label': getTranslate()('countries.CG')},
    {'code': "CD", 'label': getTranslate()('countries.CD')},
    {'code': "CK", 'label': getTranslate()('countries.CK')},
    {'code': "CR", 'label': getTranslate()('countries.CR')},
    {'code': "CI", 'label': getTranslate()('countries.CI')},
    {'code': "HR", 'label': getTranslate()('countries.HR')},
    {'code': "CU", 'label': getTranslate()('countries.CU')},
    {'code': "CY", 'label': getTranslate()('countries.CY')},
    {'code': "CZ", 'label': getTranslate()('countries.CZ')},
    {'code': "DK", 'label': getTranslate()('countries.DK')},
    {'code': "DJ", 'label': getTranslate()('countries.DJ')},
    {'code': "DM", 'label': getTranslate()('countries.DM')},
    {'code': "DO", 'label': getTranslate()('countries.DO')},
    {'code': "EC", 'label': getTranslate()('countries.EC')},
    {'code': "EG", 'label': getTranslate()('countries.EG')},
    {'code': "SV", 'label': getTranslate()('countries.SV')},
    {'code': "GQ", 'label': getTranslate()('countries.GQ')},
    {'code': "ER", 'label': getTranslate()('countries.ER')},
    {'code': "EE", 'label': getTranslate()('countries.EE')},
    {'code': "ET", 'label': getTranslate()('countries.ET')},
    {'code': "FK", 'label': getTranslate()('countries.FK')},
    {'code': "FO", 'label': getTranslate()('countries.FO')},
    {'code': "FJ", 'label': getTranslate()('countries.FJ')},
    {'code': "FI", 'label': getTranslate()('countries.FI')},
    {'code': "GF", 'label': getTranslate()('countries.GF')},
    {'code': "PF", 'label': getTranslate()('countries.PF')},
    {'code': "TF", 'label': getTranslate()('countries.TF')},
    {'code': "GA", 'label': getTranslate()('countries.GA')},
    {'code': "GM", 'label': getTranslate()('countries.GM')},
    {'code': "GE", 'label': getTranslate()('countries.GE')},
    {'code': "GH", 'label': getTranslate()('countries.GH')},
    {'code': "GI", 'label': getTranslate()('countries.GI')},
    {'code': "GR", 'label': getTranslate()('countries.GR')},
    {'code': "GL", 'label': getTranslate()('countries.GL')},
    {'code': "GD", 'label': getTranslate()('countries.GD')},
    {'code': "GP", 'label': getTranslate()('countries.GP')},
    {'code': "GU", 'label': getTranslate()('countries.GU')},
    {'code': "GT", 'label': getTranslate()('countries.GT')},
    {'code': "GG", 'label': getTranslate()('countries.GG')},
    {'code': "GN", 'label': getTranslate()('countries.GN')},
    {'code': "GW", 'label': getTranslate()('countries.GW')},
    {'code': "GY", 'label': getTranslate()('countries.GY')},
    {'code': "HT", 'label': getTranslate()('countries.HT')},
    {'code': "HM", 'label': getTranslate()('countries.HM')},
    {'code': "VA", 'label': getTranslate()('countries.VA')},
    {'code': "HN", 'label': getTranslate()('countries.HN')},
    {'code': "HK", 'label': getTranslate()('countries.HK')},
    {'code': "HU", 'label': getTranslate()('countries.HU')},
    {'code': "IS", 'label': getTranslate()('countries.IS')},
    {'code': "IN", 'label': getTranslate()('countries.IN')},
    {'code': "ID", 'label': getTranslate()('countries.ID')},
    {'code': "IR", 'label': getTranslate()('countries.IR')},
    {'code': "IQ", 'label': getTranslate()('countries.IQ')},
    {'code': "IE", 'label': getTranslate()('countries.IE')},
    {'code': "IM", 'label': getTranslate()('countries.IM')},
    {'code': "IL", 'label': getTranslate()('countries.IL')},
    {'code': "IT", 'label': getTranslate()('countries.IT')},
    {'code': "JM", 'label': getTranslate()('countries.JM')},
    {'code': "JP", 'label': getTranslate()('countries.JP')},
    {'code': "JE", 'label': getTranslate()('countries.JE')},
    {'code': "JO", 'label': getTranslate()('countries.JO')},
    {'code': "KZ", 'label': getTranslate()('countries.KZ')},
    {'code': "KE", 'label': getTranslate()('countries.KE')},
    {'code': "KI", 'label': getTranslate()('countries.KI')},
    {'code': "KR", 'label': getTranslate()('countries.KR')},
    {'code': "KP", 'label': getTranslate()('countries.KP')},
    {'code': "KW", 'label': getTranslate()('countries.KW')},
    {'code': "KG", 'label': getTranslate()('countries.KG')},
    {'code': "LA", 'label': getTranslate()('countries.LA')},
    {'code': "LV", 'label': getTranslate()('countries.LV')},
    {'code': "LB", 'label': getTranslate()('countries.LB')},
    {'code': "LS", 'label': getTranslate()('countries.LS')},
    {'code': "LR", 'label': getTranslate()('countries.LR')},
    {'code': "LY", 'label': getTranslate()('countries.LY')},
    {'code': "LI", 'label': getTranslate()('countries.LI')},
    {'code': "LT", 'label': getTranslate()('countries.LT')},
    {'code': "MO", 'label': getTranslate()('countries.MO')},
    {'code': "MK", 'label': getTranslate()('countries.MK')},
    {'code': "MG", 'label': getTranslate()('countries.MG')},
    {'code': "MW", 'label': getTranslate()('countries.MW')},
    {'code': "MY", 'label': getTranslate()('countries.MY')},
    {'code': "MV", 'label': getTranslate()('countries.MV')},
    {'code': "ML", 'label': getTranslate()('countries.ML')},
    {'code': "MT", 'label': getTranslate()('countries.MT')},
    {'code': "MH", 'label': getTranslate()('countries.MH')},
    {'code': "MQ", 'label': getTranslate()('countries.MQ')},
    {'code': "MR", 'label': getTranslate()('countries.MR')},
    {'code': "MU", 'label': getTranslate()('countries.MU')},
    {'code': "YT", 'label': getTranslate()('countries.YT')},
    {'code': "MX", 'label': getTranslate()('countries.MX')},
    {'code': "FM", 'label': getTranslate()('countries.FM')},
    {'code': "MD", 'label': getTranslate()('countries.MD')},
    {'code': "MC", 'label': getTranslate()('countries.MC')},
    {'code': "MN", 'label': getTranslate()('countries.MN')},
    {'code': "ME", 'label': getTranslate()('countries.ME')},
    {'code': "MS", 'label': getTranslate()('countries.MS')},
    {'code': "MA", 'label': getTranslate()('countries.MA')},
    {'code': "MZ", 'label': getTranslate()('countries.MZ')},
    {'code': "MM", 'label': getTranslate()('countries.MM')},
    {'code': "NA", 'label': getTranslate()('countries.NA')},
    {'code': "NR", 'label': getTranslate()('countries.NR')},
    {'code': "NP", 'label': getTranslate()('countries.NP')},
    {'code': "AN", 'label': getTranslate()('countries.AN')},
    {'code': "NC", 'label': getTranslate()('countries.NC')},
    {'code': "NZ", 'label': getTranslate()('countries.NZ')},
    {'code': "NI", 'label': getTranslate()('countries.NI')},
    {'code': "NE", 'label': getTranslate()('countries.NE')},
    {'code': "NG", 'label': getTranslate()('countries.NG')},
    {'code': "NU", 'label': getTranslate()('countries.NU')},
    {'code': "NF", 'label': getTranslate()('countries.NF')},
    {'code': "MP", 'label': getTranslate()('countries.MP')},
    {'code': "NO", 'label': getTranslate()('countries.NO')},
    {'code': "OM", 'label': getTranslate()('countries.OM')},
    {'code': "PK", 'label': getTranslate()('countries.PK')},
    {'code': "PW", 'label': getTranslate()('countries.PW')},
    {'code': "PS", 'label': getTranslate()('countries.PS')},
    {'code': "PA", 'label': getTranslate()('countries.PA')},
    {'code': "PG", 'label': getTranslate()('countries.PG')},
    {'code': "PY", 'label': getTranslate()('countries.PY')},
    {'code': "PE", 'label': getTranslate()('countries.PE')},
    {'code': "PH", 'label': getTranslate()('countries.PH')},
    {'code': "PN", 'label': getTranslate()('countries.PN')},
    {'code': "PL", 'label': getTranslate()('countries.PL')},
    {'code': "PT", 'label': getTranslate()('countries.PT')},
    {'code': "PR", 'label': getTranslate()('countries.PR')},
    {'code': "QA", 'label': getTranslate()('countries.QA')},
    {'code': "RE", 'label': getTranslate()('countries.RE')},
    {'code': "RO", 'label': getTranslate()('countries.RO')},
    {'code': "RU", 'label': getTranslate()('countries.RU')},
    {'code': "RW", 'label': getTranslate()('countries.RW')},
    {'code': "BL", 'label': getTranslate()('countries.BL')},
    {'code': "SH", 'label': getTranslate()('countries.SH')},
    {'code': "KN", 'label': getTranslate()('countries.KN')},
    {'code': "LC", 'label': getTranslate()('countries.LC')},
    {'code': "MF", 'label': getTranslate()('countries.MF')},
    {'code': "PM", 'label': getTranslate()('countries.PM')},
    {'code': "VC", 'label': getTranslate()('countries.VC')},
    {'code': "WS", 'label': getTranslate()('countries.WS')},
    {'code': "SM", 'label': getTranslate()('countries.SM')},
    {'code': "ST", 'label': getTranslate()('countries.ST')},
    {'code': "SA", 'label': getTranslate()('countries.SA')},
    {'code': "SN", 'label': getTranslate()('countries.SN')},
    {'code': "RS", 'label': getTranslate()('countries.RS')},
    {'code': "SC", 'label': getTranslate()('countries.SC')},
    {'code': "SL", 'label': getTranslate()('countries.SL')},
    {'code': "SG", 'label': getTranslate()('countries.SG')},
    {'code': "SK", 'label': getTranslate()('countries.SK')},
    {'code': "SI", 'label': getTranslate()('countries.SI')},
    {'code': "SB", 'label': getTranslate()('countries.SB')},
    {'code': "SO", 'label': getTranslate()('countries.SO')},
    {'code': "ZA", 'label': getTranslate()('countries.ZA')},
    {'code': "GS", 'label': getTranslate()('countries.GS')},
    {'code': "ES", 'label': getTranslate()('countries.ES')},
    {'code': "LK", 'label': getTranslate()('countries.LK')},
    {'code': "SD", 'label': getTranslate()('countries.SD')},
    {'code': "SR", 'label': getTranslate()('countries.SR')},
    {'code': "SJ", 'label': getTranslate()('countries.SJ')},
    {'code': "SZ", 'label': getTranslate()('countries.SZ')},
    {'code': "SE", 'label': getTranslate()('countries.SE')},
    {'code': "CH", 'label': getTranslate()('countries.CH')},
    {'code': "SY", 'label': getTranslate()('countries.SY')},
    {'code': "TW", 'label': getTranslate()('countries.TW')},
    {'code': "TJ", 'label': getTranslate()('countries.TJ')},
    {'code': "TZ", 'label': getTranslate()('countries.TZ')},
    {'code': "TH", 'label': getTranslate()('countries.TH')},
    {'code': "TL", 'label': getTranslate()('countries.TL')},
    {'code': "TG", 'label': getTranslate()('countries.TG')},
    {'code': "TK", 'label': getTranslate()('countries.TK')},
    {'code': "TO", 'label': getTranslate()('countries.TO')},
    {'code': "TT", 'label': getTranslate()('countries.TT')},
    {'code': "TN", 'label': getTranslate()('countries.TN')},
    {'code': "TR", 'label': getTranslate()('countries.TR')},
    {'code': "TM", 'label': getTranslate()('countries.TM')},
    {'code': "TC", 'label': getTranslate()('countries.TC')},
    {'code': "TV", 'label': getTranslate()('countries.TV')},
    {'code': "UG", 'label': getTranslate()('countries.UG')},
    {'code': "UA", 'label': getTranslate()('countries.UA')},
    {'code': "AE", 'label': getTranslate()('countries.AE')},
    {'code': "GB", 'label': getTranslate()('countries.GB')},
    {'code': "US", 'label': getTranslate()('countries.US')},
    {'code': "UM", 'label': getTranslate()('countries.UM')},
    {'code': "UY", 'label': getTranslate()('countries.UY')},
    {'code': "UZ", 'label': getTranslate()('countries.UZ')},
    {'code': "VU", 'label': getTranslate()('countries.VU')},
    {'code': "VE", 'label': getTranslate()('countries.VE')},
    {'code': "VN", 'label': getTranslate()('countries.VN')},
    {'code': "VG", 'label': getTranslate()('countries.VG')},
    {'code': "VI", 'label': getTranslate()('countries.VI')},
    {'code': "WF", 'label': getTranslate()('countries.WF')},
    {'code': "EH", 'label': getTranslate()('countries.EH')},
    {'code': "YE", 'label': getTranslate()('countries.YE')},
    {'code': "ZM", 'label': getTranslate()('countries.ZM')},
    {'code': "ZW", 'label': getTranslate()('countries.ZW')},
  ]
}

export default CountryCodes

<template>
  <layout>
    <template #header>
      <ui-header :title="organisation.name ? organisation.name : $t('sys_admin.org_details.title')"
                 back-route="/admin/organisations/list">
        <template #actions>
          <div class="btn-group">
            <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="actionsDropdown"
                    data-bs-toggle="dropdown">
              {{ $t('general.actions') }}
            </button>
            <ul data-cy="ecmr-actions" class="dropdown-menu" aria-labelledby="actionsDropdown">
              <li>
                <button class="dropdown-item" @click="verifyMatis()">
                  {{ $t('sys_admin.org_details.actions.verify_matis') }}
                </button>
              </li>
              <li>
                <button class="dropdown-item" @click="showAddApiKey()">
                  {{ $t('sys_admin.org_details.actions.add_api_key') }}
                </button>
              </li>
              <li>
                <button v-if="organisation.organisationState === OrganisationState.ONBOARDING" class="dropdown-item"
                        @click="showDeleteOrganisation()">
                  {{ $t('sys_admin.org_details.actions.delete_organisation') }}
                </button>
              </li>
            </ul>
          </div>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    <ui-content>
      <spinner v-if="loading"/>
      <template v-if="!loading">
        <div class="container-fluid">
          <div class="container-fluid">
            <div class="row justify-content-center">
              <ui-view-card :title="$t('my_organisation.basic_information')">
                <template #actions>
                  <button v-if="!editing" type="button" class="btn btn-sm btn-outline-secondary"
                          @click="editing = true"><i class="bi-pencil"/>&nbsp;{{ $t('general.edit') }}
                  </button>
                  <div class="btn-group" v-if="editing">
                    <button type="button" class="btn btn-sm btn-outline-secondary" @click="editing = false">
                      &nbsp;{{ $t('general.back') }}
                    </button>
                    <button type="button" class="btn btn-sm btn-outline-primary" :disabled="saving" @click="save()"><i
                        class="bi-save"/>&nbsp;{{ $t('general.save') }}
                    </button>
                  </div>
                </template>
                <div class="row">
                  <template v-if="!editing">
                    <div>
                      {{ $t('my_organisation.name') }}: {{ organisation.name }}
                    </div>
                    <div>
                      {{ $t('my_organisation.contact_email') }}: {{ organisation.contactEmail }}
                    </div>
                    <div class="d-flex">
                      {{ $t('my_organisation.language') }}:<i style="font-size: 0.75rem; padding-right: 0.5rem;"
                                                              class="bi-question-circle-fill"
                                                              :title="$t('my_organisation.language_title')"/>
                      {{ languages.getLanguage(organisation.language) }}
                    </div>
                    <div>
                      {{ $t('my_organisation.support_credits') }}: {{ organisation.supportCredits }}
                    </div>
                    <template v-for="identification in organisation.identifications" :key="identification.id">
                      <div class="d-flex">
                        {{ $t('form.labels.identification_number') }}:&nbsp;
                        <span v-if="identification.identificationType">
                          {{
                            $t('identification_types.' + identification.identificationType)
                          }}: {{ identification.value }}
                        </span>
                      </div>
                    </template>
                    <hr/>
                    <b class="d-flex">
                      Public config
                      <i style="font-size: 0.75rem; padding-right: 0.5rem;" class="bi-question-circle-fill"
                         title="Config die de klant zelf mag instellen"/>
                    </b>
                    <div>
                      {{ $t('my_organisation.config.empty_container_status') }}: {{
                        organisation.config.empty_container
                        ? $t('general.enabled')
                        : $t('general.disabled')
                      }}
                    </div>
                    <div>
                      {{ $t('my_organisation.config.include_work_slip') }}: {{
                        organisation.config.include_work_slip
                        ? $t('general.enabled')
                        : $t('general.disabled')
                      }}
                    </div>
                    <div>
                      {{ $t('my_organisation.config.annex_7') }}: {{
                        organisation.config.ANNEX_7
                        ? $t('general.enabled')
                        : $t('general.disabled')
                      }}
                    </div>
                    <hr/>
                    <b class="d-flex">
                      Private config
                      <i style="font-size: 0.75rem; padding-right: 0.5rem;" class="bi-question-circle-fill"
                         title="Config die de klant zelf niet mag instellen (bv moet extra betalen hiervoor)"/>
                    </b>
                    <div>
                      {{ $t('my_organisation.config.attachments') }}: {{
                        organisation.privateConfig.ATTACHMENTS
                        ? $t('general.enabled')
                        : $t('general.disabled')
                      }}
                    </div>
                    <div>
                      {{ $t('my_organisation.config.ecmr') }}: {{
                        organisation.privateConfig.ECMR
                        ? $t('general.enabled')
                        : $t('general.disabled')
                      }}
                    </div>
                    <div>
                      {{ $t('my_organisation.config.grondbank') }}: {{
                        organisation.privateConfig.GRONDBANK
                        ? $t('general.enabled')
                        : $t('general.disabled')
                      }}
                    </div>
                    <div>
                      {{ $t('my_organisation.config.grondwijzer') }}: {{
                        organisation.privateConfig.GRONDWIJZER
                        ? $t('general.enabled')
                        : $t('general.disabled')
                      }}
                    </div>
                    <div>
                      {{ $t('my_organisation.config.matis') }}: {{
                        organisation.privateConfig.MATIS
                        ? $t('general.enabled')
                        : $t('general.disabled')
                      }}
                    </div>
                    <div>
                      {{ $t('my_organisation.config.matis_check') }}: {{
                        organisation.privateConfig.MATIS_CHECK
                        ? $t('general.enabled')
                        : $t('general.disabled')
                      }}
                    </div>
                    <div>
                      {{ $t('my_organisation.config.swagger') }}: {{
                        organisation.privateConfig.SWAGGER
                        ? $t('general.enabled')
                        : $t('general.disabled')
                      }}
                    </div>
                    <div>
                      {{ $t('my_organisation.config.private_note') }}: {{
                        organisation.privateConfig.PRIVATE_NOTE
                        ? $t('general.enabled')
                        : $t('general.disabled')
                      }}
                    </div>
                    <div>
                      {{ $t('my_organisation.config.sjablonen') }}: {{
                        organisation.privateConfig.SJABLONEN
                        ? $t('general.enabled')
                        : $t('general.disabled')
                      }}
                    </div>
                    <div>
                      {{ $t('my_organisation.config.linked_organisations') }}: {{
                        organisation.privateConfig.LINKED_ORGANISATIONS
                        ? $t('general.enabled')
                        : $t('general.disabled')
                      }}
                    </div>
                    <div>
                      {{ $t('my_organisation.config.bosschaert_driver') }}: {{
                        organisation.privateConfig.BOSSCHAERT
                        ? $t('general.enabled')
                        : $t('general.disabled')
                      }}
                    </div>
                  </template>
                  <template v-if="editing">
                    <form id="organisation-form">
                      <ui-view-card-field :label="$t('my_organisation.name')" v-model="organisation.name"
                                          :required="true"/>
                      <ui-view-card-field :label="$t('my_organisation.contact_email')" :required="true">
                        <v-select :options="orgAdmins" v-model="organisation.contactEmail"
                                  :reduce="contact => contact.email" label="email" required>
                          <template #search="{attributes, events}">
                            <input
                                class="vs__search"
                                :required="!organisation.contactEmail"
                                v-bind="attributes"
                                v-on="events"
                            />
                          </template>
                          <template #no-options>
                            {{ $t('general.no_options') }}
                          </template>
                        </v-select>
                      </ui-view-card-field>
                      <ui-view-card-language :label="$t('my_organisation.language')" v-model="organisation.language"/>
                      <ui-view-card-number :label="$t('my_organisation.support_credits')"
                                           v-model="organisation.supportCredits"/>
                      <ui-view-card-ids v-model="organisation.identifications"/>
                      <hr/>
                      <b class="d-flex">
                        {{ $t('my_organisation.config.public_config') }}
                        <i style="font-size: 0.75rem; padding-right: 0.5rem;" class="bi-question-circle-fill"
                           title="Config die de klant zelf mag instellen"/>
                      </b>
                      <ui-view-card-field :label="$t('my_organisation.config.empty_container_status')">
                        <input class="form-check-input" type="checkbox" v-model="organisation.config.empty_container">
                      </ui-view-card-field>
                      <ui-view-card-field :label="$t('my_organisation.config.include_work_slip')">
                        <input id="workslipCheckbox" class="form-check-input" type="checkbox"
                               v-model="organisation.config.include_work_slip">
                      </ui-view-card-field>
                      <ui-view-card-field :label="$t('my_organisation.config.annex_7')">
                        <input class="form-check-input" type="checkbox" v-model="organisation.config.ANNEX_7">
                      </ui-view-card-field>
                      <hr/>
                      <b class="d-flex">
                        {{ $t('my_organisation.config.private_config') }}
                        <i style="font-size: 0.75rem; padding-right: 0.5rem;" class="bi-question-circle-fill"
                           title="Config die de klant zelf niet mag instellen (bv moet extra betalen hiervoor)"/>
                      </b>
                      <ui-view-card-field :label="$t('my_organisation.config.attachments')">
                        <input class="form-check-input" type="checkbox"
                               v-model="organisation.privateConfig.ATTACHMENTS">
                      </ui-view-card-field>
                      <ui-view-card-field :label="$t('my_organisation.config.ecmr')">
                        <input class="form-check-input" type="checkbox" v-model="organisation.privateConfig.ECMR">
                      </ui-view-card-field>
                      <ui-view-card-field :label="$t('my_organisation.config.grondbank')">
                        <input class="form-check-input" type="checkbox" v-model="organisation.privateConfig.GRONDBANK">
                      </ui-view-card-field>
                      <ui-view-card-field :label="$t('my_organisation.config.grondwijzer')">
                        <input class="form-check-input" type="checkbox"
                               v-model="organisation.privateConfig.GRONDWIJZER">
                      </ui-view-card-field>
                      <ui-view-card-field :label="$t('my_organisation.config.matis')">
                        <input class="form-check-input" type="checkbox" v-model="organisation.privateConfig.MATIS">
                      </ui-view-card-field>
                      <ui-view-card-field :label="$t('my_organisation.config.matis_check')">
                        <input class="form-check-input" type="checkbox"
                               v-model="organisation.privateConfig.MATIS_CHECK">
                      </ui-view-card-field>
                      <ui-view-card-field :label="$t('my_organisation.config.swagger')">
                        <input class="form-check-input" type="checkbox" v-model="organisation.privateConfig.SWAGGER">
                      </ui-view-card-field>
                      <ui-view-card-field :label="$t('my_organisation.config.private_note')">
                        <input class="form-check-input" type="checkbox"
                               v-model="organisation.privateConfig.PRIVATE_NOTE">
                      </ui-view-card-field>
                      <ui-view-card-field :label="$t('my_organisation.config.sjablonen')">
                        <input class="form-check-input" type="checkbox" v-model="organisation.privateConfig.SJABLONEN">
                      </ui-view-card-field>
                      <ui-view-card-field :label="$t('my_organisation.config.linked_organisations')">
                        <input class="form-check-input" type="checkbox"
                               v-model="organisation.privateConfig.LINKED_ORGANISATIONS">
                      </ui-view-card-field>
                      <ui-view-card-field :label="$t('my_organisation.config.bosschaert_driver')">
                        <input class="form-check-input" type="checkbox" v-model="organisation.privateConfig.BOSSCHAERT">
                      </ui-view-card-field>
                    </form>
                  </template>
                </div>
              </ui-view-card>
            </div>
            <div class="row justify-content-center">
              <ui-view-card :title="$t('my_organisation.contract.active_contract')">
                <template v-if="activeContract">
                  <div class="row">
                    <div>
                      {{ $t('my_organisation.contract.status.status') }}:
                      {{ $t('my_organisation.contract.status.' + activeContract.contractState) }}
                    </div>
                    <div>
                      {{ $t('my_organisation.contract.remaining_credits') }}: {{ activeContract.currentCredits }} /
                      {{ activeContract.initialCredits }}
                    </div>
                    <div v-if="activeContract.contractState === ContractState.IN_OVERDRAFT">
                      {{ $t('my_organisation.contract.overdraft') }}: {{ activeContract.overdraft }}
                    </div>
                    <div>
                      {{ $t('my_organisation.contract.start') }}: {{ DateFormatter.formatDate(activeContract.start) }}
                    </div>
                    <div>
                      {{ $t('my_organisation.contract.end') }}: {{ DateFormatter.formatDate(activeContract.stop) }}
                    </div>
                    <div>
                      {{ $t('my_organisation.contract.auto_renew') }}: {{
                        organisation.autoRenewContract
                        ? $t('my_organisation.contract.auto_renew_enabled')
                        : $t('my_organisation.contract.auto_renew_disabled')
                      }}
                    </div>
                  </div>
                </template>
                <template v-else>
                  {{ $t('my_organisation.contract.no_active_contract') }}
                </template>
              </ui-view-card>
            </div>
            <div class="row justify-content-center">
              <ui-view-card :title="$t('my_organisation.contracts.contracts')">
                <template #actions>
                  <div class="btn-group">
                    <div class="btn-group">
                      <button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button"
                              id="actionsDropdown" data-bs-toggle="dropdown" aria-expanded="false"
                              :disabled="selectedIDs.length === 0">
                        {{ $t('general.actions') }}
                      </button>
                      <ul data-cy="organisations-actions" class="dropdown-menu" aria-labelledby="actionsDropdown">
                        <li>
                          <button class="dropdown-item" @click="showActivate()" :disabled="selectedIDs.length !== 1">
                            {{ $t('organisations.list.actions.activate') }}
                          </button>
                        </li>
                        <li>
                          <button class="dropdown-item" @click="showRemove()" :disabled="selectedIDs.length === 0">
                            {{ $t('organisations.list.actions.delete') }}
                          </button>
                        </li>
                      </ul>
                    </div>
                    <button type="button" class="btn btn-sm btn-outline-primary" @click="addContract()"><i
                        class="bi-plus"/>&nbsp;{{ $t('my_organisation.contracts.add_contract') }}
                    </button>
                  </div>
                </template>
                <ag-grid-vue
                    @grid-ready="onGridReady"
                    style="min-height:100%"
                    class="ag-theme-quartz"
                    :columnDefs="columnDefs"
                    :defaultColDef="grid.defaultColDef"
                    :rowData="contracts"
                    @row-clicked="rowClick"
                    rowSelection='multiple'
                    @selection-changed="refreshSelection"
                    :getLocaleText="grid.getLocaleText"
                />
              </ui-view-card>
            </div>
          </div>
        </div>
      </template>
    </ui-content>
  </layout>
  <contracts-activate-modal ref="activateModal" :callback="activate"/>
  <contracts-delete-modal ref="removeModal" :amount="selectedIDs.length" :callback="remove"/>
  <admin-organisation-delete-modal ref="deleteOrganisationModal" :callback="deleteOrganisation"/>
  <admin-add-api-key-modal ref="addApiKeyModal" :callback="addApiKey"/>
</template>

<script>

import Layout from '@/components/layout'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import UiContent from '@/components/ui-content'
import {inject, onBeforeUnmount, ref} from 'vue'
import backend from '@/util/backend'
import Spinner from '@/components/spinner'
import UiViewCard from '@/components/ui-view-card'
import languages from '@/types/languages'
import OrganisationState from '@/types/organisationState'
import {DateFormatter} from '@/services/dateFormatter'
import {AgGridVue} from 'ag-grid-vue3'
import {useRouter} from 'vue-router'
import Notifier from '@/util/notifier'
import ContractsDeleteModal from '@/views/contracts/Delete-Modal'
import UiViewCardLanguage from '@/components/ui-view-card-language'
import UiViewCardField from '@/components/ui-view-card-field'
import session from '@/util/session'
import ContractsActivateModal from '@/views/contracts/Activate-Modal'
import ContractState from '@/types/contractState'
import grid from '@/util/grid'
import AdminOrganisationDeleteModal from '@/views/sys-admin/Admin-Organsation-Delete-Modal.vue'
import AdminAddApiKeyModal from '@/views/sys-admin/Admin-Add-Api-Key-Modal.vue'
import UiViewCardIds from '@/components/ui-view-card-ids.vue'
import UiViewCardNumber from '@/components/ui-view-card-number.vue'

export default {
  name:       'Organisation-Details',
  computed:   {
    grid() {
      return grid
    },
    ContractState() {
      return ContractState
    },
  },
  components: {
    UiViewCardNumber,
    UiViewCardIds,
    AdminAddApiKeyModal,
    AdminOrganisationDeleteModal,
    ContractsActivateModal,
    UiViewCardField,
    UiViewCardLanguage,
    ContractsDeleteModal,
    AgGridVue,
    UiViewCard,
    Spinner,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout,
  },
  props:      {
    id: {
      type:     String,
      required: true,
    },
  },
  setup:      (props) => {
    const breadcrumbs  = [BREADCRUMBS.HOME, BREADCRUMBS.SYSORGANISATIONS, BREADCRUMBS.SYSORGANISATIONDETAIL]
    const $t           = inject('$t')
    const loading      = ref(true)
    const editing      = ref(false)
    const organisation = ref({})

    const activeContract  = ref(null)
    const getOrganisation = () => {
      backend.get(`api/admin/organisations/${props.id}`).then((r) => {
        organisation.value               = r.data
        organisation.value.config        = JSON.parse(organisation.value.config ?? '{}') ?? {}
        organisation.value.privateConfig = JSON.parse(organisation.value.privateConfig ?? '{}') ?? {}
        activeContract.value             = r.data.contracts.reverse().filter((contract) => {
          return [
            ContractState.ACTIVE,
            ContractState.LOW_CREDITS,
            ContractState.IN_OVERDRAFT,
          ].indexOf(contract.contractState) > -1
        })[0]
        contracts.value                  = r.data.contracts

        loading.value = false
      })
    }
    getOrganisation()

    const contracts = ref([])

    const addContract = () => {
      router.push(`/admin/organisations/${props.id}/contracts/new`)
    }

    let api           = null
    const onGridReady = (params) => {
      api = params.api
      api.deselectAll() // if we can correctly get selection from saved state we can re-evaluate this
      api.sizeColumnsToFit({'defaultMinWidth': 200})
      api.setGridOption('domLayout', 'autoHeight')
    }
    onBeforeUnmount(() => {
      api = null
    })

    const router   = useRouter()
    const rowClick = (event) => {
      if (event.data) {
        router.push(`/admin/organisations/${props.id}/contracts/edit/${event.data.id}`)
      }
    }

    const columnDefs = [
      {
        headerName:     $t('my_organisation.contracts.key'),
        field:          'id',
        floatingFilter: false,
      },
      {
        headerName:     $t('my_organisation.contracts.start'),
        floatingFilter: false,
        filter:         'agDateColumnFilter',
        filterParams:   {
          minValidYear: 2000,
          maxValidYear: 2099,
          comparator:   (a, b) => {
            const newB = new Date(b.getFullYear(), b.getMonth(), b.getDate())
            if (a.getTime() === newB.getTime()) {
              return 0
            }
            if (newB < a) {
              return -1
            }
            if (newB > a) {
              return 1
            }
          },
        },
        valueGetter:    (params) => {
          return new Date(params.data.start)
        },
        valueFormatter: (params) => {
          return DateFormatter.formatDate(params.data.start)
        },
      },
      {
        headerName:     $t('my_organisation.contracts.stop'),
        floatingFilter: false,
        filter:         'agDateColumnFilter',
        filterParams:   {
          minValidYear: 2000,
          maxValidYear: 2099,
          comparator:   (a, b) => {
            const newB = new Date(b.getFullYear(), b.getMonth(), b.getDate())
            if (a.getTime() === newB.getTime()) {
              return 0
            }
            if (newB < a) {
              return -1
            }
            if (newB > a) {
              return 1
            }
          },
        },
        valueGetter:    (params) => {
          return new Date(params.data.stop)
        },
        valueFormatter: (params) => {
          return DateFormatter.formatDate(params.data.stop)
        },
      },
      {
        headerName:     $t('my_organisation.contracts.credits'),
        floatingFilter: false,
        field:          'initialCredits',
      },
      {
        headerName:     $t('my_organisation.contracts.state'),
        floatingFilter: false,
        field:          'contractState',
      },
      {
        headerName:     $t('my_organisation.contracts.subscription_id'),
        floatingFilter: false,
        field:          'subscriptionId',
      },
    ]

    const selectedIDs      = ref([])
    const refreshSelection = (params) => {
      selectedIDs.value = params.api.getSelectedRows().map((row) => {
        return row.id
      })
    }

    const removeModal = ref('removeModal')
    const showRemove  = () => {
      removeModal.value.modal.open()
    }
    const remove      = () => {
      const ids      = api.getSelectedRows().filter((row) => {
        return row.contractState === ContractState.FUTURE
      }).map((row) => {
        return row.id
      })
      const promises = []
      if (ids.length > 0) {
        ids.forEach((id) => {
          promises.push(backend.delete(`api/organisations/${props.id}/contracts/${id}`))
        })
      }
      Promise.all(promises).then(() => {
        notifier.success('toast.contract_delete_success')
      }).catch(() => {
        notifier.error('toast.contract_delete_failed')
      }).finally(() => {
        removeModal.value.modal.close()
        getOrganisation()
      })
    }

    const activateModal = ref('activateModal')
    const showActivate  = () => {
      activateModal.value.modal.open()
    }
    const activate      = async () => {
      const id = api.getSelectedRows().filter((row) => {
        return row.contractState === ContractState.FUTURE
      }).map((row) => {
        return row.id
      })
      if (id) {
        const result = await backend.put(`api/organisations/${props.id}/contracts/${id}/activate`)
        if (result.status !== 200) {
          notifier.error('toast.contract_activate_failed')
        } else {
          notifier.success('toast.contract_activate_success')
        }
      }
      activateModal.value.modal.close()
      getOrganisation()
      session.setOrganisation()
    }

    const saving = ref(false)
    const save   = async () => {
      saving.value      = true
      const formElement = $('#organisation-form')[0]
      if (!formElement.checkValidity()) {
        // something in the form is not valid so don't allow signing
        formElement.reportValidity()
        saving.value = false
        return false
      }

      try {
        organisation.value.config        = JSON.stringify(organisation.value.config)
        organisation.value.privateConfig = JSON.stringify(organisation.value.privateConfig)
        const result                     = await backend.put('api/admin/organisations/' + props.id, organisation.value)
        if (result.status === 200) {
          notifier.success('toast.successfully_saved')
          editing.value = false
          session.setOrganisation()
          getOrganisation()
        } else {
          notifier.error('toast.save_failed')
        }
      } catch (error) {
        notifier.error('toast.save_failed')
      }
      saving.value = false
    }

    const orgAdmins = ref([])
    backend.get(`api/users?role=ORGANISATION_ADMIN&organisationId=${props.id}`).then((r) => {
      orgAdmins.value = r.data
    })

    const verifyMatis = () => {
      backend.get(`api/matisuploads/verify?organisationId=${props.id}`).then((r) => {
        if (r.data.length === 0) {
          notifier.success('Everything uploaded')
        } else {
          notifier.error('Missing uploads for ids: ' + r.data.join(', '))
        }
      })
    }

    const deleteOrganisationModal = ref('deleteOrganisationModal')
    const showDeleteOrganisation  = () => {
      deleteOrganisationModal.value.modal.open()
    }
    const deleteOrganisation      = () => {
      backend.delete(`/api/organisations/${props.id}`).then(() => {
        deleteOrganisationModal.value.modal.close()
        router.push({name: 'SysOrgList'})
      })
    }

    const notifier       = Notifier()
    const addApiKeyModal = ref('addApiKeyModal')
    const showAddApiKey  = () => {
      addApiKeyModal.value.modal.open()
    }
    const addApiKey      = () => {
      backend.post(`/api/admin/organisations/${props.id}/apikey`).then((result) => {
        if (result.status === 200) {
          notifier.success('toast.credentials_create_success')
          alert(result.data)
        }
      }).finally(() => {
        addApiKeyModal.value.modal.close()
      })
    }

    return {
      breadcrumbs,
      loading,
      editing,
      organisation,
      orgAdmins,
      OrganisationState,
      activeContract,
      DateFormatter,
      languages,
      addContract,
      onGridReady,
      columnDefs,
      contracts,
      rowClick,
      selectedIDs,
      refreshSelection,
      removeModal,
      showRemove,
      remove,
      activateModal,
      showActivate,
      activate,
      save,
      saving,
      verifyMatis,
      showDeleteOrganisation,
      deleteOrganisationModal,
      deleteOrganisation,
      showAddApiKey,
      addApiKeyModal,
      addApiKey,
    }
  },
}
</script>

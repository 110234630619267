import backend from '@/util/backend'
import session from '@/util/session'
import i18n, {languages} from '@/util/i18n'

const getTranslate = () => {
  const user = session.getUser()
  return i18n(user?.language ?? languages.NL)
}

const ProcessingTechniques = {
  cache: [],
  clear: () => {
    ProcessingTechniques.cache = []
  },
  get: async () => {
    if (ProcessingTechniques.cache.length === 0) {
      await backend.get('api/processingtechniques').then((result) => {
        result.data.forEach((val) => {
          if (val !== 'TEMP') {
            ProcessingTechniques.cache.push({value: val, label: getTranslate()('processing_techniques.' + val)})
          }
        })
      })
    }
    return ProcessingTechniques.cache
  }
}

export default ProcessingTechniques

<template>
  <layout>
    <template #header>
      <ui-header :title="loading ? '' : $t('grondbank.view.title', {id: link.documentId})"/>
    </template>
    <ui-content class="pb-1">
      <spinner v-if="loading"/>
      <template v-else-if="step === steps.signerinfo">
        <div class="container-fluid">
          <form id="signer-form" class="row g-2 justify-content-center">
            <ui-view-card data-cy="information" :title="$t('shared.signer_information')" :key="signer.language">
              <ui-view-card-language v-model="signer.language" :required="true" :own-language="true"/>
              <template v-if="signer.language">
                <ui-view-card-field data-cy="signer-email" :label="$t('user.labels.email')" v-model="signer.email"
                                    :required="true" :readonly="true"/>
                <ui-view-card-field data-cy="signer-first-name" :label="$t('user.labels.first_name')"
                                    v-model="signer.firstName" :required="true"/>
                <ui-view-card-field data-cy="signer-last-name" :label="$t('user.labels.last_name')"
                                    v-model="signer.lastName" :required="true"/>
                <ui-view-card-field data-cy="signer-organisation" :label="$t('user.labels.organisation')"
                                    v-model="signer.organisation" :required="true"/>
                <br/>
                <br/>
                <div class="row g-0 justify-content-center">
                  <button id="saveSignerBtn" type="button" class="btn" :class="'btn-outline-primary'"
                          @click="saveSigner()" form="signer-form" :disabled="signerSaving"><i
                      class="bi-save"/>&nbsp;{{ $t('general.confirm') }}
                  </button>
                </div>
              </template>
            </ui-view-card>
          </form>
        </div>
      </template>
      <template v-else-if="step === steps.viewing">
        <grondbank-form-view :form="form" :uuid="link.id" @signed="loadForm()" :key="form.lastUpdate"
                             :allow-transporter-signature="false"/>
      </template>
    </ui-content>
  </layout>
</template>

<script>

import UiContent from '@/components/ui-content'
import {inject, provide, ref, watch} from 'vue'
import UiViewCard from '@/components/ui-view-card'
import UiViewCardField from '@/components/ui-view-card-field'
import Notifier from '@/util/notifier'
import backend from '@/util/backend'
import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import Spinner from '@/components/spinner'
import UiViewCardLanguage from '@/components/ui-view-card-language'
import FormState from '@/types/formState'
import GrondbankFormView from '@/partials/grondbank/FormView'

export default {
  name:       'SharedLinkSignatureProcessorGrondbank',
  computed:   {
    FormState() {
      return FormState
    },
  },
  components: {
    GrondbankFormView,
    UiViewCardLanguage,
    Spinner,
    UiHeader,
    Layout,
    UiViewCardField,
    UiViewCard,
    UiContent,
  },
  props:      {
    link: {
      type:     Object,
      required: true,
    },
  },
  setup:      (props) => {
    const $tset    = inject('$tset')
    const notifier = Notifier()

    const steps = {
      signerinfo: 'signerinfo',
      viewing:    'viewing',
    }
    const step  = ref(steps.signerinfo)

    $tset(props.link.language)
    const signer = ref({
      email:        props.link.email,
      firstName:    props.link.firstName,
      lastName:     props.link.lastName,
      organisation: props.link.organisation,
      language:     props.link.language,
    })
    watch(() => signer.value.language, (l) => {
      $tset(l)
    })
    provide('processorInfo', signer)

    const loading      = ref(true)
    const signerSaving = ref(false)
    const saveSigner   = () => {
      signerSaving.value = true
      const signerForm   = $('#signer-form')[0]
      if (!signerForm.checkValidity()) {
        // something in the form is not valid so don't allow signing
        signerForm.reportValidity()
        signerSaving.value = false
        return
      }
      backend.put(`/api/sharedlinks/${props.link.id}/userData`, {
        firstName:    signer.value.firstName,
        lastName:     signer.value.lastName,
        organisation: signer.value.organisation,
        language:     signer.value.language,
      }).then(() => {
        notifier.success('toast.user_confirmed')
        step.value = steps.viewing
      }).finally(() => {
        signerSaving.value = false
      })
    }

    const form     = ref({})
    const loadForm = () => {
      backend.get(`api/sharedlinks/${props.link.id}/document`).then((doc) => {
        form.value    = doc.data
        loading.value = false
      })
    }
    loadForm()

    const saving         = ref(false)
    const saveWeightEdit = async () => {
      saving.value = true
      const result = await backend.put(`api/sharedlinks/${props.link.id}/document/${props.link.documentId}`, form.value)
      saving.value = false
      if (result.status === 200) {
        notifier.success('toast.successfully_saved')
        loadForm()
        return true
      } else {
        notifier.error('toast.save_failed')
        return false
      }
    }

    const stopEdit = () => {
      loadForm()
      step.value = steps.viewing
    }

    const signerInfo    = ref({})
    const signProcessor = () => {
      step.value       = steps.signing
      signerInfo.value = {
        'language':     form.value.processorIdentification.language,
        'organisation': form.value.processorIdentification.name,
      }
    }

    const signSigner = () => {
      step.value       = steps.signing
      signerInfo.value = {
        'firstName':    signer.value.firstName,
        'lastName':     signer.value.lastName,
        'language':     signer.value.language,
        'organisation': signer.value.organisation,
      }
    }

    const signing               = ref(false)
    // click button at bottom to trigger signing
    const clickSignProcessorBtn = () => {
      signing.value = true
      document.getElementById('signProcessorBtn').click()
      setTimeout(() => {
        signing.value = false
      }, 4000)
    }

    const isProcessorSet = ref(false)

    const backToViewing = () => {
      step.value = steps.viewing
    }

    const signed = () => {
      step.value    = steps.viewing
      signing.value = false
      loadForm()
    }

    const editWeightsModal     = ref('editWeightsModal')
    const showEditWeightsModal = () => {
      editWeightsModal.value.modal.open()
    }

    return {
      loading,
      step,
      steps,
      signer,
      saveSigner,
      signerSaving,
      form,
      stopEdit,
      saving,
      backToViewing,
      signProcessor,
      signSigner,
      signerInfo,
      signing,
      clickSignProcessorBtn,
      isProcessorSet,
      signed,
      saveWeightEdit,
      editWeightsModal,
      showEditWeightsModal,
      loadForm,
    }
  },
}
</script>

<template>
  <div ref="modalRef" class="modal" tabindex="-1">
    <div class="modal-dialog" :class="size ? 'modal-'+size : ''">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            <slot name="title"/>
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <slot />
        </div>
        <div class="modal-footer">
          <slot name="footer">
            <template v-if="noConfirm">
              <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                {{$t('modal.close')}}
              </button>
            </template>
            <template v-else>
              <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                {{$t(cancelButton)}}
              </button>
              <button v-if="!noConfirm" id="modalConfirmBtn" type="button" class="btn btn-outline-primary" :disabled="!canConf()" @click="confirm()">
                <slot name="confirm-button">
                  <div class="spinner-border spinner-border-sm" role="status" v-if="running">
                    <span class="visually-hidden">{{$t('general.runningOoo')}}</span>
                  </div>
                  {{$t(confirmButton)}}
                </slot>
              </button>
            </template>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from 'bootstrap'
import {ref, watch} from 'vue'

export default {
  name: 'ui-modal',
  components: {},
  props: {
    callback: {
      type:    Function,
      default: () => {},
    },
    canConfirm: {
      type:    Function,
      default: () => {return true}
    },
    cancelButton: {
      type: String,
      default: 'modal.cancel'
    },
    confirmButton: {
      type:    String,
      default: 'modal.confirm'
    },
    size: {
      type:    String,
      default: ''
    },
    noConfirm: {
      type:    Boolean,
      default: false,
    },
    onOpen: {
      type:    Function,
      default: () => {},
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  setup: (props) => {
    const running = ref(false)

    const canConf = () => {
      return !running.value && props.canConfirm()
    }

    const modalRef = ref('modalRef')
    const modal = ref(null)
    watch(() => modalRef.value, (val) => {
      modal.value = new Modal(val)
      if (props.onClose) {
        modal.value._element.addEventListener('hidden.bs.modal', props.onClose)
      }
    })

    const open = () => {
      modal.value.show()
      props.onOpen()
    }

    const close = () => {
      modal.value.hide()
    }

    const confirm = async () => {
      running.value = true
      try {
        await props.callback()
      } catch (e) {
        running.value = false
        throw e
      }
      running.value = false
    }

    return {
      modalRef,
      modal,
      running,
      canConf,
      confirm,
      open,
      close,
    }
  }
}
</script>

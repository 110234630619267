const OrganisationModel = {
  empty: {
    id: null,
    language: null,
    name: '',
    email: '',
    phone: '',
    address: {},
    shipmentAddress: {},
    identifications: [{
      value: null,
      identificationType: null
    }],
  },

  validProducer: (producer) => {
    let allIdentificationsValid = true
    producer?.identifications?.forEach((identification) => {
      if (!identification.value || !identification.identificationType) {
        allIdentificationsValid = false
      }
    })
    return producer.name &&
      producer.email &&
      producer.language &&
      producer.address &&
      producer.address.street &&
      producer.address.number &&
      producer.address.zip &&
      producer.address.city &&
      producer.address.country &&
      producer.shipmentAddress &&
      producer.shipmentAddress.street &&
      producer.shipmentAddress.number &&
      producer.shipmentAddress.zip &&
      producer.shipmentAddress.city &&
      producer.shipmentAddress.country &&
      producer.identifications &&
      producer.identifications.length > 0 &&
      allIdentificationsValid
  },
  validProcessor: (processor) => {
    let allIdentificationsValid = true
    processor?.identifications?.forEach((identification) => {
      if (!identification.value || !identification.identificationType) {
        allIdentificationsValid = false
      }
    })
    return processor.name &&
      processor.email &&
      processor.language &&
      processor.address &&
      processor.address.street &&
      processor.address.number &&
      processor.address.zip &&
      processor.address.city &&
      processor.address.country &&
      processor.shipmentAddress &&
      processor.shipmentAddress.street &&
      processor.shipmentAddress.number &&
      processor.shipmentAddress.zip &&
      processor.shipmentAddress.city &&
      processor.shipmentAddress.country &&
      processor.identifications &&
      processor.identifications.length > 0 &&
      allIdentificationsValid
  },
  validOrganiser: (organiser) => {
    let allIdentificationsValid = true
    organiser?.identifications?.forEach((identification) => {
      if (!identification.value || !identification.identificationType) {
        allIdentificationsValid = false
      }
    })
    return organiser.name &&
      organiser.language &&
      organiser.address &&
      organiser.address.street &&
      organiser.address.number &&
      organiser.address.zip &&
      organiser.address.city &&
      organiser.address.country &&
      organiser.identifications &&
      organiser.identifications.length > 0 &&
      allIdentificationsValid
  },
  validTransporter: (transporter) => {
    let allIdentificationsValid = true
    transporter?.identifications?.forEach((identification) => {
      if (!identification.value || !identification.identificationType) {
        allIdentificationsValid = false
      }
    })
    return transporter.name &&
      transporter.language &&
      transporter.address &&
      transporter.address.street &&
      transporter.address.number &&
      transporter.address.zip &&
      transporter.address.city &&
      transporter.address.country &&
      transporter.identifications &&
      transporter.identifications.length > 0 &&
      allIdentificationsValid
  }
}

export default OrganisationModel

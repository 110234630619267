const OrganisationType = {
  REGULAR:     'REGULAR',
  OVERVIEWER:  'OVERVIEWER',
  TRANSPORTER: 'TRANSPORTER',
  SUPPORT: 'SUPPORT',

  getAll: () => {
    return [
      OrganisationType.REGULAR,
      OrganisationType.OVERVIEWER,
      OrganisationType.TRANSPORTER,
      OrganisationType.SUPPORT,
    ]
  }
}

export default OrganisationType

<template>
  <layout>
    <template v-if="failed">
      <div class="display-3 mt-5">{{$t('error.failed_to_generate_export')}}</div>
    </template>
  </layout>
</template>

<script>
import backend from '@/util/backend'
import Layout from '@/components/layout.vue'
import {ref} from 'vue'

export default {
  name: 'SafariCsv',
  components: {Layout},
  props: {
    language: {
      type: String,
      required: true,
    },
    ids: {
      type: String,
      required: true,
    }
  },
  setup: (props) => {
    const failed = ref(false)
    const ids = props.ids.split(',')

    const exportCsv = () => {
      backend.post('api/documents/batch', {
        'action': 'EXPORT_CSV',
        'ids': ids,
        'params': {
          'language': props.language.toUpperCase()
        }
      }, {responseType: 'blob'}).then((result) => {
        if (result.status === 200) {
          const blob = new Blob([result.data], {type: 'application/pdf'})
          const url = URL.createObjectURL(blob)
          window.open(url, '_self')
        } else {
          failed.value = true
        }
      }).catch(() => {
        failed.value = true
      })
    }
    exportCsv()

    return {
      failed,
    }
  }
}

</script>
<template>
  <ui-modal id="createFormModal" ref="modal" size="xl" :on-open="init">
    <template #title>
      {{ $t('transport_document_templates.waste_identification.create_form_modal.title') }}
    </template>
    <template #default>
      <template v-if="currentStep === possibleSteps.PRODUCER">
        <template v-if="template.producers.length < 10">
          <h5>
            {{ $t('transport_document_templates.waste_identification.create_form_modal.select_producer') }}
          </h5>
          <div class="row">
            <div class="col-lg-4 d-flex justify-content-center pb-2" v-for="producer in template.producers" :key="producer.id">
              <button
                class="btn btn-lg btn-outline-primary w-100"
                @click="selectProducer(producer)"
              >
                {{ producer.name }}
                <br />
                {{ getAddressLine1(producer) }}
                <br />
                {{ getAddressLine2(producer) }}
              </button>
            </div>
          </div>
        </template>
        <template v-else>
          <ui-view-card-field :label="$t('transport_document_templates.waste_identification.create_form_modal.select_producer')">
            <v-select :options="template.producers" v-model="form.producerId" :label="'name'" :reduce="p => p.id">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
              <template #no-options>
                {{ $t('general.no_options') }}
              </template>
            </v-select>
          </ui-view-card-field>
          <template v-if="producer.id">
            <hr />
            <div class="row">
              <div class="col-lg-6">
                {{ $t('form.labels.name') }}: {{producer.name}}
              </div>
              <div class="col-lg-6">
                {{ $t('form.labels.email') }}: {{producer.email}}
              </div>
            </div>
            <ui-view-card-address :model-value="producer.address" />
            <div class="col-lg-6">
              {{ $t('form.labels.language') }}: {{languages.getLanguage(producer.language)}}
            </div>
            <template v-for="identification in producer.identifications" :key="identification.id">
              <div class="d-flex">
                {{ $t('form.labels.identification_number') }}:
                <i style="font-size: 0.75rem;" class="bi-question-circle-fill" :title="$t('form.idnumber_title')" />&nbsp;
                <span v-if="identification.identificationType">
                {{ $t('identification_types.' + identification.identificationType) }}: {{ identification.value}}
              </span>
              </div>
            </template>
            <hr />
            <h6 class="card-subtitle text-muted">
              {{$t('form.sending_address')}}
            </h6>
            <ui-view-card-address :model-value="producer.shipmentAddress" />
            <div class="col-lg-6">
              {{ $t('form.labels.matis_location_type') }}:
              <span v-if="producer.locationType">
              {{ $t('matis_location_types.' + producer.locationType) }}
            </span>
            </div>
          </template>
        </template>
      </template>
      <template v-if="currentStep === possibleSteps.WASTE">
        <template v-if="template.wasteIdentificationTemplateProcessors.length < 10">
          <h5>
            {{ $t('transport_document_templates.waste_identification.create_form_modal.select_wastes') }}
          </h5>
          <div class="row">
            <div class="col-lg-4 d-flex justify-content-center pb-2" v-for="waste in template.wasteIdentificationTemplateProcessors" :key="waste.id">
              <button
                class="btn btn-lg btn-outline-primary w-100"
                @click="selectWaste(waste)"
              >
                {{ waste.wasteIdentification.description }}
              </button>
            </div>
          </div>
        </template>
        <template v-else>
          <ui-view-card-field :label="$t('transport_document_templates.waste_identification.create_form_modal.select_waste')">
            <v-select :options="template.wasteIdentificationTemplateProcessors" v-model="form.wasteIdentificationId" :get-option-label="(w) => { return w?.wasteIdentification?.description }" :reduce="(w) => { return w.id }">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
              <template #no-options>
                {{ $t('general.no_options') }}
              </template>
            </v-select>
          </ui-view-card-field>
        </template>
      </template>
      <template v-if="currentStep === possibleSteps.PROCESSOR">
        <template v-if="availableProcessors.length < 10">
          <h5>
            {{ $t('transport_document_templates.waste_identification.create_form_modal.select_processor') }}
          </h5>
          <div class="row">
            <div class="col-lg-4 d-flex justify-content-center pb-2" v-for="processor in availableProcessors" :key="processor.id">
              <button
                class="btn btn-lg btn-outline-primary w-100"
                @click="selectProcessor(processor)"
              >
                {{ processor.name }}
                <br />
                {{ getAddressLine1(processor) }}
                <br />
                {{ getAddressLine2(processor) }}
              </button>
            </div>
          </div>
        </template>
        <template v-else>
          <ui-view-card-field :label="$t('transport_document_templates.waste_identification.create_form_modal.select_processor')">
            <v-select :options="availableProcessors" v-model="form.processorId" :label="'name'" :reduce="p => p.id">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
              <template #no-options>
                {{ $t('general.no_options') }}
              </template>
            </v-select>
          </ui-view-card-field>
          <template v-if="processor.id">
            <hr />
            <div class="row">
              <div class="col-lg-6">
                {{ $t('form.labels.name') }}: {{processor.name}}
              </div>
              <div class="col-lg-6">
                {{ $t('form.labels.email') }}: {{processor.email}}
              </div>
            </div>
            <ui-view-card-address :model-value="processor.address" />
            <div class="col-lg-6">
              {{ $t('form.labels.language') }}: {{languages.getLanguage(processor.language)}}
            </div>
            <template v-for="identification in processor.identifications" :key="identification.id">
              <div class="d-flex">
                {{ $t('form.labels.identification_number') }}:
                <i style="font-size: 0.75rem;" class="bi-question-circle-fill" :title="$t('form.idnumber_title')" />&nbsp;
                <span v-if="identification.identificationType">
                {{ $t('identification_types.' + identification.identificationType) }}: {{ identification.value}}
              </span>
              </div>
            </template>
            <div class="col-lg-6">
              {{ $t('form.labels.matis_location_type') }}:
              <span v-if="processor.locationType">
              {{ $t('matis_location_types.' + processor.locationType) }}
            </span>
            </div>
          </template>
        </template>
      </template>
      <template v-if="currentStep === possibleSteps.TRANSPORTER">
        <template v-if="template.transporters.length < 10">
          <h5>
            {{ $t('transport_document_templates.waste_identification.create_form_modal.select_transporters') }}
          </h5>
          <div class="row">
            <div class="col-lg-4 d-flex justify-content-center pb-2" v-for="transporter in template.transporters" :key="transporter.id">
              <button
                class="btn btn-lg btn-outline-primary w-100"
                @click="selectTransporter(transporter)"
              >
                {{ transporter.name }}
                <br />
                {{ getAddressLine1(transporter) }}
                <br />
                {{ getAddressLine2(transporter) }}
              </button>
            </div>
          </div>
        </template>
        <template v-else>
          <ui-view-card-field :label="$t('transport_document_templates.waste_identification.create_form_modal.select_transporters')">
            <v-select :options="template.transporters" v-model="form.transporterId" :label="'name'" :reduce="transp => transp.id">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
              <template #no-options>
                {{ $t('general.no_options') }}
              </template>
            </v-select>
          </ui-view-card-field>
          <template v-if="transporter">
            <hr />
            <div class="row">
              <div class="col-lg-6">
                {{ $t('form.labels.name') }}: {{transporter.name}}
              </div>
              <div class="col-lg-6">
                {{ $t('form.labels.email') }}: {{transporter.email}}
              </div>
            </div>
            <ui-view-card-address :model-value="transporter.address" />
            <div class="col-lg-6">
              {{ $t('form.labels.language') }}: {{languages.getLanguage(transporter.language)}}
            </div>
            <template v-for="identification in transporter.identifications" :key="identification.id">
              <div class="d-flex">
                {{ $t('form.labels.identification_number') }}:
                <i style="font-size: 0.75rem;" class="bi-question-circle-fill" :title="$t('form.idnumber_title')" />&nbsp;
                <span v-if="identification.identificationType">
                {{ $t('identification_types.' + identification.identificationType) }}: {{ identification.value}}
              </span>
              </div>
            </template>
          </template>
        </template>
      </template>
      <template v-if="currentStep === possibleSteps.CREATE">
        {{ $t('transport_document_templates.waste_identification.create_form_modal.create_form') }}
      </template>
    </template>
    <template #footer>
      <template v-if="currentStep === possibleSteps.PRODUCER">
        <button v-if="currentStepIndex >= 1" type="button" class="btn btn-outline-secondary" @click="back()">
          {{$t('modal.back')}}
        </button>
        <button v-else type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
          {{$t('modal.cancel')}}
        </button>
        <button type="button" class="btn btn-outline-primary" :disabled="!form.producerId" @click="next()">
          {{ currentStepIndex+1 === steps.length ? $t('modal.confirm') : $t('modal.next') }}
        </button>
      </template>
      <template v-if="currentStep === possibleSteps.WASTE">
        <button v-if="currentStepIndex >= 1" type="button" class="btn btn-outline-secondary" @click="back()">
          {{$t('modal.back')}}
        </button>
        <button v-else type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
          {{$t('modal.cancel')}}
        </button>
        <button type="button" class="btn btn-outline-primary" :disabled="!form.wasteIdentificationId" @click="next()">
          {{ currentStepIndex+1 === steps.length ? $t('modal.confirm') : $t('modal.next') }}
        </button>
      </template>
      <template v-if="currentStep === possibleSteps.PROCESSOR">
        <button v-if="currentStepIndex >= 1" type="button" class="btn btn-outline-secondary" @click="back()">
          {{$t('modal.back')}}
        </button>
        <button v-else type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
          {{$t('modal.cancel')}}
        </button>
        <button type="button" class="btn btn-outline-primary" :disabled="!form.processorId" @click="next()">
          {{ currentStepIndex+1 === steps.length ? $t('modal.confirm') : $t('modal.next') }}
        </button>
      </template>
      <template v-if="currentStep === possibleSteps.TRANSPORTER">
        <button v-if="currentStepIndex >= 1" type="button" class="btn btn-outline-secondary" @click="back()">
          {{$t('modal.back')}}
        </button>
        <button v-else type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
          {{$t('modal.cancel')}}
        </button>
        <button type="button" class="btn btn-outline-primary" :disabled="!form.transporterId" @click="next()">
          {{ currentStepIndex+1 === steps.length ? $t('modal.confirm') : $t('modal.next') }}
        </button>
      </template>
      <template v-if="currentStep === possibleSteps.CREATE">
        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
          {{$t('modal.cancel')}}
        </button>
        <button type="button" class="btn btn-outline-primary" @click="next()">
          {{ $t('modal.confirm') }}
        </button>
      </template>
    </template>
  </ui-modal>
</template>

<script>

import UiViewCardField from '@/components/ui-view-card-field'
import {computed, ref} from 'vue'
import UiViewCardAddress from '@/components/ui-view-card-address.vue'
import languages from '../../../types/languages'
import UiModal from '@/components/ui-modal.vue'
import {AddressFormatter} from '@/services/addressFormatter'

export default {
  name: 'WasteIdentificationTemplatesCreateForm',
  computed: {
    languages() {
      return languages
    }
  },
  components: {
    UiModal,
    UiViewCardAddress,
    UiViewCardField,
  },
  props: {
    template: {
      type:    Object,
      default: null,
    },
    callback: {
      type:     Function,
      required: true,
    }
  },
  setup: (props) => {
    const modal = ref('modal')

    const form = ref(null)

    const possibleSteps = {
      PRODUCER:    'PRODUCER',
      PROCESSOR:   'PROCESSOR',
      TRANSPORTER: 'TRANSPORTER',
      WASTE:       'WASTE',
      CREATE:      'CREATE',
    }
    const steps = ref([])
    const currentStep = ref(null)
    const currentStepIndex = ref(0)
    const init = () => {
      form.value = {
        producerId: null,
        wasteIdentificationId: null,
        processorId: null,
        organiserId: null,
        transporterId: null,
      }

      if (props.template.producers.length === 1) {
        form.value.producerId = props.template.producers[0].id
      } else {
        steps.value.push(possibleSteps.PRODUCER)
      }

      if (props.template.wasteIdentificationTemplateProcessors.length === 1) {
        form.value.wasteIdentificationId = props.template.wasteIdentificationTemplateProcessors[0].id
      } else {
        steps.value.push(possibleSteps.WASTE)
      }

      if (availableProcessors.value.length === 1) {
        form.value.processorId = availableProcessors.value[0].id
      } else {
        steps.value.push(possibleSteps.PROCESSOR)
      }

      if (props.template?.organiser?.id) {
        form.value.organiserId = props.template.organiser.id
      } else {
        form.value.organiserId = null
      }

      if (props.template.transporters.length === 1) {
        form.value.transporterId = props.template.transporters[0].id
      } else {
        steps.value.push(possibleSteps.TRANSPORTER)
      }

      if (steps.value.length === 0) {
        steps.value.push(possibleSteps.CREATE)
      }
      currentStepIndex.value = 0
      currentStep.value      = steps.value[currentStepIndex.value]
    }

    const next = () => {
      currentStepIndex.value++
      if (currentStepIndex.value >= steps.value.length) {
        cb()
        return
      }
      currentStep.value = steps.value[currentStepIndex.value]
    }
    const back = () => {
      currentStepIndex.value--
      currentStep.value = steps.value[currentStepIndex.value]
    }

    const producer = computed(() => {
      return props.template.producers.filter((producer) => { return producer.id === form.value.producerId }).length > 0 ?
          props.template.producers.filter((producer) => { return producer.id === form.value.producerId })[0] : {}
    })

    const waste = computed(() => {
      return props.template.wasteIdentificationTemplateProcessors.filter((waste) => { return waste.id === form.value.wasteIdentificationId }).length > 0 ?
          props.template.wasteIdentificationTemplateProcessors.filter((waste) => { return waste.id === form.value.wasteIdentificationId })[0] : {}
    })

    const availableProcessors = computed(() => {
      let processorIds = waste.value.allowedProcessors ?? []
      return props.template.processors.filter((processor) => { return processorIds.indexOf(processor.id) > -1 })
    })

    const processor = computed(() => {
      return props.template.processors.filter((processor) => { return processor.id === form.value.processorId }).length > 0 ?
          props.template.processors.filter((processor) => { return processor.id === form.value.processorId })[0] : {}
    })

    const organiser = computed(() => {
      return [props.template.organiser].filter((organiser) => { return organiser.id === form.value.organiserId }).length > 0 ?
          [props.template.organiser].filter((organiser) => { return organiser.id === form.value.organiserId })[0] : {}
    })

    const transporter = computed(() => {
      return props.template.transporters.filter((transporter) => { return transporter.id === form.value.transporterId }).length > 0 ?
          props.template.transporters.filter((transporter) => { return transporter.id === form.value.transporterId })[0] : {}
    })

    const cb = () => {
      props.callback(form.value)
    }

    const getAddressLine1 = (organisation) => {
      const address = AddressFormatter.formatAddress(organisation.shipmentAddress?.street ? organisation.shipmentAddress : organisation.address)
      return address.line1
    }

    const getAddressLine2 = (organisation) => {
      const address = AddressFormatter.formatAddress(organisation.shipmentAddress?.street ? organisation.shipmentAddress : organisation.address)
      return address.line2
    }

    const selectProducer = (producer) => {
      form.value.producerId = producer.id
      next()
    }

    const selectProcessor = (processor) => {
      form.value.processorId = processor.id
      next()
    }

    const selectTransporter = (transporter) => {
      form.value.transporterId = transporter.id
      next()
    }

    const selectWaste = (waste) => {
      form.value.wasteIdentificationId = waste.id
      next()
    }

    return {
      modal,
      form,
      possibleSteps,
      steps,
      currentStep,
      currentStepIndex,
      next,
      back,
      getAddressLine1,
      getAddressLine2,
      availableProcessors,
      producer,
      processor,
      organiser,
      transporter,
      selectProducer,
      selectProcessor,
      selectTransporter,
      selectWaste,
      cb,
      init,
    }
  }
}
</script>
import FormState from "@/types/formState";

export default {
    template: `<div><span :class="'color-dot-'+color" /> {{ $t("form.state." + params.data.state) }}</div>`,
    setup(props) {
        let color = 'orange'
        switch (props.params.data.state) {
            case FormState.DRAFT: {
                color = 'orange'
                break
            }
            case FormState.EMPTY_CONTAINER: {
                color = 'grey'
                break
            }
            case FormState.SIGNED: {
                color = 'orange'
                break
            }
            case FormState.SHARED: {
                color = 'blue'
                break
            }
            case FormState.ON_THE_ROAD: {
                color = 'blue'
                break
            }
            case FormState.ARRIVED: {
                color = 'orange'
                break
            }
            case FormState.FINISHED: {
                color = 'green'
                break
            }
            case FormState.CANCELLED: {
                color = 'red'
                break
            }
            case FormState.STOPPED: {
                color = 'red'
                break
            }
        }
        return {
          color
        }
    }
}
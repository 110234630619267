import session from '@/util/session'
import i18n, {languages} from '@/util/i18n'

const $t = () => {
    const user = session.getUser()
    return i18n(user?.language ?? languages.NL)
}

const formatAddress = (address) => {
    let line1 = (address.street ?? '') + ' ' + (address.number ?? '')
    let line2 = (address.zip ?? '') + ' ' + (address.city ?? '')
    if (address.country) {
        line2 += line2.length > 1 ? ', ' : ''
        const country = $t()('countries.'+address.country)
        if (country.indexOf('countries.') > -1) { // if translation not found just use filled in address
            line2 += address.country
        } else {
            line2 += country
        }
    }
    return {line1, line2}
}


export const AddressFormatter = {
    formatAddress,
}

import backend from '@/util/backend'
import session from '@/util/session'
import i18n, {languages} from '@/util/i18n'

const getTranslate = () => {
    const user = session.getUser()
  return i18n(user?.language ?? languages.NL)
}

const RemovalOperations = {
  cache: [],
  clear: () => {
    RemovalOperations.cache = []
  },
  get: async () => {
    if (RemovalOperations.cache.length === 0) {
      await backend.get('api/removaloperations').then((result) => {
        result.data.forEach((RDValue) => {
          if (RDValue !== 'TMP') {
            RemovalOperations.cache.push({value: RDValue, label: RDValue + ': ' + getTranslate()('processing_modes.' + RDValue), selectedLabel: 'value'})
          }
        })
      })
    }
    return RemovalOperations.cache
  }
}

export default RemovalOperations

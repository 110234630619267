const FormState = {
  DRAFT: "DRAFT",
  EMPTY_CONTAINER: "EMPTY_CONTAINER",
  SIGNED: "SIGNED",
  SHARED: "SHARED",
  ON_THE_ROAD: "ON_THE_ROAD",
  ARRIVED: "ARRIVED",
  FINISHED: "FINISHED",
  CANCELLED: "CANCELLED",
  STOPPED: "STOPPED",

  getAll: (includeEndStates) => {
    let result = [
      FormState.DRAFT,
      FormState.EMPTY_CONTAINER,
      FormState.SIGNED,
      FormState.SHARED,
      FormState.ON_THE_ROAD,
      FormState.ARRIVED,
      FormState.FINISHED,
      FormState.CANCELLED,
      FormState.STOPPED,
    ]
    if (!includeEndStates) {
      result = result.filter((s) => {
        return !FormState.isEndState(s)
      })
    }
    return result
  },

  // only drafts can be in an invalid form state (e.g. required fields missing)
  isDraft: (state) => {
    return [FormState.DRAFT, FormState.EMPTY_CONTAINER].indexOf(state) > -1
  },

  isEndState: (state) => {
    return [FormState.CANCELLED, FormState.STOPPED, FormState.FINISHED].indexOf(state) > -1
  },

  canExport: (state) => {
    return [FormState.SIGNED, FormState.SHARED, FormState.ON_THE_ROAD, FormState.ARRIVED, FormState.FINISHED, FormState.CANCELLED, FormState.STOPPED].indexOf(state) > -1
  },

  canDelete: (state) => {
    return [FormState.DRAFT, FormState.EMPTY_CONTAINER].indexOf(state) > -1
  },

  canCancel: (state) => {
    return [FormState.SIGNED, FormState.SHARED, FormState.ON_THE_ROAD].indexOf(state) > -1
  },

  canSetStateEmpty: (state) => {
    return [FormState.DRAFT].indexOf(state) > -1
  },

  canShare: (state, alsoArrived = false) => {
    const res = [FormState.SIGNED, FormState.SHARED, FormState.ON_THE_ROAD]
    if (alsoArrived) {
      res.push(FormState.ARRIVED)
    }
    return res.indexOf(state) > -1
  },

  canStart: (state, multipleRides = false) => {
    const arr = [FormState.SIGNED, FormState.SHARED]
    if (multipleRides) { // if we can do multiple rides we can start again after arriving
      arr.push(FormState.ARRIVED)
    }
    return arr.indexOf(state) > -1
  },

  canArrive: (state) => {
    return [FormState.ON_THE_ROAD].indexOf(state) > -1
  },

  canControl: (state) => {
    return [FormState.ON_THE_ROAD].indexOf(state) > -1
  },

  canFinish: (state) => {
    return [FormState.ARRIVED].indexOf(state) > -1
  },
}


export default FormState

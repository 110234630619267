import EuralCodes from '@/models/EuralCodes'
import ProcessingTechniques from '@/models/ProcessingTechniques'
import RemovalOperations from '@/models/RemovalOperations'

const Cache = {
  reset: () => {
    EuralCodes.clear()
    ProcessingTechniques.clear()
    RemovalOperations.clear()
  }
}

export default Cache

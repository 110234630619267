const clearField = (input, field) => {
    // if it's an array then perform it on every item in the array
    if (Array.isArray(input)) {
        return input.map((i) => { return clearField(i, field) })
    }
    // if it's an array then perform it on every key in the object
    else if (typeof input === 'object' && input) {
        const keys = Object.keys(input)
        const cleared = {}
        keys.forEach((k) => {
            if (k !== field) {
                cleared[k] = clearField(input[k], field)
            }
        })
        return cleared
    } else {
        return input
    }
}

export default clearField
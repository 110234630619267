<template>
  <layout>
    <template #header>
      <ui-header :title="$t('annex_7.view.title', {id})" back-route="/annex-7/list">
        <template #actions>
          <div class="btn-group">
            <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="actionsDropdown"
                    data-bs-toggle="dropdown">
              {{ $t('general.actions') }}
            </button>
            <ul data-cy="contacts-actions" class="dropdown-menu">
              <li
                  v-if="$roles([roles.USER])"
              >
                <button class="dropdown-item" @click="duplicate()">
                  {{ $t('annex_7.view.duplicate') }}
                </button>
              </li>
              <li
                  v-if="$roles([roles.USER]) && !FormState.isEndState(form.state)"
                  id="deleteBtn"
                  :title="!FormState.canDelete(form.state) ? $t('annex_7.view.only_draft_deleted') : ''"
              >
                <button class="dropdown-item" :class="!FormState.canDelete(form.state) ? 'disabled' : ''"
                        @click="showDelete()">
                  {{ $t('annex_7.view.delete') }}
                </button>
              </li>
              <li
                  v-if="$roles([roles.USER, roles.DRIVER]) && valid"
                  id="editBtn"
              >
                <button class="dropdown-item" @click="edit()">
                  {{ $t('annex_7.view.edit') }}
                </button>
              </li>
            </ul>
          </div>
          <button
              v-if="!valid"
              data-cy="prim-edit"
              type="button"
              class="btn btn-outline-primary"
              @click="edit()"
          >
            <i class="bi-pencil"/>&nbsp;{{ $t('annex_7.view.edit') }}
          </button>
          <button
              v-if="valid && FormState.isDraft(form.state)"
              data-cy="prim-sign"
              type="button"
              class="btn btn-outline-primary"
              @click="showSign()"
          >
            <i class="bi-pencil"/>&nbsp;{{ $t('annex_7.view.sign') }}
          </button>
          <button
              v-if="valid && FormState.canExport(form.state)"
              data-cy="prim-export"
              type="button"
              class="btn btn-outline-primary"
              @click="exportForm()"
          >
            <i class="bi-cloud-download"/>&nbsp;{{ $t('annex_7.view.export') }}
          </button>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    <ui-content class="pb-1">
      <spinner v-if="loading"/>
      <template v-if="!loading">
        <annex7-form-view :form="form" :key="form.id+'-'+form.lastUpdate"/>
      </template>
    </ui-content>
  </layout>
  <annex7-form v-if="!loading" style="display: none" :item="form" :key="form.id+'-'+form.lastUpdate"/>
  <form-duplicate-modal ref="duplicateModal" :amount="1" :callback="duplicate"/>
  <form-delete-modal ref="deleteModal" :amount="1" :callback="deleteAction"/>
  <form-sign-modal ref="signModal" :callback="sign" :form="form"/>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import backend from '@/util/backend'
import {computed, ref, watch} from 'vue'
import Spinner from '@/components/spinner'
import {useRouter} from 'vue-router'
import {DateFormatter} from '@/services/dateFormatter'
import Notifier from '@/util/notifier'
import FormState from '@/types/formState'
import FormDuplicateModal from '@/views/forms/shared/Duplicate-Modal'
import FormDeleteModal from '@/views/forms/shared/Delete-Modal'
import OrganisationType from '@/types/organisationType'
import DocumentType from '@/types/documentType'
import Annex7Model from '@/models/Annex7Model'
import Annex7FormView from '@/partials/annex7/FormView.vue'
import Annex7Form from '@/partials/annex7/Form.vue'
import FormSignModal from '@/views/forms/shared/Sign-Modal.vue'
import session from '@/util/session'

export default {
  name:       'Annex7ViewUser',
  computed:   {
    DocumentType() {
      return DocumentType
    },
    OrganisationType() {
      return OrganisationType
    },
  },
  components: {
    FormSignModal,
    Annex7Form,
    Annex7FormView,
    FormDeleteModal,
    FormDuplicateModal,
    Spinner,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout,
  },
  props:      {
    id: {
      type:     String,
      required: true,
    },
  },
  setup:      (props) => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.ANNEX_7, BREADCRUMBS.VIEW]
    const notifier    = Notifier()
    const loading     = ref(true)
    const valid       = ref(false)

    const form     = ref({})
    const loadForm = () => {
      backend.get(`api/documents/${props.id}`).then((r) => {
        if (r.data.type !== DocumentType.ANNEX_7) {
          router.push(`/annex-7/list`)
        }
        form.value    = r.data
        loading.value = false

        setTimeout(() => {
          valid.value = !Annex7Model.formCheck(form.value, false)
        }, 1)
      })
    }
    loadForm()

    watch(() => props.id, () => {
      loadForm()
    })

    const router = useRouter()
    const edit   = () => {
      router.push(`/annex-7/edit/${props.id}`)
    }

    const date = computed(() => {
      if (!loading.value) {
        return DateFormatter.formatDate(form.value.transportDate)
      }
      return ''
    })

    const updateDate = computed(() => {
      if (!loading.value) {
        return DateFormatter.formatDateTime(form.value.lastUpdate)
      }
      return ''
    })

    const deleteModal  = ref('deleteModal')
    const showDelete   = () => {
      deleteModal.value.modal.open()
    }
    const deleteAction = async () => {
      await backend.delete(`api/documents/${props.id}`).then((result) => {
        if (result.status === 200) {
          notifier.success('toast.deleting_successful')
          setTimeout(() => {
            router.push(`/annex-7/list`)
          }, 2000)
        } else {
          notifier.error('toast.deleting_failed')
        }
      }).finally(() => {
        deleteModal.value.modal.close()
      })
    }

    const duplicateModal = ref('duplicateModal')
    const showDuplicate  = () => {
      duplicateModal.value.modal.open()
    }
    const duplicate      = async () => {
      loading.value = true
      await backend.post(`api/documents/${props.id}/duplicate`).then((result) => {
        if (result.status === 200) {
          backend.get(`api/documents/${props.id}`).then((doc) => {
            form.value = doc.data
            router.push(`/annex-7/view/${result.data.id}`)
            notifier.success('toast.duplication_successful')
          })
        } else {
          notifier.error('toast.duplication_failed')
        }
      }).finally(() => {
        duplicateModal.value.modal.close()
        loading.value = false
      })
    }

    const signModal = ref('signModal')
    const showSign  = () => {
      const err = Annex7Model.formCheck(form.value, true)
      if (err) {
        notifier.error(err)
        return false
      }
      signModal.value.modal.open()
    }
    const sign      = () => {
      backend.put(`api/documents/${props.id}/sign`).then(() => {
        session.setOrganisation()
        notifier.success('toast.form_signed')
      }).catch((err) => {
        notifier.error(err.response.data.messageKey ?? 'toast.form_sign_failed')
      }).finally(() => {
        loadForm()
        signModal.value.modal.close()
      })
    }

    const exportForm = () => {
      backend.get(`api/documents/${props.id}/pdf`,
          {responseType: 'blob'}).then((result) => {
        if (result.status === 200) {
          const blob = new Blob([result.data], {type: 'application/pdf'})
          const url  = URL.createObjectURL(blob)
          window.open(url)
        } else {
          notifier.error('toast.exporting_failed')
        }
      })
    }

    return {
      breadcrumbs,
      loading,
      date,
      updateDate,
      edit,
      loadForm,
      form,
      FormState,
      duplicateModal,
      showDuplicate,
      duplicate,
      deleteModal,
      showDelete,
      deleteAction,
      valid,
      showSign,
      signModal,
      sign,
      exportForm,
    }
  },
}
</script>

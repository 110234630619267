const CountryType = {
	EXPORT:      'EXPORT',
	TRANSIT:     'TRANSIT',
	DESTINATION: 'DESTINATION',

	getAll: () => {
		return [
			CountryType.EXPORT,
			CountryType.TRANSIT,
			CountryType.DESTINATION,
		]
	},
}

export default CountryType

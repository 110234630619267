<template>
  <layout>
    <template #header>
      <ui-header :title="$t('waste_identification_template.new.title')" back-route="/waste-identification-templates/list">
        <template #actions>
          <button type="button" class="btn btn-outline-primary" :disabled="saving" @click="save()" form="waste-identification-template-form" id="createTemplateBtn"><i class="bi-save" />&nbsp;{{$t('general.create')}}</button>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs" />
    <ui-content class="pb-1">
      <waste-identification-template-form :item="template" />
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import backend from '@/util/backend'
import {ref} from 'vue'
import {useRouter} from 'vue-router'
import Notifier from '@/util/notifier'
import WasteIdentificationTemplateForm from '@/partials/waste-identification-template/Form'
import WasteIdentificationTemplateModel from '@/models/WasteIdentificationTemplateModel'

export default {
  name: 'WasteIdentificationTemplateNew',
  components: {WasteIdentificationTemplateForm, UiContent, UiBreadcrumbs, UiHeader, Layout},
  setup: () => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.WASTES, BREADCRUMBS.NEW]
    const template = ref(JSON.parse(JSON.stringify(WasteIdentificationTemplateModel.empty)))
    const notifier = Notifier()
    const router = useRouter()

    const saving = ref(false)
    const save = async () => {
      saving.value = true
      const formElement = $('#waste-identification-template-form')[0]
      if (!formElement.checkValidity()) {
        // something in the form is not valid so don't allow signing
        formElement.reportValidity()
        saving.value = false
        return false
      }

      const result = await backend.post('api/wasteidentificationtemplates', template.value)
      if (result.status === 200) {
        router.push(`/waste-identification-templates/view/${result.data.id}`)
      } else {
        notifier.error('toast.save_failed')
      }
      saving.value = false
    }

    return {
      breadcrumbs,
      template,
      save,
      saving,
    }
  }
}
</script>

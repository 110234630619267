<template>
  <layout>
    <template #header>
      <ui-header :title="user ? (user.firstName ?? '') + ' ' + (user.lastName ?? '') : ''" :back-route="`/users/view/${id}`">
        <template #actions>
          <button class="btn btn-outline-primary" form="user-form" type="button" @click="save()" :disabled="!valid || saving"><i class="bi-save"/>&nbsp;{{ $t('general.save') }}</button>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs" />
    <ui-content class="pb-1">
      <spinner v-if="loading"/>
      <template v-if="!loading">
        <div class="container-fluid">
          <form id="user-form" class="row g-2 justify-content-center">
            <ui-view-card :title="$t('user.title.user_information')">
              <ui-view-card-field :label="$t('user.labels.email')" v-model="user.email" :required="true" :readonly="true" />
              <ui-view-card-field :label="$t('user.labels.first_name')" v-model="user.firstName" :required="true" />
              <ui-view-card-field :label="$t('user.labels.last_name')" v-model="user.lastName" :required="true" />
              <ui-view-card-language :label="$t('form.labels.language')" v-model="user.language" :required="true" />
              <ui-view-card-field :label="$t('user.labels.roles')" :required="true">
                <v-select :options="roleOptions" multiple v-model="user.organisationRoles[currentOrgIndex].roles" :reduce="role => role.value" id="userRoleSelect">
                  <template #option="option">
                    <i style="font-size: 0.75rem;" class="bi-question-circle-fill" :title="option.title" />&nbsp;
                    {{ option.label }}
                  </template>
                  <template #selected-option="option">
                    <i style="font-size: 0.75rem;cursor: pointer;" class="bi-question-circle-fill" :title="option.title" />&nbsp;
                    {{ option.label }}
                  </template>
                  <template #search="{attributes, events}">
                    <input
                      class="vs__search"
                      :required="user.organisationRoles[currentOrgIndex].roles.length === 0"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <template #no-options>
                    {{ $t('general.no_options') }}
                  </template>
                </v-select>
              </ui-view-card-field>
            </ui-view-card>
          </form>
        </div>
      </template>
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import backend from '@/util/backend'
import {ref, watch} from 'vue'
import {useRouter} from 'vue-router'
import Notifier from '@/util/notifier'
import UiViewCard from '@/components/ui-view-card'
import UiViewCardField from '@/components/ui-view-card-field'
import session from '@/util/session'
import roles from '@/util/roles'
import Spinner from '@/components/spinner'
import UiViewCardLanguage from '@/components/ui-view-card-language.vue'

export default {
  name: 'UserEdit',
  components: {
    UiViewCardLanguage,
    Spinner, UiViewCardField, UiViewCard, UiContent, UiBreadcrumbs, UiHeader, Layout},
  props: {
    id: {
      type:     String,
      required: true,
    }
  },
  setup: (props) => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.USERS, BREADCRUMBS.NEW]
    const loading = ref(true)
    const user = ref({})
    const currentOrgIndex = ref(0)
    const org = session.getOrganisation()
    backend.get(`api/users/${props.id}`).then((r) => {
      user.value = r.data
      user.value.organisationRoles.forEach((o, index) => {
        if (o.organisation.id === org.id) {
          currentOrgIndex.value = index
        }
      })
      loading.value = false
    })
    const notifier = Notifier()
    const router = useRouter()

    const roleOptions = ref(roles.getPossibleRoles())

    const saving = ref(false)
    const save = async () => {
      saving.value = true
      const formElement = $('#user-form')[0]
      if (!formElement.checkValidity()) {
        // something in the form is not valid so don't allow signing
        formElement.reportValidity()
        saving.value = false
        return false
      }

      try {
        const result = await backend.put('api/users/'+props.id, user.value)
        if (result.status === 200) {
          const currentUser = await session.getUser()
          if (props.id === currentUser.id+'') {
            await session.setUser()
          }
          router.push(`/users/view/${result.data.id}`)
        } else {
          notifier.error('toast.save_failed')
        }
      } catch (error) {
        notifier.error('toast.save_failed')
      } finally {
        saving.value = false
      }
    }

    const valid = ref(false)
    watch(() => user.value, (user) => {
      const roles = user.organisationRoles[currentOrgIndex.value].roles
      valid.value = roles && roles.length > 0
    }, {deep: true})

    return {
      breadcrumbs,
      user,
      currentOrgIndex,
      save,
      saving,
      valid,
      loading,
      roleOptions,
    }
  }
}
</script>

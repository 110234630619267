<template>
  <div id="CMRFormView" class="container-fluid">
    <div class="row">
      <ui-view-card id="CMRBasicInfo" data-bs-toggle="collapse" :title="$t('form.title.basic_information')" type="col-lg-12">
        <div class="row">
          <div class="col-lg-6">
            {{ $t('form.labels.unique_identifier') }}: {{'B-'+form.id}}
            <br />
            <template v-if="form.externalId">
              {{ $t('form.labels.external_identifier') }}: {{form.externalId}}
              <br />
            </template>
            {{ $t('form.labels.last_update')}}: {{updateDate}}
            <br />
            {{ $t('form.labels.updated_by')}}: {{form.modifiedBy}}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.status') }}: {{$t('form.state.'+form.state)}}
            <br />
            {{ $t('form.labels.transport_date') }}: {{date}}
          </div>
        </div>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.producer')">
        <div class="row">
          <div class="col-lg-6">
            {{ $t('form.labels.name') }}: {{form.producerIdentification.name}}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.email') }}: {{form.producerIdentification.email}}
          </div>
        </div>
        <ui-view-card-address :model-value="form.producerIdentification.address" />
        <div class="col-lg-6">
          {{ $t('form.labels.language') }}: {{languages.getLanguage(form.producerIdentification.language)}}
        </div>
        <template v-for="identification in form.producerIdentification.identifications" :key="identification.id">
          <div class="d-flex">
            {{ $t('form.labels.identification_number') }}:
            <i style="font-size: 0.75rem;" class="bi-question-circle-fill" :title="$t('form.idnumber_title')" />&nbsp;
            <span v-if="identification.identificationType">
              {{ $t('identification_types.' + identification.identificationType) }}: {{ identification.value}}
            </span>
          </div>
        </template>
        <hr />
        <h6 class="card-subtitle text-muted">
          {{$t('form.sending_address')}}
        </h6>
        <ui-view-card-address :model-value="form.producerIdentification.shipmentAddress" />
      </ui-view-card>
      <ui-view-card :title="$t('form.title.processor')">
        <div class="row">
          <div class="col-lg-6">
            {{ $t('form.labels.name') }}: {{form.processorIdentification.name}}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.email') }}: {{form.processorIdentification.email}}
          </div>
        </div>
        <ui-view-card-address :model-value="form.processorIdentification.address" />
        <div class="col-lg-6">
          {{ $t('form.labels.language') }}: {{languages.getLanguage(form.processorIdentification.language)}}
        </div>
        <template v-for="identification in form.processorIdentification.identifications" :key="identification.id">
          <div class="d-flex">
            {{ $t('form.labels.identification_number') }}:
            <i style="font-size: 0.75rem;" class="bi-question-circle-fill" :title="$t('form.idnumber_title')" />&nbsp;
            <span v-if="identification.identificationType">
              {{ $t('identification_types.' + identification.identificationType) }}: {{ identification.value}}
            </span>
          </div>
        </template>
        <hr />
        <h6 class="card-subtitle text-muted">
          {{$t('form.receiving_address')}}
        </h6>
        <ui-view-card-address :model-value="form.processorIdentification.shipmentAddress" />
      </ui-view-card>
      <ui-view-card :title="$t('form.title.organiser')">
        <template #subtitle>{{$t('form.organiser_subtitle')}}</template>
        {{ $t('form.labels.name') }}: {{form.organiserIdentification.name}}
        <br />
        <ui-view-card-address :model-value="form.organiserIdentification.address" />
        <div class="col-lg-6">
          {{ $t('form.labels.language') }}: {{languages.getLanguage(form.organiserIdentification.language)}}
        </div>
        <template v-for="identification in form.organiserIdentification.identifications" :key="identification.id">
          <div class="d-flex">
            {{ $t('form.labels.identification_number') }}:
            <i style="font-size: 0.75rem;" class="bi-question-circle-fill" :title="$t('form.idnumber_title')" />&nbsp;
            <span v-if="identification.identificationType">
              {{ $t('identification_types.' + identification.identificationType) }}: {{ identification.value}}
            </span>
          </div>
        </template>
      </ui-view-card>
      <template v-for="(transporter, index) in form.transporterIdentifications" :key="index">
        <ui-view-card :title="index === 0 ? $t('form.title.primary_transporter') : $t('form.title.transporter_count', {count: index+1})">
          {{ $t('form.labels.name') }}: {{transporter.name}}
          <br />
          <ui-view-card-address v-model="transporter.address" />
          <div class="col-lg-6">
            {{ $t('form.labels.language') }}: {{languages.getLanguage(transporter.language)}}
          </div>
          <template v-for="identification in transporter.identifications" :key="identification.id">
            <div class="d-flex">
              {{ $t('form.labels.identification_number') }}:
              <i style="font-size: 0.75rem;" class="bi-question-circle-fill" :title="$t('form.idnumber_title')" />&nbsp;
              <span v-if="identification.identificationType">
              {{ $t('identification_types.' + identification.identificationType) }}: {{ identification.value}}
            </span>
            </div>
          </template>
          <div class="col-lg-6">
            {{ $t('form.labels.license_plate') }}: {{transporter.licensePlate}}
          </div>
        </ui-view-card>
      </template>
      <ui-view-card :title="$t('form.title.goods')">
        {{ form.cmrGoods }}
      </ui-view-card>
      <ui-view-card :title="$t('form.title.costs')">
        <table class="table table-bordered table-striped">
          <thead>
          <tr>
            <th scope="col">{{$t('form.table.type')}}</th>
            <th scope="col">{{$t('form.table.description')}}</th>
            <th scope="col">{{$t('form.table.amount')}}</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(row, index) in form.transportCosts" :key="index">
            <tr>
              <td>
                <span v-if="row.transportCostType">
                  {{ $t('transport_cost_types.' + row.transportCostType) }}
                </span>
              </td>
              <td>
                {{ row.description}}
              </td>
              <td>

                {{ '€' +  row.amount }}
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </ui-view-card>
    </div>
    <div class="row">
      <ui-view-card-signature :form="form" :processor-info="processorInfo" @signed="$emit('signed')" :uuid="uuid" :sharelink="sharelink" />
    </div>
  </div>
</template>
<script>
import UiViewCardSignature from '@/components/ui-view-card-signature'
import UiViewCard from '@/components/ui-view-card'
import UiViewCardAddress from '@/components/ui-view-card-address'
import {computed} from 'vue'
import {DateFormatter} from '@/services/dateFormatter'
import languages from '@/types/languages'

export default {
  name: 'ECmrFormView',
  components: {UiViewCardAddress, UiViewCard, UiViewCardSignature},
  props: {
    form: {
      type:     Object,
      required: true,
    },
    processorInfo: {
      type:     Object,
      default:  () => {},
    },
    uuid: {
      type:    String,
      default: '',
    },
    sharelink: {
      type:    Boolean,
      default: false,
    },
  },
  emits: [ 'signed' ],
  setup: (props) => {
    const date = computed(() => {
      return DateFormatter.formatDate(props.form.transportDate)
    })

    const updateDate = computed(() => {
      return DateFormatter.formatDateTime(props.form.lastUpdate)
    })

    return {
      date,
      updateDate,
      languages,
    }
  }
}
</script>

import DocumentType from '@/types/documentType'

const ECmrModel = {
    empty: {
        id: null,
        externalId: null,
        type: DocumentType.E_CMR,
        state: 'DRAFT',
        notes: '',
        transportDate: null,
        producerIdentification: {
            language: 'NL',
            address: {},
            shipmentAddress: {},
            identifications: [{
                value: null,
                identificationType: null
            }],
        },
        organiserIdentification: {
            language: 'NL',
            address: {},
            identifications: [{
                value: null,
                identificationType: null
            }],
        },
        transporterIdentifications: [{
            language: 'NL',
            address: {},
            identifications: [{
                value: null,
                identificationType: null
            }],
        }],
        processorIdentification: {
            language: 'NL',
            address: {},
            identifications: [{
                value: null,
                identificationType: null
            }],
        },
        organiserSignature: null,
        processorSignature: null,
        startPoint: null,
        endPoint: null,
        cmrGoods: '',
        transportSignature: null,
        transportCosts: [],
    }
}

export default ECmrModel

import session from '@/util/session'
import i18n, {languages} from '@/util/i18n'

const $t = () => {
	const user = session.getUser()
	return i18n(user?.language ?? languages.NL)
}

const Annex7RecoveryDestinationType = {
	LABORATORY:        'LABORATORY',
	RECOVERY_FACILITY: 'RECOVERY_FACILITY',

	getAll: () => {
		return [
			{
				label: $t()('form.table.recovery_destination_type.LABORATORY'),
				value: Annex7RecoveryDestinationType.LABORATORY,
			},
			{
				label: $t()('form.table.recovery_destination_type.RECOVERY_FACILITY'),
				value: Annex7RecoveryDestinationType.RECOVERY_FACILITY,
			},
		]
	},
}

export default Annex7RecoveryDestinationType

<template>
  <ui-modal ref="modal" :callback="callback">
    <template #title>
      {{ $t('sys_admin.org_details.add_api_key_modal.title')}}
    </template>
    <template #default>
      {{ $t('sys_admin.org_details.add_api_key_modal.text') }}
    </template>
  </ui-modal>
</template>

<script>

import {ref} from 'vue'
import UiModal from '@/components/ui-modal'

export default {
  name: 'AdminAddApiKeyModal',
  components: {
    UiModal
  },
  props: {
    callback: {
      type:     Function,
      required: true,
    }
  },
  setup: () => {
    const modal = ref('modal')

    return {
      modal,
    }
  }
}
</script>

<template>
  <template v-if="signatureRef?.signingDate">
    {{ signatureRef.firstName + ' ' + signatureRef.lastName }}
    <template v-if="signatureRef.email">
      <br/>
      {{ signatureRef.email }}
    </template>
    <br/>
    {{ signatureRef.organisation }}
    <br/>
    {{ formatDate(signatureRef.signingDate) }}
  </template>
  <template v-else-if="canSign() || canRequest()">
    <div class="btn-group">
      <button v-if="canSign()" id="signBtn" type="button" class="btn btn-outline-primary" @click="showSign()"><i
          class="bi-pencil-square"/>&nbsp;{{ $t('signature.sign') }}
      </button>
      <button v-if="canRequest()" id="requestSignBtn" type="button" class="btn btn-outline-primary"
              @click="showRequestSign()"><i class="bi-send"/>&nbsp;{{ $t('signature.request_signature') }}
      </button>
    </div>
  </template>
  <template v-else>
    <h4 class="text-center">
      {{ $t('signature.not_signed') }}
    </h4>
    <h6 v-if="subtitle" class="text-center text-muted">
      {{ subtitle }}
    </h6>
  </template>
  <form-sign-form-modal v-if="rideId === 0" ref="signModal" :callback="sign" :signature-type="signatureType"/>
  <form-sign-ride-modal v-else ref="signModal" :callback="signRide" :signature-type="signatureType"/>
  <form-request-sign-form-modal ref="requestSignModal" :callback="requestSignForm" :signature-type="signatureType"
                                :email="email" :language="language"/>
</template>

<script>

import {computed, inject, ref} from 'vue'
import backend from '@/util/backend'
import Notifier from '@/util/notifier'
import session from '@/util/session'
import FormSignRideModal from '@/views/forms/shared/Sign-Ride-Modal'
import FormSignFormModal from '@/views/forms/shared/Sign-Basic-Modal'
import FormRequestSignFormModal from '@/views/forms/shared/Request-Sign-Form-Modal.vue'
import SignatureType from '@/types/signatureType'
import ShareLinkType from '@/types/shareLinkType'
import {languages} from '@/util/i18n'

export default {
  name:       'ui-view-card-signature-field',
  components: {
    FormRequestSignFormModal,
    FormSignFormModal,
    FormSignRideModal,
  },
  props:      {
    signature:     {
      type:    Object,
      default: () => {
      },
    },
    id:            {
      type:    Number,
      default: -1,
    },
    signable:      {
      type:    Boolean,
      default: false,
    },
    request:       {
      type:    Boolean,
      default: false,
    },
    email:         {
      type:    String,
      default: '',
    },
    edit:          {
      type:    Boolean,
      default: false,
    },
    rideId:        {
      type:    Number,
      default: 0,
    },
    signatureType: {
      type:    String,
      default: null,
    },
    uuid:          {
      type:    String,
      default: '',
    },
    subtitle:      {
      type:    String,
      default: '',
    },
    documentType:  {
      type:    String,
      default: '',
    },
  },
  emits:      ['signed'],
  setup:      (props, {emit}) => {
    const isSharelink      = inject('isSharelink')
    const signModal        = ref('signModal')
    const requestSignModal = ref('requestSignModal')
    const notifier         = Notifier()
    const signatureRef     = ref(props.signature)

    const showSign = () => {
      signModal.value.modal.open()
    }

    const sign = (info) => {
      const url = isSharelink ? `api/sharedlinks/${props.uuid}/signBasic` : `api/documents/${props.id}/signBasic`
      backend.put(url, info).then(() => {
        session.setOrganisation()
        notifier.success('toast.form_signed')
        emit('signed')
      }).catch((err) => {
        notifier.error(err.response.data.messageKey ?? 'toast.form_sign_failed')
      }).finally(() => {
        signModal.value.modal.close()
      })
    }

    const signRide = (info) => {
      const url = isSharelink
                  ? `api/sharedlinks/${props.uuid}/rides/${props.rideId}/sign`
                  : `api/documents/${props.id}/rides/${props.rideId}/sign`
      backend.put(url, info).then(() => {
        session.setOrganisation()
        notifier.success('toast.ride_signed')
        emit('signed')
      }).catch(() => {
        notifier.error('toast.ride_sign_failed')
      }).finally(() => {
        signModal.value.modal.close()
      })
    }

    const dateOptions = {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
    }

    const formatDate = (date) => {
      const d = new Date(date)
      return new Intl.DateTimeFormat('nl', dateOptions).format(d)
    }

    // Can only ever sign on View page, not Edit pages
    const canSign = () => {
      return !props.edit && props.signable
    }

    // Can only ever request sign on View page, not Edit pages
    const canRequest = () => {
      return !props.edit && props.request
    }

    const showRequestSign = () => {
      requestSignModal.value.modal.open()
    }

    const requestSignForm = (info) => {
      const url         = `api/documents/${props.id}/requestSignature`
      let shareLinkType = null
      switch (props.signatureType) {
        case SignatureType.TRANSPORTER: {
          shareLinkType = ShareLinkType.SIGNATURE_TRANSPORTER
          break
        }
        case SignatureType.PROCESSOR: {
          shareLinkType = ShareLinkType.SIGNATURE_PROCESSOR
          break
        }
      }
      if (!shareLinkType) {
        notifier.error('toast.signature_request_failed')
        return
      }
      const payload = {
        email:         info.email,
        language:      info.language,
        shareLinkType: shareLinkType,
      }
      backend.put(url, payload).then(() => {
        notifier.success('toast.signature_requested')
      }).catch(() => {
        notifier.error('toast.signature_request_failed')
      }).finally(() => {
        requestSignModal.value.modal.close()
      })
    }

    const language = computed(() => {
      return session?.getUser()?.language ?? languages.NL
    })

    return {
      isSharelink,
      // signing
      signModal,
      canSign,
      showSign,
      sign,
      signRide,
      // requesting
      canRequest,
      requestSignModal,
      showRequestSign,
      requestSignForm,
      // signature
      formatDate,
      signatureRef,
      language,
    }
  },
}
</script>

<template>
  <layout :key="language">
    <template #header>
      <ui-header :title="(user.firstName ?? '') + ' ' + (user.lastName ?? '')" :back-route="'/profile'" />
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs" />
    <ui-content class="pb-1">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <form id="user-form" class="row justify-content-center">
            <ui-view-card :title="$t('user.title.user_information')">
              <template #actions>
                <button type="button" class="btn btn-outline-primary" :disabled="saving" @click="save()" form="user-form"><i class="bi-save" />&nbsp;{{$t('general.save')}}</button>
              </template>
              <ui-view-card-field data-cy="profile-email" :label="$t('user.labels.email')" v-model="user.email" required readonly />
              <ui-view-card-field data-cy="profile-first-name" :label="$t('user.labels.first_name')" v-model="user.firstName" required />
              <ui-view-card-field data-cy="profile-last-name" :label="$t('user.labels.last_name')" v-model="user.lastName" required />
              <ui-view-card-language :label="$t('user.labels.language')" v-model="user.language" required own-language />
            </ui-view-card>
          </form>
        </div>
      </div>
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import {inject, ref} from 'vue'
import UiViewCard from '@/components/ui-view-card'
import roles from '@/util/roles'
import session from '@/util/session'
import UiViewCardField from '@/components/ui-view-card-field'
import backend from '@/util/backend'
import Notifier from '@/util/notifier'
import {useRouter} from 'vue-router'
import UiViewCardLanguage from '@/components/ui-view-card-language'
import languages from '@/types/languages'
import Cache from '@/services/cache'

export default {
  name: 'ProfileEdit',
  components: {
    UiViewCardLanguage,
    UiViewCardField,
    UiViewCard,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout
  },
  setup: () => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.PROFILE]
    const user = ref(session.getUser())
    const notifier = Notifier()
    const $tset = inject('$tset')
    const language = ref(user.value.language)
    const router = useRouter()

    const saving = ref(false)
    const save = async () => {
      saving.value = true
      const formElement = $('#user-form')[0]
      if (!formElement.checkValidity()) {
        // something in the form is not valid so don't allow signing
        formElement.reportValidity()
        saving.value = false
        return false
      }

      const result = await backend.put(`api/users/${user.value.id}`, user.value)
      if (result.status === 200) {
        await session.setUser()
        setTimeout(() => {
          notifier.success('toast.successfully_saved')
        }, 10)
        language.value = session.getLanguage()
        Cache.reset()
        $tset(session.getLanguage())
        await router.push({name: 'Profile'})
      } else {
        notifier.error('toast.save_failed')
      }
      saving.value = false
    }

    return {
      breadcrumbs,
      router,
      user,
      roles,
      save,
      saving,
      language,
      languages,
    }
  }
}
</script>

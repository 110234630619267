<template>
  <layout>
    <template #header>
      <ui-header :title="$t('matis.list.matis')"/>
    </template>
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
      </div>
      <div>
        <button class="btn btn-sm btn-outline-secondary" @click="resetGrid()">{{ $t('general.reset_filters') }}</button>
      </div>
    </div>
    <ui-content>
      <ag-grid-vue
        :serverSideDatasource="grid.datasource('api/matisuploads/list')"
        :grid-options="grid.defaultOptions"
        :columnDefs="columnDefs"
        class="ag-theme-quartz"
        style="min-height:100%"
        @row-clicked="rowClick"
        @selection-changed="refreshSelection"
        @grid-ready="onGridReady"
        @grid-pre-destroyed="onGridPreDestroyed"
        @filter-changed="onFilterChanged"
        :initialState="initialState"
        :getRowId="getRowId"
      />
    </ui-content>
  </layout>
</template>

<script>

import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import UiHeader from '@/components/ui-header'
import Layout from '@/components/layout'
import UiContent from '@/components/ui-content'
import BREADCRUMBS from '@/util/breadcrumbs'
import {inject, onBeforeUnmount, ref} from 'vue'
import {AgGridVue} from 'ag-grid-vue3'
import matisStateColorRenderer from '@/util/matisStateColorRenderer'
import {DateFormatter} from '@/services/dateFormatter'
import {useRouter} from 'vue-router'
import grid from '@/util/grid'
import session from '@/util/session'

export default {
  name: 'MatisList',
  computed: {
    grid() {
      return grid
    }
  },
  components: {
    AgGridVue,
    UiContent,
    Layout,
    UiHeader,
    UiBreadcrumbs,
    // eslint-disable-next-line
    matisStateColorRenderer,
  },
  setup: () => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.MATIS]
    const $t = inject('$t')

    const gridKey = 'matis-list'
    const initialState = ref(session.loadFormState(gridKey))
    const onGridPreDestroyed = (params) => {
      session.saveFormState(gridKey, params.state)
    }
    const onFilterChanged = (event) => {
      session.saveFormState(gridKey, event.api.getState())
    }

    let api = null
    const onGridReady = (params) => {
      api = params.api
      api.deselectAll() // if we can correctly get selection from saved state we can re-evaluate this
      api.sizeColumnsToFit({'defaultMinWidth': 200})
    }
    onBeforeUnmount(() => {
      api = null
    })

    const resize = () => {
      setTimeout(() => {
        api.sizeColumnsToFit({'defaultMinWidth': 200})
      }, 1)
    }
    window.addEventListener("resize", resize)
    onBeforeUnmount(() => {
      window.removeEventListener("resize", resize)
    })

    const resetGrid = () => {
      api?.setFilterModel(null)
      api?.onFilterChanged()
      session.saveFormState(gridKey, null)
    }

    const columnDefs = [
      {
        headerName: $t('matis.list.id'),
        field: 'matisIdentification'
      },
      {
        headerName: $t('matis.list.external_document_id'),
        field: 'externalDocumentId',
      },
      {
        headerName: $t('matis.list.date'),
        filter: 'agDateColumnFilter',
        field: 'uploadDate',
        valueGetter: (params) => {
          return new Date(params.data.uploadDateTime)
        },
        valueFormatter: (params) => {
          return DateFormatter.formatDate(params.data.uploadDateTime)
        },
        filterParams: {
          minValidYear: 2000,
          maxValidYear: 2099,
          filterOptions: ['inRange'],
        },
      },
      {
        headerName: $t('matis.list.matis_reference_id'),
        field: 'matisReferenceId'
      },
      {
        headerName: $t('matis.list.state'),
        field: 'uploaded',
        cellRenderer: 'matisStateColorRenderer',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['DONE', 'ERROR'],
        },
        valueGetter: (params) => {
          return params.data.matisReferenceId ? 'DONE' : 'ERROR'
        },
        comparator: grid.caseInsensitiveStateComparator("matis.state."),
        filterValueGetter: (params) => {
          return $t("matis.state." + params.data.state)
        }
      },
    ]

    const selectedIDs = ref([])
    const refreshSelection = (params) => {
      selectedIDs.value = params.api.getSelectedRows().map((row) => {
        return row.id
      })
    }

    const router = useRouter()
    const rowClick = (event) => {
      if (event.data) {
        router.push(`/matis/${event.data.matisIdentification}`)
      }
    }

    const getRowId = (row) => {
      return row.data.matisIdentification
    }

    return {
      breadcrumbs,
      onGridReady,
      onGridPreDestroyed,
      onFilterChanged,
      initialState,
      columnDefs,
      selectedIDs,
      refreshSelection,
      rowClick,
      getRowId,
      resetGrid,
    }
  }
}
</script>

<template>
  <ui-modal ref="modal" :callback="cb" :can-confirm="isValid">
    <template #title>
      {{ $t('grondbank.sign_ride.title')}}
    </template>
    <template #default>
      {{ $t('grondbank.sign_ride.text') }}
      <br />
      <br />
      <form id="grondbank-sign-ride-form">
        <ui-view-card-field :label="$t('forms.sign_ride_modal.end_hour')" :required="true">
          <datepicker time-picker data-cy="grondbank-ride-start-time" v-model="info.endOfTransport" />
        </ui-view-card-field>
        <ui-view-card-field :label="$t('forms.sign_ride_modal.first_name')" v-model="info.firstName" :required="true" />
        <ui-view-card-field :label="$t('forms.sign_ride_modal.last_name')" v-model="info.lastName" :required="true" />
        <ui-view-card-field :label="$t('forms.sign_ride_modal.organisation')" v-model="info.organisation" :required="true" />
      </form>
    </template>
  </ui-modal>
</template>

<script>

import UiModal from '@/components/ui-modal'
import {ref, watch} from 'vue'
import Datepicker from '@vuepic/vue-datepicker'
import UiViewCardField from '@/components/ui-view-card-field'
import SignatureType from '@/types/signatureType'

export default {
  name: 'SignRideModal',
  components: {
    UiViewCardField,
    Datepicker,
    UiModal
  },
  props: {
    callback: {
      type:     Function,
      required: true,
    },
    endOfTransport: {
      type:    String,
      default: '',
    },
  },
  setup: (props) => {
    const modal = ref('modal')
    const info = ref({
      signatureType: SignatureType.PROCESSOR,
      endOfTransport: props.endOfTransport ? {
        hours: props.endOfTransport.slice(0,2),
        minutes: props.endOfTransport.slice(3,5)
      } : '',
    })

    watch(() => props.endOfTransport, () => {
      info.value.endOfTransport = props.endOfTransport ? {
        hours: props.endOfTransport.slice(0,2),
        minutes: props.endOfTransport.slice(3,5)
      } : ''
    })

    const cb = async () => {
      const formElement = $('#grondbank-sign-ride-form')[0]
      if (!formElement.checkValidity()) {
        // something in the form is not valid so don't allow signing
        formElement.reportValidity()
        return false
      }

      let res = props.callback(info.value)
      if (res) {
        modal.value.close()
      }
    }

    const isValid = () => {
      return info.value.signatureType && info.value.firstName && info.value.lastName && info.value.organisation && info.value.endOfTransport
    }

    return {
      modal,
      info,
      cb,
      isValid,
    }
  }
}
</script>

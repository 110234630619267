<template>
  <ui-modal ref="modal" :callback="cb" :can-confirm="isValid" >
    <template #title>
      {{ $t('credentials.add_modal.title')}}
    </template>
    <template #default>
      <ui-view-card-field :label="$t('credentials.application')">
        <v-select data-cy="language" :options="types" v-model="credentials.credentialApplication">
          <template #search="{attributes, events}">
            <input
              class="vs__search"
              :required="!credentials.credentialApplication"
              v-bind="attributes"
              v-on="events"
            />
          </template>
          <template #no-options>
            {{ $t('general.no_options') }}
          </template>
        </v-select>
      </ui-view-card-field>
      <template v-if="credentials.credentialApplication === CredentialApplicationType.MATIS">
        <ui-view-card-field label="">
          <div class="d-flex align-items-center">
            <input class="form-check-input flex-shrink-0 me-2" type="checkbox" v-model="agree">
            <label>
              {{ $t('credentials.add_modal.matis_legal') }}<span :title="$t('general.required')" class="text-danger">*</span>
            </label>
          </div>
        </ui-view-card-field>
        <ui-view-card-field
          :label="$t('credentials.add_modal.client_id')"
          v-model="credentials.clientId"
          :required="true"
          placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
          icon="bi-question-circle-fill"
          :icon-text="$t('help.matis_client_id')"
        />
        <ui-view-card-field
          :label="$t('credentials.add_modal.client_secret')"
          v-model="credentials.clientSecret"
          :required="true"
          :maxlength="32"
          placeholder="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
          icon="bi-question-circle-fill"
          :icon-text="$t('help.matis_client_secret')"
        />
        <ui-view-card-field :label="$t('credentials.add_modal.active_from')" :required="true">
          <datepicker data-cy="credentials-active-from-date"
                      v-model="credentials.activeFrom"
                      :enableTimePicker="false"
                      :autoApply="true"
                      format="dd/MM/yyyy"
                      :start-time="{ hours: 0, minutes: 0 }"
                      :required="true"
          />
        </ui-view-card-field>
      </template>
    </template>
  </ui-modal>
</template>

<script>

import {ref} from 'vue'
import UiModal from '@/components/ui-modal'
import UiViewCardField from '@/components/ui-view-card-field'
import CredentialApplicationType from '@/types/credentialApplicationType'
import Datepicker from '@vuepic/vue-datepicker'
import Notifier from '@/util/notifier'

export default {
  name: 'CredentialsAddModal',
  components: {
    Datepicker,
    UiViewCardField,
    UiModal
  },
  props: {
    callback: {
      type:     Function,
      required: true,
    },
    types: {
      type:     Array,
      required: true,
    }
  },
  setup: (props) => {
    const modal = ref('modal')
    const credentials = ref({})

    const notifier = Notifier()
    const cb = async () => {
      switch (credentials.value.credentialApplication) {
        case CredentialApplicationType.MATIS: {
          const regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
          if (!regex.test(credentials.value.clientId)) {
            notifier.error('toast.matis_client_uuid')
            return
          }
          if (!(credentials.value.clientSecret.length === 32)) {
            notifier.error('toast.matis_client_secret')
            return
          }
        }
      }

      await props.callback(credentials.value)
      credentials.value = {}
    }

    const isValid = () => {
      switch (credentials.value.credentialApplication) {
        case CredentialApplicationType.MATIS: {
          return agree.value && credentials.value.clientId && credentials.value.clientSecret && credentials.value.activeFrom
        }
      }
      return !!credentials.value.type
    }

    const agree = ref(false)

    return {
      modal,
      cb,
      isValid,
      credentials,
      CredentialApplicationType,
      agree,
    }
  }
}
</script>

<template>
  <Popper arrow class="light" @close="closePopper" :show="showPopper && suggestions.length > 0"
          v-click-outside="closePopper">
    <ui-view-card-field :data-cy="cy+'-name'" v-if="haveField(OrganisationField.NAME)" :label="$t('form.labels.name')"
                        v-model="model.name"
                        :required="isFieldRequired(OrganisationField.NAME)"
    />
    <template #content>
      <div v-for="suggestion in suggestions" :key="suggestion.name" class="popper-suggestion">
        <div @click="useSuggestion(suggestion)">
          <span v-if="suggestion.address && suggestion.address.street && suggestion.address.number">
            {{ suggestion.name }}: {{ suggestion.address.street }} {{ suggestion.address.number }}
          </span>
          <span v-else>{{ suggestion.name }}</span>
        </div>
      </div>
    </template>
  </Popper>
  <ui-view-card-field :data-cy="cy+'-email'" v-if="haveField(OrganisationField.EMAIL)" v-model="model.email"
                      :label="$t('form.labels.email')"
                      :required="isFieldRequired(OrganisationField.EMAIL)" type="email" :maxlength="50"
  />
  <template v-if="haveField(OrganisationField.LANGUAGE) && haveField(OrganisationField.CONTACTPERSON)">
    <div class="row">
      <div class="col-lg-6">
        <ui-view-card-field :data-cy="cy+'-contactPerson'" v-model="model.contactPerson"
                            :label="$t('form.labels.contact_person')"
                            :required="isFieldRequired(OrganisationField.CONTACTPERSON)" :maxlength="50"
        />
      </div>
      <div class="col-lg-6">
        <ui-view-card-language :data-cy="cy+'-language'" :label="$t('form.labels.language')"
                               v-model="model.language" :required="isFieldRequired(OrganisationField.LANGUAGE)"/>
      </div>
    </div>
  </template>
  <template v-else>
    <ui-view-card-field :data-cy="cy+'-contactPerson'" v-if="haveField(OrganisationField.CONTACTPERSON)"
                        v-model="model.contactPerson"
                        :label="$t('form.labels.contact_person')"
                        :required="isFieldRequired(OrganisationField.CONTACTPERSON)"
                        :maxlength="50"
    />
    <ui-view-card-language :data-cy="cy+'-language'" v-if="haveField(OrganisationField.LANGUAGE)"
                           :label="$t('form.labels.language')"
                           v-model="model.language" :required="isFieldRequired(OrganisationField.LANGUAGE)"
    />
  </template>
  <template v-if="haveField(OrganisationField.PHONE) && haveField(OrganisationField.FAX)">
    <div class="row">
      <div class="col-lg-6">
        <ui-view-card-field :data-cy="cy+'-phone'" v-model="model.phone" :label="$t('form.labels.phone_number')"
                            :required="isFieldRequired(OrganisationField.PHONE)" :maxlength="50"
        />
      </div>
      <div class="col-lg-6">
        <ui-view-card-field :data-cy="cy+'-fax'" v-model="model.fax" :label="$t('form.labels.fax')"
                            :required="isFieldRequired(OrganisationField.FAX)" :maxlength="30"
        />
      </div>
    </div>
  </template>
  <template v-else>
    <ui-view-card-field :data-cy="cy+'-phone'" v-if="haveField(OrganisationField.PHONE)" v-model="model.phone"
                        :label="$t('form.labels.phone_number')"
                        :required="isFieldRequired(OrganisationField.PHONE)"
                        :maxlength="50"
    />
    <ui-view-card-field :data-cy="cy+'-fax'" v-if="haveField(OrganisationField.FAX)" v-model="model.fax"
                        :label="$t('form.labels.fax')"
                        :required="isFieldRequired(OrganisationField.FAX)"
                        :maxlength="30"
    />
  </template>
  <template v-if="haveField(OrganisationField.MEANSOFTRANSPORT) && haveField(OrganisationField.TRANSPORTDATE)">
    <div class="row">
      <div class="col-lg-6">
        <ui-view-card-field :data-cy="cy+'-meansOfTransport'" v-if="haveField(OrganisationField.MEANSOFTRANSPORT)"
                            :label="$t('form.labels.means_of_transport')"
                            :required="isFieldRequired(OrganisationField.MEANSOFTRANSPORT)">
          <v-select :options="meansOfTransport.getForDocumentType(documentType)" v-model="model.meansOfTransport"
                    :reduce="operation => operation.value">
            <template #search="{attributes, events}">
              <input
                  :required="isFieldRequired(OrganisationField.MEANSOFTRANSPORT) && !model.meansOfTransport"
                  class="vs__search"
                  v-bind="attributes"
                  v-on="events"
              />
            </template>
            <template #no-options>
              {{ $t('general.no_options') }}
            </template>
          </v-select>
        </ui-view-card-field>
      </div>
      <div class="col-lg-6">
        <ui-view-card-field :data-cy="cy+'-date'" v-if="haveField(OrganisationField.TRANSPORTDATE)"
                            :label="$t('form.labels.transport_date')"
                            :required="isFieldRequired(OrganisationField.TRANSPORTDATE)">
          <datepicker v-model="model.transportDate" :enableTimePicker="false" :autoApply="true" :minDate="new Date()"
                      format="dd/MM/yyyy" :required="true"/>
        </ui-view-card-field>
      </div>
    </div>
  </template>
  <template v-else>
    <ui-view-card-field :data-cy="cy+'-meansOfTransport'" v-if="haveField(OrganisationField.MEANSOFTRANSPORT)"
                        :label="$t('form.labels.means_of_transport')"
                        :required="isFieldRequired(OrganisationField.MEANSOFTRANSPORT)">
      <v-select :options="meansOfTransport.getForDocumentType(documentType)" v-model="model.meansOfTransport"
                :reduce="operation => operation.value">
        <template #search="{attributes, events}">
          <input
              :required="isFieldRequired(OrganisationField.MEANSOFTRANSPORT) && !model.meansOfTransport"
              class="vs__search"
              v-bind="attributes"
              v-on="events"
          />
        </template>
        <template #no-options>
          {{ $t('general.no_options') }}
        </template>
      </v-select>
    </ui-view-card-field>
    <ui-view-card-field :data-cy="cy+'-date'" v-if="haveField(OrganisationField.TRANSPORTDATE)"
                        :label="$t('form.labels.transport_date')"
                        :required="isFieldRequired(OrganisationField.TRANSPORTDATE)">
      <datepicker v-model="model.transportDate" :enableTimePicker="false" :autoApply="true" :minDate="new Date()"
                  format="dd/MM/yyyy" :required="true"/>
    </ui-view-card-field>
  </template>
  <ui-view-card-address :cy="cy" v-if="haveField(OrganisationField.ADDRESS)" :edit="true"
                        v-model="model.address" :required="isFieldRequired(OrganisationField.ADDRESS)"/>
  <ui-view-card-field :cy="cy" v-if="haveField(OrganisationField.LOCATIONINFO)"
                      :required="isFieldRequired(OrganisationField.LOCATIONINFO)"
                      :label="$t('form.labels.other_location_info')" v-model="model.otherLocationDetermination"
                      :maxlength="50"
  />
  <ui-view-card-field :cy="cy" v-if="haveField(OrganisationField.DESTINATIONTYPE)"
                      :required="isFieldRequired(OrganisationField.DESTINATIONTYPE)"
                      :label="$t('form.labels.destination_type')" v-model="model.destinationType"
                      :maxlength="50"
  />
  <ui-view-card-field :data-cy="cy+'-annex7RecoveryDestinationType'"
                      v-if="haveField(OrganisationField.ANNEX7RECOVERYDESTINATIONTYPE)"
                      :label="$t('form.labels.annex_7_recovery_destination_type')">
    <v-select :options="Annex7RecoveryDestinationType.getAll()" v-model="model.annex7RecoveryDestinationType"
              :reduce="operation => operation.value">
      <template #search="{attributes, events}">
        <input
            class="vs__search"
            v-bind="attributes"
            v-on="events"
        />
      </template>
      <template #no-options>
        {{ $t('general.no_options') }}
      </template>
    </v-select>
  </ui-view-card-field>
  <ui-view-card-field :cy="cy" v-if="haveField(OrganisationField.RESPONSIBLE)"
                      :required="isFieldRequired(OrganisationField.RESPONSIBLE)" :label="$t('form.labels.responsible')"
                      v-model="model.responsible"
                      :maxlength="50"
  />
  <ui-view-card-field :cy="cy" v-if="haveField(OrganisationField.GRONDBANKNUMBER)"
                      :required="isFieldRequired(OrganisationField.GRONDBANKNUMBER)"
                      :label="$t('form.labels.soil_bank_number')" v-model="model.soilBankNumber" :maxlength="50"/>
</template>

<script>

import UiViewCardField from '@/components/ui-view-card-field'
import UiViewCardAddress from '@/components/ui-view-card-address'
import {ref, watch} from 'vue'
import {useModelWrapper} from '@/util/modelWrapper'
import clearField from '@/services/clearfield'
import UiViewCardLanguage from '@/components/ui-view-card-language'
import organisationField from '@/types/organisationField'
import OrganisationField from '@/types/organisationField'
import Datepicker from '@vuepic/vue-datepicker'
import Annex7RecoveryDestinationType from '@/types/annex7RecoveryDestinationType'
import {debounce} from '@/util/debounce'
import backend from '@/util/backend'
import meansOfTransport from '@/types/meansOfTransport'

export default {
  name:       'ui-view-card-organisation',
  computed:   {
    meansOfTransport() {
      return meansOfTransport
    },
    Annex7RecoveryDestinationType() {
      return Annex7RecoveryDestinationType
    },
    OrganisationField() {
      return organisationField
    },
  },
  components: {
    Datepicker,
    UiViewCardLanguage,
    UiViewCardAddress,
    UiViewCardField,
  },
  props:      {
    modelValue:     {
      type:    Object,
      default: () => {
      },
    },
    fields:         {
      type:    Array,
      default: () => [],
    },
    requiredFields: {
      type:    Array,
      default: () => [],
    },
    documentType:   {
      type:    String,
      default: '',
    },
    cy:             {
      type:    String,
      default: '',
    },
  },
  setup:      (props, {emit}) => {
    const model = ref(useModelWrapper(props, emit))

    if (props.fields.indexOf(OrganisationField.ADDRESS) > -1 && !model.value.address) {
      model.value.address = {}
    }

    const suggestions = ref([])
    const hidePopup = ref(true)
    const showPopper = ref(true)
    watch(() => model.value.name, debounce(async (newVal) => {
      if (newVal && !hidePopup.value) {
        showPopper.value = true
        const result = await backend.get(`/api/contacts/suggest?searchTerm=${newVal}`)
        if (result.status === 200 && result.data.length > 0) {
          suggestions.value = result.data.slice(0, 5)
        } else {
          showPopper.value = false
        }
      }
      hidePopup.value = false
    }, 400))

    setTimeout(() => {
      hidePopup.value = false // hide popup on open, then enable after half a second
    }, 500)

    const closePopper = () => {
      showPopper.value = false
    }

    const useSuggestion = (suggestion) => {
      const cleared = clearField(suggestion, 'id')
      if (cleared.identifications.length === 0) {
        cleared.identifications.push({
          value:              null,
          identificationType: null,
        })
      }
      Object.assign(model.value, cleared)
      hidePopup.value = true
      closePopper()
    }

    const haveField = (field) => {
      return props.fields.indexOf(field) > -1
    }

    const isFieldRequired = (field) => {
      return props.requiredFields.indexOf(field) > -1
    }

    return {
      model,
      suggestions,
      showPopper,
      closePopper,
      useSuggestion,
      haveField,
      isFieldRequired,
    }
  },
}
</script>

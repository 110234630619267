<template>
  <grondbank-view-driver :id="id" v-if="roles.hasOnlyRole(roles.DRIVER)" />
  <grondbank-view-user :id="id" v-else />
</template>

<script>

import GrondbankViewDriver from '@/views/forms/grondbank/View-Driver.vue'
import GrondbankViewUser from '@/views/forms/grondbank/View-User.vue'

export default {
  name: 'GrondbankView',
  components: {
    GrondbankViewUser,
    GrondbankViewDriver,
  },
  props: {
    id: {
      type: String,
      required: true,
    }
  },
}
</script>

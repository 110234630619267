<template>
  <layout>
    <template #header>
      <ui-header :title="$t('grondwijzer.list.grondwijzer')">
        <template #actions>
          <div v-if="$roles([roles.USER])" class="btn-group">
            <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="actionsDropdown" data-bs-toggle="dropdown" :disabled="selectedIDs.length === 0">
              {{$t('general.actions')}}
            </button>
            <ul data-cy="grondwijzer-actions" class="dropdown-menu" aria-labelledby="actionsDropdown">
              <li v-if="$roles([roles.USER])" :title="notShareableSelected ? $t('grondwijzer.view.only_signed_shared') : ''">
                <button :disabled="selectedIDs.length === 0 || notShareableSelected" class="dropdown-item" @click="showShare()">
                  {{ $t('grondwijzer.list.actions.share_with_driver') }}
                </button>
              </li>
              <li v-if="$roles([roles.USER])" :title="draftSelected ? $t('grondwijzer.view.draft_no_export') : ''">
                <button :disabled="selectedIDs.length === 0 || draftSelected" class="dropdown-item" @click="showExport()">
                  {{ $t('grondwijzer.list.actions.export') }}
                </button>
              </li>
              <li v-if="$roles([roles.USER])">
                <button :disabled="selectedIDs.length === 0" class="dropdown-item" @click="showDuplicate()">
                  {{ $t('grondwijzer.list.actions.duplicate') }}
                </button>
              </li>
              <li v-if="$roles([roles.USER])">
                <button :disabled="selectedIDs.length === 0" class="dropdown-item" @click="showRemove()">
                  {{ $t('grondwijzer.list.actions.delete') }}
                </button>
              </li>
              <li v-if="$flag('empty_container') && $roles([roles.USER])">
                <button :disabled="selectedIDs.length === 0" class="dropdown-item" @click="showSetStateEmpty()">
                  {{ $t('grondwijzer.list.actions.set_state_empty') }}
                </button>
              </li>
            </ul>
          </div>
          <button v-if="$roles([roles.USER])" data-cy="create-form" type="button" class="btn btn-outline-primary" @click="addForm()"><i class="bi-plus" />&nbsp;{{ $t('general.add_form') }}</button>
        </template>
      </ui-header>
    </template>
    <div class="d-flex justify-content-between">
      <ui-breadcrumbs :breadcrumbs="breadcrumbs" />
      <div class="text-muted">
        <template v-if="$isMobile">
          <div class="dropdown-toggle" type="button" id="optionsDropdown" data-bs-toggle="dropdown">
            {{$t('grondwijzer.list.list_options')}}
          </div>
          <ul data-cy="grondwijzer-options" class="dropdown-menu" aria-labelledby="optionsDropdown">
            <li>
              <div style="padding: 0.25rem 1rem;">
                <ui-view-card-field :label="$t('grondwijzer.list.options.start_date')">
                  <datepicker data-cy="list-from-date" v-model="listFrom" :enableTimePicker="false" :autoApply="true" format="dd-MM-yyyy" />
                </ui-view-card-field>
              </div>
            </li>
            <li>
              <div style="padding: 0.25rem 1rem;">
                <ui-view-card-field :label="$t('grondwijzer.list.options.end_date')">
                  <datepicker data-cy="list-to-date" v-model="listTo" :enableTimePicker="false" :autoApply="true" format="dd-MM-yyyy" />
                </ui-view-card-field>
              </div>
            </li>
            <li>
              <div style="padding: 0.25rem 1rem;">
                <ui-view-card-field :label="$t('grondwijzer.list.options.show_finished')" v-model="listShowFinished" type="checkbox" />
              </div>
            </li>
          </ul>
        </template>
        <template v-else>
          <div class="d-flex">
            <label for="dp-input-start_date">{{ $t('grondwijzer.list.options.start_date') }}&nbsp;</label>
            <datepicker :uid="'start_date'" data-cy="list-from-date" v-model="listFrom" :enableTimePicker="false" :autoApply="true" format="dd-MM-yyyy" style="top: -8px;position: relative;max-width: 160px" class="pe-2" />
            <label for="dp-input-end_date">{{ $t('grondwijzer.list.options.end_date') }}&nbsp;</label>
            <datepicker :uid="'end_date'" data-cy="list-to-date" v-model="listTo" :enableTimePicker="false" :autoApply="true" format="dd-MM-yyyy" style="top: -8px;position: relative;max-width: 160px" class="pe-2" />
            <ui-view-card-field :label="$t('grondwijzer.list.options.show_finished')" v-model="listShowFinished" type="checkbox" />
          </div>
        </template>
      </div>
    </div>
    <ui-content>
      <spinner v-if="loading"/>
      <template v-if="!loading">
        <ag-grid-vue
          @grid-ready="onGridReady"
          style="min-height:100%"
          class="ag-theme-quartz"
          :columnDefs="xsWindow ? xsColumnDefs : columnDefs"
          :defaultColDef="grid.defaultColDef"
          :rowData="items"
          rowSelection='multiple'
          @selection-changed="refreshSelection"
          @row-clicked="rowClick"
          :getLocaleText="grid.getLocaleText"
          :suppressMenuHide="true"
        />
      </template>
    </ui-content>
  </layout>
  <form-duplicate-modal ref="duplicateModal" :amount="selectedIDs.length" :callback="duplicate" />
  <form-delete-modal ref="removeModal" :amount="selectedIDs.length" :callback="remove" />
  <form-share-modal ref="shareModal" :multiple="selectedIDs.length > 1" :amount="selectedIDs.length" :callback="share" :id="selectedIDs.length === 1 ? selectedIDs[0] : null" :document-type="DocumentType.GRONDWIJZER" />
  <form-export-modal ref="exportModal" :amount="selectedIDs.length" :callback="exportForms" />
  <form-set-state-empty-modal ref="setStateEmptyModal" :amount="selectedIDs.length" :callback="setStateEmpty" />
</template>

<script>

import {inject, onBeforeUnmount, ref, watch} from 'vue'
import backend from '@/util/backend'
import Layout from '@/components/layout'
import {AgGridVue} from 'ag-grid-vue3'
import {useRouter} from 'vue-router'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import UiHeader from '@/components/ui-header'
import UiContent from '@/components/ui-content'
import Spinner from '@/components/spinner'
import FormDuplicateModal from '@/views/forms/shared/Duplicate-Modal'
import FormDeleteModal from '@/views/forms/shared/Delete-Modal'
import FormShareModal from '@/views/forms/shared/Share-Modal'
import FormExportModal from '@/views/forms/shared/Export-Modal'
import FormSetStateEmptyModal from '@/views/forms/shared/Set-State-Empty-Modal'
import {DateFormatter} from '@/services/dateFormatter'
import Notifier from '@/util/notifier'
import FormState from "@/types/formState"
import roles from '@/util/roles'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import UiViewCardField from '@/components/ui-view-card-field'
import DocumentType from '@/types/documentType'
import grid from '@/util/grid'

export default {
  name: 'GrondwijzerList',
  computed: {
    grid() {
      return grid
    },
    DocumentType() {
      return DocumentType
    },
  },
  components: {
    UiViewCardField,
    FormSetStateEmptyModal,
    FormExportModal,
    FormShareModal,
    FormDeleteModal,
    FormDuplicateModal,
    Spinner,
    UiContent,
    UiHeader,
    Layout,
    UiBreadcrumbs,
    AgGridVue,
    Datepicker,
  },
  setup: () => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.GRONDWIJZER]
    const items = ref([])
    const loading = ref(true)
    const $t = inject('$t')
    const notifier = Notifier()

    const listFrom = ref(new Date())
    listFrom.value.setUTCDate(listFrom.value.getUTCDate() - 7);
    const listTo = ref(new Date())
    listTo.value.setUTCDate(listTo.value.getUTCDate() + 7)
    const listShowFinished = ref(false)
    const getItems = () => {
      let from = ''
      if (listFrom.value && listFrom.value.toISOString()) {
        from = listFrom.value.toISOString().substring(0, 10)
      }

      let to = ''
      if (listTo.value && listTo.value.toISOString()) {
        to = listTo.value.toISOString().substring(0, 10)
      }

      let states = [
        FormState.DRAFT,
        FormState.EMPTY_CONTAINER,
        FormState.SIGNED,
        FormState.SHARED,
        FormState.ON_THE_ROAD,
        FormState.ARRIVED,
      ]

      if (listShowFinished.value) {
        states.push(FormState.FINISHED, FormState.CANCELLED, FormState.STOPPED)
      }

      const url = `api/documents?documentType=${DocumentType.E_CMR}&from=${from}&to=${to}&states=${states.join(',')}`
      backend.get(url).then((r) => {
        items.value = r.data
        loading.value = false
      })
    }
    getItems()

    watch(() => listFrom.value, () => {
      getItems()
    })
    watch(() => listTo.value, () => {
      getItems()
    })
    watch(() => listShowFinished.value, () => {
      getItems()
    })

    const xsWindow = ref(window.innerWidth < 576)
    const resize = () => {
      xsWindow.value = window.innerWidth < 576
      setTimeout(() => {
        api.sizeColumnsToFit({'defaultMinWidth': 200})
      }, 1)
    }
    window.addEventListener("resize", resize)
    onBeforeUnmount(() => {
      window.removeEventListener("resize", resize)
    })

    const xsColumnDefs = [
      {
        headerName: $t('grondwijzer.list.table.key'),
        valueGetter: (params) => {
          return 'B-' + params.data.id
        },
        comparator: (a, b) => {
          a = parseInt(a.substring(4), 10)
          b = parseInt(b.substring(4), 10)
          if (a === b) { return 0 }
          return a > b ? 1 : -1
        }
      },
      { headerName: $t('grondwijzer.list.table.state'),
        valueGetter: (params) => {
          return $t("form.state."+params.data.state)
        },
      },
    ]

    const columnDefs = [
      {
        headerName: $t('grondwijzer.list.table.key'),
        valueGetter: (params) => {
          return 'B-' + params.data.id
        },
        comparator: (a, b) => {
          a = parseInt(a.substring(4), 10)
          b = parseInt(b.substring(4), 10)
          if (a === b) { return 0 }
          return a > b ? 1 : -1
        }
      },
      { headerName: $t('grondwijzer.list.table.date'),
        filter: 'agDateColumnFilter',
        filterParams: {
          minValidYear: 2000,
          maxValidYear: 2099,
          comparator: (a, b) => {
            const newB = new Date(b.getFullYear(), b.getMonth(), b.getDate())
            if (a.getTime() === newB.getTime()) {
              return 0;
            }
            if (newB < a ) {
              return -1;
            }
            if (newB > a) {
              return 1;
            }
          },
        },
        valueGetter: (params) => {
          return new Date(params.data.transportDate)
        },
        valueFormatter: (params) => {
          return DateFormatter.formatDate(params.data.transportDate)
        }
      },
      { headerName: $t('grondwijzer.list.table.from'), field: 'from' },
      { headerName: $t('grondwijzer.list.table.to'), field: 'to' },
      { headerName: $t('grondwijzer.list.table.state'),
        valueGetter: (params) => {
          return $t("form.state."+params.data.state)
        },
      },
    ]

    if (roles.hasOnlyRole(roles.DRIVER)) {
      const orgColumn = {
        headerName: $t('grondwijzer.list.table.organisation'),
        field: 'organisation',
      }
      columnDefs.splice(1, 0, orgColumn)
    }

    let api = null
    const onGridReady = (params) => {
      api = params.api
      api.deselectAll() // if we can correctly get selection from saved state we can re-evaluate this
      api.sizeColumnsToFit({'defaultMinWidth': 200})
    }
    onBeforeUnmount(() => {
      api = null
    })

    const router = useRouter()
    const rowClick = (event) => {
      if (event.data) {
        router.push(`/grondwijzer/view/${event.data.id}`)
      }
    }

    const addForm = () => {
      router.push(`/grondwijzer/new`)
    }

    const duplicateModal = ref('duplicateModal')
    const showDuplicate = () => {
      duplicateModal.value.modal.open()
    }
    const duplicate = () => {
      const ids = api.getSelectedRows().map((row) => { return row.id })
      if (ids.length > 0) {
        backend.post('api/documents/batch', {
          'action': 'COPY',
          'ids':    ids,
        }).then((result) => {
          if (result.status === 200) {
            notifier.success('toast.duplication_successful')
          } else {
            notifier.error('toast.duplication_failed')
          }
        }).finally(() => {
          duplicateModal.value.modal.close()
          getItems()
        })
      }
    }

    const shareModal = ref('shareModal')
    const showShare = () => {
      shareModal.value.modal.open()
    }
    const share = async (shareWith) => {
      const ids = api.getSelectedRows().filter((row) => { return row.state !== 'DRAFT' }).map((row) => { return row.id })
      if (ids.length > 0) {
        await backend.post('api/documents/batch', {
          'action': 'SHARE_DRIVER',
          'ids':    ids,
          'params': shareWith,
        }).then((result) => {
          if (result.status === 200) {
            notifier.success('toast.sharing_successful')
          } else {
            notifier.error('toast.sharing_failed')
          }
        }).finally(() => {
          shareModal.value.modal.close()
          getItems()
        })
      }
    }

    const exportModal = ref('exportModal')
    const showExport = () => {
      exportModal.value.modal.open()
    }
    const exportForms = (language, type) => {
      const ids = api.getSelectedRows().filter((row) => { return row.state !== 'DRAFT' }).map((row) => { return row.id })
      if (ids.length > 0) {
        switch (type) {
          case 'PDF': {
            exportPdf(ids, language)
            return
          }
          case 'JSON': {
            exportJson(ids)
            return
          }
        }
      }
    }

    const exportPdf = (ids, language) => {
      backend.post('api/documents/batch', {
        'action': 'EXPORT_PDF',
        'ids':    ids,
        'params': {
          'language': language
        }
      }, {responseType: 'blob'}).then((result) => {
        if (result.status === 200) {
          const blob = new Blob([result.data], {type : 'application/pdf'})
          const url = URL.createObjectURL(blob)
          window.open(url)
        } else {
          notifier.error('toast.exporting_failed')
        }
      }).finally(() => {
        exportModal.value.modal.close()
      })
    }

    const exportJson = (ids) => {
      backend.post('api/documents/batch', {
        'action': 'EXPORT_JSON',
        'ids':    ids,
      }).then((result) => {
        if (result.status === 200) {
          const blob = new Blob([result.data], {type : 'application/json'})
          const url = URL.createObjectURL(blob)
          window.open(url)
        } else {
          notifier.error('toast.exporting_failed')
        }
      }).finally(() => {
        exportModal.value.modal.close()
      })
    }

    const removeModal = ref('removeModal')
    const showRemove = () => {
      removeModal.value.modal.open()
    }
    const remove = () => {
      const ids = api.getSelectedRows().filter((row) => { return FormState.canDelete(row.state) }).map((row) => { return row.id })
      if (ids.length > 0) {
        backend.post('api/documents/batch', {
          'action': 'DELETE',
          'ids':    ids,
        }).then((result) => {
          if (result.status === 200) {
            notifier.success('toast.deleting_successful')
          } else {
            notifier.error('toast.deleting_failed')
          }
        }).finally(() => {
          removeModal.value.modal.close()
          getItems()
        })
      } else {
        removeModal.value.modal.close()
      }
    }

    const setStateEmptyModal = ref('setStateEmptyModal')
    const showSetStateEmpty = () => {
      setStateEmptyModal.value.modal.open()
    }
    const setStateEmpty = () => {
      const ids = api.getSelectedRows().filter((row) => { return row.state === 'DRAFT' }).map((row) => { return row.id })
      if (ids.length > 0) {
        backend.post('api/documents/batch', {
          'action': 'EMPTY',
          'ids':    ids,
        }).then((result) => {
          if (result.status === 200) {
            notifier.success('toast.update_successful')
          } else {
            notifier.error('toast.update_failed')
          }
        }).finally(() => {
          setStateEmptyModal.value.modal.close()
          getItems()
        })
      }
    }

    const draftSelected = ref(false)
    const notShareableSelected = ref(false)

    const selectedIDs = ref([])
    const selection = ref([])
    const refreshSelection = (params) => {
      selection.value = params.api.getSelectedRows()
      selectedIDs.value = params.api.getSelectedRows().map((row) => { return row.id })
      draftSelected.value = items.value.filter((item) => { return selectedIDs.value.indexOf(item.id) > -1 && item.state === 'DRAFT' }).length > 0
      notShareableSelected.value = selection.value.filter((item) => {
        return !FormState.canShare(item.state)
      }).length > 0
    }

    return {
      breadcrumbs,
      loading,
      listFrom,
      listTo,
      listShowFinished,
      items,
      xsWindow,
      xsColumnDefs,
      columnDefs,
      rowClick,
      onGridReady,
      addForm,
      refreshSelection,
      draftSelected,
      notShareableSelected,
      selectedIDs,
      duplicateModal,
      showDuplicate,
      duplicate,
      removeModal,
      showRemove,
      remove,
      shareModal,
      showShare,
      share,
      exportModal,
      showExport,
      exportForms,
      setStateEmptyModal,
      showSetStateEmpty,
      setStateEmpty,
    }
  }
}
</script>

<template>
  <layout>
    <template #header>
      <ui-header :title="$t('drivers.edit.title', {name: getName})" :back-route="`/drivers/view/${id}`">
        <template #actions>
          <button type="button" class="btn btn-outline-primary" :disabled="saving" @click="save()" form="driver-form"><i class="bi-save" />&nbsp;{{$t('general.save')}}</button>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs" />
    <ui-content class="pb-1">
      <spinner v-if="loading"/>
      <template v-if="!loading">
        <driver-form :item="driver" />
      </template>
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import backend from '@/util/backend'
import {computed, ref} from 'vue'
import {useRouter} from 'vue-router'
import Notifier from '@/util/notifier'
import DriverForm from '@/partials/driver/Form'
import Spinner from '@/components/spinner'

export default {
  name: 'DriverEdit',
  components: {Spinner, DriverForm, UiContent, UiBreadcrumbs, UiHeader, Layout},
  props: {
    id: {
      type:     String,
      required: true,
    }
  },
  setup: (props) => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.DRIVERS, BREADCRUMBS.EDIT]
    const loading = ref(true)
    const notifier = Notifier()
    const router = useRouter()

    const driver = ref(null)
    backend.get(`api/drivers/${props.id}`).then((r) => {
      driver.value = r.data
      if (driver.value.userId) {
        router.push(`/drivers/view/${props.id}`)
      }
      loading.value = false
    })

    const saving = ref(false)
    const save = async () => {
      saving.value = true
      const formElement = $('#driver-form')[0]
      if (!formElement.checkValidity()) {
        // something in the form is not valid so don't allow signing
        formElement.reportValidity()
        saving.value = false
        return false
      }

      const result = await backend.put(`api/drivers/${props.id}`, driver.value)
      if (result.status === 200) {
        router.push(`/drivers/view/${props.id}`)
      } else {
        notifier.error('toast.save_failed')
      }
      saving.value = false
    }

    const getName = computed(() => {
      if (!driver.value || !driver.value.firstName || !driver.value.lastName) {
        return ''
      } else {
        return driver.value.firstName + ' ' + driver.value.lastName
      }
    })

    return {
      breadcrumbs,
      driver,
      save,
      saving,
      loading,
      getName,
    }
  }
}
</script>

<template>
  <layout>
    <template #header>
      <ui-header :title="$t('annex_7.list.title')">
        <template #actions>
          <div v-if="$roles([roles.OVERVIEWER, roles.USER])" class="btn-group">
            <button id="actionsDropdown" :disabled="selection.length === 0"
                    class="btn btn-outline-secondary dropdown-toggle"
                    data-bs-toggle="dropdown" type="button">
              {{ $t('general.actions') }}
            </button>
            <ul class="dropdown-menu" aria-labelledby="actionsDropdown">
              <li v-if="$roles([roles.USER])">
                <button :disabled="selection.length === 0" class="dropdown-item" @click="showDuplicate()">
                  {{ $t('annex_7.list.actions.duplicate') }}
                </button>
              </li>
              <li v-if="$roles([roles.USER])">
                <button :disabled="selection.length === 0" class="dropdown-item" @click="showRemove()">
                  {{ $t('annex_7.list.actions.delete') }}
                </button>
              </li>
            </ul>
          </div>
          <button v-if="$roles([roles.USER])" class="btn btn-outline-primary" data-cy="create-form" type="button"
                  @click="addForm()"><i class="bi-plus"/>&nbsp;{{ $t('general.add_form') }}
          </button>
        </template>
      </ui-header>
    </template>
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
      </div>
      <div>
        <button class="btn btn-sm btn-outline-secondary" @click="resetGrid()">{{ $t('general.reset_filters') }}</button>
      </div>
    </div>
    <ui-content>
      <div class="tab-content h-100">
        <div id="forms" class="h-100">
          <div class="d-flex flex-column h-100">
            <ag-grid-vue
              :serverSideDatasource="grid.datasource(`api/documents/list?documentType=${DocumentType.ANNEX_7}`)"
              :grid-options="grid.defaultOptions"
              :columnDefs="xsWindow ? xsColumnDefs : columnDefs"
              class="ag-theme-quartz"
              style="min-height:100%"
              @row-clicked="rowClick"
              @selection-changed="refreshSelection"
              @grid-ready="onGridReady"
              @grid-pre-destroyed="onGridPreDestroyed"
              @filter-changed="onFilterChanged"
              :initialState="initialState"
            />
          </div>
        </div>
      </div>
    </ui-content>
  </layout>
  <form-duplicate-modal ref="duplicateModal" :amount="selection.length" :callback="duplicate"/>
  <form-delete-modal ref="removeModal" :amount="countSelectedDrafts" :callback="remove"/>
</template>

<script>

import {computed, inject, onBeforeUnmount, ref, watch} from 'vue'
import backend from '@/util/backend'
import Layout from '@/components/layout'
import {AgGridVue} from 'ag-grid-vue3'
import {useRouter} from 'vue-router'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import UiHeader from '@/components/ui-header'
import UiContent from '@/components/ui-content'
import FormDuplicateModal from '@/views/forms/shared/Duplicate-Modal'
import FormDeleteModal from '@/views/forms/shared/Delete-Modal'
import {DateFormatter} from '@/services/dateFormatter'
import Notifier from '@/util/notifier'
import FormState from '@/types/formState'
import '@vuepic/vue-datepicker/dist/main.css'
import DocumentType from '@/types/documentType'
import stateColorRenderer from '@/util/stateColorRenderer'
import checkboxRenderer from '@/util/checkboxRenderer'
import grid from '@/util/grid'
import session from '@/util/session'
import Annex7Model from '@/models/Annex7Model'

export default {
  name: 'Annex7ListUser',
  computed: {
    grid() {
      return grid
    },
    DocumentType() {
      return DocumentType
    },
  },
  components: {
    FormDeleteModal,
    FormDuplicateModal,
    UiContent,
    UiHeader,
    Layout,
    UiBreadcrumbs,
    AgGridVue,
    // eslint-disable-next-line
    stateColorRenderer,
    // eslint-disable-next-line
    checkboxRenderer,
  },
  setup: () => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.ANNEX_7]
    const $t = inject('$t')
    const notifier = Notifier()

    const xsWindow = ref(window.innerWidth < 576)
    const xsColumnDefs = [
      {
        headerName: $t('annex_7.list.table.key'),
        valueGetter: (params) => {
          return 'DGF-A7-' + params.data.id
        },
        field: 'id',
      },
      {
        headerName: $t('annex_7.list.table.state'),
        field: 'state',
        cellRenderer: 'stateColorRenderer',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: FormState.getAll(true),
          valueFormatter: (val) => {
            return $t('form.state.' + val.value)
          }
        },
      },
    ]

    const columnDefs = [
      {
        headerName: $t('annex_7.list.table.key'),
        field: 'id',
        maxWidth: 250,
        valueGetter: (params) => {
          return 'DGF-A7-' + params.data.id
        },
      },
      {
        headerName: $t('annex_7.list.table.date'),
        field: 'transportDate',
        maxWidth: 250,
        filter: 'agDateColumnFilter',
        filterParams: {
          filterOptions: ['inRange'],
          minValidYear: 2000,
          maxValidYear: 2099,
        },
        valueGetter: (params) => {
          return new Date(params.data.transportDate)
        },
        valueFormatter: (params) => {
          return DateFormatter.formatDate(params.data.transportDate)
        }
      },
      {headerName: $t('annex_7.list.table.waste_description'), field: 'wasteDescription'},
      {
        headerName: $t('annex_7.list.table.amount'),
        field: 'amount',
        maxWidth: 200,
        valueFormatter: (params) => {
          if (params.value) {
            let result = ''
            const parts = params.value.split(' ')
            if (parts[0] !== 'null') {
              result += parts[0] + ' ' + $t('form.table.tonne') + ' '
            }
            if (parts[1] !== 'null') {
              result += parts[1] + ' ' + 'm³'
            }
            return result

          } else {
            return ''
          }
        }
      },
      {headerName: $t('annex_7.list.table.from'), field: 'from'},
      {headerName: $t('annex_7.list.table.to'), field: 'to'},
      {headerName: $t('annex_7.list.table.transporter'), field: 'transporter'},
      {
        headerName: $t('annex_7.list.table.state'),
        field: 'state',
        maxWidth: 400,
        cellRenderer: 'stateColorRenderer',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: FormState.getAll(true),
          valueFormatter: (val) => {
            return $t('form.state.'+val.value)
          }
        },
      },
    ]

    let active = computed(() => {
      return true
    })
    let sized = false
    watch(() => active.value, (val) => {
      if (val && !sized) {
        api.sizeColumnsToFit({'defaultMinWidth': 200})
        sized = true
      }
    })

    const resize = () => {
      if (active.value) {
        xsWindow.value = window.innerWidth < 576
        setTimeout(() => {
          api.sizeColumnsToFit({'defaultMinWidth': 200})
        }, 1)
      } else {
        sized = false
      }
    }
    window.addEventListener("resize", resize)
    onBeforeUnmount(() => {
      window.removeEventListener("resize", resize)
    })

    const gridKey = 'annex-7-list-user'
    const initialState = ref(session.loadFormState(gridKey))
    const onGridPreDestroyed = (params) => {
      session.saveFormState(gridKey, params.state)
    }
    const onFilterChanged = (event) => {
      session.saveFormState(gridKey, event.api.getState())
    }

    let api = null
    const onGridReady = (params) => {
      api = params.api
      api.deselectAll() // if we can correctly get selection from saved state we can re-evaluate this
      if (active.value) {
        api.sizeColumnsToFit({'defaultMinWidth': 200})
        sized = true
      }
    }
    onBeforeUnmount(() => {
      api = null
    })
    const reload = () => {
      api?.refreshServerSide()
      api?.deselectAll()
    }
    const resetGrid = () => {
      api?.setFilterModel({
        'state': {
          filterType: 'set',
          values: FormState.getAll(false),
        }
      })
      api?.onFilterChanged()
      session.saveFormState(gridKey, null)
    }

    const router = useRouter()
    const rowClick = (event) => {
      if (event.data) {
        router.push(`/annex-7/view/${event.data.id}`)
      }
    }

    const addForm = async () => {
      const result = await backend.post('api/documents', JSON.parse(JSON.stringify(Annex7Model.empty)))
      if (result.status === 200) {
        router.push(`/annex-7/edit/${result.data.id}`)
      } else {
        notifier.error('toast.form_create_failed')
      }
    }

    const duplicateModal = ref('duplicateModal')
    const showDuplicate = () => {
      duplicateModal.value.modal.open()
    }
    const duplicate = () => {
      const ids = api.getSelectedRows().map((row) => {
        return row.id
      })
      if (ids.length > 0) {
        backend.post('api/documents/batch', {
          'action': 'COPY',
          'ids': ids,
        }).then((result) => {
          if (result.status === 200) {
            notifier.success('toast.duplication_successful')
          } else {
            notifier.error('toast.duplication_failed')
          }
        }).finally(() => {
          duplicateModal.value.modal.close()
          reload()
        })
      }
    }

    const removeModal = ref('removeModal')
    const showRemove = () => {
      removeModal.value.modal.open()
    }
    const remove = () => {
      const ids = api.getSelectedRows().filter((row) => {
        return FormState.canDelete(row.state)
      }).map((row) => {
        return row.id
      })
      if (ids.length > 0) {
        backend.post('api/documents/batch', {
          'action': 'DELETE',
          'ids': ids,
        }).then((result) => {
          if (result.status === 200) {
            notifier.success('toast.deleting_successful')
          } else {
            notifier.error('toast.deleting_failed')
          }
        }).finally(() => {
          removeModal.value.modal.close()
          reload()
        })
      } else {
        removeModal.value.modal.close()
      }
    }

    const draftSelected = ref(false)
    const notShareableSelected = ref(false)
    const countSelectedDrafts = ref(0)

    const selection = ref([])
    const refreshSelection = (params) => {
      selection.value = params.api.getSelectedRows()
      draftSelected.value = selection.value.filter((item) => {
        return FormState.isDraft(item.state)
      }).length > 0
      countSelectedDrafts.value = selection.value.filter((item) => {
        return FormState.isDraft(item.state)
      }).length
      notShareableSelected.value = selection.value.filter((item) => {
        return !FormState.canShare(item.state)
      }).length > 0
    }

    return {
      breadcrumbs,
      reload,
      xsWindow,
      xsColumnDefs,
      columnDefs,
      rowClick,
      onGridReady,
      onGridPreDestroyed,
      onFilterChanged,
      initialState,
      addForm,
      refreshSelection,
      notShareableSelected,
      draftSelected,
      countSelectedDrafts,
      selection,
      duplicateModal,
      showDuplicate,
      duplicate,
      removeModal,
      showRemove,
      remove,
      resetGrid,
    }
  }
}
</script>

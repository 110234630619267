<template>
  <ui-modal id="attachmentsModal" ref="modal" :callback="cb" :no-confirm="true">
    <template #title>
      {{ $t('waste_identification.attachments_modal.title') }}
    </template>
    <template #default>
      <div class="pb-2">
        {{ $t('waste_identification.attachments_modal.help') }}
      </div>
      <div v-if="files.length === 0">
        {{ $t('waste_identification.attachments_modal.no_attachments') }}
      </div>
      <div class="text-danger" v-if="tooMuch">
        {{ $t('waste_identification.attachments_modal.too_much') }}
      </div>
      <div class="d-flex justify-content-between" v-for="(file, index) in files" :key="index">
        <input type="file" :id="'file'+index" @change="handleFileUpload($event, index)" style="display:none;"
               :ref="el => inputs[index] = el"/>
        <label v-if="!file.name" class="btn btn-outline-secondary flex-grow-1 text-truncate" style="min-width:0"
               :for="'file'+index">
          <span>
            {{ $t('waste_identification.attachments_modal.choose_file') }}
          </span>
        </label>
        <label v-else class="btn btn-outline-secondary flex-grow-1 text-truncate" @click="getFile(file)">
          <span>
            {{ file.name }}
          </span>
        </label>
        <div class="btn-group ml-auto flex-shrink-0">
          <button class="btn btn-outline-secondary" @click="inputs[index].click()"
                  v-if="$roles([roles.USER, roles.DRIVER, roles.DRIVER_BOSSCHAERT, roles.ANONYMOUS])"><i
              class="bi-pencil"/></button>
          <button class="btn btn-outline-danger" @click="deleteFile(index)"
                  v-if="$roles([roles.USER, roles.DRIVER, roles.DRIVER_BOSSCHAERT, roles.ANONYMOUS])"><i
              class="bi-trash"/></button>
        </div>
      </div>
      <br/>
      <div class="d-flex justify-content-center">
        <button class="btn btn-outline-primary" @click="addFile"
                v-if="$roles([roles.USER, roles.DRIVER, roles.DRIVER_BOSSCHAERT, roles.ANONYMOUS])">
          {{ $t('waste_identification.attachments_modal.add_file') }}
        </button>
      </div>
    </template>
  </ui-modal>
</template>

<script>

import UiModal from '@/components/ui-modal'
import {ref} from 'vue'
import backend from '@/util/backend'
import Notifier from '@/util/notifier'

export default {
  name:       'FormAttachmentsModal',
  components: {
    UiModal,
  },
  props:      {
    id:          {
      type:    Number,
      default: null,
    },
    uuid:        {
      type:    String,
      default: null,
    },
    attachments: {
      type:     Array,
      required: true,
    },
    callback:    {
      type:     Function,
      required: true,
    },
    sharelink:   {
      type:    Boolean,
      default: false,
    },
  },
  setup:      (props) => {
    const notifier = Notifier()
    const modal    = ref('modal')
    const files    = ref([])
    const tooMuch  = ref(false)
    const inputs   = ref([])

    if (props.sharelink && !props.uuid) {
      throw new Error('Attachments-modal: Sharelink needs uuid')
    } else if (!props.sharelink && !props.id) {
      throw new Error('Attachments-modal: View needs id')
    }

    props.attachments.forEach((attachment) => {
      files.value.push({id: attachment.id, name: attachment.name, url: attachment.url})
    })

    const cb = async () => {
      await props.callback(files.value)
    }

    const handleFileUpload = async (event, index) => {
      if (!event.target.files || !event.target.files[0]) {
        return
      }
      if (files.value[index]?.url) {
        const res = await deleteFile(index)
        if (!res) {
          return
        }
      }

      const file     = event.target.files[0]
      const formData = new FormData()
      formData.append('multipartFile', file)
      const postUrl = props.sharelink
                      ? `api/sharedlinks/${props.uuid}/attachments`
                      : `api/documents/${props.id}/attachments`
      await backend.post(postUrl, formData).then((result) => {
        tooMuch.value = false
        if (result.status === 200) {
          notifier.success('toast.upload_successful')
          files.value = []
          result.data.attachments.forEach((attachment) => {
            files.value.push({id: attachment.id, name: attachment.name, url: attachment.url})
          })
        } else {
          notifier.error('toast.upload_failed')
        }
      }).catch(() => {
        tooMuch.value = true
        notifier.error('toast.upload_failed')
      })
    }

    const deleteFile = async (index) => {
      const file = files.value[index]
      if (!file.url) {
        files.value.splice(index, 1)
        return true
      }

      const deleteUrl = props.sharelink
                        ? `api/sharedlinks/${props.uuid}/attachments/${file.id}`
                        : `api/documents/${props.id}/attachments/${file.id}`
      return await backend.delete(deleteUrl).then((result) => {
        if (result.status === 200) {
          notifier.success('toast.attachment_delete_successful')
          files.value.splice(index, 1)
          return true
        } else {
          notifier.error('toast.attachment_delete_failed')
          return false
        }
      }).catch(() => {
        notifier.error('toast.attachment_delete_failed')
        return false
      })
    }

    const addFile = () => {
      files.value.push({name: '', url: null})
    }

    const getFile = async (file) => {
      window.open(file.url, '_blank')
    }

    return {
      inputs,
      files,
      modal,
      handleFileUpload,
      deleteFile,
      getFile,
      addFile,
      cb,
      tooMuch,
    }
  },
}
</script>

import session from '@/util/session'
import i18n, {languages} from '@/util/i18n'

const $t = () => {
  const user = session.getUser()
  return i18n(user?.language ?? languages.NL)
}

const SignatureType = {
  ORGANISER: "ORGANISER",
  PROCESSOR: "PROCESSOR",
  TRANSPORTER: "TRANSPORTER",

  getAll: () => {
    return [
      {value: SignatureType.ORGANISER, label: $t()('signature_type.ORGANISER')},
      {value: SignatureType.TRANSPORTER, label: $t()('signature_type.TRANSPORTER')},
      {value: SignatureType.PROCESSOR, label: $t()('signature_type.PROCESSOR')},
    ]
  }
}

export default SignatureType

<template>
  <ui-modal id="signTemplateModal" ref="modal" :callback="callback">
    <template #title>
      {{ $t('transport_document_templates.sign_template_modal.title')}}
    </template>
    <template #default>
      <form id="signature-form">
        {{ $t('transport_document_templates.sign_template_modal.text')}}
      </form>
    </template>
  </ui-modal>
</template>

<script>

import UiModal from '@/components/ui-modal'
import {ref} from 'vue'

export default {
  name: 'SignTemplateModal',
  components: {
    UiModal
  },
  props: {
    callback: {
      type:    Function,
      default: () => {}
    },
  },
  setup: () => {
    const modal = ref('modal')

    return {
      modal,
    }
  }
}
</script>

<template>
  <layout>
    <template #header>
      <ui-header data-cy="grondbank-title" :title="$t('grondbank.edit.title', {id})"
                 :back-route="`/grondbank/view/${id}`">
        <template #actions>
          <button data-cy="grondbank-check" type="button" class="btn btn-outline-secondary" @click="check()"><i
              class="bi-check"/>&nbsp;{{ $t('form.check_form') }}
          </button>
          <button data-cy="grondbank-save" type="button" class="btn btn-outline-primary" :disabled="saving"
                  @click="save()" form="grondbank-form"><i class="bi-save"/>&nbsp;{{ $t('general.save') }}
          </button>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    <ui-content class="pb-1">
      <spinner v-if="loading"/>
      <template v-if="!loading">
        <grondbank-form :item="item"/>
      </template>
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import backend from '@/util/backend'
import {ref} from 'vue'
import Spinner from '@/components/spinner'
import Notifier from '@/util/notifier'
import FormState from '@/types/formState'
import {useRouter} from 'vue-router'
import DocumentType from '@/types/documentType'
import GrondbankForm from '@/partials/grondbank/Form'
import GrondbankModel from '@/models/GrondbankModel'

export default {
  name:       'GrondbankEdit',
  components: {
    GrondbankForm,
    Spinner,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout,
  },
  props:      {
    id: {
      type:     String,
      required: true,
    },
  },
  setup:      (props) => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.GRONDBANK, BREADCRUMBS.EDIT]
    const item        = ref({})
    const loading     = ref(true)
    const date        = ref(null)
    backend.get(`api/documents/${props.id}`).then((r) => {
      if (r.data.type !== DocumentType.GRONDBANK) {
        router.push(`/grondbank/list`)
      }
      // if the form is in an end state we can't show edit page anymore, only view page
      if (FormState.isEndState(r.data.state)) {
        router.push(`/grondbank/view/${props.id}`)
      }
      item.value    = r.data
      loading.value = false
    })

    const check = () => {
      const err = GrondbankModel.formCheck(item.value, FormState.isDraft(item.value.state))
      if (err) {
        const formElement = $('#grondbank-form')[0]
        formElement.reportValidity()
        notifier.error(err)
        return false
      }

      notifier.success('toast.form_valid')
      return true
    }

    const validate = () => {
      // if we're not in a draft state, then all validations should be run
      if (!FormState.isDraft(item.value.state)) {
        return check()
      }
      return true
    }

    const saving   = ref(false)
    const router   = useRouter()
    const notifier = Notifier()
    const save     = () => {
      saving.value = true
      if (!validate()) {
        saving.value = false
        return false
      }

      backend.put(`api/documents/${props.id}`, item.value)
          .then((result) => {
            if (result.status === 200) {
              router.push(`/grondbank/view/${props.id}`)
            } else {
              notifier.error('toast.save_failed')
            }
          })
          .finally(() => {
            saving.value = false
          })
    }

    return {
      breadcrumbs,
      item,
      loading,
      date,
      save,
      saving,
      check,
    }
  },
}
</script>

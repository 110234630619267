<template>
  <ui-modal id="cancelModal" ref="modal" :callback="cb" :can-confirm="isValid">
    <template #title>
      {{ $t('waste_identification.cancel_modal.title') }}
    </template>
    <template #default>
      <div class="pb-2">
        {{ $t('waste_identification.cancel_modal.cancel_form') }}
      </div>
      <ui-view-card-field :label="$t('waste_identification.cancel_modal.reason')">
        <textarea class="form-control" rows="4" style="width: 100%" v-model="reason" :maxlength="200"/>
      </ui-view-card-field>
    </template>
  </ui-modal>
</template>

<script>

import {ref} from 'vue'
import UiModal from '@/components/ui-modal'
import UiViewCardField from '@/components/ui-view-card-field'

export default {
  name:       'FormCancelModal',
  components: {
    UiViewCardField,
    UiModal,
  },
  props:      {
    callback: {
      type:     Function,
      required: true,
    },
  },
  setup:      (props) => {
    const modal  = ref('modal')
    const reason = ref('')

    const cb = async () => {
      await props.callback(reason.value)
      reason.value = ''
    }

    const isValid = () => {
      return validReason()
    }

    const validReason = () => {
      return !!reason.value
    }

    return {
      isValid,
      reason,
      modal,
      cb,
    }
  },
}
</script>

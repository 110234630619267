<template>
  <div class="d-flex flex-column flex-fill overflow-auto">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ui-content',
  setup: () => {
    return {
    }
  }
}
</script>
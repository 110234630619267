<template>
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a  class="nav-link cursor-pointer" id="forms-tab" data-bs-toggle="tab" data-bs-target="#forms" :class="tab === TABS.FORMS ? 'active' : ''" @click="setTab(TABS.FORMS)">{{ $t('tabs.forms') }}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link cursor-pointer" id="templates-tab" data-bs-toggle="tab" data-bs-target="#templates" :class="tab === TABS.TEMPLATES ? 'active' : ''" @click="setTab(TABS.TEMPLATES)">{{ $t('tabs.templates') }}</a>
    </li>
  </ul>
</template>

<script>

import {useModelWrapper} from '@/util/modelWrapper'
import {mod} from 'qrcode/lib/core/polynomial'
import TABS from '@/types/tabs'

export default {
  name: 'ui-list-tabs',
  computed: {
    TABS() {
      return TABS
    }
  },
  methods: {mod},
  props: {
    modelValue: {
      type:    String,
      default: 'Forms'
    }
  },
  setup: (props, {emit}) => {
    const tab = useModelWrapper(props, emit)
    const setTab = (t) => {
      tab.value = t
    }

    return {
      tab,
      setTab,
    }
  }
}
</script>

<template>
  <shared-link-signature-processor-grondbank v-if="link.documentType === DocumentType.GRONDBANK" :link="link"/>
</template>

<script>

import DocumentType from '@/types/documentType'
import SharedLinkSignatureProcessorGrondbank
  from '@/views/shared-links/grondbank/Shared-Link-Signature-Processor-Grondbank.vue'

export default {
  name:       'SharedLinkSignatureProcessor',
  computed:   {
    DocumentType() {
      return DocumentType
    },
  },
  components: {
    SharedLinkSignatureProcessorGrondbank,
  },
  props:      {
    link: {
      type:     Object,
      required: true,
    },
  },
  setup:      () => {
    return {}
  },
}
</script>

<template>
  <ui-modal ref="modal" :callback="sendMail">
    <template #title>
      {{ $t('share_link.control_qr.title')}}
    </template>
    <template #default>
      {{ $t('share_link.control_qr.text') }}
      <div class="d-flex justify-content-center">
        <img id="qr-canvas" :src="qrSrc" alt="qrSrc" />
      </div>
      {{ $t('share_link.control_qr.text2') }}
      <div class="d-flex justify-content-center">
        <ui-view-card-field :label="$t('form.labels.email')" v-model="mailInput" />
      </div>
    </template>
  </ui-modal>
</template>

<script>

import {ref, watch} from 'vue'
import UiModal from '@/components/ui-modal'
import UiViewCardField from '@/components/ui-view-card-field'
import backend from '@/util/backend'
import Notifier from '@/util/notifier'

export default {
  name: 'SharedLinkControlQr',
  components: {UiViewCardField, UiModal},
  props: {
    url: {
      type:     String,
      required: true,
    },
    driverUuid: {
      type:     String,
      required: true,
    }
  },
  setup: (props) => {
    const notifier = Notifier()
    const modal = ref('modal')
    const qrSrc = ref(null)

    watch(() => props.url, (url) => {
      if (url) {
        // get control sharelink qr code
        setTimeout(() => {
          var QRCode = require('qrcode')
          QRCode.toDataURL(props.url)
            .then(url => {
              qrSrc.value = url
            })
            .catch(err => {
              console.error(err)
            })
        }, 100)
      }
    })

    const mailInput = ref('')
    const sendMail = () => {
      if (mailInput.value) {
        backend.put(`api/sharedlinks/${props.driverUuid}/share`, {
          email: mailInput.value
        }).then((res) => {
          if (res.status === 200) {
            notifier.success('toast.email_sent')
          } else {
            notifier.error('toast.email_failed')
          }
        })
      } else {
        modal.value.close()
      }
    }

    return {
      modal,
      qrSrc,
      mailInput,
      sendMail,
    }
  }
}
</script>

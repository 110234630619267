const ShareLinkType = {
  PROCESSOR: "PROCESSOR",
  CONTROL: "CONTROL",
  TRANSPORTER: "TRANSPORTER",
  SIGNATURE_TRANSPORTER: "SIGNATURE_TRANSPORTER",
  SIGNATURE_PROCESSOR: "SIGNATURE_PROCESSOR",

  isValidType: (type) => {
    return [
      ShareLinkType.PROCESSOR,
      ShareLinkType.CONTROL,
      ShareLinkType.TRANSPORTER,
      ShareLinkType.SIGNATURE_TRANSPORTER,
      ShareLinkType.SIGNATURE_PROCESSOR,
    ].includes(type.toUpperCase())
  },
}

export default ShareLinkType

<template>
  <layout>
    <template #header>
      <ui-header :title="loading ? '' : item.description" back-route="/waste-identification-templates/list">
        <template #actions>
          <button type="button" class="btn btn-outline-secondary" @click="edit()"><i
              class="bi-pencil"/>&nbsp;{{ $t('general.edit') }}
          </button>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    <ui-content class="pb-1">
      <spinner v-if="loading"/>
      <template v-if="!loading">
        <div class="container-fluid">
          <div class="container-fluid">
            <div class="row justify-content-center">
              <ui-view-card :title="$t('waste_identification_template.form.title')">
                <div class="row">
                  <div class="col-lg-6">
                    {{ $t('waste_identification_template.form.description') }}: {{ item.description }}
                  </div>
                  <div class="col-lg-6">
                    {{ $t('waste_identification_template.form.external_id') }}: {{ item.externalId }}
                  </div>
                  <div class="col-lg-6">
                    {{ $t('waste_identification_template.form.EURAL-code') }}: {{
                      item.euralCode
                      ? item.euralCode.substring(6).replaceAll('_', ' ') + (item.dangerous
                                                                            ? '*'
                                                                            : '')
                      : ''
                    }}
                  </div>
                  <div class="col-lg-6">
                    {{ $t('waste_identification_template.form.dangerous') }}: {{ item.dangerous }}
                  </div>
                  <div class="col-lg-6">
                    {{ $t('waste_identification_template.form.removal_operation') }}: {{ item.removalOperation }}
                  </div>
                  <div class="col-lg-6">
                    {{ $t('waste_identification_template.form.processing_technique') }}: {{
                      item.processingTechnique
                      ? $t('processing_techniques.' + item.processingTechnique)
                      : ''
                    }}
                  </div>
                  <template v-if="item.dangerous">
                    <div class="col-lg-6">
                      {{ $t('waste_identification_template.form.physical') }}: {{ item.physical }}
                    </div>
                    <div class="col-lg-6">
                      {{ $t('waste_identification_template.form.chemical') }}: {{ item.chemical }}
                    </div>
                    <div class="col-lg-6">
                      {{ $t('waste_identification_template.form.packaging_type') }}: {{ item.packagingType }}
                    </div>
                  </template>
                </div>
              </ui-view-card>
            </div>
          </div>
        </div>
      </template>
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import backend from '@/util/backend'
import {ref} from 'vue'
import Spinner from '@/components/spinner'
import UiViewCard from '@/components/ui-view-card'
import {useRouter} from 'vue-router'

export default {
  name:       'WasteIdentificationTemplateView',
  components: {
    UiViewCard,
    Spinner,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout,
  },
  props:      {
    id: {
      type:     String,
      required: true,
    },
  },
  setup:      (props) => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.WASTES, BREADCRUMBS.VIEW]
    const router      = useRouter()

    const loading = ref(true)
    const item    = ref({})
    backend.get(`api/wasteidentificationtemplates/${props.id}`).then((r) => {
      item.value    = r.data
      loading.value = false
    })

    const edit = () => {
      router.push(`/waste-identification-templates/edit/${props.id}`)
    }

    return {
      breadcrumbs,
      loading,
      item,
      edit,
    }
  },
}
</script>

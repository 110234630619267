<template>
  <layout>
    <template #header>
      <ui-header :title="$t('contracts.new.title')" :back-route="`/admin/organisations/${organisationId}`">
        <template #actions>
          <button type="button" class="btn btn-outline-primary" :disabled="saving" @click="save()" form="contract-form"><i class="bi-save" />&nbsp;{{$t('general.create')}}</button>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs" />
    <ui-content class="pb-1">
      <contract-form :item="item" />
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import backend from '@/util/backend'
import {ref, watch} from 'vue'
import ContractForm from '@/partials/contract/Form'
import ContractModel from '@/models/ContractModel'
import {useRouter} from 'vue-router'
import Notifier from "@/util/notifier"

export default {
  name: 'ContractsNew',
  components: {ContractForm, UiContent, UiBreadcrumbs, UiHeader, Layout},
  props: {
    organisationId: {
      type:     String,
      required: true,
    },
  },
  setup: (props) => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.SYSORGANISATIONS, BREADCRUMBS.NEW]
    const item = ref(JSON.parse(JSON.stringify(ContractModel.empty)))
    const date = ref(null)

    const router = useRouter()

    // if no end date set then put it 1 year in the future
    watch(item.value, (value) => {
      if (value.start && !value.stop) {
        value.stop = new Date(value.start)
        value.stop.setFullYear(value.stop.getFullYear() + 1)
      }
    })

    const saving = ref(false)
    const notifier = Notifier()
    const save = async () => {
      saving.value = true
      const result = await backend.post(`api/organisations/${props.organisationId}/contracts`, item.value)
      if (result.status === 200) {
        router.push(`/admin/organisations/${props.organisationId}`)
      } else {
        notifier.error('toast.save_failed')
      }
      saving.value = false
    }

    return {
      breadcrumbs,
      item,
      date,
      save,
      saving,
    }
  }
}
</script>

import backend from '@/util/backend'

const EuralCodes = {
  cache:             [],
  clear:             () => {
    EuralCodes.cache = []
  },
  get:               async () => {
    if (EuralCodes.cache.length === 0) {
      await backend.get('api/euralcodes').then((result) => {
        Object.keys(result.data).sort().forEach((code) => {
          let end = 8
          if (result.data[code].substring(8, 9) === '*') {
            end = 9
          }
          EuralCodes.cache.push({
            label:     result.data[code].substring(0, end),
            value:     code,
            dangerous: result.data[code].indexOf('*') > -1
          })
        })
      })
    }
    return EuralCodes.cache
  },
  getText:           (euralCode) => {
    if (!euralCode) {
      return ''
    }

    return euralCode.substring(6).replaceAll('_', ' ')
  },
  ignoreSpaceSearch: (options, search) => {
    if (search[2] && search[2] !== ' ') {
      search = search.substring(0, 2) + ' ' + search.substring(2)
    }
    if (search[5] && search[5] !== ' ') {
      search = search.substring(0, 5) + ' ' + search.substring(5)
    }
    return search.length
      ? options.filter((o) => {
        return o.label.indexOf(search) > -1
      })
      : options
  }
}

export default EuralCodes

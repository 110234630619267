<template>
  <layout>
    <template #header>
      <ui-header :title="$t('drivers.list.title')">
        <template #actions>
          <div class="btn-group">
            <button id="actionsDropdown" :disabled="selectedIDs.length === 0" aria-expanded="false"
                    class="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" type="button">
              {{ $t('general.actions') }}
            </button>
            <ul data-cy="drivers-actions" class="dropdown-menu" aria-labelledby="actionsDropdown">
              <li>
                <button :disabled="selectedIDs.length === 0" class="dropdown-item" @click="showRemove()">
                  {{ $t('drivers.list.actions.delete') }}
                </button>
              </li>
            </ul>
          </div>
          <button id="addDriverBtn" class="btn btn-outline-primary" type="button" @click="addDriver()"><i
              class="bi-plus"/>&nbsp;{{ $t('drivers.add_driver') }}
          </button>
        </template>
      </ui-header>
    </template>
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
      </div>
      <div>
        <button class="btn btn-sm btn-outline-secondary" @click="resetGrid()">{{ $t('general.reset_filters') }}</button>
      </div>
    </div>
    <ui-content>
      <ag-grid-vue
        :serverSideDatasource="grid.datasource('api/drivers/list')"
        :grid-options="grid.defaultOptions"
        :columnDefs="xsWindow ? xsColumnDefs : columnDefs"
        class="ag-theme-quartz"
        style="min-height:100%"
        @row-clicked="rowClick"
        @selection-changed="refreshSelection"
        @grid-ready="onGridReady"
        @grid-pre-destroyed="onGridPreDestroyed"
        @filter-changed="onFilterChanged"
        :initialState="initialState"
      />
    </ui-content>
  </layout>
  <driver-delete-modal ref="removeModal" :amount="selectedIDs.length" :callback="remove"/>
</template>

<script>

import {inject, onBeforeUnmount, ref} from 'vue'
import backend from '@/util/backend'
import Layout from '@/components/layout'
import {AgGridVue} from 'ag-grid-vue3'
import {useRouter} from 'vue-router'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import UiContent from '@/components/ui-content'
import DriverDeleteModal from '@/views/drivers/Delete-Modal'
import languages from '@/types/languages'
import grid from '@/util/grid'
import session from '@/util/session'

export default {
  name: 'DriversList',
  computed: {
    grid() {
      return grid
    }
  },
  components: {
    DriverDeleteModal,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    AgGridVue,
    Layout,
  },
  setup: () => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.DRIVERS]
    const $t = inject('$t')

    const gridKey = 'drivers-list'
    const initialState = ref(session.loadFormState(gridKey))
    const onGridPreDestroyed = (params) => {
      session.saveFormState(gridKey, params.state)
    }
    const onFilterChanged = (event) => {
      session.saveFormState(gridKey, event.api.getState())
    }

    let api = null
    const onGridReady = (params) => {
      api = params.api
      api.deselectAll() // if we can correctly get selection from saved state we can re-evaluate this
      api.sizeColumnsToFit({'defaultMinWidth': 200})
    }
    onBeforeUnmount(() => {
      api = null
    })
    const reload = () => {
      api?.refreshServerSide()
      api?.deselectAll()
    }

    const resetGrid = () => {
      api?.setFilterModel(null)
      api?.onFilterChanged()
      session.saveFormState(gridKey, null)
    }

    const xsWindow = ref(window.innerWidth < 576)
    const resize = () => {
      xsWindow.value = window.innerWidth < 576
      setTimeout(() => {
        api.sizeColumnsToFit({'defaultMinWidth': 200})
      }, 1)
    }
    window.addEventListener("resize", resize)
    onBeforeUnmount(() => {
      window.removeEventListener("resize", resize)
    })

    const xsColumnDefs = [
      {
        headerName: $t('drivers.list.first_name'),
        field: 'firstName',
      },
    ]

    const columnDefs = [
      {
        headerName: $t('drivers.list.first_name'),
        field: 'firstName',
      },
      {headerName: $t('drivers.list.last_name'), field: 'lastName'},
      {headerName: $t('drivers.list.email'), field: 'email'},
      {
        headerName: $t('drivers.list.language'),
        field: 'language',
        filter: 'agSetColumnFilter',
        valueGetter: (params) => {
          return languages.getLanguage(params.data.language, $t)
        },
        filterParams: {
          values: languages.languages,
          keyCreator: (val) => {
            return val.value.value // filter key sent to backend e.g. EN or NL
          },
          valueFormatter: (val) => {
            return $t(val.value.label) // filter value shown in dropdown e.g. English or Dutch
          }
        },
        comparator: grid.caseInsensitiveStateComparator,
      },
    ]

    const router = useRouter()
    const rowClick = (event) => {
      if (event.data) {
        router.push(`/drivers/view/${event.data.id}`)
      }
    }

    const addDriver = () => {
      router.push(`/drivers/new`)
    }

    const removeModal = ref('removeModal')
    const showRemove = () => {
      removeModal.value.modal.open()
    }
    const remove = () => {
      const ids = api.getSelectedRows().map((row) => {
        return row.id
      })
      if (ids.length > 0) {
        backend.post('api/drivers/batch', {
          'action': 'DELETE',
          'ids': ids,
        }).finally(() => {
          removeModal.value.modal.close()
          reload()
        })
      }
    }

    const selectedIDs = ref([])
    const refreshSelection = (params) => {
      selectedIDs.value = params.api.getSelectedRows().map((row) => {
        return row.id
      })
    }

    return {
      breadcrumbs,
      onGridReady,
      onGridPreDestroyed,
      onFilterChanged,
      initialState,
      xsWindow,
      xsColumnDefs,
      columnDefs,
      rowClick,
      addDriver,
      selectedIDs,
      refreshSelection,
      removeModal,
      showRemove,
      remove,
      resetGrid,
    }
  }
}
</script>

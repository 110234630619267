<template>
  <div class="container-fluid">
    <form id="ecmr-form" class="row g-2">
      <ui-view-card :title="$t('form.title.basic_information')" type="col-lg-12">
        <div class="row">
          <div class="col-lg-6">
            <ui-view-card-field data-cy="ecmr-id" :label="$t('form.labels.unique_identifier')" :model-value="itemRef.id ? 'B-'+itemRef.id : ''" readonly />
            <ui-view-card-field data-cy="ecmr-external-id" :label="$t('form.labels.external_identifier')" v-model="itemRef.externalId" />
            <ui-view-card-field data-cy="ecmr-last-update" :label="$t('form.labels.last_update')" :model-value="updateDate" readonly />
            <ui-view-card-field data-cy="ecmr-updated-by" :label="$t('form.labels.updated_by')" :model-value="itemRef.modifiedBy" readonly />
          </div>
          <div class="col-lg-6">
            <ui-view-card-field data-cy="ecmr-state" :label="$t('form.labels.status')" :model-value="$t('form.state.'+itemRef.state)" readonly />
            <ui-view-card-field :label="$t('form.labels.transport_date')" required>
              <datepicker data-cy="ecmr-transport-date" v-model="itemRef.transportDate" :enableTimePicker="false" :autoApply="true" :minDate="new Date()" format="dd/MM/yyyy" :required="true" />
            </ui-view-card-field>
          </div>
        </div>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.producer')">
        <ui-view-card-producer v-model="itemRef.producerIdentification" shipment-address />
      </ui-view-card>
      <ui-view-card :title="$t('form.title.processor')">
        <ui-view-card-processor v-model="itemRef.processorIdentification" shipment-address />
      </ui-view-card>
      <ui-view-card :title="$t('form.title.organiser')">
        <ui-view-card-organiser v-model="itemRef.organiserIdentification" />
      </ui-view-card>
      <template v-for="(_, index) in itemRef.transporterIdentifications" :key="index">
        <ui-view-card :title="index === 0 ? $t('form.title.primary_transporter') : $t('form.title.transporter_count', {count: index+1})">
          <template #actions>
            <button v-if="index === 0" type="button" class="btn btn-sm btn-outline-primary" @click="addTransporter()"><i class="bi-plus" />&nbsp;{{ $t('form.table.add_transporter') }}</button>
            <button v-else type="button" class="btn btn-sm btn-outline-danger" @click="removeTransporter(index)"><i class="bi-minus" />&nbsp;{{ $t('form.table.remove_transporter') }}</button>
          </template>
          <ui-view-card-transporter v-model="itemRef.transporterIdentifications[index]" />
        </ui-view-card>
      </template>
      <ui-view-card :title="$t('form.title.goods')" type="col-lg-6">
        <textarea class="form-control" rows="4" style="width: 100%" v-model="itemRef.cmrGoods" required />
      </ui-view-card>
      <ui-view-card :title="$t('form.title.costs')" type="col-lg-6">
        <template #actions>
          <button type="button" class="btn btn-sm btn-outline-primary" @click="addCost()"><i class="bi-plus" />&nbsp;{{ $t('form.table.add_cost') }}</button>
        </template>
        <table class="table table-bordered table-striped">
          <thead>
          <tr>
            <th>{{$t('form.table.type')}}<span :title="$t('general.required')" class="text-danger">*</span></th>
            <th style="width: 500px">{{$t('form.table.description')}}<span :title="$t('general.required')" class="text-danger">*</span></th>
            <th style="width: 60px">{{$t('form.table.amount')}}<span :title="$t('general.required')" class="text-danger">*</span></th>
            <th style="width: 60px" />
          </tr>
          </thead>
          <tbody>
          <template v-for="(row, index) in itemRef.transportCosts" :key="index">
            <tr>
              <td>
                <v-select :options="transportCostTypes" v-model="row.transportCostType" :reduce="operation => operation.value">
                  <template #search="{attributes, events}">
                    <input
                      class="vs__search"
                      :required="!row.transportCostType"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <template #no-options>
                    {{ $t('general.no_options') }}
                  </template>
                </v-select>
              </td>
              <td>
                <input class="form-control" v-model="row.description" required />
              </td>
              <td>
                <div class="container">
                  <div class="row">
                    <input class="col form-control" readonly placeholder="€" />
                    <input v-model="row.amount" class="col form-control" required/>
                  </div>
                </div>
              </td>
              <td>
                <div>
                  <div class="btn btn-danger" @click="removeCost(index)">
                    <i class="bi-trash" />
                  </div>
                </div>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </ui-view-card>
    </form>
  </div>
</template>

<script>

import {computed, inject, ref} from 'vue'
import UiViewCard from '@/components/ui-view-card'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import UiViewCardField from '@/components/ui-view-card-field'
import {DateFormatter} from '@/services/dateFormatter'
import UiViewCardProducer from '@/components/ui-view-card-producer'
import UiViewCardOrganiser from '@/components/ui-view-card-organiser'
import UiViewCardTransporter from '@/components/ui-view-card-transporter'
import UiViewCardProcessor from '@/components/ui-view-card-processor'
import transportCostType from '@/types/transportCostType'

export default {
  name: 'ECmrForm',
  components: {
    UiViewCardProcessor,
    UiViewCardTransporter,
    UiViewCardOrganiser,
    UiViewCardProducer,
    UiViewCardField,
    Datepicker,
    UiViewCard,
  },
  props: {
    item: {
      type:     Object,
      required: true,
    }
  },
  setup: (props) => {
    const $t = inject('$t')

    let itemRef = ref(props.item)
    const state = $t("form.state."+itemRef.value.state)

    const addTransporter = () => {
      itemRef.value.transporterIdentifications.push({'address': {}})
    }

    const removeTransporter = (index) => {
      if (index !== 0) {
        itemRef.value.transporterIdentifications.splice(index, 1)
      }
    }

    const updateDate = computed(() => {
      return DateFormatter.formatDateTime(itemRef.value.lastUpdate)
    })

    const addCost = () => {
      itemRef.value.transportCosts.push({'type': transportCostType.GENERAL, 'description': '', 'amount': null})
    }

    const removeCost = (index) => {
      itemRef.value.transportCosts.splice(index, 1)
    }

    const transportCostTypes = ref([
      {label: $t('transport_cost_types.GENERAL'), value: 'GENERAL'},
      {label: $t('transport_cost_types.REIMBURSEMENT'), value:'REIMBURSEMENT'},
    ])

    return {
      itemRef,
      state,
      addTransporter,
      removeTransporter,
      updateDate,
      addCost,
      removeCost,
      transportCostTypes,
    }
  }
}
</script>

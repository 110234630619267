<template>
  <ui-modal ref="modal" :callback="callback" :can-confirm="canConfirm">
    <template #title>
      {{ $t('sys_admin.org_details.delete_modal.title')}}
    </template>
    <template #default>
      {{ $t('sys_admin.org_details.delete_modal.delete_organisation') }}
      <br />
      <br />
      <ui-view-card-field label="Please type 'DELETE ME' to confirm">
        <input class="form-control" v-model="checkField" type="text">
      </ui-view-card-field>
    </template>
  </ui-modal>
</template>

<script>

import {ref} from 'vue'
import UiModal from '@/components/ui-modal'
import UiViewCardField from '@/components/ui-view-card-field.vue'

export default {
  name: 'AdminOrganisationDeleteModal',
  components: {
    UiViewCardField,
    UiModal
  },
  props: {
    callback: {
      type:     Function,
      required: true,
    }
  },
  setup: () => {
    const modal = ref('modal')

    const checkField = ref('')
    const canConfirm = () => {
      return checkField.value === 'DELETE ME'
    }

    return {
      modal,
      checkField,
      canConfirm
    }
  }
}
</script>

const OrganisationState = {
  ONBOARDING: "ONBOARDING",
  ACTIVE: "ACTIVE",
  DELETED: "DELETED",
  INACTIVE: "INACTIVE",

  getAll: () => {
    return [
      OrganisationState.ONBOARDING,
      OrganisationState.ACTIVE,
      OrganisationState.DELETED,
      OrganisationState.INACTIVE
    ]
  }
}

export default OrganisationState

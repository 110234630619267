<template>
  <layout>
    <div class="container">
      {{ text }}
      <br />
      <br />
      <router-link class="btn btn-primary" :to="{ name: 'Home'}">{{ $t('logout.return_to_login') }}</router-link>
    </div>
  </layout>
</template>

<script>

import {inject, ref} from 'vue'
import Layout from '@/components/layout'

export default {
  name: 'Logout',
  components: {Layout},
  setup: () => {
    const $t = inject('$t')
    const text = ref($t('logout.you_logged_out'))

    return {text}
  }
}
</script>

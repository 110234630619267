import DocumentType from '../types/documentType'
import WasteIdentificationTemplateModel from '@/models/WasteIdentificationTemplateModel'
import OrganisationModel from '@/models/OrganisationModel'
import backend from '@/util/backend'
import TransportDocumentTemplateState from '@/types/transportDocumentTemplateState'

const TransportDocumentTemplate = {
  wasteIdentification: {
    id: null,
    documentType: DocumentType.WASTE_IDENTIFICATION,
    name: '',
    notes: '',
    producers: [],
    processors: [],
    wasteIdentificationTemplateProcessors: [],
    transporters: [],
    organiser: null,
    organiserSignature: null
  },
  validWasteIdentificationTemplateProcessors: (waste, processors) => {
    const validIDs = processors.map((proc) => { return proc.id })
    let allProcessorsValid = waste.allowedProcessors &&
      waste.allowedProcessors.length > 0
    waste.allowedProcessors.forEach((processor) => {
      if (validIDs.indexOf(processor) === -1) {
        allProcessorsValid = false
      }
    })
    return WasteIdentificationTemplateModel.validWaste(waste.wasteIdentification) &&
      allProcessorsValid
  },
  canSignWasteIdentification: (template) => {
    if (!template.name) {
      return 'toast.invalid_name'
    }
    const validProducers = template.producers.length > 0 && template.producers.filter((producer) => { return !OrganisationModel.validProducer(producer)}).length === 0
    if (!validProducers) {
      return 'toast.invalid_producer'
    }
    const validProcessors = template.processors.length > 0 && template.processors.filter((processor) => { return !OrganisationModel.validProcessor(processor)}).length === 0
    if (!validProcessors) {
      return 'toast.invalid_processor'
    }
    const validOrganiser = template.organiser === null || (template.organiser && OrganisationModel.validOrganiser(template.organiser))
    if (!validOrganiser) {
      return 'toast.invalid_organiser'
    }
    const validTransporters = template.transporters.length > 0 && template.transporters.filter((transporter) => { return !OrganisationModel.validTransporter(transporter)}).length === 0
    if (!validTransporters) {
      return 'toast.invalid_transporter'
    }
    const validWastes = template.wasteIdentificationTemplateProcessors.length > 0 && template.wasteIdentificationTemplateProcessors.filter((waste) => { return !TransportDocumentTemplate.validWasteIdentificationTemplateProcessors(waste, template.processors)}).length === 0
    if (!validWastes) {
      return 'toast.invalid_waste'
    }
  },
  setSigned: async (template) => {
    return await backend.put(`api/transportDocumentTemplates/${template.id}/changeState`, {transportDocumentTemplateState: TransportDocumentTemplateState.SIGNED})
  },
  setDraft: async (template) => {
    return await backend.put(`api/transportDocumentTemplates/${template.id}/changeState`, {transportDocumentTemplateState: TransportDocumentTemplateState.DRAFT})
  },
  setNotActive: async (template) => {
      return await backend.put(`api/transportDocumentTemplates/${template.id}/changeState`, {transportDocumentTemplateState: TransportDocumentTemplateState.NOT_ACTIVE})
  },
}

export default TransportDocumentTemplate

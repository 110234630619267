<template>
  <div class="d-flex align-items-center justify-content-center" id="spinner">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">{{$t('general.loadingOoo')}}</span>
    </div>
    <strong class="ms-3">{{$t('general.loadingOoo')}}</strong>
  </div>
</template>

<script>

export default {
  name: 'spinner',
  setup: () => {
    return {}
  }
}
</script>
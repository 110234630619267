<template>
  <layout>
    <template #header>
      <ui-header :title="$t('organisations.edit.title', {name: organisation && organisation.name ? organisation.name : ''})" :back-route="`/organisations/view/${id}`">
        <template #actions>
          <button type="button" class="btn btn-outline-primary" :disabled="saving" @click="save()" form="organisation-form"><i class="bi-save" />&nbsp;{{$t('general.save')}}</button>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs" />
    <ui-content class="pb-1">
      <spinner v-if="loading"/>
      <template v-if="!loading">
        <organisation-form :item="organisation" />
      </template>
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import backend from '@/util/backend'
import {ref} from 'vue'
import {useRouter} from 'vue-router'
import Notifier from '@/util/notifier'
import OrganisationForm from '@/partials/organisation/Form'
import Spinner from '@/components/spinner'

export default {
  name: 'OrganisationEdit',
  components: {Spinner, OrganisationForm, UiContent, UiBreadcrumbs, UiHeader, Layout},
  props: {
    id: {
      type:     String,
      required: true,
    }
  },
  setup: (props) => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.ORGANISATIONS, BREADCRUMBS.EDIT]
    const loading = ref(true)
    const notifier = Notifier()
    const router = useRouter()

    const organisation = ref(null)
    backend.get(`api/contacts/${props.id}`).then((r) => {
      organisation.value = r.data
      loading.value = false
    })

    const saving = ref(false)
    const save = async () => {
      saving.value = true
      const formElement = $('#organisation-form')[0]
      if (!formElement.checkValidity()) {
        // something in the form is not valid so don't allow signing
        formElement.reportValidity()
        saving.value = false
        return false
      }

      const result = await backend.put(`api/contacts/${props.id}`, organisation.value)
      if (result.status === 200) {
        router.push(`/organisations/view/${props.id}`)
      } else {
        notifier.error('toast.save_failed')
      }
      saving.value = false
    }

    return {
      breadcrumbs,
      organisation,
      save,
      saving,
      loading,
    }
  }
}
</script>

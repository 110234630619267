<template>
  <div class="d-flex justify-content-between">
    <h3 class="d-none d-sm-flex">
      <span data-cy="back" class="btn-group-sm pe-3" v-if="backRoute">
        <button type="button" class="btn btn-outline-secondary" @click="goBack"><i class="bi-arrow-left" /></button>
      </span>
      <span data-cy="back" class="btn-group-sm pe-3" v-if="backFunction">
        <button type="button" class="btn btn-outline-secondary" @click="backFunction()"><i class="bi-arrow-left" /></button>
      </span>
      <span data-cy="title" :style="backRoute || backFunction ? 'margin-left: 0' : 'margin-left: 3rem'">
        {{ title }}
      </span>
    </h3>
    <h3 class="d-flex d-sm-none">
      <span data-cy="title">
        {{ title }}
      </span>
    </h3>
    <div class="d-flex align-items-center">
      <slot name="endtext" />
      <div class="btn-group float-end ps-3">
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script>
import {useRouter} from 'vue-router'
import OrganisationType from '@/types/organisationType'

export default {
  name: 'ui-header',
  computed: {
    OrganisationType() {
      return OrganisationType
    }
  },
  props: {
    title: {
      type:     String,
      required: true
    },
    backRoute: {
      type:    String,
      default: null,
    },
    backFunction: {
      type:    Function,
      default: null,
    }
  },
  setup: (props) => {
    const router = useRouter()
    const goBack = () => {
      router.push(props.backRoute)
    }

    return {
      goBack
    }
  }
}
</script>
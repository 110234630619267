<template>
  <layout>
    <div class="display-3 mt-5">{{ $t('valid.title') }}</div>
    <br/>
    <br/>
    <div class="grey--text lighten-5">
      {{ $t('valid.text_0') }}
      <br/>
      {{ $t('valid.text_1') }}
      <br/>
      {{ $t('valid.text_2') }}
    </div>
    <br/>
    <br/>
    <img src="../../assets/logo.svg" alt="" width="80" height="80" class="d-inline-block align-bottom">
  </layout>

</template>

<script>

import Layout from '@/components/layout'
export default {
  name: 'ValidSignature',
  components: {Layout},
  setup: () => {
    return []
  }
}
</script>

<template>
  <div class="row">
    <div class="col-lg-6">
      <Popper arrow class="light" @close="closePopper" :show="showPopper && suggestions.length > 0" v-click-outside="closePopper">
        <ui-view-card-field data-cy="processor-name" :label="$t('form.labels.name')" v-model="model.name" :required="true" />
        <template #content>
          <div v-for="suggestion in suggestions" :key="suggestion.name" class="popper-suggestion" >
            <div @click="useSuggestion(suggestion)">
              <span v-if="suggestion" >
                {{suggestion.name}}: {{getStreet(suggestion)}} {{getNumber(suggestion)}}
              </span>
            </div>
          </div>
        </template>
      </Popper>
    </div>
    <div class="col-lg-6">
      <ui-view-card-field v-model="model.externalId" :label="$t('form.labels.external_identifier')" :maxlength="50" />
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6">
      <ui-view-card-field v-model="model.email" :label="$t('form.labels.email')" :required="true" data-cy="processor-email"
                          type="email"/>
    </div>
    <div class="col-lg-6">
      <ui-view-card-field data-cy="processor-phone'" v-model="model.phone" :label="$t('form.labels.phone_number')" />
    </div>
  </div>
  <ui-view-card-address cy="processor" :edit="true" v-model="model.address" :required="true" />
  <ui-view-card-language cy="processor-language" :label="$t('form.labels.language')" v-model="model.language" :required="true" />
  <ui-view-card-ids v-model="model.identifications" :required="true" cy="processor" />
  <ui-view-card-field v-if="sameAddress" :label="$t('form.labels.matis_location_type')" >
    <v-select v-if="matisLocationTypesLoaded" :options="matisLocationTypes"
              v-model="model.locationType" :reduce="type => type.value">
      <template #search="{attributes, events}">
        <input
          class="vs__search"
          v-bind="attributes"
          v-on="events"
        />
      </template>
      <template #no-options>
        {{ $t('general.no_options') }}
      </template>
    </v-select>
  </ui-view-card-field>
  <template v-if="shipmentAddress">
    <hr />
    <h6 class="card-subtitle text-muted">
      {{$t('form.receiving_address')}}
    </h6>
    <ui-view-card-field :label="$t('form.labels.same_address')" data-cy="processor-same-address" v-model="sameAddress" type="checkbox" />
    <ui-view-card-address v-if="!sameAddress && model.shipmentAddress" cy="processor-shipment" :edit="true" v-model="model.shipmentAddress" />
    <ui-view-card-field v-if="!sameAddress" :label="$t('form.labels.matis_location_type')" >
      <v-select v-if="matisLocationTypesLoaded" :options="matisLocationTypes"
                v-model="model.locationType" :reduce="type => type.value">
        <template #search="{attributes, events}">
          <input
              class="vs__search"
              v-bind="attributes"
              v-on="events"
          />
        </template>
        <template #no-options>
          {{ $t('general.no_options') }}
        </template>
      </v-select>
    </ui-view-card-field>
  </template>
</template>

<script>

import UiViewCardField from '@/components/ui-view-card-field'
import UiViewCardAddress from '@/components/ui-view-card-address'
import {ref, watch} from 'vue'
import {useModelWrapper} from '@/util/modelWrapper'
import {debounce} from '@/util/debounce'
import backend from '@/util/backend'
import UiViewCardIds from '@/components/ui-view-card-ids'
import clearField from '@/services/clearfield'
import UiViewCardLanguage from '@/components/ui-view-card-language'
import _ from 'lodash'
import MatisLocationTypes from '@/models/MatisLocationTypes'

export default {
  name: 'ui-view-card-processor',
  components: {
    UiViewCardLanguage,
    UiViewCardIds,
    UiViewCardAddress,
    UiViewCardField
  },
  props: {
    modelValue: {
      default: '',
    },
    shipmentAddress: {
      type:    Boolean,
      default: false,
    }
  },
  setup: (props, {emit}) => {
    const model = ref(useModelWrapper(props, emit))
    const sameAddress = ref(true)

    if (!model.value.address) {
      model.value.address = {}
    }

    if (props.shipmentAddress) {
      if (!model.value.shipmentAddress) {
        model.value.shipmentAddress = {}
      }

      //----------------------------
      // Same Address checkbox logic
      //----------------------------
      const shipmentAddress = JSON.parse(JSON.stringify(model.value.shipmentAddress))
      const address = JSON.parse(JSON.stringify(model.value.address))
      delete shipmentAddress.id
      delete address.id
      sameAddress.value = JSON.stringify(shipmentAddress) === JSON.stringify(address)

      watch(() => sameAddress.value, (newVal) => {
        if (hidePopup.value) { // if triggered by suggestion then ignore
          return
        }
        if (!newVal) {
          model.value.shipmentAddress = {}
        } else {
          model.value.shipmentAddress = clearField(model.value.address, 'id')
        }
      })

      // if sameAddress then keep shipmentAddress in sync with address
      watch(() => model.value.address, (newVal) => {
        if (sameAddress.value) {
          if (newVal.id) {
            delete newVal.id
          }
          model.value.shipmentAddress = newVal
        }
      }, {deep: true})
    }

    const suggestions = ref([])
    const hidePopup = ref(false)
    const showPopper = ref(true)
    watch(() => model.value.name, debounce(async (newVal) => {
      if (newVal && !hidePopup.value) {
        showPopper.value = true
        const result = await backend.get(`/api/contacts/suggest?searchTerm=${encodeURIComponent(newVal)}`)
        if (result.status === 200 && result.data.length > 0) {
          suggestions.value = result.data.slice(0, 5)
        } else {
          showPopper.value = false
        }
      }
      hidePopup.value = false
    }, 400))

    setTimeout(() => {
      hidePopup.value = false // hide popup on open, then enable after half a second
    }, 500)

    const closePopper = () => {
      showPopper.value = false
    }

    const useSuggestion = (suggestion) => {
      const cleared = clearField(suggestion, 'id')
      if (!cleared.shipmentAddress) {
        cleared.shipmentAddress = props.shipmentAddress ? {} : null
      }
      if (cleared.identifications.length === 0) {
        cleared.identifications.push({
          value: null,
          identificationType: null
        })
      }
      sameAddress.value = _.isEqual(cleared.address, cleared.shipmentAddress)
      Object.assign(model.value, cleared)
      hidePopup.value = true
      closePopper()
    }

    const matisLocationTypes = ref([])
    const matisLocationTypesLoaded = ref(false)
    const getMatisLocationTypes = async () => {
      matisLocationTypes.value = await MatisLocationTypes.get()
      matisLocationTypesLoaded.value = true
    }
    getMatisLocationTypes()

    const getStreet = (suggestion) => {
      if (suggestion.shipmentAddress && suggestion.shipmentAddress.street) {
        return suggestion.shipmentAddress.street
      }
      if (suggestion.address && suggestion.address.street) {
        return suggestion.address.street
      }
    }

    const getNumber = (suggestion) => {
      if (suggestion.shipmentAddress && suggestion.shipmentAddress.number) {
        return suggestion.shipmentAddress.number
      }
      if (suggestion.address && suggestion.address.number) {
        return suggestion.address.number
      }
    }

    return {
      model,
      sameAddress,
      suggestions,
      showPopper,
      closePopper,
      useSuggestion,
      matisLocationTypes,
      matisLocationTypesLoaded,
      getStreet,
      getNumber,
    }
  }
}
</script>

<template>
  <layout>
    {{ text }}
  </layout>
</template>

<script>

import backend from '@/util/backend'
import {inject, ref} from 'vue'
import Layout from '@/components/layout'
import session from '@/util/session'
import router from '@/router/router'

export default {
  name: 'Login',
  components: {Layout},
  setup: () => {
    const $t = inject('$t')
    const text = ref($t('login.try_login_page'))

    const goMaintenance = () => {
      router.push({'name': 'Maintenance'})
    }
    setTimeout(goMaintenance, 5000)

    backend.getLogin().then((r) => {
      session.removeOrganisation()
      session.removeUser()
      location.href = r.data
    })

    return {text}
  }
}
</script>

<template>
  <layout>
    <template #header>
      <ui-header :title="$t('sys_admin.dashboard.title')">
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    <ui-content>
      <div class="p-5">
        <ag-charts-vue :options="docsPerDayOptions"/>
      </div>
      <div class="p-5">
        <ag-charts-vue :options="contractStartOptions"/>
      </div>
      <div class="p-5">
        <ag-charts-vue :options="amountStartOptions"/>
      </div>
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import UiContent from '@/components/ui-content'
import backend from '@/util/backend'
import {AgChartsVue} from 'ag-charts-vue3'
import {ref} from 'vue'
import ContractState from '@/types/contractState'
import {DateFormatter} from '@/services/dateFormatter'

export default {
  name:       'Dashboard',
  components: {
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout,
    AgChartsVue,
  },
  setup:      () => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.DASHBOARD]

    const docsPerDay = ref([])
    const docsPerDayOptions = ref({
      title:  {text: 'Signed forms'},
      legend: {position: 'bottom'},
      data:   docsPerDay.value,
      series: [
        {
          type: 'line',
          xKey: 'date',
          yKey: 'count',
        },
      ],
      axes:   [
        {
          type:     'number',
          position: 'left',
          tick:     {
            interval: 1,
          },
          min: 0,
        },
        {
          type:     'time',
          position: 'bottom',
          label:    {
            formatter: (params) => {
              return DateFormatter.formatDate(params.value)
            },
          },
          min: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
          max: new Date(),
        },
      ],
    })

    const contractStarts = ref([])
    const contractStartOptions = ref({
      title:  {text: 'Contract start'},
      legend: {position: 'bottom'},
      data:   contractStarts.value,
      series: [
        {
          type: 'line',
          xKey: 'date',
          yKey: 'count',
        },
      ],
      axes:   [
        {
          type:     'number',
          position: 'left',
          tick:     {
            interval: 1,
          },
          min: 0,
        },
        {
          type:     'time',
          position: 'bottom',
          label:    {
            formatter: (params) => {
              return DateFormatter.formatDate(params.value)
            },
          },
          min: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
          max: new Date(),
        },
      ],
    })

    const amountStartOptions = ref({
      title:  {text: 'Amount of forms'},
      legend: {position: 'bottom'},
      data:   contractStarts.value,
      series: [
        {
          type: 'line',
          xKey: 'date',
          yKey: 'amount',
        },
      ],
      axes:   [
        {
          type:     'number',
          position: 'left',
          min: 0,
        },
        {
          type:     'time',
          position: 'bottom',
          label:    {
            formatter: (params) => {
              return DateFormatter.formatDate(params.value)
            },
          },
          min: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
          max: new Date(),
        },
      ],
    })

    let index = 0
    const getOrgs = () => {
      backend.post(`api/admin/organisations/list`, {
        startRow:    index,
        endRow:      index + 25,
        sortModel:   [],
        filterModel: {},
      }).then((result) => {
        result.data.forEach((org) => {
          const contract = org.contracts.find((c) => {
            return c.contractState === ContractState.ACTIVE
          })
          if (contract) {
            const start = new Date(contract.start).setUTCHours(0, 0, 0, 0)
            const o = contractStarts.value.find((cs) => {
              return cs.date === start
            })
            if (o) {
              o.count++
              o.amount += contract.initialCredits
            } else {
              contractStarts.value.push({
                date:   start,
                count:  1,
                amount: contract.initialCredits,
              })
            }
          }
        })
        if (result.data.length === 25) {
          index += 25
          getOrgs()
        } else {
          contractStarts.value.sort((a, b) => {
            return a.date > b.date ? 1 : -1
          })
        }
      })
    }
    getOrgs()

    const getDocs = async () => {
      const to = new Date()

      let date = new Date(new Date().setFullYear(new Date().getFullYear() - 1))
      date.setUTCHours(0, 0, 0, 0)
      do {
        const count = await backend.get(`api/documents/count?from=${date.toISOString().substring(0,10)}&to=${new Date(date.getTime()+86400000).toISOString().substring(0,10)}`)
        docsPerDay.value.push({
          date: date,
          count: count.data,
        })
        date = new Date(date.getTime()+86400000)
      } while (date < to)
    }
    getDocs()

    return {
      breadcrumbs,
      contractStartOptions,
      amountStartOptions,
      docsPerDayOptions,
    }
  },
}
</script>

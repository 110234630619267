<template>
<!--  <layout>-->
  <div class="m-5">
    <div class="display-3">{{ $t('share_link.expired.title') }}</div>
    <div class="paragraph-text mt-2">
      <br />
      <br />
      {{ $t('share_link.expired.text') }}
    </div>
  </div>
<!--  </layout>-->
</template>

<script>

// import Layout from '@/components/layout'
export default {
  name: 'SharedLinkExpired',
  // components: {Layout},
  setup: () => {
    return []
  }
}
</script>

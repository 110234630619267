<template>
  <ui-modal ref="modal" :callback="callback" :on-open="checkLinkableOrganisation" :no-confirm="!idFound || (!found && !mailFound)">
    <template #title>
      {{ $t('organisations.link_modal.title') }}
    </template>
    <template #default v-if="loading">
      <spinner />
    </template>
    <template #default v-else-if="!idFound">
      {{ $t('organisations.link_modal.missing_identification') }}
    </template>
    <template #default v-else-if="!found && mailFound">
      {{ $t('organisations.link_modal.not_found', {email: organisation.email}) }}
    </template>
    <template #default v-else-if="!found">
      {{ $t('organisations.link_modal.not_found_missing_mail') }}
    </template>
    <template #default v-else>
      {{ $t('organisations.link_modal.found') }}
    </template>
  </ui-modal>
</template>

<script>

import {ref} from 'vue'
import UiModal from '@/components/ui-modal'
import IdentificationType from '@/types/identificationType'
import backend from '@/util/backend'
import Spinner from '@/components/spinner.vue'

export default {
  name:       'OrganisationLinkModal',
  components: {
    Spinner,
    UiModal,
  },
  props:      {
    callback:     {
      type:     Function,
      required: true,
    },
    organisation: {
      type:     Object,
      required: true,
    },
  },
  setup:      (props) => {
    const modal = ref('modal')

    const loading = ref(true)
    const idFound = ref(false)
    const mailFound = ref(false)
    const found = ref(false)
    const checkLinkableOrganisation = () => {
      let identification = null
      props.organisation.identifications.forEach((id) => {
        if (id.identificationType === IdentificationType.VAT) {
          identification = {
            type:  id.identificationType,
            value: id.value,
          }
        }
      })
      mailFound.value = props.organisation.email
      idFound.value = !!identification
      backend.get(`/api/organisations/search?identification=${identification.value}&identificationType=${identification.type}`).then((res) => {
        if (res.data.length > 0) {
          found.value = res.data[0]
        }
      }).finally(() => {
        loading.value = false
      })
    }

    return {
      modal,
      loading,
      idFound,
      mailFound,
      found,
      checkLinkableOrganisation,
    }
  },
}
</script>

<template>
  <div class="pb-1">
    <label class="pe-2" :for="id">
      {{ label }}<span v-if="required" :title="$t('general.required')" class="text-danger">*</span><i v-if="icon" style="font-size: 0.75rem; position: absolute" :class="icon" :title="iconText" />
    </label>
    <slot>
      <number-input v-model="model" :id="id" :autocomplete="autocomplete" :readonly="readonly" :class="readonly ? 'form-control-plaintext' : 'form-control'" :required="required" :placeholder="placeholder" :maxlength="maxlength"/>
    </slot>
  </div>
</template>

<script>

import {useModelWrapper} from '@/util/modelWrapper'
import NumberInput from '@/components/NumberInput.vue'

export default {
  name: 'ui-view-card-number',
  components: {NumberInput},
  props: {
    label: {
      type:     String,
      required: true,
    },
    modelValue: {
      default: '',
    },
    readonly: {
      type:    Boolean,
      default: false,
    },
    required: {
      type:    Boolean,
      default: false,
    },
    type: {
      type:    String,
      default: 'text',
    },
    autocomplete: {
      type:    String,
      default: '',
    },
    icon: {
      type:    String,
      default: ''
    },
    iconText: {
      type:    String,
      default: ''
    },
    placeholder: {
      type:    String,
      default: '',
    },
    maxlength: {
      type:    Number,
      default: 255,
    },
  },
  setup: (props, {emit}) => {
    const id = 'id-'+Math.random()
    return {
      model: useModelWrapper(props, emit),
      id,
    }
  }
}
</script>

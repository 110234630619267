<template>
  <layout :standard-header="false">
    <template #altHeader>
      <ui-list-driver-tabs/>
    </template>
    <div class="d-flex justify-content-end mb-2">
      <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
      <div class="text-muted">
        <template v-if="$isMobile">
          <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="optionsDropdown"
                  data-bs-toggle="dropdown">
            {{ $t('forms.list.list_options') }}
          </button>
          <ul data-cy="grondbank-options" class="dropdown-menu" aria-labelledby="optionsDropdown">
            <li>
              <div style="padding: 0.25rem 1rem;">
                <ui-view-card-field :label="$t('forms.list.options.start_date')">
                  <datepicker data-cy="list-from-date" v-model="listFrom" :enableTimePicker="false" :autoApply="true"
                              format="dd-MM-yyyy"/>
                </ui-view-card-field>
              </div>
            </li>
            <li>
              <div style="padding: 0.25rem 1rem;">
                <ui-view-card-field :label="$t('forms.list.options.end_date')">
                  <datepicker data-cy="list-to-date" v-model="listTo" :enableTimePicker="false" :autoApply="true"
                              format="dd-MM-yyyy"/>
                </ui-view-card-field>
              </div>
            </li>
            <li>
              <div style="padding: 0.25rem 1rem;">
                <ui-view-card-field :label="$t('forms.list.options.show_finished')" v-model="listShowFinished"
                                    type="checkbox"/>
              </div>
            </li>
          </ul>
        </template>
        <template v-else>
          <div class="d-flex">
            <label for="dp-input-start_date">{{ $t('forms.list.options.start_date') }}&nbsp;</label>
            <datepicker :uid="'start_date'" data-cy="list-from-date" v-model="listFrom" :enableTimePicker="false"
                        :autoApply="true" format="dd-MM-yyyy" style="top: -8px;position: relative;max-width: 160px"
                        class="pe-2"/>
            <label for="dp-input-end_date">{{ $t('forms.list.options.end_date') }}&nbsp;</label>
            <datepicker :uid="'end_date'" data-cy="list-to-date" v-model="listTo" :enableTimePicker="false"
                        :autoApply="true" format="dd-MM-yyyy" style="top: -8px;position: relative;max-width: 160px"
                        class="pe-2"/>
            <ui-view-card-field :label="$t('forms.list.options.show_finished')" v-model="listShowFinished"
                                type="checkbox"/>
          </div>
        </template>
      </div>
    </div>
    <ui-content>
      <spinner v-if="loading"/>
      <template v-if="!loading">
        <div class="container">
          <div class="row">
            <div v-if="items.length === 0">
              {{ $t('general.list_empty') }}
            </div>
            <!-- TODO find right measurements for all devices -->
            <div
                class="col-12 mb-3"
                v-for="item in items"
                :key="item.id"
                @click="itemClick(item.id)"
            >
              <div class="card col-12 border-dark">
                <div class="card-body">
                  <div class="row">
                    <h4 class="col-8">
                      {{ 'DGF-GB-' + item.id }}
                    </h4>
                    <div class="col-4 float-end">
                      {{ $t('form.state.' + item.state) }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-8">
                      {{ $t('forms.list_driver.from', {from: item.from}) }}
                    </div>
                    <div class="col-4 float-end">
                      {{ DateFormatter.formatDate(item.transportDate) }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-8">
                      {{ $t('forms.list_driver.to', {to: item.to}) }}
                    </div>
                    <div class="col-4 float-end">
                      {{ $t('forms.list_driver.permissionId', {permissionId: item.permissionId}) }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-8">
                      {{ $t('forms.list_driver.description', {description: item.wasteDescription}) }}
                    </div>
                  </div>
                  <div class="row" v-if="item.privateNotes">
                    <div class="col-12">
                      {{ item.privateNotes }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ui-content>
  </layout>
</template>

<script>

import {ref, watch} from 'vue'
import backend from '@/util/backend'
import Layout from '@/components/layout'
import {useRouter} from 'vue-router'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import UiContent from '@/components/ui-content'
import Spinner from '@/components/spinner'
import FormState from '@/types/formState'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import UiViewCardField from '@/components/ui-view-card-field'
import DocumentType from '@/types/documentType'
import {DateFormatter} from '@/services/dateFormatter'
import UiListDriverTabs from '@/components/ui-list-driver-tabs.vue'

export default {
  name:       'GrondbankListDriver',
  computed:   {
    DateFormatter() {
      return DateFormatter
    },
  },
  components: {
    UiListDriverTabs,
    UiViewCardField,
    Spinner,
    UiContent,
    Layout,
    UiBreadcrumbs,
    Datepicker,
  },
  setup:      () => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.GRONDBANK]
    const items       = ref([])
    const loading     = ref(true)

    const listFromKey = 'GBlistFrom'
    const listFrom    = ref(new Date())
    if (sessionStorage[listFromKey]) {
      const d        = Date.parse(sessionStorage[listFromKey])
      listFrom.value = !isNaN(d) ? new Date(sessionStorage[listFromKey]) : null
    } else {
      listFrom.value.setUTCDate(listFrom.value.getUTCDate() - 7)
    }
    const listToKey = 'GBlistTo'
    const listTo    = ref(new Date())
    if (sessionStorage[listToKey]) {
      const d      = Date.parse(sessionStorage[listToKey])
      listTo.value = !isNaN(d) ? new Date(sessionStorage[listToKey]) : null
    } else {
      listTo.value.setUTCDate(listTo.value.getUTCDate())
    }
    const listShowFinishedKey = 'GBlistShowFinished'
    const listShowFinished    = ref(sessionStorage.getItem(listShowFinishedKey) === 'true' ?? false)
    const getItems            = () => {
      let from = ''
      if (listFrom.value && listFrom.value.toISOString()) {
        from = listFrom.value.toISOString().substring(0, 10)
      }

      let to = ''
      if (listTo.value && listTo.value.toISOString()) {
        to = listTo.value.toISOString().substring(0, 10)
      }

      let states = [
        FormState.SHARED,
        FormState.ON_THE_ROAD,
        FormState.ARRIVED,
      ]

      if (listShowFinished.value) {
        states.push(FormState.FINISHED, FormState.CANCELLED, FormState.STOPPED)
      }

      const url = `api/documents?documentType=${DocumentType.GRONDBANK}&from=${from}&to=${to}&states=${states.join(',')}`
      backend.get(url).then((r) => {
        items.value   = r.data
        loading.value = false
      })
    }
    getItems()

    watch(() => listFrom.value, () => {
      sessionStorage.setItem(listFromKey, listFrom.value)
      getItems()
    })
    watch(() => listTo.value, () => {
      sessionStorage.setItem(listToKey, listTo.value)
      getItems()
    })
    watch(() => listShowFinished.value, () => {
      sessionStorage.setItem(listShowFinishedKey, listShowFinished.value)
      getItems()
    })

    const router    = useRouter()
    const itemClick = (itemid) => {
      router.push(`/grondbank/view/${itemid}`)
    }

    return {
      breadcrumbs,
      loading,
      listFrom,
      listTo,
      listShowFinished,
      items,
      itemClick,
    }
  },
}
</script>

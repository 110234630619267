<template>
  <transport-document-templates-view-driver :id="id" v-if="roles.hasOnlyRole(roles.DRIVER)" />
  <transport-document-templates-view-user :id="id" v-else />
</template>

<script>

import TransportDocumentTemplatesViewDriver from '@/views/transport-document-templates/View-Driver'
import TransportDocumentTemplatesViewUser from '@/views/transport-document-templates/View-User'

export default {
  name: 'TransportDocumentTemplatesView',
  components: {
    TransportDocumentTemplatesViewUser,
    TransportDocumentTemplatesViewDriver,
  },
  props: {
    id: {
      type: String,
      required: true,
    }
  },
  setup: () => {
    return {}
  }
}
</script>
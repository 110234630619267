<template>
  <template v-for="(identification, index) in model" :key="identification.id">
    <div class="pb-1" data-cy="wi-id-number">
      <label class="pe-2">
        {{ $t('form.labels.identification_number') }}<span v-if="required" :title="$t('general.required')"
                                                           class="text-danger">*</span>
        <i
          style="font-size: 0.75rem; position: absolute"
          class="bi-question-circle-fill"
          :title="$t('form.idnumber_title')"
        />
      </label>
      <div class="d-flex">
        <div class="container flex-column">
          <div class="row">
            <v-select
              :data-cy="cy+'-id-type'"
              class="col form-control"
              :class="{'col': !$isMobile}"
              :options="identificationTypes"
              v-model="identification.identificationType"
              :reduce="idType => idType.value"
            >
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="required && !identification.identificationType"
                  v-bind="attributes"
                  v-on="events"
                  @input="resetInvalid"
                />
              </template>
              <template #no-options>
                {{ $t('general.no_options') }}
              </template>
            </v-select>
            <input
              :data-cy="cy+'-id-value'"
              class="form-control wi-id-value"
              :class="{
                       'id-type-vat': identification.identificationType === IdentificationType.VAT,
                       'col': !$isMobile,
                     }"
              v-model="identification.value"
              :key="identification.identificationType"
              :required="required" maxlength="30" :pattern="identification.identificationType === IdentificationType.VAT ? vatPattern : '.*'"
              @invalid="invalid" @input="resetInvalid"
              :style="identification.identificationType === IdentificationType.VAT ? 'text-transform:uppercase' : ''"
              @blur="identification.identificationType === IdentificationType.VAT ? identification.value = identification.value.toLocaleUpperCase() : ''"
              :disabled="identification.identificationType === IdentificationType.INDIVIDUAL"
            >
            <i
              v-if="identification.identificationType === IdentificationType.VAT"
              style="font-size: 0.75rem; position: absolute"
              class="bi-question-circle-fill"
              :title="$t('form.vat_title')"
            />
          </div>
        </div>
        <div v-if="index === 0" class="btn btn-primary" @click="addId()">
          <i class="bi-plus" />
        </div>
        <div v-if="index > 0" class="btn btn-danger" @click="deleteId(index)">
          <i class="bi-trash" />
        </div>
      </div>
    </div>
  </template>
</template>

<script>

import {useModelWrapper} from '@/util/modelWrapper'
import {inject, ref, watch} from 'vue'
import IdentificationType from '@/types/identificationType'

export default {
  name: 'ui-view-card-ids',
  components: {},
  props: {
    modelValue: {
      default: [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    cy: {
      type: String,
      default: '',
    },
    allowIndividual: {
      type:    Boolean,
      default: false,
    }
  },
  setup: (props, {emit}) => {
    const $t = inject('$t')
    const model = useModelWrapper(props, emit)

    const addId = () => {
      model.value.push({})
    }

    if (model.value.length < 1) {
      console.error('Should have at least one ID')
      addId()
    }

    const deleteId = (index) => {
      model.value.splice(index, 1)
    }

    const identificationTypes = ref(IdentificationType.getAll(props.allowIndividual))

    const vatPattern = '^((AT)U[0-9]{8}|(BE)(0|1)[0-9]{9}|(BG)[0-9]{9,10}|(CY)[0-9]{8}L|(CZ)[0-9]{8,10}|(DE)[0-9]{9}|(DK)[0-9]{8}|(EE)[0-9]{9}|(EL|GR)[0-9]{9}|(ES)[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)[0-9]{8}|(FR)[0-9A-Z]{2}[0-9]{9}|(GB)([0-9]{9}([0-9]{3})|[A-Z]{2}[0-9]{3})|(HU)[0-9]{8}|(IE)[0-9]S[0-9]{5}L|(IT)[0-9]{11}|(LT)([0-9]{9}|[0-9]{12})|(LU)[0-9]{8}|(LV)[0-9]{11}|(MT)[0-9]{8}|(NL)[0-9]{9}B[0-9]{2}|(PL)[0-9]{10}|(PT)[0-9]{9}|(RO)[0-9]{2,10}|(SE)[0-9]{12}|(SI)[0-9]{8}|(SK)[0-9]{10})$'

    const invalidVatMessage = $t('error.invalid_vat')

    const invalid = (event) => {
      event.target.setCustomValidity(invalidVatMessage)
    }

    const resetInvalid = (event) => {
      event.target.setCustomValidity('')
    }

    watch(() => model, (val) => {
      val.value.forEach((v) => {
        if (v.identificationType === IdentificationType.INDIVIDUAL) {
          v.value = $t('identification_types.INDIVIDUAL').toUpperCase()
        } else {
          if (v.value === $t('identification_types.INDIVIDUAL').toUpperCase()) {
            v.value = ''
          }
        }
      })
    }, {deep: true})

    return {
      addId,
      deleteId,
      identificationTypes,
      model,
      IdentificationType,
      vatPattern,
      invalid,
      resetInvalid,
    }
  }
}
</script>

import session from '@/util/session'
import i18n, {languages} from '@/util/i18n'
import backend from '@/util/backend'

const $t = () => {
  const user = session.getUser()
  return i18n(user?.language ?? languages.NL)
}

window.isFirstColumn = function isFirstColumn(params) {
  const displayedColumns = params.api.getAllDisplayedColumns();
  return displayedColumns[0] === params.column;
};

const defaultColDef = {
  menuTabs: ['filterMenuTab'],
  floatingFilter: true,
  suppressMenu: true,
  filter: 'agTextColumnFilter',
  filterParams: {
    filterOptions: ['contains'],
    maxNumConditions: 1,
  },
  comparator: self.caseInsensitiveComparator,
  checkboxSelection: window.isFirstColumn,
}

const grid = {
  caseInsensitiveComparator: (valueA, valueB) => {
    if (valueA?.toUpperCase() === valueB?.toUpperCase()) return 0
    return (valueA?.toUpperCase() > valueB?.toUpperCase()) ? 1 : -1
  },
  caseInsensitiveStateComparator: (prefix) => {
    return (stateA, stateB) => {
      if (!stateA) {
        return 1
      }
      if (!stateB) {
        return -1
      }
      const a = $t()(prefix + stateA)
      const b = $t()(prefix + stateB)
      if (a.toUpperCase() === b.toUpperCase()) {
        return 0
      }
      return (a.toUpperCase() > b.toUpperCase()) ? 1 : -1
    }
  },
  datasource: (endpoint) => {
    return {
      // called by the grid when more rows are required
      getRows: async (params) => {
        // get data for request from server
        const response = await backend.post(endpoint, params.request)
        if (response.status === 200) {
          // supply rows for requested block to grid
          params.success({
            rowData: response.data,
            rowCount: response.data.length !== 15 ? params.request.startRow + response.data.length : -1,
          })
        } else {
          // inform grid request failed
          params.fail();
        }
      }
    }
  },
  defaultColDef: defaultColDef,
  defaultOptions: {
    rowModelType: 'serverSide',
    maxConcurrentDatasourceRequests: 2,
    getLocaleText: (params) => { return grid.getLocaleText(params) },
    cacheBlockSize: 15,
    defaultColDef: defaultColDef,
    getRowId: (row) => { return row.data.id },
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    autoSizeStrategy: {
      type: 'fitGridWidth',
      defaultMinWidth: 200,
    },
  },
  getLocaleText: (params) => {
    return $t()('grid.' + params.key)
  },
}

export default grid

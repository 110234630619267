<template>
  <layout>
    <template #header>
      <ui-header :title="$t('user.new.title')" back-route="/users/list">
        <template #actions>
          <button type="button" class="btn btn-outline-primary" @click="save()" :disabled="!valid() || saving"
                  form="user-form"
                  id="inviteUserBtn">
            <i class="bi-send"/>&nbsp;{{ $t('users.invite') }}
          </button>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    <ui-content class="pb-1">
      <div class="container-fluid">
        <form id="user-form" class="row g-2 justify-content-center">
          <ui-view-card :title="$t('user.title.user_information')">
            <ui-view-card-field :label="$t('user.labels.email')" v-model="user.email" :required="true" id="userEmail"
                                type="email"/>
            <ui-view-card-field :label="$t('user.labels.first_name')" v-model="user.firstName" :required="true"
                                id="userFirstName"/>
            <ui-view-card-field :label="$t('user.labels.last_name')" v-model="user.lastName" :required="true"
                                id="userLastName"/>
            <ui-view-card-language :label="$t('form.labels.language')" v-model="user.language" :required="true"/>
            <ui-view-card-field :label="$t('user.labels.roles')" :required="true">
              <v-select :options="roleOptions" multiple v-model="user.roles" :reduce="role => role.value"
                        id="userRoleSelect">
                <template #option="option">
                  <i style="font-size: 0.75rem;" class="bi-question-circle-fill" :title="option.title" />&nbsp;
                  {{ option.label }}
                </template>
                <template #selected-option="option">
                  <i style="font-size: 0.75rem;cursor: pointer;" class="bi-question-circle-fill" :title="option.title" />&nbsp;
                  {{ option.label }}
                </template>
                <template #search="{attributes, events}">
                  <input
                      class="vs__search"
                      :required="user.roles.length === 0"
                      v-bind="attributes"
                      v-on="events"
                  />
                </template>
                <template #no-options>
                  {{ $t('general.no_options') }}
                </template>
              </v-select>
            </ui-view-card-field>
          </ui-view-card>
        </form>
      </div>
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import backend from '@/util/backend'
import {ref} from 'vue'
import {useRouter} from 'vue-router'
import UserModel from '@/models/UserModel'
import Notifier from '@/util/notifier'
import UiViewCard from '@/components/ui-view-card'
import UiViewCardField from '@/components/ui-view-card-field'
import session from '@/util/session'
import roles from '@/util/roles'
import UiViewCardLanguage from '@/components/ui-view-card-language.vue'

export default {
  name:       'UserNew',
  components: {
    UiViewCardLanguage,
    UiViewCardField,
    UiViewCard,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout,
  },
  setup:      () => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.USERS, BREADCRUMBS.NEW]
    const user = ref(JSON.parse(JSON.stringify(UserModel.empty)))
    user.value.language = session.getLanguage()
    const notifier = Notifier()
    const router = useRouter()
    const org = session.getOrganisation()
    const saving = ref(false)

    const save = async () => {
      saving.value = true
      const formElement = $('#user-form')[0]
      if (!formElement.checkValidity()) {
        // something in the form is not valid so don't allow signing
        formElement.reportValidity()
        saving.value = false
        return false
      }

      backend.post(`api/organisations/${org.id}/users`, user.value).then((res) => {
        if (res.status === 200) {
          router.push(`/users/view/${res.data.id}`)
        } else {
          notifier.error('toast.save_failed')
        }
      }).catch(() => {
        notifier.error('toast.save_failed')
      }).finally(() => {
        saving.value = false
      })
    }

    const roleOptions = ref(roles.getPossibleRoles())

    const valid = () => {
      return user.value.roles.length > 0
    }

    return {
      breadcrumbs,
      user,
      save,
      saving,
      valid,
      roleOptions,
    }
  },
}
</script>

import backend from '../util/backend'
import ContractState from '../types/contractState'
import roles from '../util/roles'

const setLocalStorage = (key, value) => {
    const now = new Date()

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
        value: value,
        expiry: now.getTime() + 3600000,
    }
    localStorage.setItem(key, JSON.stringify(item))
}

const getLocalStorage = (key) => {
    const itemStr = localStorage.getItem(key)
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key)
        return null
    }
    return item.value
}

const setSessionStorage = (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value))
}

const getSessionStorage = (key) => {
    const itemStr = sessionStorage.getItem(key)
    if (!itemStr) {
        return null
    }
    return JSON.parse(itemStr)
}

const session = {
    key: 'digiform-auth',
    setUser: async () => {
        const result = await backend.get('api/auth/me')
        setLocalStorage('digiform-user', result.data)
        await session.setOrganisation()
    },
    getUser: () => {
        return getLocalStorage('digiform-user')
    },
    removeUser: () => {
        window.localStorage.removeItem('digiform-user')
    },
    setOrganisation: async () => {
        const result = await backend.get('api/organisations/me')
        setLocalStorage('digiform-org', result.data)
    },
    getOrganisation: () => {
        return getLocalStorage('digiform-org')
    },
    getLowCreditsContract: () => {
        const org = session.getOrganisation()
        return org?.contracts.filter((c) => {
            return c.contractState === ContractState.LOW_CREDITS
        })[0]
    },
    getOverdraftContract: () => {
        const org = session.getOrganisation()
        return org?.contracts.filter((c) => {
            return c.contractState === ContractState.IN_OVERDRAFT
        })[0]
    },
    removeOrganisation: () => {
        window.localStorage.removeItem('digiform-org')
    },
    getCurrentOrganisation: () => {
        const user = session.getUser()
        if (!user) {
            return null
        }
        const organisation = session.getOrganisation()
        if (organisation) {
            return user.organisationRoles?.find((orgs) => {
                return orgs.organisation.id === organisation.id
            })
        }
        return null
    },
    getLanguage: () => {
        let user = session.getUser()
        return user?.language ?? 'nl'
    },
    getOrganisationLanguage: () => {
        let org = session.getOrganisation()
        return org?.language ?? 'nl'
    },
    isAnonymousUser: () => {
        const user = session.getUser()
        return user && roles.hasOnlyRole(roles.ANONYMOUS)
    },
    saveFormState: (key, state) => {
        if (session.getUser()) { // only save when we have a logged in user
            setSessionStorage(key, state)
        }
    },
    loadFormState: (key) => {
        return getSessionStorage(key)
    },
    clearSessionStorage: () => {
        sessionStorage.clear()
    },
    setSessionStorage: (key, value) => {
        setSessionStorage(key, value)
    },
    getSessionStorage: (key) => {
        return getSessionStorage(key)
    },
    setActiveForm: (form) => {
      setSessionStorage('active-form', form)
    },
    getActiveForm: () => {
        return getSessionStorage('active-form')
    },
}

export default session

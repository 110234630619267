<template>
  <router-view/>
</template>

<script>

import {provide} from 'vue'

export default {
  name:       'App',
  components: {},
  setup:      () => {
    provide('isSharelink', false)
    return {}
  },
}
</script>


const WasteIdentificationTemplateModel = {
  empty: {
    'description': '',
    'euralCode': null,
    'dangerous': false,
    'removalOperation': null,
    'processingTechnique': null,
    'physical': null,
    'chemical': null,
    'packagingType': null,
    'amountOfPackages': null,
    'amount': null,
    'externalId': null,
  },
  validWaste: (waste) => {
    return waste.description &&
        waste.euralCode &&
        waste.removalOperation &&
        waste.processingTechnique &&
        Number(waste.amount.replace(',', '.')) > 0 &&
        (!waste.dangerous || (
                waste.dangerous &&
                waste.physical &&
                waste.chemical &&
                waste.packagingType &&
                waste.amountOfPackages
            )
        )
  }
}

export default WasteIdentificationTemplateModel

<template>
  <ui-modal id="shareModal" ref="modal" size="xl" :on-open="load" :no-confirm="true">
    <template #title>
      {{ $t('transport_document_templates.manage_shares_modal.title') }}
    </template>
    <template #default>
      <spinner v-if="loading" />
      <ui-view-card-field :label="$t('transport_document_templates.manage_shares_modal.filter')" v-model="filter" />
      <table class="table table-bordered table-striped">
        <thead>
          <tr>
            <th>{{$t('form.table.email')}}</th>
            <th>{{$t('form.table.first_name')}}</th>
            <th>{{$t('form.table.last_name')}}</th>
            <th style="width: 60px" />
          </tr>
        </thead>
        <tbody>
          <tr v-for="s in filteredList" :key="s.id">
            <td>
              {{ s.email }}
            </td>
            <td>
              {{ s.firstName }}
            </td>
            <td>
              {{ s.lastName }}
            </td>
            <td>
              <div v-if="s.found" class="btn btn-danger" @click="removeShared(s)">
                <i class="bi-trash" />
              </div>
              <div v-else-if="!s.found" class="btn btn-success" @click="addShared(s)">
                <i class="bi-plus" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-modal>
</template>

<script>

import {computed, ref} from 'vue'
import UiModal from '@/components/ui-modal'
import {DateFormatter} from '@/services/dateFormatter'
import backend from '@/util/backend'
import Spinner from '@/components/spinner.vue'
import Notifier from '@/util/notifier'
import roles from '@/util/roles'
import UiViewCardField from '@/components/ui-view-card-field.vue'

export default {
  name: 'TemplateSharedWithModal',
  computed: {
    DateFormatter() {
      return DateFormatter
    }
  },
  components: {
    UiViewCardField,
    Spinner,
    UiModal
  },
  props: {
    template: {
      type:     Object,
      required: true,
    },
  },
  setup: (props) => {
    const modal = ref('modal')
    const list = ref([])
    const loading = ref(true)

    let shares = []
    let drivers = []

    const load = () => {
      const promise1 = backend.get(`api/transportDocumentTemplateShared?transportDocumentTemplateId=${props.template.id}`)
      const promise2 = backend.get(`api/users?role=${roles.DRIVER}`)

      Promise.all([promise1, promise2])
      .then((res) => {
        const temp = []
        shares = res[0].data
        drivers = res[1].data

        drivers.forEach((driver) => {
          let found = false
          shares.forEach((share) => {
            if (share.email === driver.email) {
              found = true
              temp.push({
                'id': share.id,
                'email': driver.email,
                'firstName': driver.firstName,
                'lastName': driver.lastName,
                'found': true,
              })
            }
          })
          if (!found) {
            temp.push({
              'email': driver.email,
              'firstName': driver.firstName,
              'lastName': driver.lastName,
              'found': false,
            })
          }
        })

        list.value = temp.sort((a, b) => {
          return a.email > b.email ? 1 : -1
        })
      }).finally(() => {
        loading.value = false
      })
    }

    const notifier = Notifier()

    const addShared = (driver) => {
      backend.post(`api/transportDocumentTemplateShared`, {
        'email': driver.email,
        'templateName': props.template.name,
        'templateId': props.template.id,
      }).then((result) => {
        if (result.status === 200) {
          notifier.success('toast.sharing_successful')
        } else {
          notifier.error('toast.sharing_failed')
        }
      }).finally(() => {
        load()
      })
    }

    const removeShared = (shared) => {
      backend.delete(`api/transportDocumentTemplateShared/${shared.id}`).then((res) => {
        if (res.status === 200) {
          notifier.success("toast.deleting_successful")
        } else {
          notifier.error("toast.deleting_failed")
        }
      }).finally(() => {
        load()
      })
    }

    const filter = ref('')
    const filteredList = computed(() => {
      if (filter.value.length > 0) {
        return list.value.filter((item) => {
          return item.email.toLowerCase().indexOf(filter.value.toLowerCase()) > -1 ||
                 item.firstName.toLowerCase().indexOf(filter.value.toLowerCase()) > -1 ||
                 item.lastName.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
        })
      } else {
        return list.value
      }
    })

    return {
      modal,
      list,
      filter,
      filteredList,
      loading,
      load,
      addShared,
      removeShared,
    }
  }
}
</script>

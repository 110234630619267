<template>
  <ui-modal ref="modal" :callback="callback" :can-confirm="validAmount">
    <template #title>
      {{ $t('credentials.delete_modal.title')}}
    </template>
    <template #default>
      {{ $t('credentials.delete_modal.delete_credentials', {amount}) }}
    </template>
  </ui-modal>
</template>

<script>

import {ref} from 'vue'
import UiModal from '@/components/ui-modal'

export default {
  name: 'CredentialsDeleteModal',
  components: {UiModal},
  props: {
    amount: {
      type:     Number,
      required: true,
    },
    callback: {
      type:     Function,
      required: true,
    }
  },
  setup: (props) => {
    const modal = ref('modal')

    const validAmount = () => {
      return props.amount > 0
    }

    return {
      modal,
      validAmount,
    }
  }
}
</script>

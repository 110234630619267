<template>
  <shared-link-signature-transporter-grondbank v-if="link.documentType === DocumentType.GRONDBANK" :link="link" />
</template>

<script>

import DocumentType from '@/types/documentType'
import SharedLinkSignatureTransporterGrondbank
  from '@/views/shared-links/grondbank/Shared-Link-Signature-Transporter-Grondbank.vue'

export default {
  name:  'SharedLinkSignatureTransporter',
  computed: {
    DocumentType() {
      return DocumentType
    }
  },
  components: {
    SharedLinkSignatureTransporterGrondbank,
  },
  props: {
    link: {
      type:     Object,
      required: true,
    }
  },
  setup: () => {
    return {}
  }
}
</script>

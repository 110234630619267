import MatisState from '@/types/matisState'

export default {
    template: `<div><span :class="'color-dot-'+color" /> {{ $t("matis.state." + params.value) }}</div>`,
    setup(props) {
        let color = 'orange'
        switch (props.params.value) {
            case MatisState.DONE: {
                color = 'green'
                break
            }
            case MatisState.ERROR: {
                color = 'red'
                break
            }
        }
        return {
          color
        }
    }
}
<template>
  <ui-modal ref="modal" :callback="callback">
    <template #title>
      {{ $t('user.resend_invite_modal.title')}}
    </template>
    <template #default>
      {{ $t('user.resend_invite_modal.resend_invite') }}
    </template>
  </ui-modal>
</template>

<script>

import {ref} from 'vue'
import UiModal from '@/components/ui-modal'

export default {
  name: 'UserResendInviteModal',
  components: {UiModal},
  props: {
    callback: {
      type:     Function,
      required: true,
    }
  },
  setup: () => {
    const modal = ref('modal')

    return {
      modal,
    }
  }
}
</script>

<template>
  <ui-view-card :title="$t('credentials.title')" v-if="types.length > 0">
    <template #actions>
      <div class="btn-group">
        <div class="btn-group">
          <button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" id="actionsDropdown" data-bs-toggle="dropdown" aria-expanded="false" :disabled="selectedIDs.length === 0">
            {{$t('general.actions')}}
          </button>
          <ul data-cy="organisations-actions" class="dropdown-menu" aria-labelledby="actionsDropdown">
            <li><button class="dropdown-item" @click="showRemove()" :disabled="selectedIDs.length === 0">{{ $t('credentials.list.actions.delete') }}</button></li>
          </ul>
        </div>
        <button type="button" class="btn btn-sm btn-outline-primary" @click="showCredentialsModal()"><i class="bi-plus" />&nbsp;{{ $t('credentials.add_credentials') }}</button>
      </div>
    </template>
    <spinner v-if="loading" />
    <template v-else>
      <ag-grid-vue
        @grid-ready="onGridReady"
        style="min-height:100%"
        class="ag-theme-quartz"
        :columnDefs="columnDefs"
        :defaultColDef="grid.defaultColDef"
        :rowData="items"
        rowSelection='multiple'
        @selectionChanged="refreshSelection"
        :getLocaleText="grid.getLocaleText"
        :suppressMenuHide="true"
      />
    </template>
  </ui-view-card>
  <credentials-add-modal ref="addModal" :callback="addCredential" :types="types" />
  <credentials-delete-modal ref="removeModal" :callback="remove" :amount="selectedIDs.length" />
</template>

<script>
import backend from '@/util/backend'
import session from '@/util/session'
import {inject, ref} from 'vue'
import Notifier from '@/util/notifier'
import Spinner from '@/components/spinner'
import {AgGridVue} from 'ag-grid-vue3'
import UiViewCard from '@/components/ui-view-card'
import credentialsAddModal from '@/views/my-organisation/Add-Modal'
import credentialsDeleteModal from '@/views/my-organisation/Delete-Modal'
import CredentialApplicationType from '@/types/credentialApplicationType'
import {DateFormatter} from '@/services/dateFormatter'
import grid from '@/util/grid'

export default {
  name: 'PCredentials',
  computed: {
    grid() {
      return grid
    }
  },
  components: {
    credentialsAddModal,
    credentialsDeleteModal,
    UiViewCard,
    AgGridVue,
    Spinner,
  },
  setup: () => {
    const notifier = Notifier()
    const $t = inject('$t')
    const loading = ref(true)
    const items = ref([])
    const flag = inject('$flag')
    const types = CredentialApplicationType.ALL(flag('MATIS'))

    const getcredentials = () => {
      backend.get(`/api/organisations/${session.getOrganisation().id}/credentials`).then((res) => {
        items.value = res.data
        loading.value = false
      })
    }
    getcredentials()

    let api = null
    const onGridReady = (params) => {
      api = params.api
      api.deselectAll() // if we can correctly get selection from saved state we can re-evaluate this
      api.sizeColumnsToFit({'defaultMinWidth': 200})
      api.setGridOption('domLayout', 'autoHeight')
    }

    const columnDefs = [
      {
        headerName: $t('credentials.application'),
        field: 'credentialApplication',
        floatingFilter: false,
      },
      {
        headerName: $t('credentials.active_from'),
        field: 'credentialApplication',
        floatingFilter: false,
        filter: 'agDateColumnFilter',
        filterParams: {
          minValidYear: 2000,
          maxValidYear: 2099,
          comparator: (a, b) => {
            const newB = new Date(b.getFullYear(), b.getMonth(), b.getDate())
            if (a.getTime() === newB.getTime()) {
              return 0;
            }
            if (newB < a ) {
              return -1;
            }
            if (newB > a) {
              return 1;
            }
          },
        },
        valueGetter: (params) => {
          return new Date(params.data.activeFrom)
        },
        valueFormatter: (params) => {
          return DateFormatter.formatDate(params.data.activeFrom)
        }
      }
    ]

    const selectedIDs = ref([])
    const refreshSelection = (params) => {
      selectedIDs.value = params.api.getSelectedRows().map((row) => { return row.id })
    }

    const addModal = ref('addModal')
    const showCredentialsModal = () => {
      addModal.value.modal.open()
    }
    const addCredential = async (credentials) => {
      await backend.post(`/api/organisations/${session.getOrganisation().id}/credentials`, {
        'clientId': credentials.clientId,
        'clientSecret': credentials.clientSecret,
        'credentialApplication': credentials.credentialApplication,
        'activeFrom': credentials.activeFrom,
      }).then((result) => {
        if (result.status === 200) {
          notifier.success('toast.credentials_create_success')
        } else {
          notifier.error('toast.credentials_create_failed')
        }
      }).finally(() => {
        addModal.value.modal.close()
        getcredentials()
      })
    }

    const removeModal = ref('removeModal')
    const showRemove = () => {
      removeModal.value.modal.open()
    }
    const remove = () => {
      const ids = api.getSelectedRows().map((row) => { return row.id })
      if (ids.length > 0) {
        backend.post(`api/organisations/${session.getOrganisation().id}/credentials/batch`, {
          ids:    ids,
          action: 'DELETE'
        }).then(() => {
          notifier.success('toast.credentials_delete_success')
        }).catch(() => {
          notifier.error('toast.credentials_delete_failed')
        }).finally(() => {
          removeModal.value.modal.close()
          getcredentials()
        })
      } else {
        removeModal.value.modal.close()
      }
    }

    return {
      items,
      loading,
      addModal,
      showCredentialsModal,
      addCredential,
      onGridReady,
      columnDefs,
      selectedIDs,
      refreshSelection,
      removeModal,
      showRemove,
      remove,
      types,
    }
  }
}
</script>

<template>
  <ui-modal id="shareModal" ref="modal" size="fullscreen" :on-open="load" :no-confirm="true">
    <template #title>
      {{ $t('forms.shared_with_modal.title') }}
    </template>
    <template #default>
      <table class="table table-bordered table-striped">
        <thead>
          <tr>
            <th>{{$t('form.table.created_on')}}</th>
            <th>{{$t('form.table.email')}}</th>
            <th>{{$t('form.table.first_name')}}</th>
            <th>{{$t('form.table.last_name')}}</th>
            <th>{{$t('form.table.organisation')}}</th>
            <th>{{$t('form.table.type')}}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-for="s in list" :key="s.id">
            <td>
              {{ DateFormatter.formatDateTime(s.createdOn) }}
            </td>
            <td>
              {{ s.email }}
            </td>
            <td>
              {{ s.firstName }}
            </td>
            <td>
              {{ s.lastName }}
            </td>
            <td>
              {{ s.organisation }}
            </td>
            <td>
              {{ $t('share_link_types.' + s.shareLinkType) }}
            </td>
            <td>
              <div class="btn btn-danger" @click="remove(s)">
                <i class="bi-trash" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-modal>
</template>

<script>

import {ref} from 'vue'
import UiModal from '@/components/ui-modal'
import backend from '@/util/backend'
import {DateFormatter} from '@/services/dateFormatter'
import Notifier from '@/util/notifier'

export default {
  name: 'FormSharedWithModal',
  computed: {
    DateFormatter() {
      return DateFormatter
    }
  },
  components: {
    UiModal
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  setup: (props) => {
    const modal = ref('modal')
    const list = ref([])
    const notifier = Notifier()

    const load = () => {
      backend.get(`api/documents/${props.id}/sharedWith`).then((res) => {
        list.value = res.data
      })
    }

    const remove = (s) => {
      backend.delete(`api/sharedlinks/${s.id}`).then((res) => {
        if (res.status === 200) {
          notifier.success('toast.sharelink_delete_success')
        } else {
          notifier.success('toast.sharelink_delete_failed')
        }
      }).catch(() => {
        notifier.success('toast.sharelink_delete_failed')
      }).finally(() => {
        load()
      })
    }

    return {
      modal,
      list,
      load,
      remove,
    }
  }
}
</script>

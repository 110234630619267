import Polyglot from 'node-polyglot'
import locale_nl from '../../../i18n/nl.json'
import locale_en from '../../../i18n/en.json'
import locale_fr from '../../../i18n/fr.json'
import locale_de from '../../../i18n/de.json'

export const languages = {
    NL: 'NL',
    EN: 'EN',
    FR: 'FR',
    DE: 'DE',
}

const locales = {
    'NL': locale_nl,
    'EN': locale_en,
    'FR': locale_fr,
    'DE': locale_de,
}

const translations = {}

Object.keys(locales).forEach((key) => {
    translations[key] = new Polyglot({
        phrases:      locales[key],
        locale:       key,
        allowMissing: true
    })
})

export default function (locale) {
    const supportedlocales = Object.keys(locales)
    // If there's no locale or the locale is not supported, fallback to dutch (main client language)
    if (!locale || !supportedlocales.includes(locale)) {
        locale = languages.NL
    }

    // need to return a function with context otherwise it won't work, not sure why...
    {
        return function (phrase, options) {
            // if there is no translation then fallback to english (main developer language)
            return translations[locale].t(phrase, options) || translations[languages.EN].t(phrase, options)
        }
    }
}

<template>
  <layout>
    <template #header>
      <ui-header :title="(user.firstName ?? '') + ' ' + (user.lastName ?? '')" back-route="/users/list">
        <template #actions>
          <div class="btn-group">
            <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="actionsDropdown"
                    data-bs-toggle="dropdown" aria-expanded="false">
              {{ $t('general.actions') }}
            </button>
            <ul data-cy="user-actions" class="dropdown-menu" aria-labelledby="actionsDropdown">
              <li>
                <button class="dropdown-item" @click="showRemove()">
                  {{ $t('user.view.actions.remove') }}
                </button>
              </li>
              <li>
                <button class="dropdown-item" @click="showResend()">
                  {{ $t('user.view.actions.resend') }}
                </button>
              </li>
            </ul>
          </div>
          <button type="button" class="btn" :class="'btn-outline-secondary'" v-if="$roles([roles.ORGANISATION_ADMIN])"
                  @click="edit()" form="user-form"><i class="bi-pencil"/>&nbsp;{{ $t('general.edit') }}
          </button>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    <ui-content class="pb-1">
      <spinner v-if="loading"/>
      <template v-if="!loading">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <ui-view-card :title="$t('user.title.user_information')">
              {{ $t('user.labels.email') }}: {{ user.email }}
              <br/>
              {{ $t('user.labels.first_name') }}: {{ user.firstName }}
              <br/>
              {{ $t('user.labels.last_name') }}: {{ user.lastName }}
              <br/>
              {{ $t('user.labels.language') }}: {{ languages.getLanguage(user.language) }}
              <br/>
              {{ $t('user.labels.roles') }}: {{ roles.getRoleText(user.organisationRoles[currentOrgIndex].roles) }}
            </ui-view-card>
          </div>
        </div>
      </template>
    </ui-content>
  </layout>
  <user-delete-modal :callback="remove" :amount="1" ref="removeModal"/>
  <user-resend-invite-modal :callback="resend" ref="resendModal"/>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import backend from '@/util/backend'
import {ref} from 'vue'
import UiViewCard from '@/components/ui-view-card'
import Spinner from '@/components/spinner'
import roles from '@/util/roles'
import {useRouter} from 'vue-router'
import session from '@/util/session'
import Notifier from '@/util/notifier'
import UserResendInviteModal from '@/views/users/Resend-Invite-Modal'
import UserDeleteModal from '@/views/users/Delete-Modal.vue'
import languages from '@/types/languages'

export default {
  name:       'UserView',
  computed:   {
    languages() {
      return languages
    },
  },
  components: {
    UserDeleteModal,
    UserResendInviteModal,
    Spinner,
    UiViewCard,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout,
  },
  props:      {
    id: {
      type:     String,
      required: true,
    },
  },
  setup:      (props) => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.USERS, BREADCRUMBS.VIEW]
    const router      = useRouter()
    const notifier    = Notifier()

    const loading         = ref(true)
    const user            = ref({})
    const currentOrgIndex = ref(0)
    const org             = session.getOrganisation()
    backend.get(`api/users/${props.id}`).then((r) => {
      user.value = r.data
      user.value.organisationRoles.forEach((o, index) => {
        if (o.organisation.id === org.id) {
          currentOrgIndex.value = index
        }
      })
      loading.value = false
    })

    const edit = () => {
      router.push(`/users/edit/${props.id}`)
    }

    const resendModal = ref('resendModal')
    const showResend  = () => {
      resendModal.value.modal.open()
    }
    const resend      = async () => {
      await backend.post(`api/organisations/${org.id}/users/${props.id}/invite`).then((result) => {
        if (result.status === 200) {
          notifier.success('toast.sending_invite_successful')
        } else {
          notifier.error('toast.sending_invite_failed')
        }
      }).finally(() => {
        resendModal.value.modal.close()
      })
    }

    const removeModal = ref('removeModal')
    const showRemove  = () => {
      removeModal.value.modal.open()
    }

    const remove = () => {
      backend.delete(`api/organisations/${org.id}/users/${props.id}`).then(() => {
        removeModal.value.modal.close()
        router.push(`/users/list`)
        notifier.success('toast.user_delete_success')
      }).catch(() => {
        notifier.error('toast.user_delete_failed')
      })
    }

    return {
      breadcrumbs,
      loading,
      user,
      currentOrgIndex,
      roles,
      edit,
      resendModal,
      showResend,
      resend,
      removeModal,
      showRemove,
      remove,
    }
  },
}
</script>

<template>
  <layout>
    <template #header>
      <ui-header :title="$t('organisations.new.title')" back-route="/organisations/list">
        <template #actions>
          <button type="button" class="btn btn-outline-primary" :disabled="saving" @click="save()" form="organisation-form" id="createOrganisationBtn"><i class="bi-save" />&nbsp;{{$t('general.create')}}</button>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs" />
    <ui-content class="pb-1">
      <organisation-form :item="organisation" />
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import backend from '@/util/backend'
import {ref} from 'vue'
import {useRouter} from 'vue-router'
import Notifier from '@/util/notifier'
import OrganisationForm from '@/partials/organisation/Form'
import OrganisationModel from '@/models/OrganisationModel'

export default {
  name: 'OrganisationNew',
  components: {OrganisationForm, UiContent, UiBreadcrumbs, UiHeader, Layout},
  setup: () => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.ORGANISATIONS, BREADCRUMBS.NEW]
    const organisation = ref(JSON.parse(JSON.stringify(OrganisationModel.empty)))
    const notifier = Notifier()
    const router = useRouter()

    const saving = ref(false)
    const save = async () => {
      saving.value = true
      const formElement = $('#organisation-form')[0]
      if (!formElement.checkValidity()) {
        // something in the form is not valid so don't allow signing
        formElement.reportValidity()
        saving.value = false
        return false
      }

      const result = await backend.post('api/contacts', organisation.value)
      if (result.status === 200) {
        router.push(`/organisations/view/${result.data.id}`)
      } else {
        notifier.error('toast.save_failed')
      }
      saving.value = false
    }

    return {
      breadcrumbs,
      organisation,
      save,
      saving,
    }
  }
}
</script>

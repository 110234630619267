<template>
  <layout>
    <template #header>
      <ui-header :title="$t('users.title')">
        <template #actions>
          <div class="btn-group">
            <button id="actionsDropdown" :disabled="selectedIDs.length === 0" aria-expanded="false"
                    class="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" type="button">
              {{ $t('general.actions') }}
            </button>
            <ul data-cy="contacts-actions" class="dropdown-menu" aria-labelledby="actionsDropdown">
              <li>
                <button :disabled="selectedIDs.length === 0" class="dropdown-item" @click="showRemove()">
                  {{ $t('users.list.actions.delete') }}
                </button>
              </li>
            </ul>
          </div>
          <button id="addUserBtn" class="btn btn-outline-primary" type="button" @click="addUser()"><i class="bi-plus"/>&nbsp;{{
              $t('users.add_user')
            }}
          </button>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    <ui-content>
        <ag-grid-vue
            :columnDefs="columnDefs"
            :grid-options="grid.defaultOptions"
            :initialState="initialState"
            :serverSideDatasource="grid.datasource('api/users/list')"
            class="ag-theme-quartz"
            style="min-height:100%"
            @grid-ready="onGridReady"
            @row-clicked="rowClick"
            @selection-changed="refreshSelection"
            @grid-pre-destroyed="onGridPreDestroyed"
            @filter-changed="onFilterChanged"
        />
    </ui-content>
  </layout>
  <user-delete-modal ref="removeModal" :amount="selectedIDs.length" :callback="remove"/>
</template>

<script>

import {inject, onBeforeUnmount, ref} from 'vue'
import backend from '@/util/backend'
import Layout from '@/components/layout'
import {AgGridVue} from 'ag-grid-vue3'
import {useRouter} from 'vue-router'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import UiContent from '@/components/ui-content'
import roles from '@/util/roles'
import UserDeleteModal from '@/views/users/Delete-Modal'
import Notifier from '@/util/notifier'
import {DateFormatter} from '@/services/dateFormatter'
import session from '@/util/session'
import grid from '@/util/grid'

export default {
  name: 'UsersList',
  computed: {
    grid() {
      return grid
    }
  },
  components: {
    UserDeleteModal,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    AgGridVue,
    Layout,
  },
  setup: () => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.USERS]
    const notifier = Notifier()
    const $t = inject('$t')

    const organisation = session.getOrganisation()

    const reload = () => {
      api?.refreshServerSide()
      api?.deselectAll()
    }

    const gridKey = 'users-list'
    const initialState = ref(session.loadFormState(gridKey))
    const onGridPreDestroyed = (params) => {
      session.saveFormState(gridKey, params.state)
    }
    const onFilterChanged = (event) => {
      session.saveFormState(gridKey, event.api.getState())
    }

    let api = null
    const onGridReady = (params) => {
      api = params.api
      api.deselectAll() // if we can correctly get selection from saved state we can re-evaluate this
      api.sizeColumnsToFit({'defaultMinWidth': 200})
    }
    onBeforeUnmount(() => {
      api = null
    })
    const resetGrid = () => {
      api?.setFilterModel(null)
      api?.onFilterChanged()
      session.saveFormState(gridKey, null)
    }
    const resize = () => {
      setTimeout(() => {
        api.sizeColumnsToFit({'defaultMinWidth': 200})
      }, 1)
    }
    window.addEventListener("resize", resize)
    onBeforeUnmount(() => {
      window.removeEventListener("resize", resize)
    })

    const columnDefs = [
      {
        headerName: $t('users.email'),
        field: 'email',
      },
      {
        headerName: $t('users.first_name'),
        field: 'firstName',
      },
      {
        headerName: $t('users.last_name'),
        field: 'lastName',
      },
      {
        headerName: $t('users.role'),
        field: 'role',
        sortable: false,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: roles.getPossibleRoles().map((role) => { return role.value}),
          valueFormatter: (val) => {
            return $t('users.roles.'+val.value.toLowerCase())
          }
        },
        valueGetter: (params) => {
          return roles.getRoleText(params.data.roles)
        }
      },
      {
        headerName: $t('users.last_login'),
        field: 'lastLogin',
        valueGetter: (params) => {
          return DateFormatter.formatDateTime(params.data.lastLogin)
        },
        floatingFilter: false,
      }
    ]

    const router = useRouter()
    const rowClick = (event) => {
      if (event.data) {
        router.push(`/users/view/${event.data.id}`)
      }
    }

    const addUser = () => {
      router.push(`/users/new`)
    }

    const selectedIDs = ref([])
    const refreshSelection = (params) => {
      selectedIDs.value = params.api.getSelectedRows().map((row) => {
        return row.id
      })
    }

    const removeModal = ref('removeModal')
    const showRemove = () => {
      removeModal.value.modal.open()
    }
    const remove = () => {
      const ids = api.getSelectedRows().map((row) => {
        return row.id
      })
      const promises = []
      if (ids.length > 0) {
        ids.forEach((id) => {
          promises.push(backend.delete(`api/organisations/${organisation.id}/users/${id}`))
        })
      }
      Promise.all(promises).then(() => {
        notifier.success('toast.user_delete_success')
      }).catch(() => {
        notifier.error('toast.user_delete_failed')
      }).finally(() => {
        removeModal.value.modal.close()
        reload()
      })
    }

    return {
      breadcrumbs,
      onGridReady,
      onGridPreDestroyed,
      onFilterChanged,
      initialState,
      columnDefs,
      rowClick,
      addUser,
      selectedIDs,
      refreshSelection,
      removeModal,
      showRemove,
      remove,
      resetGrid
    }
  }
}
</script>

<template>
  <div class="container-fluid">
    <form id="driver-form" class="row g-2 justify-content-center">
      <ui-view-card :title="$t('drivers.form.title')">
        <div class="row">
          <div class="col-lg-12">
            <ui-view-card-field data-cy="driver-email" :label="$t('drivers.form.email')" v-model="itemRef.email" required type="email" />
          </div>
          <div class="col-lg-6">
            <ui-view-card-field data-cy="driver-first-name" :label="$t('drivers.form.first_name')" v-model="itemRef.firstName" required />
          </div>
          <div class="col-lg-6">
            <ui-view-card-field data-cy="driver-last-name" :label="$t('drivers.form.last_name')" v-model="itemRef.lastName" required />
          </div>
          <div class="col-lg-6">
            <ui-view-card-field data-cy="driver-organisation" :label="$t('drivers.form.organisation')" v-model="itemRef.organisation" required />
          </div>
          <div class="col-lg-6">
            <ui-view-card-language data-cy="driver-language" :label="$t('drivers.form.language')" v-model="itemRef.language" required />
          </div>
        </div>
      </ui-view-card>
    </form>
  </div>
</template>

<script>

import {inject, ref} from 'vue'
import UiViewCard from '@/components/ui-view-card'
import UiViewCardField from '@/components/ui-view-card-field'
import UiViewCardLanguage from '@/components/ui-view-card-language'

export default {
  name: 'DriverForm',
  components: {
    UiViewCardLanguage,
    UiViewCardField,
    UiViewCard,
  },
  props: {
    item: {
      type:     Object,
      required: true,
    },
  },
  setup: (props) => {
    const $t = inject('$t')

    let itemRef = ref(props.item)

    const languages = ref([
      { label: $t('languages.dutch'), value: 'NL' },
      { label: $t('languages.english'), value: 'EN' },
      { label: $t('languages.french'), value: 'FR' },
      { label: $t('languages.german'), value: 'DE' },
    ])

    return {
      languages,
      itemRef,
    }
  }
}
</script>

const ExportType = {
  PDF: "PDF",
  JSON: "JSON",
  CSV: "CSV (UTF-8)",
  getAll: () => {
    return [ExportType.PDF, ExportType.JSON, ExportType.CSV]
  }
}

export default ExportType

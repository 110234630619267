<template>
  <layout>
    <template #header>
      <ui-header :title="loading ? '' : organisation.name" back-route="/organisations/list">
        <template #actions>
          <div v-if="$flag('LINKED_ORGANISATIONS')" class="btn-group">
            <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="actionsDropdown"
                    data-bs-toggle="dropdown" aria-expanded="false">
              {{ $t('general.actions') }}
            </button>
            <ul data-cy="user-actions" class="dropdown-menu" aria-labelledby="actionsDropdown">
              <li v-if="$flag('LINKED_ORGANISATIONS')">
                <button class="dropdown-item" @click="showLinkOrganisation()">
                  {{ $t('organisations.view.actions.link_organisation') }}
                </button>
              </li>
            </ul>
          </div>
          <button type="button" class="btn btn-outline-secondary" @click="edit()"><i
              class="bi-pencil"/>&nbsp;{{ $t('general.edit') }}
          </button>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    <ui-content class="pb-1">
      <spinner v-if="loading"/>
      <template v-if="!loading">
        <div class="container-fluid">
          <div class="container-fluid">
            <div class="row justify-content-center">
              <ui-view-card :title="$t('organisations.organisation_information')">
                <div class="row">
                  <div class="col-lg-6">
                    {{ $t('form.labels.name') }}: {{ organisation.name }}
                  </div>
                  <div class="col-lg-6">
                    {{ $t('form.labels.external_identifier') }}: {{ organisation.externalId }}
                  </div>
                </div>
              </ui-view-card>
            </div>
            <div class="row justify-content-center">
              <ui-view-card :title="$t('organisations.form.contact_information')">
                <div class="row">
                  <div class="col-lg-12">
                    {{ $t('form.labels.language') }}: {{ languages.getLanguage(organisation.language) }}
                  </div>
                  <div class="col-lg-6">
                    {{ $t('form.labels.email') }}: {{ organisation.email }}
                  </div>
                  <div class="col-lg-6">
                    {{ $t('form.labels.phonenumber') }}: {{ organisation.phone }}
                  </div>
                  <div class="col-lg-6">
                    {{ $t('form.labels.fax') }}: {{ organisation.fax }}
                  </div>
                  <div class="col-lg-6">
                    {{ $t('form.labels.contact_person') }}: {{ organisation.contactPerson }}
                  </div>
                </div>
              </ui-view-card>
            </div>
            <div class="row justify-content-center">
              <ui-view-card :title="$t('organisations.form.address')">
                <ui-view-card-address :model-value="organisation.address"/>
                <div>
                  {{ $t('form.labels.other_location_info') }}: {{ organisation.otherLocationDetermination }}
                </div>
              </ui-view-card>
            </div>
            <div class="row justify-content-center">
              <ui-view-card :title="$t('organisations.form.shipment_address')">
                <ui-view-card-address :model-value="organisation.shipmentAddress"/>
                <div class="col-lg-6">
                  <div class="d-flex">
                    {{ $t('form.labels.matis_location_type') }}:<i style="font-size: 0.75rem; padding-right: 0.5rem;"
                                                                   class="bi-question-circle-fill"
                                                                   :title="$t('help.matis_location_type')"/>
                    <span v-if="organisation.locationType">
                      {{ $t('matis_location_types.' + organisation.locationType) }}
                    </span>
                  </div>
                </div>
              </ui-view-card>
            </div>
            <div class="row justify-content-center">
              <ui-view-card :title="$t('organisations.form.additional_information')">
                <template v-for="identification in organisation.identifications" :key="identification.id">
                  <div class="d-flex">
                    {{ $t('form.labels.identification_number') }}:<i style="font-size: 0.75rem; padding-right: 0.5rem;"
                                                                     class="bi-question-circle-fill"
                                                                     :title="$t('form.idnumber_title')"/>
                    {{ $t('identification_types.' + identification.identificationType) }}: {{ identification.value }}
                  </div>
                </template>
                <div class="col-lg-6">
                  {{ $t('form.labels.license_plate') }}: {{ organisation.licensePlate }}
                </div>
                <div class="col-lg-6">
                  {{ $t('form.labels.means_of_transport') }}: {{
                    organisation.meansOfTransport
                    ? $t('means_of_transport.' + organisation.meansOfTransport)
                    : ''
                  }}
                </div>
                <div class="col-lg-6">
                  <div class="d-flex">
                    {{ $t('form.labels.annex_7_recovery_destination_type') }}:<i
                      style="font-size: 0.75rem; padding-right: 0.5rem;" class="bi-question-circle-fill"
                      :title="$t('help.annex_7_recovery_destination_type')"/>
                    {{ organisation.annex7RecoveryDestinationType }}
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="d-flex">
                    {{ $t('form.labels.responsible') }}:
                    {{ organisation.responsible }}
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="d-flex">
                    {{ $t('form.labels.soil_bank_number') }}:
                    {{ organisation.soilBankNumber }}
                  </div>
                </div>
              </ui-view-card>
            </div>
          </div>
        </div>
      </template>
    </ui-content>
  </layout>
  <organisation-link-modal :callback="linkOrganisation" :organisation="organisation" ref="linkOrganisationModal"/>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import backend from '@/util/backend'
import {ref} from 'vue'
import Spinner from '@/components/spinner'
import UiViewCardAddress from '@/components/ui-view-card-address'
import UiViewCard from '@/components/ui-view-card'
import {useRouter} from 'vue-router'
import languages from '@/types/languages'
import Notifier from '@/util/notifier'
import OrganisationLinkModal from '@/views/organisations/Link-Modal.vue'

export default {
  name:       'OrganisationView',
  components: {
    OrganisationLinkModal,
    UiViewCard,
    UiViewCardAddress,
    Spinner,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout,
  },
  props:      {
    id: {
      type:     String,
      required: true,
    },
  },
  setup:      (props) => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.ORGANISATIONS, BREADCRUMBS.VIEW]
    const router      = useRouter()
    const notifier    = Notifier()

    const loading      = ref(true)
    const organisation = ref({})
    backend.get(`api/contacts/${props.id}`).then((r) => {
      organisation.value = r.data
      if (!organisation.value.shipmentAddress) {
        organisation.value.shipmentAddress = {}
      }
      loading.value = false
    })

    const edit = () => {
      router.push(`/organisations/edit/${props.id}`)
    }

    const linkOrganisationModal = ref('linkOrganisationModal')
    const showLinkOrganisation  = () => {
      linkOrganisationModal.value.modal.open()
    }

    const linkOrganisation = () => {
      backend.delete(`api/organisations`).then(() => {
        linkOrganisationModal.value.modal.close()
        router.push(`/users/list`)
        notifier.success('toast.user_delete_success')
      }).catch(() => {
        notifier.error('toast.user_delete_failed')
      })
    }

    return {
      breadcrumbs,
      loading,
      organisation,
      edit,
      languages,
      linkOrganisationModal,
      showLinkOrganisation,
      linkOrganisation,
    }
  },
}
</script>

<template>
  <div class="d-flex flex-column h-100">
    <ag-grid-vue
      :serverSideDatasource="grid.datasource('api/transportDocumentTemplates/list?documentType=WASTE_IDENTIFICATION')"
      :grid-options="grid.defaultOptions"
      :columnDefs=columnDefs
      class="ag-theme-quartz"
      style="min-height:100%"
      @row-clicked="rowClick"
      @selection-changed="refreshSelection"
      @grid-ready="onGridReady"
      @grid-pre-destroyed="onGridPreDestroyed"
      @filter-changed="onFilterChanged"
      :initialState="initialState"
    />
  </div>
</template>

<script>

import {inject, onBeforeUnmount, ref, watch} from 'vue'
import {AgGridVue} from 'ag-grid-vue3'
import {useRouter} from 'vue-router'
import grid from '@/util/grid'
import {useModelWrapper} from '@/util/modelWrapper'
import TransportDocumentTemplateState from "@/types/transportDocumentTemplateState";
import session from '@/util/session'

export default {
  name: 'WasteIdentificationListUserTemplates',
  computed: {
    grid() {
      return grid
    },
  },
  components: {
    AgGridVue,
  },
  props: {
    selection: {
      type: Array,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
  setup: (props, {emit}) => {
    const $t = inject('$t')

    const columnDefs = [
      {
        headerName: $t('transport_document_templates.list.table.name'),
        field: 'name',
      },
      {
        headerName: $t('transport_document_templates.list.table.notes'),
        field: 'notes'
      },
      {
        headerName: $t('transport_document_templates.list.table.state'),
        field: 'state',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: TransportDocumentTemplateState.getAll(),
          valueFormatter: (val) => {
            return $t('form.state.'+val.value)
          }
        },
        valueFormatter: (params) => {
          return $t('form.state.'+params.value)
        },
      }
    ]

    let sized = false
    watch(() => props.active, (val) => {
      if (val && !sized) {
        api.sizeColumnsToFit({'defaultMinWidth': 200})
        sized = true
      }
    })

    const resize = () => {
      if (props.active) {
        setTimeout(() => {
          api.sizeColumnsToFit({'defaultMinWidth': 200})
        }, 1)
      } else {
        sized = false
      }
    }
    window.addEventListener("resize", resize)
    onBeforeUnmount(() => {
      window.removeEventListener("resize", resize)
    })

    const gridKey = 'waste-identification-list-user-templates'
    const initialState = ref(session.loadFormState(gridKey))
    const onGridPreDestroyed = (params) => {
      session.saveFormState(gridKey, params.state)
    }
    const onFilterChanged = (event) => {
      session.saveFormState(gridKey, event.api.getState())
    }

    let api = null
    const onGridReady = (params) => {
      api = params.api
      api.deselectAll() // if we can correctly get selection from saved state we can re-evaluate this
      if (props.active) {
        api.sizeColumnsToFit({'defaultMinWidth': 200})
        sized = true
      }
    }
    onBeforeUnmount(() => {
      api = null
    })
    const reload = () => {
      api?.refreshServerSide()
      api?.deselectAll()
    }

    const resetGrid = () => {
      api?.setFilterModel(null)
      api?.onFilterChanged()
      session.saveFormState(gridKey, null)
    }

    const router = useRouter()
    const rowClick = (event) => {
      if (event.data) {
        router.push(`/transport-document-templates/view/${event.data.id}`)
      }
    }

    const selection = useModelWrapper(props, emit, 'selection')
    const refreshSelection = (params) => {
      selection.value = params.api.getSelectedRows()
    }

    return {
      columnDefs,
      rowClick,
      onGridReady,
      onGridPreDestroyed,
      onFilterChanged,
      initialState,
      refreshSelection,
      reload,
      resetGrid,
    }
  }
}
</script>

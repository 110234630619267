<template>
  <div id="WIFormView" class="container-fluid">
    <div class="row">
      <ui-view-card :title="$t('form.title.from', {from: form.producerIdentification.name})">
        <template v-if="form.producerIdentification.shipmentAddress.street">
          <ui-view-card-address :model-value="form.producerIdentification.shipmentAddress" :compact-address="true"/>
        </template>
        <template v-else>
          <ui-view-card-address :model-value="form.producerIdentification.address" :compact-address="true"/>
        </template>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.to', {to: form.processorIdentification.name ?? ''})">
        <ui-view-card-address :model-value="form.processorIdentification.address" :compact-address="true"/>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.waste_identification')" type="col-lg-12">
        <table class="table table-bordered table-striped">
          <thead>
          <tr>
            <th scope="col">{{ $t('form.table.description') }}</th>
            <th scope="col">
              {{ $t('form.table.amount') }}
              <span class="fs-4" v-if="weightEdited" :title="$t('form.weight_edited')">
                <weight-edited-icon/>
              </span>
            </th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(row, index) in form.wasteIdentifications" :key="index">
            <tr>
              <td>
                {{ row.description }}
              </td>
              <td>
                {{ row.amount }} {{ $t('form.table.tonne') }}
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </ui-view-card>
      <ui-view-card id="WIBasicInfo" data-bs-toggle="collapse" :title="$t('form.title.basic_information')"
                    type="col-lg-12">
        <div class="row">
          <div class="col-lg-6">
            {{ $t('form.labels.unique_identifier') }}: {{ 'DGF-' + form.id }}
            <br/>
            <template v-if="form.externalId">
              {{ $t('form.labels.external_identifier') }}: {{ form.externalId }}
              <br/>
            </template>
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.status') }}: {{ $t('form.state.' + form.state) }}
            <br/>
            {{ $t('form.labels.transport_date') }}: {{ date }}
          </div>
        </div>
      </ui-view-card>
      <ui-view-card v-if="form.notes" :title="$t('form.title.notes')" :type="isDangerous ? 'col-lg-6' : 'col-lg-12'"
                    style="white-space: pre-wrap">
        {{ form.notes }}
      </ui-view-card>
    </div>
    <div class="row">
      <ui-view-card-signature :form="form" :processor-info="processorInfo" @signed="$emit('signed')" :uuid="uuid"
                              :sharelink="sharelink"/>
      <ui-view-card-transport :id="form.id" :start="form.startPoint" :end="form.endPoint"
                              :key="form.startPoint && form.endPoint ? form.startPoint.timestamp + form.endPoint.timestamp : 'transportKey'"/>
    </div>
  </div>
</template>
<script>
import UiViewCardSignature from '@/components/ui-view-card-signature'
import UiViewCardTransport from '@/components/ui-view-card-transport'
import UiViewCard from '@/components/ui-view-card'
import UiViewCardAddress from '@/components/ui-view-card-address'
import {computed} from 'vue'
import {DateFormatter} from '@/services/dateFormatter'
import languages from '@/types/languages'
import weightEditedChecker from '@/services/weightEditedChecker'
import WeightEditedIcon from '@/components/weight-edited-icon.vue'

export default {
  name:       'WasteIdentificationFormViewDriver',
  components: {WeightEditedIcon, UiViewCardAddress, UiViewCard, UiViewCardTransport, UiViewCardSignature},
  props:      {
    form:          {
      type:     Object,
      required: true,
    },
    processorInfo: {
      type:    Object,
      default: () => {
      },
    },
    uuid:          {
      type:    String,
      default: '',
    },
    sharelink:     {
      type:    Boolean,
      default: false,
    },
  },
  emits:      ['signed'],
  setup:      (props) => {
    const date = computed(() => {
      return DateFormatter.formatDate(props.form.transportDate)
    })

    const updateDate = computed(() => {
      return DateFormatter.formatDateTime(props.form.lastUpdate)
    })

    const getEuralCodeText = (row) => {
      if (!row.euralCode) {
        return ''
      }

      let result = row.euralCode.substring(6).replaceAll('_', ' ')
      if (row.dangerous) {
        result += '*'
      }

      return result
    }

    const isDangerous = computed(() => {
      let dangerous = false
      props.form.wasteIdentifications.forEach((row) => {
        if (row.dangerous) {
          dangerous = true
        }
      })
      return dangerous
    })

    const weightEdited = computed(() => {
      return weightEditedChecker.check(props.form)
    })

    return {
      date,
      updateDate,
      languages,
      getEuralCodeText,
      isDangerous,
      weightEdited,
    }
  },
}
</script>

<template>
  <layout>
    <div class="display-3 mt-5">{{$t('maintenance.title')}}</div>
    <br />
    <div class="grey--text lighten-5">
      {{ $t('maintenance.text_1') }}
    </div>
  </layout>

</template>

<script>

import Layout from '@/components/layout'
export default {
  name: 'Maintenance',
  components: {Layout},
  setup: () => {
    return []
  }
}
</script>

<template>
  <layout>
    <div class="display-3 mt-5 ">{{ $t('invalid.title') }}</div>
    <br/>
    <br/>
    <div class="grey--text lighten-5">
      {{ $t('invalid.text_0') }}
      <br/>
      {{ $t('invalid.text_1') }}
      <br/>
      {{ $t('invalid.text_2') }}
    </div>
    <br/>
    <br/>
    <img src="../../assets/logo.svg" alt="" width="80" height="80" class="d-inline-block align-bottom">
  </layout>

</template>

<script>

import Layout from '@/components/layout'
export default {
  name: 'InvalidSignature',
  components: {Layout},
  setup: () => {
    return []
  }
}
</script>

<template>
  <div id="GrondbankFormView" class="container-fluid">
    <div class="row">
      <ui-view-card id="GrondbankBasicInfo" data-bs-toggle="collapse" :title="$t('form.title.basic_information')"
                    type="col-lg-12">
        <div class="row">
          <div class="col-lg-6">
            {{ $t('form.labels.unique_identifier') }}: {{ 'DGF-GB-' + form.id }}
            <br/>
            {{ $t('form.labels.permission_identifier') }}: {{ form.permissionId }}
            <br/>
            {{ $t('form.labels.grondbank_identifier') }}: {{ form.soilReferenceId }}
            <br/>
            <template v-if="form.externalId">
              {{ $t('form.labels.external_identifier') }}: {{ form.externalId }}
              <br/>
            </template>
            {{ $t('form.labels.last_update') }}: {{ updateDate }}
            <br/>
            {{ $t('form.labels.updated_by') }}: {{ form.modifiedBy }}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.status') }}: {{ $t('form.state.' + form.state) }}
            <br/>
            {{ $t('form.labels.license_plate') }}: {{ form.licensePlate }}
            <br/>
            {{ $t('form.labels.transport_date') }}: {{ date }}
            <br/>
            {{ $t('form.labels.description') }}: {{ form.description }}
            <br/>
            {{ $t('form.labels.estimatedVolume') }}: {{ form.volume }}
            <br/>
            {{ $t('form.labels.calculatedVolume') }}: {{ form.calculatedVolume }}
            <br/>
            {{ $t('form.labels.notes') }}: {{ form.notes }}
          </div>
        </div>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.origin')">
        <div class="col-lg-6">
          {{ $t('form.labels.name') }}: {{ form.soilOrigin.name }}
        </div>
        <ui-view-card-address :model-value="form.soilOrigin.address"/>
        <div class="col-lg-6">
          {{ $t('form.labels.other_location_info') }}: {{ form.soilOrigin.otherLocationDetermination }}
        </div>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.destination')">
        <div class="col-lg-6">
          {{ $t('form.labels.name') }}: {{ form.soilDestination.name }}
        </div>
        <div class="col-lg-6">
          {{ $t('form.labels.email') }}: {{ form.soilDestination.email }}
        </div>
        <ui-view-card-address :model-value="form.soilDestination.address"/>
        <div class="col-lg-6">
          {{ $t('form.labels.other_location_info') }}: {{ form.soilDestination.otherLocationDetermination }}
        </div>
        <div class="col-lg-6">
          {{ $t('form.labels.destination_type') }}: {{ form.soilDestination.destinationType }}
        </div>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.soil_origin_contractor')">
        <div class="col-lg-6">
          {{ $t('form.labels.name') }}: {{ form.soilOriginContractor.name }}
        </div>
        <div class="col-lg-6">
          {{ $t('form.labels.responsible') }}: {{ form.soilOriginContractor.responsible }}
        </div>
        <div class="col-lg-6">
          {{ $t('form.labels.phone_number') }}: {{ form.soilOriginContractor.phone }}
        </div>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.transporter')">
        <div class="col-lg-6">
          {{ $t('form.labels.name') }}: {{ form.soilTransporter.name }}
        </div>
        <div class="col-lg-6">
          {{ $t('form.labels.email') }}: {{ form.soilTransporter.email }}
        </div>
        <ui-view-card-address :model-value="form.soilTransporter.address"/>
        <div class="col-lg-6">
          {{ $t('form.labels.soil_bank_number') }}: {{ form.soilTransporter.soilBankNumber }}
        </div>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.rides')" type="col-lg-12">
        <table class="table table-bordered table-striped">
          <thead>
          <tr>
            <th scope="col">{{ $t('form.table.ride_number') }}</th>
            <th scope="col">{{ $t('form.table.start_hour') }}</th>
            <th scope="col">{{ $t('form.table.volume') }}</th>
            <th scope="col">{{ $t('form.table.end_hour') }}</th>
            <th scope="col">{{ $t('form.table.signature_organiser') }}</th>
            <th scope="col">{{ $t('form.table.signature_transporter') }}</th>
            <th scope="col">{{ $t('form.table.signature_processor') }}</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(ride, index) in form.soilRides" :key="index">
            <tr>
              <td>{{ index + 1 }}</td>
              <td>{{ ride.startOfTransport }}</td>
              <td>{{ ride.volume }} m³</td>
              <td>{{ ride.endOfTransport }}</td>
              <td :key="ride.id + '-' + ride?.organiserSignature?.signingDate">
                <ui-view-card-signature-field :signature="ride.organiserSignature" :id="form.id" :ride-id="ride.id"
                                              :signature-type="SignatureType.ORGANISER"
                                              :uuid="uuid"/>
              </td>
              <td :key="ride.id + '-' + ride?.transporterSignature?.signingDate">
                <ui-view-card-signature-field :signature="ride.transporterSignature" :id="form.id" :ride-id="ride.id"
                                              :signature-type="SignatureType.TRANSPORTER"
                                              :uuid="uuid"/>
              </td>
              <td :key="ride.id + '-' + ride?.processorSignature?.signingDate">
                <ui-view-card-signature-field :signature="ride.processorSignature" :id="form.id" :ride-id="ride.id"
                                              :signature-type="SignatureType.PROCESSOR"
                                              :uuid="uuid"/>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.organiser')" type="col-lg-4">
        <div :key="form.id + '-' + form?.organiserSignature?.signingDate">
          <ui-view-card-signature-field :signature="form.organiserSignature" :id="form.id"
                                        :signature-type="SignatureType.ORGANISER" @signed="$emit('signed')"
                                        :signable="organiserFormSignable" :uuid="uuid"/>
        </div>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.transporter')" type="col-lg-4">
        <div :key="form.id + '-' + form?.transporterSignature?.signingDate">
          <ui-view-card-signature-field :signature="form.transporterSignature" :id="form.id"
                                        :signature-type="SignatureType.TRANSPORTER" @signed="$emit('signed')"
                                        :signable="transporterFormSignable" :request="transporterFormRequest"
                                        :document-type="DocumentType.GRONDBANK" :email="form.soilTransporter.email"
                                        :uuid="uuid"
                                        :subtitle="form.state === FormState.ON_THE_ROAD ? $t('signature.cant_sign_while_ride_in_progress') : ''"/>
        </div>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.processor')" type="col-lg-4">
        <div :key="form.id + '-' + form?.processorSignature?.signingDate">
          <ui-view-card-signature-field :signature="form.processorSignature" :id="form.id"
                                        :signature-type="SignatureType.PROCESSOR" @signed="$emit('signed')"
                                        :signable="processorFormSignable" :request="processorFormRequest"
                                        :document-type="DocumentType.GRONDBANK" :email="form.soilDestination.email"
                                        :uuid="uuid"
                                        :subtitle="form.state === FormState.ON_THE_ROAD ? $t('signature.cant_sign_while_ride_in_progress') : ''"/>
        </div>
      </ui-view-card>
    </div>
  </div>
  <sign-ride-modal ref="signRideModal" :callback="signRide" :end-of-transport="endOfTransport"/>
</template>

<script>
import UiViewCard from '@/components/ui-view-card'
import UiViewCardAddress from '@/components/ui-view-card-address'
import {computed, inject, ref} from 'vue'
import {DateFormatter} from '@/services/dateFormatter'
import languages from '@/types/languages'
import UiViewCardSignatureField from '@/components/ui-view-card-signature-field'
import SignatureType from '@/types/signatureType'
import roles from '@/util/roles'
import GrondbankModel from '@/models/GrondbankModel'
import backend from '@/util/backend'
import Notifier from '@/util/notifier'
import FormState from '@/types/formState'
import SignRideModal from '@/views/forms/grondbank/Sign-Ride-Modal'
import DocumentType from '@/types/documentType'

export default {
  name:       'GrondbankFormView',
  computed:   {
    DocumentType() {
      return DocumentType
    },
    FormState() {
      return FormState
    },
    SignatureType() {
      return SignatureType
    },
  },
  components: {
    SignRideModal,
    UiViewCardSignatureField,
    UiViewCardAddress,
    UiViewCard,
  },
  props:      {
    form:                      {
      type:     Object,
      required: true,
    },
    processorInfo:             {
      type:    Object,
      default: () => {
      },
    },
    uuid:                      {
      type:    String,
      default: '',
    },
    allowTransporterSignature: {
      type:    Boolean,
      default: true,
    },
    allowProcessorSignature:   {
      type:    Boolean,
      default: true,
    },
  },
  emits:      ['signed', 'rideAdded'],
  setup:      (props, {emit}) => {
    const isSharelink = inject('isSharelink')
    const notifier    = Notifier()
    const date        = computed(() => {
      return DateFormatter.formatDate(props.form.transportDate)
    })

    const updateDate = computed(() => {
      return DateFormatter.formatDateTime(props.form.lastUpdate)
    })

    const organiserFormSignable   = ref(false)
    const transporterFormSignable = ref(false)
    const processorFormSignable   = ref(false)
    const transporterFormRequest  = ref(false)
    const processorFormRequest    = ref(false)
    const checkSignable           = () => {
      let allRidesFinished = true
      props.form.soilRides.forEach((ride) => {
        if (!(ride.startOfTransport && ride.volume && ride.endOfTransport)) {
          allRidesFinished = false
        }
      })

      const err = GrondbankModel.formCheck(props.form, FormState.isDraft(props.form.state))
      organiserFormSignable.value
                = !err && roles.hasOneOfRoles(roles.USER) && !FormState.isEndState(props.form.state)
      transporterFormSignable.value
                = (((!err && roles.hasOneOfRoles(roles.DRIVER)) || (isSharelink && props.allowTransporterSignature)) && allRidesFinished) && !FormState.isEndState(props.form.state)
      processorFormSignable.value
                = (((!err && roles.hasOneOfRoles(roles.DRIVER)) || (isSharelink && props.allowProcessorSignature)) && allRidesFinished) && !FormState.isEndState(props.form.state)
      transporterFormRequest.value
                = !err && roles.hasOneOfRoles([roles.USER]) && allRidesFinished && !FormState.isEndState(props.form.state)
      processorFormRequest.value
                = !err && roles.hasOneOfRoles([roles.USER]) && allRidesFinished && !FormState.isEndState(props.form.state)
    }
    setTimeout(checkSignable, 1)

    const signRideModal     = ref('signRideModal')
    const showSignRide      = (rideIndex) => {
      selectedRideIndex.value = rideIndex
      endOfTransport.value    = props.form.soilRides[selectedRideIndex.value].endOfTransport
      signRideModal.value.modal.open()
    }
    const selectedRideIndex = ref(null)
    const endOfTransport    = ref('')
    const signRide          = async (info) => {
      const form   = props.form
      form.soilRides[selectedRideIndex.value].endOfTransport
                   = info.endOfTransport.hours.toString().padStart(2, '0') + ':' + info.endOfTransport.minutes.toString().padStart(2, '0')
      const rideId = form.soilRides[selectedRideIndex.value].id
      const url    = isSharelink
                     ? `api/sharedlinks/${props.uuid}/document/${props.form.id}`
                     : `api/documents/${props.form.id}`
      const result = await backend.put(url, props.form)
      if (result.status === 200) {
        delete info.endOfTransport
        const url = isSharelink
                    ? `api/sharedlinks/${props.uuid}/rides/${rideId}/sign`
                    : `api/documents/${props.form.id}/rides/${rideId}/sign`
        backend.put(url, info).then(() => {
          notifier.success('toast.ride_signed')
          emit('signed')
        }).catch(() => {
          notifier.error('toast.ride_sign_failed')
        })
      } else {
        notifier.error('toast.ride_sign_failed')
      }
    }

    return {
      date,
      updateDate,
      languages,
      organiserFormSignable,
      transporterFormSignable,
      processorFormSignable,
      transporterFormRequest,
      processorFormRequest,
      selectedRideIndex,
      endOfTransport,
      showSignRide,
      signRideModal,
      signRide,
    }
  },
}
</script>

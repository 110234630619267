<template>
  <ui-modal id="selectTemplateModal" ref="modal" size="xl" :on-open="load" :callback="cb" :can-confirm="() => { return !!selectedTemplate }">
    <template #title>
      {{ $t('transport_document_templates.waste_identification.select_template_modal.title') }}
    </template>
    <template #default>
      <ui-view-card-field :label="$t('transport_document_templates.waste_identification.select_template_modal.select_template')">
        <v-select :options="templates" v-model="selectedTemplate" :label="'templateName'" :reduce="t => t.templateId">
          <template #search="{attributes, events}">
            <input
              class="vs__search"
              v-bind="attributes"
              v-on="events"
            />
          </template>
          <template #no-options>
            {{ $t('general.no_options') }}
          </template>
        </v-select>
      </ui-view-card-field>
      <template v-if="selectedTemplate && template">
        <hr />
        <div class="row">
          <template class="row">
            <h4>
              {{ $t('transport_document_templates.waste_identification.select_template_modal.producers') }}
            </h4>
            <table class="table table-bordered table-striped">
              <thead>
              <tr>
                <th>{{$t('transport_document_templates.waste_identification.edit.name')}}</th>
                <th>{{$t('transport_document_templates.waste_identification.edit.address')}}</th>
                <th>{{$t('transport_document_templates.waste_identification.edit.sending_address')}}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="producer in template.producers" :key="producer.id">
                <td>{{ producer.name }}</td>
                <td>
                  <ui-view-card-address v-model="producer.address" :compact-address="true" />
                </td>
                <td>
                  <ui-view-card-address v-model="producer.shipmentAddress" :compact-address="true" />
                </td>
              </tr>
              </tbody>
            </table>
          </template>
          <template class="row">
            <h4>
              {{ $t('transport_document_templates.waste_identification.select_template_modal.processors') }}
            </h4>
            <table class="table table-bordered table-striped">
              <thead>
              <tr>
                <th>{{$t('transport_document_templates.waste_identification.edit.name')}}</th>
                <th>{{$t('transport_document_templates.waste_identification.edit.address')}}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="processor in template.processors" :key="processor.id">
                <td>{{processor.name}}</td>
                <td>
                  <ui-view-card-address v-model="processor.address" :compact-address="true" />
                </td>
              </tr>
              </tbody>
            </table>
          </template>
          <template class="row">
            <h4>
              {{ $t('transport_document_templates.waste_identification.select_template_modal.waste_identifications') }}
            </h4>
            <table class="table table-bordered table-striped">
              <thead>
              <tr>
                <th>{{$t('waste_identification_template.form.description')}}</th>
                <th>{{$t('waste_identification_template.form.EURAL-code')}}</th>
                <th>{{$t('transport_document_templates.waste_identification.edit.processors')}}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="waste in template.wasteIdentificationTemplateProcessors" :key="waste.id">
                <td>{{ waste.wasteIdentification.description }}</td>
                <td>{{ waste.wasteIdentification.euralCode ? waste.wasteIdentification.euralCode.substring(6).replaceAll('_', ' ') : '' }}
                  <template v-if="waste.dangerous">*</template>
                </td>
                <td>{{ getProcessorsForWaste(waste) }}</td>
              </tr>
              </tbody>
            </table>
          </template>
        </div>
      </template>
    </template>
  </ui-modal>
</template>

<script>

import UiViewCardField from '@/components/ui-view-card-field'
import {ref, watch} from 'vue'
import languages from '../../../types/languages'
import UiModal from '@/components/ui-modal.vue'
import session from '@/util/session'
import backend from '@/util/backend'
import UiViewCardAddress from '@/components/ui-view-card-address.vue'
import TransportDocumentTemplateState from '@/types/transportDocumentTemplateState'

export default {
  name: 'WasteIdentificationTemplatesSelectTemplate',
  computed: {
    languages() {
      return languages
    }
  },
  components: {
    UiViewCardAddress,
    UiModal,
    UiViewCardField,
  },
  props: {
    callback: {
      type:     Function,
      required: true,
    }
  },
  setup: (props) => {
    const modal = ref('modal')
    const loading = ref(false)
    const templates = ref([])

    const selectedTemplate = ref(null)
    const load = () => {
      const user = session.getUser()
      if (user) {
        backend.get(`api/transportDocumentTemplateShared?email=${encodeURIComponent(user.email)}`).then((res) => {
          templates.value = res.data.filter((template) => { return template.state !== TransportDocumentTemplateState.DRAFT })
        }).finally(() => {
          loading.value = false
        })
      } else {
        loading.value = false
      }
    }

    const template = ref(null)
    const cb = () => {
      props.callback(template.value)
    }

    watch(() => selectedTemplate.value, (val) => {
      backend.get(`api/transportDocumentTemplates/${val}`).then((res) => {
        if (res.status === 200) {
          template.value = res.data
        }
      })
    })

    const getProcessorsForWaste = (waste) => {
      const processorsMap = {}
      template.value.processors.forEach((proc) => {
        processorsMap[proc.id] = proc.name
      })
      let names = []
      waste?.allowedProcessors.forEach((proc) => {
        names.push(processorsMap[proc])
      })
      return names.join(', ')
    }

    return {
      modal,
      load,
      templates,
      selectedTemplate,
      template,
      cb,
      getProcessorsForWaste,
    }
  }
}
</script>
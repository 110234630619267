<template>
  <layout>
    <template #header>
      <ui-header :title="$t('support.mails.title')" />
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    <ui-content>
      <div class="p-5">
        <table class="table table-bordered table-striped">
          <thead>
          <tr>
            <th>{{$t('support.mails.table.mail')}}</th>
            <th>{{$t('support.mails.table.reason')}}</th>
            <th>{{$t('support.mails.table.added_on')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="blockedMails.length === 0">
            No blocked mails
          </tr>
          <template v-for="(mail, index) in blockedMails" :key="index">
            <tr>
              <td>
                {{ mail.mail }}
              </td>
              <td>
                {{ mail.reason }}
              </td>
              <td>
                {{ DateFormatter.formatDate(mail.addedOn) }}
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import UiContent from '@/components/ui-content'
import backend from '@/util/backend'
import {ref} from 'vue'
import {DateFormatter} from '@/services/dateFormatter'


export default {
  name:       'Support-Mails',
  components: {
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout,
  },
  setup:      () => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.MAILS]

    const blockedMails = ref([])
    backend.get(`api/blockedMails`).then((res) => {
      blockedMails.value = res.data
    })

    return {
      DateFormatter,
      breadcrumbs,
      blockedMails,
    }
  },
}
</script>

const sharelinkConfig = {
  publicConfig: {},
  privateConfig: {},
  parse: (input) => {
    self.publicConfig = input.config ? JSON.parse(input.config) : {}
    self.privateConfig = input.privateConfig ? JSON.parse(input.privateConfig) : {}
  },
  getConfig: (key) => {
    if (self.publicConfig[key]) {
      return self.publicConfig[key]
    } else if (self.privateConfig[key]) {
      return self.privateConfig[key]
    } else {
      return null
    }
  },
}

export default sharelinkConfig

<template>
  <div></div>
</template>

<script>

export default {
  name: 'TransportDocumentTemplatesViewDriver',
  props: {
    id: {
      type: String,
      required: true,
    }
  },
  setup: () => {
    return {}
  }
}
</script>
<template>
  <ui-modal ref="modal" :callback="cb" :can-confirm="validInfo">
    <template #title>
      {{ $t('forms.request_sign_form_modal.title') }}
    </template>
    <template #default>
      <div class="pb-2">
        {{ $t('forms.request_sign_form_modal.request_sign_form') }}
      </div>
      <form>
        <ui-view-card-field :label="$t('form.labels.email')" v-model="info.email" :required="true"/>
        <ui-view-card-language v-model="info.language" />
      </form>
    </template>
  </ui-modal>
</template>

<script>

import {ref} from 'vue'
import UiModal from '@/components/ui-modal'
import UiViewCardField from '@/components/ui-view-card-field'
import UiViewCardLanguage from '@/components/ui-view-card-language.vue'

export default {
  name:       'FormRequestSignFormModal',
  components: {UiViewCardLanguage, UiViewCardField, UiModal},
  props:      {
    callback:      {
      type:     Function,
      required: true,
    },
    signatureType: {
      type:    String,
      default: null,
    },
    email:         {
      type:    String,
      default: '',
    },
    language:      {
      type:    String,
      default: 'NL',
    },
  },
  setup:      (props) => {
    const modal = ref('modal')
    const info  = ref({
      signatureType: props.signatureType,
      email:         props.email,
      language:      props.language,
    })

    const cb = async () => {
      await props.callback(info.value)
    }

    const validInfo = () => {
      return info.value.signatureType && info.value.email
    }

    return {
      modal,
      info,
      cb,
      validInfo,
    }
  },
}
</script>

<template>
  <waste-identification-templates-edit v-if="template?.documentType === documentType.WASTE_IDENTIFICATION" :template="template" @reload="load" />
  <layout v-else>
    <spinner />
  </layout>
</template>

<script>

import backend from '@/util/backend'
import {ref} from 'vue'
import documentType from '@/types/documentType'
import Layout from '@/components/layout'
import Spinner from '@/components/spinner'
import WasteIdentificationTemplatesEdit from '@/views/transport-document-templates/waste-identification/Edit'

export default {
  name: 'TransportDocumentTemplatesEdit',
  computed: {
    documentType() {
      return documentType
    }
  },
  components: {
    WasteIdentificationTemplatesEdit,
    Spinner,
    Layout,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup: (props) => {
    const template = ref(null)

    const load = () => {
      backend.get(`api/transportDocumentTemplates/${props.id}`).then((result) => {
        template.value = result.data
      })
    }
    load()

    return {
      template,
      load,
    }
  }
}
</script>

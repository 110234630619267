const OrganisationField = {
	NAME:                          'NAME',
	ADDRESS:                       'ADDRESS',
	CONTACTPERSON:                 'CONTACTPERSON',
	PHONE:                         'PHONE',
	FAX:                           'FAX',
	EMAIL:                         'EMAIL',
	LANGUAGE:                      'LANGUAGE',
	ANNEX7RECOVERYDESTINATIONTYPE: 'ANNEX7RECOVERYDESTINATIONTYPE',
	MEANSOFTRANSPORT:              'MEANSOFTRANSPORT',
	TRANSPORTDATE:                 'TRANSPORTDATE',
	SIGNATURE:                     'SIGNATURE',
    LOCATIONINFO:                  'LOCATIONINFO',
    DESTINATIONTYPE:               'DESTINATIONTYPE',
    RESPONSIBLE:                   'RESPONSIBLE',
    GRONDBANKNUMBER:               'GRONDBANKNUMBER',
}

export default OrganisationField

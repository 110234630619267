const CredentialApplicationType = {
  MATIS: "MATIS",
  ALL: (matis) => {
    const result = []
    if (matis) {
      result.push(CredentialApplicationType.MATIS)
    }
    return result
  },
}

export default CredentialApplicationType

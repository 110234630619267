import {languages} from '@/util/i18n'

const UserModel = {
    empty: {
        id: null,
        firstName: '',
        lastName: '',
        email: '',
        roles: [],
        language: languages.NL,
    }
}

export default UserModel

<template>
  <layout>
    <template #header>
      <ui-header :title="$t('waste_identification.view.title', {id})" back-route="/waste-identification/list">
        <template #actions>
          <template v-if="!stopping && showActions">
            <div class="btn-group">
              <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="actionsDropdown"
                      data-bs-toggle="dropdown">
                {{ $t('general.actions') }}
              </button>
              <ul data-cy="contacts-actions" class="dropdown-menu">
                <li
                    v-if="$roles([roles.USER, roles.DRIVER, roles.DRIVER_BOSSCHAERT])"
                    id="fullViewBtn"
                >
                  <button v-if="showDriverView" class="dropdown-item" @click="$emit('showDriverView')">
                    {{ $t('waste_identification.view.show_simple_form') }}
                  </button>
                </li>
                <li
                    v-if="$roles([roles.USER, roles.DRIVER_BOSSCHAERT]) && !FormState.isEndState(form.state)"
                    id="shareBtn"
                    :title="!FormState.canShare(form.state) ? $t('waste_identification.view.only_signed_shared') : ''"
                >
                  <button class="dropdown-item" :class="!FormState.canShare(form.state) ? 'disabled' : ''"
                          @click="showShare()">
                    {{ $t('waste_identification.view.share_with_driver') }}
                  </button>
                </li>
                <li
                    v-if="$roles([roles.USER, roles.DRIVER_BOSSCHAERT, roles.SUPPORT]) && !FormState.isDraft(form.state)"
                    id="sharedWithBtn"
                    :title="!FormState.isDraft(form.state) ? $t('waste_identification.view.only_signed_shared') : ''"
                >
                  <button class="dropdown-item" :class="FormState.isDraft(form.state) ? 'disabled' : ''"
                          @click="showSharedWith()">
                    {{ $t('waste_identification.view.show_shared_with') }}
                  </button>
                </li>
                <li
                    v-if="$roles([roles.OVERVIEWER, roles.SUPPORT, roles.USER, roles.DRIVER, roles.DRIVER_BOSSCHAERT])"
                    id="changeLogBtn"
                >
                  <button class="dropdown-item" @click="showChangeLog()">
                    {{ $t('waste_identification.view.view_change_log') }}
                  </button>
                </li>
                <li
                    v-if="$roles([roles.OVERVIEWER, roles.SUPPORT, roles.USER, roles.DRIVER_BOSSCHAERT])"
                    :title="!FormState.canExport(form.state) ? $t('waste_identification.view.draft_no_export') : ''"
                >
                  <button class="dropdown-item" :class="!FormState.canExport(form.state) ? 'disabled' : ''"
                          @click="showExport()">
                    {{ $t('waste_identification.view.export') }}
                  </button>
                </li>
                <li
                    v-if="$roles([roles.USER, roles.DRIVER_BOSSCHAERT])"
                >
                  <button class="dropdown-item" @click="duplicate()">
                    {{ $t('waste_identification.view.duplicate') }}
                  </button>
                </li>
                <li
                    v-if="$roles([roles.USER, roles.DRIVER_BOSSCHAERT]) && !FormState.isEndState(form.state)"
                    id="deleteBtn"
                    :title="!FormState.canDelete(form.state) ? $t('waste_identification.view.only_draft_deleted') : ''"
                >
                  <button class="dropdown-item" :class="!FormState.canDelete(form.state) ? 'disabled' : ''"
                          @click="showDelete()">
                    {{ $t('waste_identification.view.delete') }}
                  </button>
                </li>
                <li
                    v-if="$roles([roles.USER, roles.DRIVER_BOSSCHAERT]) && !FormState.isEndState(form.state)"
                    id="cancelBtn"
                    :title="!FormState.canCancel(form.state) ? $t('waste_identification.view.only_signed_cancel') : ''"
                >
                  <button class="dropdown-item" :class="!FormState.canCancel(form.state) ? 'disabled' : ''"
                          @click="showCancel()">
                    {{ $t('waste_identification.view.cancel') }}
                  </button>
                </li>
                <li
                    v-if="$flag('empty_container') && $roles([roles.USER, roles.DRIVER_BOSSCHAERT]) && !FormState.isEndState(form.state)"
                    id="setStateEmptyBtn"
                    :title="!FormState.canSetStateEmpty(form.state) ? $t('waste_identification.view.only_draft_empty') : ''"
                >
                  <button class="dropdown-item" :class="!FormState.canSetStateEmpty(form.state) ? 'disabled' : ''"
                          @click="showSetStateEmpty()">
                    {{ $t('waste_identification.view.set_state_empty') }}
                  </button>
                </li>
                <li
                    v-if="$roles([roles.DRIVER, roles.DRIVER_BOSSCHAERT]) && form.state && FormState.canControl(form.state)"
                    id="editBtn"
                >
                  <button class="dropdown-item" @click="showControl()">
                    {{ $t('waste_identification.view.control') }}
                  </button>
                </li>
                <li
                    v-if="(canStart || canArrive) && $roles([roles.USER, roles.DRIVER, roles.DRIVER_BOSSCHAERT]) && form.state && !FormState.isEndState(form.state)"
                    id="editBtn"
                >
                  <button class="dropdown-item" @click="edit()">
                    {{ $t('waste_identification.view.edit') }}
                  </button>
                </li>
                <li
                    v-if="$flag('ATTACHMENTS') && $roles([roles.USER, roles.DRIVER, roles.DRIVER_BOSSCHAERT, roles.SUPPORT])"
                >
                  <button class="dropdown-item" @click="showAttachments()">
                    {{ $t('waste_identification.view.manage_attachments') }}
                  </button>
                </li>
              </ul>
            </div>
            <!-- If we can start/stop then show this as main button, else show edit-->
            <button
                v-if="(!canStart && !canArrive) && $roles([roles.USER, roles.DRIVER_BOSSCHAERT]) && form.state && !FormState.isEndState(form.state)"
                type="button"
                class="btn"
                :class="!canSign && FormState.isDraft(form.state) ? 'btn-outline-primary' : 'btn-outline-secondary'"
                @click="edit()"
            >
              <i class="bi-pencil"/>&nbsp;{{ $t('waste_identification.view.edit') }}
            </button>
            <button
                v-if="canSign && $roles([roles.ORGANISATION_ADMIN, roles.USER, roles.DRIVER_BOSSCHAERT]) && FormState.isDraft(form.state)"
                type="button" class="btn btn-outline-primary"
                @click="clickSignOrganiserBtn()"
                :disabled="locating"
            >
              <i class="bi-pencil-square"/>&nbsp;{{ $t('signature.sign_as_organiser') }}
            </button>
            <button
                v-if="!$roles([roles.DRIVER], false) && $roles([roles.USER]) && FormState.SIGNED === form.state"
                type="button"
                class="btn btn-outline-primary"
                @click="showShare()"
            >
              {{ $t('waste_identification.view.share_with_driver') }}
            </button>
            <button
                id="startBtn"
                v-if="canStart"
                type="button"
                class="btn btn-outline-primary"
                @click="start()"
                :disabled="locating"
            >
              {{ $t('waste_identification.view.start_transport') }}
            </button>
            <button
                id="arriveBtn"
                v-if="canArrive"
                type="button"
                class="btn btn-outline-primary"
                @click="stop()"
                :disabled="locating"
            >
              {{ $t('waste_identification.view.stop_transport') }}
            </button>
            <button
                v-if="$roles([roles.USER]) && form.state && FormState.canFinish(form.state)"
                type="button"
                class="btn btn-outline-primary"
                @click="showFinish()"
            >
              <i class="bi-check-circle"/>&nbsp;{{ $t('waste_identification.view.finish') }}
            </button>
            <button
                id="backBtn"
                v-if="roles.hasOnlyRole(roles.DRIVER) && form.state === FormState.ARRIVED"
                type="button"
                class="d-flex d-sm-none btn btn-outline-primary"
                @click="router.push(`/waste-identification/list`)"
            >
              {{ $t('waste_identification.view.to_overview') }}
            </button>
          </template>
          <div class="btn-group" v-if="stopping && step === steps.control">
            <button type="button" class="btn btn-outline-secondary" @click="showEditWeightsModal()" :disabled="saving">
              <i class="bi-pencil"/>&nbsp;{{ $t('share_link.processor.edit_weights') }}
            </button>
            <button id="signForDeliveryBtn" type="button" class="btn btn-outline-primary" @click="goSign()"
                    :disabled="saving || signing">
              <i class="bi-pencil-square"/>&nbsp;{{ $t('signature.sign_for_delivery') }}
            </button>
          </div>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    <ui-content class="pb-1">
      <spinner v-if="loading"/>
      <template v-if="!loading">
        <template v-if="stopping">
          <template v-if="step === steps.arrive">
            <div id="arrive" class="d-flex justify-content-center">
              <h3 class="d-flex" v-if="!$isMobile">
                <span class="btn-group pe-3">
                  <button id="signProcessorBtn" type="button" class="btn btn-outline-primary" @click="signProcessor()">{{
                      $t('shared.sign_as_processor')
                    }}</button>
                  <button id="signDriverBtn" type="button" class="btn btn-outline-secondary"
                          @click="signDriver()">{{ $t('shared.sign_as_driver') }}</button>
                  <button id="refuseBtn" type="button" class="btn btn-outline-secondary" @click="showRefuse()"
                          :disabled="refusing">{{ $t('shared.refuse') }}</button>
                </span>
              </h3>
              <h3 v-else>
                <button style="height:5rem; width:100%" id="signProcessorBtn" type="button"
                        class="btn btn-outline-primary" @click="signProcessor()">{{ $t('shared.sign_as_processor') }}
                </button>
                <button style="height:5rem; width:100%" id="signDriverBtn" type="button"
                        class="btn btn-outline-secondary" @click="signDriver()">{{ $t('shared.sign_as_driver') }}
                </button>
                <button style="height:5rem; width:100%" id="refuseBtn" type="button" class="btn btn-outline-secondary"
                        @click="showRefuse()" :disabled="refusing">{{ $t('shared.refuse') }}
                </button>
              </h3>
            </div>
          </template>
          <template v-else-if="step === steps.control">
            <waste-identification-form-view :form="form" :key="form.id+'-'+form.lastUpdate" @signed="signed()"/>
          </template>
          <template v-else-if="step === steps.processorinfo">
            <div class="container-fluid">
              <form id="processor-form" class="row g-2 justify-content-center">
                <ui-view-card data-cy="information" :title="$t('shared.processor_information')"
                              :key="signerInfo.language">
                  <template #actions>
                    <button type="button" class="btn btn-sm btn-outline-secondary" @click="backToControl()">
                      {{ $t('general.back') }}
                    </button>
                  </template>
                  <ui-view-card-language v-model="signerInfo.language" :required="true" :own-language="true"/>
                  <template v-if="signerInfo.language">
                    <ui-view-card-field data-cy="processor-first-name" :label="$t('user.labels.first_name')"
                                        v-model="signerInfo.firstName" :required="true"/>
                    <ui-view-card-field data-cy="processor-last-name" :label="$t('user.labels.last_name')"
                                        v-model="signerInfo.lastName" :required="true"/>
                    <ui-view-card-field data-cy="processor-organisation" :label="$t('user.labels.organisation')"
                                        v-model="signerInfo.organisation" :required="true"/>
                    <br/>
                    <br/>
                    <div class="row g-0 justify-content-center">
                      <button id="signBtn" type="button" class="btn" :class="'btn-outline-primary'" @click="sign(true)"
                              form="processor-form" :disabled="signing"><i
                          class="bi-pencil-square"/>&nbsp;{{ $t('signature.sign_for_delivery') }}
                      </button>
                    </div>
                  </template>
                </ui-view-card>
              </form>
            </div>
          </template>
        </template>
        <template v-else>
          <waste-identification-form-view :form="form" :key="form.id+'-'+form.lastUpdate" @signed="signed()"/>
        </template>
      </template>
    </ui-content>
  </layout>
  <waste-identification-form v-if="!loading" style="display: none" :item="form" :key="form.id+'-'+form.lastUpdate"/>
  <form-share-modal v-if="!loading" ref="shareModal" :amount="1" :callback="share" :id="form.id"
                    :document-type="DocumentType.WASTE_IDENTIFICATION" @reload="loadForm"/>
  <form-shared-with-modal ref="sharedWithModal" :id="form.id"/>
  <form-export-modal ref="exportModal" :amount="1" :callback="exportForm"/>
  <form-duplicate-modal ref="duplicateModal" :amount="1" :callback="duplicate"/>
  <form-cancel-modal ref="cancelModal" :callback="cancel"/>
  <form-refuse-modal ref="refuseModal" :callback="refuse"/>
  <form-delete-modal ref="deleteModal" :amount="1" :callback="deleteAction"/>
  <form-finish-modal ref="finishModal" :callback="finish" :demo="demo"/>
  <form-change-log-modal ref="changeLogModal" :form="form"/>
  <form-set-state-empty-modal ref="setStateEmptyModal" :amount="1" :callback="setStateEmpty"/>
  <form-control-qr-modal ref="controlQRModal" :callback="control" :url="controlUrl"/>
  <form-attachments-modal v-if="!loading" ref="attachmentsModal" :id="form.id" :attachments="form.attachments"
                          :callback="saveAttachments"/>
  <shared-link-edit-weights v-if="!loading" ref="editWeightsModal" :form="form" :callback="saveWeightEdit"/>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import backend from '@/util/backend'
import {computed, inject, ref, watch} from 'vue'
import Spinner from '@/components/spinner'
import {useRouter} from 'vue-router'
import {DateFormatter} from '@/services/dateFormatter'
import WasteIdentificationForm from '@/partials/waste-identification/Form'
import WasteIdentificationFormView from '@/partials/waste-identification/FormView'
import Notifier from '@/util/notifier'
import FormState from '@/types/formState'
import FormShareModal from '@/views/forms/shared/Share-Modal'
import FormCancelModal from '@/views/forms/shared/Cancel-Modal'
import FormFinishModal from '@/views/forms/shared/Finish-Modal'
import FormChangeLogModal from '@/views/forms/shared/Change-Log-Modal'
import FormSetStateEmptyModal from '@/views/forms/shared/Set-State-Empty-Modal'
import FormDuplicateModal from '@/views/forms/shared/Duplicate-Modal'
import FormExportModal from '@/views/forms/shared/Export-Modal'
import FormControlQrModal from '@/views/forms/shared/Control-Qr-Modal'
import FormAttachmentsModal from '@/views/forms/shared/Attachments-Modal'
import FormDeleteModal from '@/views/forms/shared/Delete-Modal'
import OrganisationType from '@/types/organisationType'
import roles from '@/util/roles'
import geolocation from '@/services/geolocation'
import session from '@/util/session'
import SharedLinkEditWeights from '@/views/shared-links/Shared-Link-Edit-Weights'
import ShareLinkType from '@/types/shareLinkType'
import DocumentType from '@/types/documentType'
import WasteIdentificationModel from '@/models/WasteIdentificationModel'
import FormSharedWithModal from '@/views/forms/shared/Shared-With-Modal'
import ExportType from '@/types/exportType'
import UiViewCard from '@/components/ui-view-card.vue'
import UiViewCardField from '@/components/ui-view-card-field.vue'
import UiViewCardLanguage from '@/components/ui-view-card-language.vue'
import FormRefuseModal from '@/views/forms/shared/Refuse-Modal.vue'

export default {
  name:       'WasteIdentificationViewUser',
  computed:   {
    DocumentType() {
      return DocumentType
    },
    OrganisationType() {
      return OrganisationType
    },
  },
  components: {
    FormRefuseModal,
    UiViewCardLanguage,
    UiViewCardField,
    UiViewCard,
    FormSharedWithModal,
    SharedLinkEditWeights,
    FormDeleteModal,
    FormAttachmentsModal,
    FormControlQrModal,
    FormExportModal,
    FormDuplicateModal,
    FormSetStateEmptyModal,
    FormChangeLogModal,
    FormFinishModal,
    FormCancelModal,
    FormShareModal,
    WasteIdentificationFormView,
    WasteIdentificationForm,
    Spinner,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout,
  },
  props:      {
    id:             {
      type:     String,
      required: true,
    },
    showDriverView: {
      type:    Boolean,
      default: false,
    },
  },
  emits:      ['showDriverView'],
  setup:      (props) => {
    const $tset       = inject('$tset')
    const $isSafari   = inject('$isSafari')
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.WASTE_IDENTIFICATION, BREADCRUMBS.VIEW]
    const notifier    = Notifier()
    const loading     = ref(true)
    const canSign     = ref(false)
    const showActions = ref(false)

    const form     = ref({})
    const loadForm = () => {
      backend.get(`api/documents/${props.id}`).then((r) => {
        if (r.data.type !== DocumentType.WASTE_IDENTIFICATION) {
          router.push(`/waste-identification/list`)
        }
        form.value    = r.data
        loading.value = false

        setTimeout(() => {
          canSign.value     = !WasteIdentificationModel.formCheck(form.value, true)
          showActions.value = true
        }, 1)
      })
    }
    loadForm()

    watch(() => props.id, () => {
      loadForm()
    })

    const router = useRouter()
    const edit   = () => {
      router.push(`/waste-identification/edit/${props.id}`)
    }

    const exportModal = ref('exportModal')
    const showExport  = () => {
      exportModal.value.modal.open()
    }
    const exportForm  = (language, type) => {
      switch (type) {
        case ExportType.PDF: {
          if ($isSafari()) {
            const route = router.resolve(`/export-pdf/${language}/${props.id}`)
            window.open(route.href, '_blank')
          } else {
            exportPdf(language)
          }
          return
        }
        case ExportType.JSON: {
          if ($isSafari()) {
            const route = router.resolve(`/export-json/${props.id}`)
            window.open(route.href, '_blank')
          } else {
            exportJson()
          }
          return
        }
        case ExportType.CSV: {
          if ($isSafari()) {
            const route = router.resolve(`/export-csv/${props.id}`)
            window.open(route.href, '_blank')
          } else {
            exportCsv()
          }
          return
        }
      }
    }

    const exportPdf = (language) => {
      backend.get(`api/documents/${props.id}/pdf?language=${language}`,
          {responseType: 'blob'}).then((result) => {
        if (result.status === 200) {
          const blob = new Blob([result.data], {type: 'application/pdf'})
          const url  = URL.createObjectURL(blob)
          window.open(url)
        } else {
          notifier.error('toast.exporting_failed')
        }
      }).finally(() => {
        exportModal.value.modal.close()
      })
    }

    const exportJson = () => {
      backend.get(`api/documents/${props.id}/json`,
          {responseType: 'blob'}).then((result) => {
        if (result.status === 200) {
          const blob = new Blob([result.data], {type: 'application/json'})
          const url  = URL.createObjectURL(blob)
          window.open(url)
        } else {
          notifier.error('toast.exporting_failed')
        }
      }).finally(() => {
        exportModal.value.modal.close()
      })
    }

    const exportCsv = () => {
      backend.get(`api/documents/${props.id}/csv`, {responseType: 'blob'}).then((result) => {
        if (result.status === 200) {
          const blob      = new Blob([result.data])
          const url       = URL.createObjectURL(blob)
          const a         = document.createElement('a')
          a.style.display = 'none'
          a.href          = url
          a.download      = `export_${props.id}.csv`
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        } else {
          notifier.error('toast.exporting_failed')
        }
      }).finally(() => {
        exportModal.value.modal.close()
      })
    }

    const date = computed(() => {
      if (!loading.value) {
        return DateFormatter.formatDate(form.value.transportDate)
      }
      return ''
    })

    const updateDate = computed(() => {
      if (!loading.value) {
        return DateFormatter.formatDateTime(form.value.lastUpdate)
      }
      return ''
    })

    const shareModal = ref('shareModal')
    const showShare  = () => {
      shareModal.value.modal.open()
    }
    const share      = async (shareWith) => {
      await backend.post('api/documents/batch', {
        'action': 'SHARE_DRIVER',
        'ids':    [props.id],
        'params': shareWith,
      }).then((result) => {
        if (result.status === 200) {
          notifier.success('toast.sharing_successful')
        } else {
          notifier.error('toast.sharing_failed')
        }
      }).finally(() => {
        shareModal.value.modal.close()
        loadForm()
      })
    }

    const sharedWithModal = ref('sharedWithModal')
    const showSharedWith  = () => {
      sharedWithModal.value.modal.open()
    }

    const deleteModal  = ref('deleteModal')
    const showDelete   = () => {
      deleteModal.value.modal.open()
    }
    const deleteAction = async () => {
      await backend.delete(`api/documents/${props.id}`).then((result) => {
        if (result.status === 200) {
          notifier.success('toast.deleting_successful')
          setTimeout(() => {
            router.push(`/waste-identification/list`)
          }, 2000)
        } else {
          notifier.error('toast.deleting_failed')
        }
      }).finally(() => {
        deleteModal.value.modal.close()
      })
    }

    const cancelModal = ref('cancelModal')
    const showCancel  = () => {
      cancelModal.value.modal.open()
    }
    const cancel      = async (reason) => {
      await backend.put(`api/documents/${props.id}/cancel`, {
        'reason':   reason,
        'language': session.getLanguage(),
      }).then((result) => {
        if (result.status === 200) {
          notifier.success('toast.cancel_successful')
        } else {
          notifier.error('toast.cancel_failed')
        }
      }).finally(() => {
        cancelModal.value.modal.close()
        loadForm()
      })
    }

    const setStateEmptyModal = ref('setStateEmptyModal')
    const showSetStateEmpty  = () => {
      setStateEmptyModal.value.modal.open()
    }
    const setStateEmpty      = async () => {
      await backend.put(`api/documents/${props.id}/empty`).then((result) => {
        if (result.status === 200) {
          notifier.success('toast.update_successful')
        } else {
          notifier.error('toast.update_failed')
        }
      }).finally(() => {
        setStateEmptyModal.value.modal.close()
        loadForm()
      })
    }

    const finishModal = ref('finishModal')
    const showFinish  = () => {
      finishModal.value.modal.open()
    }
    const finish      = async () => {
      await backend.put(`api/documents/${props.id}/finish`).then((result) => {
        if (result.status === 200) {
          notifier.success('toast.finish_successful')
        } else {
          notifier.error('toast.finish_failed')
        }
      }).finally(() => {
        finishModal.value.modal.close()
        loadForm()
      })
    }

    const changeLogModal = ref('changeLogModal')
    const showChangeLog  = () => {
      changeLogModal.value.modal.open()
    }

    const duplicateModal = ref('duplicateModal')
    const showDuplicate  = () => {
      duplicateModal.value.modal.open()
    }
    const duplicate      = async () => {
      loading.value = true
      await backend.post(`api/documents/${props.id}/duplicate`).then((result) => {
        if (result.status === 200) {
          backend.get(`api/documents/${props.id}`).then((doc) => {
            form.value = doc.data
            router.push(`/waste-identification/view/${result.data.id}`)
            notifier.success('toast.duplication_successful')
          })
        } else {
          notifier.error('toast.duplication_failed')
        }
      }).finally(() => {
        duplicateModal.value.modal.close()
        loading.value = false
      })
    }

    const attachmentsModal = ref('attachmentsModal')
    const showAttachments  = () => {
      attachmentsModal.value.modal.open()
    }
    const saveAttachments  = async () => {
      attachmentsModal.value.modal.close()
      loadForm()
    }

    // ********************
    // start and arrive for DRIVER role

    const canStart = computed(() => {
      return roles.hasOneOfRoles([roles.DRIVER, roles.DRIVER_BOSSCHAERT]) && FormState.canStart(form.value.state)
    })

    const canArrive = computed(() => {
      return roles.hasOneOfRoles([roles.DRIVER, roles.DRIVER_BOSSCHAERT]) && FormState.canArrive(form.value.state)
    })

    watch(() => canStart.value, (v) => {
      if (v) {
        geolocation.watchPosition()
      }
    })

    watch(() => canArrive.value, (v) => {
      if (v) {
        geolocation.watchPosition()
      }
    })

    const locating = ref(false)
    geolocation.watchPosition()
    const start = () => {
      locating.value = true
      notifier.notify('toast.getting_location')
      if (!geolocation.getLocation()) {
        setTimeout(() => {
          start()
        }, 3000)
      } else {
        const user         = session.getUser()
        const organisation = session.getOrganisation()
        backend.put(`api/documents/${props.id}/start`, {
          'coordinates':  geolocation.getLocation(),
          'firstName':    user.firstName,
          'lastName':     user.lastName,
          'organisation': organisation.name,
        }).then((r) => {
          notifier.success('toast.start_location_saved')
          form.value = r.data
        }).catch(() => {
          notifier.error('toast.location_failed')
        }).finally(() => {
          locating.value = false
        })
      }
    }

    const steps = {
      arrive:        'arrive',
      control:       'control',
      processorInfo: 'processorInfo',
    }

    const stopping = ref(false)
    const step     = ref(steps.arrive)
    const stop     = () => {
      stopping.value = true
      step.value     = steps.arrive
    }

    const signer        = ref(null)
    const signerInfo    = ref({})
    const signProcessor = () => {
      signer.value     = 'PROCESSOR'
      step.value       = steps.control
      signerInfo.value = {
        'language':     form.value.processorIdentification.language,
        'organisation': form.value.processorIdentification.name,
      }
    }

    // language gets set when signer is chosen, so we change to that person's language
    watch(() => signerInfo.value.language, (l) => {
      $tset(l)
    })

    const signDriver = () => {
      signer.value       = 'DRIVER'
      step.value         = steps.control
      const user         = session.getUser()
      const organisation = session.getOrganisation()
      signerInfo.value   = {
        'firstName':    user.firstName,
        'lastName':     user.lastName,
        'language':     user.language,
        'organisation': organisation.name,
      }
    }

    const backToControl = () => {
      $tset(session.getLanguage())
      step.value = steps.control
    }

    const signing = ref(false)
    const goSign  = () => {
      if (signer.value === 'PROCESSOR') {
        step.value = steps.processorinfo
      } else {
        sign()
      }
    }

    const sign = (checkForm = false) => {
      signing.value = true
      if (checkForm) {
        const formElement = $('#processor-form')[0]
        if (!formElement.checkValidity()) {
          // something in the form is not valid so don't allow signing
          formElement.reportValidity()
          signing.value = false
          return false
        }
      }
      performSign()
    }

    const performSign = () => {
      locating.value = true
      notifier.notify('toast.getting_location')
      if (!geolocation.getLocation()) {
        setTimeout(() => {
          performSign()
        }, 3000)
      } else {
        locating.value   = false
        const signingUrl = `api/documents/${props.id}/stop`
        backend.put(signingUrl, {
          'coordinates':  geolocation.getLocation(),
          'firstName':    signerInfo.value.firstName,
          'lastName':     signerInfo.value.lastName,
          'organisation': signerInfo.value.organisation,
        }).then((r) => {
          notifier.success('toast.form_signed')
          stopping.value = false
          form.value     = r.data
          $tset(session.getLanguage())
        }).catch((err) => {
          notifier.error(err.response.data.messageKey ?? 'toast.form_sign_failed')
        }).finally(() => {
          signing.value = false
        })
      }
    }

    const clickSignOrganiserBtn = () => {
      signing.value = true
      document.getElementById('signOrganiserBtn').click()
      setTimeout(() => {
        signing.value = false
      }, 4000)
    }

    const controlUrl     = ref('')
    const controlQRModal = ref('controlQRModal')
    const showControl    = () => {
      backend.post('api/sharedlinks', {
        documentId:    props.id,
        shareLinkType: ShareLinkType.CONTROL,
        documentType:  DocumentType.WASTE_IDENTIFICATION,
      }).then((result) => {
        controlUrl.value = `${window.location.hostname === 'localhost'
                              ? window.location.hostname + ':' + 8082
                              : window.location.hostname}/#/shared-links/${result.data.id}`
      })
      controlQRModal.value.modal.open()
    }

    const control = async (email) => {
      await backend.put(`api/documents/${props.id}/share`, {
        email: email,
      }).then((result) => {
        if (result.status === 200) {
          notifier.success('toast.sharing_successful')
        } else {
          notifier.error('toast.sharing_failed')
        }
      }).finally(() => {
        controlQRModal.value.modal.close()
      })
    }

    const refuseModal = ref('refuseModal')
    const showRefuse  = () => {
      refuseModal.value.modal.open()
    }
    const refusing    = ref(false)
    const refuse      = (reason) => {
      refuseModal.value.modal.close()
      refusing.value = true
      stopping.value = false
      notifier.notify('toast.refusing_transport')
      backend.put(`api/documents/${props.id}/refuse2`, {reason: reason}).then((r) => {
        notifier.success('toast.transport_refused')
        form.value = r.data
      }).finally(() => {
        refusing.value = false
      })
    }

    const signed = () => {
      $tset(session.getLanguage())
      stopping.value = false
      signing.value  = false
      loadForm()
    }

    const editWeightsModal     = ref('editWeightsModal')
    const showEditWeightsModal = () => {
      editWeightsModal.value.modal.open()
    }

    const saving         = ref(false)
    const saveWeightEdit = async () => {
      saving.value = true
      const result = await backend.put(`api/documents/${props.id}`, form.value)
      saving.value = false
      if (result.status === 200) {
        notifier.success('toast.successfully_saved')
        loadForm()
        return true
      } else {
        notifier.error('toast.save_failed')
        return false
      }
    }

    const demo = computed(() => {
      return form.value.demo
    })

    return {
      breadcrumbs,
      loading,
      router,
      date,
      updateDate,
      edit,
      exportPdf,
      shareModal,
      showShare,
      share,
      sharedWithModal,
      showSharedWith,
      cancelModal,
      showCancel,
      cancel,
      deleteModal,
      showDelete,
      deleteAction,
      finishModal,
      showFinish,
      finish,
      loadForm,
      form,
      canSign,
      showActions,
      FormState,
      showChangeLog,
      changeLogModal,
      setStateEmptyModal,
      showSetStateEmpty,
      setStateEmpty,
      duplicateModal,
      showDuplicate,
      duplicate,
      exportModal,
      showExport,
      exportForm,
      canStart,
      canArrive,
      backToControl,
      start,
      stop,
      stopping,
      locating,
      controlQRModal,
      showControl,
      control,
      controlUrl,
      step,
      steps,
      refuseModal,
      showRefuse,
      refuse,
      refusing,
      signProcessor,
      signDriver,
      signerInfo,
      signing,
      goSign,
      sign,
      clickSignOrganiserBtn,
      signed,
      saveWeightEdit,
      editWeightsModal,
      showEditWeightsModal,
      saving,
      attachmentsModal,
      showAttachments,
      saveAttachments,
      demo,
    }
  },
}
</script>

<template>
  <div class="container-fluid">
    <template v-if="step==='information'">
      <form id="processor-form" class="row g-2 justify-content-center">
        <ui-view-card :title="$t('shared.processor_information')" :key="language">
          <template #actions>
            <button type="button" class="btn btn-sm btn-outline-secondary" @click="$emit('back')">{{ $t('general.back') }}</button>
          </template>
          <ui-view-card-language v-model="language" :required="true" :own-language="true" />
          <template v-if="language">
            <ui-view-card-field data-cy="processor-first-name" :label="$t('user.labels.first_name')" v-model="processor.firstName" :required="true" />
            <ui-view-card-field data-cy="processor-last-name" :label="$t('user.labels.last_name')" v-model="processor.lastName" :required="true" />
            <ui-view-card-field data-cy="processor-organisation" :label="$t('user.labels.organisation')" v-model="processor.organisation" :required="true" />
            <br />
            <br />
            <div class="row g-0 justify-content-center">
              <button id="processorInformationConfirmBtn" type="button" class="btn" :class="'btn-outline-primary'" @click="next()" form="processor-form"><i class="bi-check" />&nbsp;{{$t('shared.confirm')}}</button>
            </div>
          </template>
        </ui-view-card>
      </form>
    </template>
    <template v-else>
      <waste-identification-form-view :form="form" :processor-info="processor" @signed="$emit('signed')" :uuid="uuid" :sharelink="sharelink" />
    </template>
  </div>
</template>

<script>

import UiViewCard from '@/components/ui-view-card'
import {inject, ref, watch} from 'vue'
import UiViewCardField from '@/components/ui-view-card-field'
import WasteIdentificationFormView from '@/partials/waste-identification/FormView'
import Notifier from '@/util/notifier'
import UiViewCardLanguage from '@/components/ui-view-card-language'

export default {
  name: 'processorSignature',
  components: {
    UiViewCardLanguage,
    WasteIdentificationFormView,
    UiViewCardField,
    UiViewCard
  },
  props: {
    id: {
      type:     String,
      required: true,
    },
    uuid: {
      type:    String,
      default: '',
    },
    form: {
      type:     Object,
      required: true,
    },
    sharelink: {
      type:     Boolean,
      required: true,
    },
    signerInfo: {
      type:    Object,
      default: () => {},
    }
  },
  emits: [ 'signed', 'processorSet' ],
  setup: (props, {emit}) => {
    const $tset = inject('$tset')

    const language = ref(props.signerInfo.language ?? props.form.processorIdentification.language)
    if (language.value) {
      $tset(language.value)
    }

    watch(() => language.value, (l) => {
      $tset(l)
    })

    const processor = ref(Object.assign({}, props.signerInfo))
    const step = ref('information')
    const notifier = Notifier()

    const next = () => {
      const formElement = $('#processor-form')[0]
      if (!formElement.checkValidity()) {
        // something in the form is not valid so don't allow signing
        formElement.reportValidity()
        return false
      }

      notifier.success('toast.processor_confirmed')
      step.value = 'sign'
      emit('processorSet')
    }

    return {
      processor,
      step,
      next,
      language,
    }
  }
}
</script>

<template>
  <nav class="d-none d-lg-block pe-5" aria-label="breadcrumb" style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);">
    <ol class="breadcrumb">
      <template v-for="(val, index) in breadcrumbsRef">
        <template v-if="index !== breadcrumbsRef.length - 1">
          <li :key="index" class="breadcrumb-item"><a :href="val.url">{{ $t(val.name) }}</a></li>
        </template>
        <template v-else>
          <li :key="index" class="breadcrumb-item active" aria-current="page">{{ $t(val.name) }}</li>
        </template>
      </template>
    </ol>
  </nav>
</template>

<script>

import {ref} from 'vue'

export default {
  name: 'ui-breadcrumbs',
  props: {
    breadcrumbs: {
      type:     Array,
      required: true
    }
  },
  setup: (props) => {
    // don't show home since we only have 1 type of form we can go straight to the waste-identification list
    const breadcrumbsRef = ref(props.breadcrumbs.filter((br) => {
      return br.name !== 'breadcrumbs.home'
    }))

    return {
      breadcrumbsRef
    }
  }
}
</script>
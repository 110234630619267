<template>
  <h6 class="card-subtitle text-muted">
    <slot name="subtitle" />
  </h6>
  <div class="row">
    <div class="col-lg-6">
      <Popper arrow class="light" @close="closePopper" :show="showPopper && suggestions.length > 0" v-click-outside="closePopper">
        <ui-view-card-field data-cy="organiser-name" :label="$t('form.labels.name')" v-model="model.name" />
        <template #content>
          <div v-for="suggestion in suggestions" :key="suggestion.name" class="popper-suggestion" >
            <div @click="useSuggestion(suggestion)">
          <span v-if="suggestion.address && suggestion.address.street && suggestion.address.number" >
            {{suggestion.name}}: {{suggestion.address.street}} {{suggestion.address.number}}
          </span>
              <span v-else>{{suggestion.name}}</span>
            </div>
          </div>
        </template>
      </Popper>
    </div>
    <div class="col-lg-6">
      <ui-view-card-field v-model="model.externalId" :label="$t('form.labels.external_identifier')" :maxlength="50" />
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6">
      <ui-view-card-field data-cy="organiser-phone'" v-model="model.phone" :label="$t('form.labels.phone_number')" />
    </div>
  </div>
  <ui-view-card-address cy="organiser" :edit="true" v-model="model.address" />
  <ui-view-card-language cy="organiser-language" :label="$t('form.labels.language')" v-model="model.language" />
  <ui-view-card-ids v-model="model.identifications" cy="organiser" />
</template>

<script>

import UiViewCardField from '@/components/ui-view-card-field'
import UiViewCardAddress from '@/components/ui-view-card-address'
import {ref, watch} from 'vue'
import {useModelWrapper} from '@/util/modelWrapper'
import {debounce} from '@/util/debounce'
import backend from '@/util/backend'
import UiViewCardIds from '@/components/ui-view-card-ids'
import clearField from '@/services/clearfield'
import UiViewCardLanguage from '@/components/ui-view-card-language'

export default {
  name: 'ui-view-card-organiser',
  components: {UiViewCardLanguage, UiViewCardIds, UiViewCardAddress, UiViewCardField},
  props: {
    modelValue: {
      default: '',
    },
  },
  setup: (props, {emit}) => {
    const model = ref(useModelWrapper(props, emit))

    if (!model.value.address) {
      model.value.address = {}
    }

    const suggestions = ref([])
    const hidePopup = ref(false)
    const showPopper = ref(true)
    watch(() => model.value.name, debounce(async (newVal) => {
      if (newVal && !hidePopup.value) {
        showPopper.value = true
        const result = await backend.get(`/api/contacts/suggest?searchTerm=${encodeURIComponent(newVal)}`)
        if (result.status === 200 && result.data.length > 0) {
          suggestions.value = result.data.slice(0, 5)
        } else {
          showPopper.value = false
        }
      }
      hidePopup.value = false
    }, 400))

    setTimeout(() => {
      hidePopup.value = false // hide popup on open, then enable after half a second
    }, 500)

    const closePopper = () => {
      showPopper.value = false
    }

    const useSuggestion = (suggestion) => {
      const cleared = clearField(suggestion, 'id')
      if (cleared.identifications.length === 0) {
        cleared.identifications.push({
          value: null,
          identificationType: null
        })
      }
      Object.assign(model.value, cleared)
      hidePopup.value = true
      closePopper()
    }

    return {
      model,
      suggestions,
      showPopper,
      closePopper,
      useSuggestion,
    }
  }
}
</script>

<template>
  <layout>
    <template #header>
      <ui-header :title="$t('matis.list.matis')" back-route="/matis/list">
        <template #actions>
          <button v-if="!upload.matisReferenceId" type="button" class="btn btn-outline-primary" @click="send()"><i
              class="bi-cloud-upload"/>&nbsp;{{ $t('matis.list.actions.upload') }}
          </button>
        </template>
      </ui-header>
    </template>
    <div class="d-flex justify-content-between">
      <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    </div>
    <ui-content>
      <spinner v-if="loading"/>
      <template v-if="!loading">
        <div class="container-fluid">
          <div class="row">
            <ui-view-card :title="$t('matis.detail.matis_result')" :type="'col-lg-12'">
              <div v-if="errors.length > 0 && errors[0].length > 0">
                {{ $t('matis.detail.reported_errors') }}
                <div v-for="(error, index) in errors" :key="index">
                  -&nbsp;{{ error.trim() }}
                </div>
              </div>
              <div v-if="upload.matisReferenceId">
                <i class="bi-check fs-2 text-success"/>
                {{ $t('matis.detail.successful_upload', {reference: upload.matisReferenceId}) }}
              </div>
            </ui-view-card>
            <ui-view-card :title="$t('matis.detail.data.basic_information')">
              <div class="row">
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('matis.detail.data.identification')" v-model="json.identificatie"
                                      :readonly="true"/>
                </div>
                <div class="col-lg-6">
                  <ui-view-card-field v-model="upload.externalDocumentId"
                                      :label="$t('matis.detail.data.external_document_id')" :readonly="true"/>
                </div>
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('matis.detail.data.collection_method')" v-model="json.inzamelwijze"/>
                </div>
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('matis.detail.data.transport_method')">
                    <v-select :options="meansOfTransport.getForMatis()" v-model="json.vervoerswijze"
                              :reduce="operation => operation.value">
                      <template #search="{attributes, events}">
                        <input
                            class="vs__search"
                            v-bind="attributes"
                            v-on="events"
                        />
                      </template>
                      <template #no-options>
                        {{ $t('general.no_options') }}
                      </template>
                    </v-select>
                  </ui-view-card-field>
                </div>
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('matis.detail.data.tonnage')" v-model="json.tonnage"/>
                </div>
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('matis.detail.data.organiser_vat')"
                                      v-model="json.regelingVerwerkingBtwNummer"/>
                </div>
              </div>
            </ui-view-card>
            <ui-view-card :title="$t('matis.detail.data.period')">
              <div class="row">
                <div class="col-lg-6">
                  <ui-view-card-field v-model="json.periode.waarde" :label="$t('matis.detail.data.value')"/>
                </div>
                <div class="col-lg-6">
                  <ui-view-card-field v-model="json.periode.eenheid" :label="$t('matis.detail.data.unit')"
                                      :readonly="true"/>
                </div>
              </div>
            </ui-view-card>
            <ui-view-card :title="$t('matis.detail.data.origin')">
              <div class="row">
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('matis.detail.data.name')" v-model="json.oorsprong.naam"/>
                </div>
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('matis.detail.data.type')">
                    <v-select v-if="matisLocationTypesLoaded" :options="matisLocationTypes"
                              v-model="json.oorsprong.type" :reduce="type => type.value">
                      <template #search="{attributes, events}">
                        <input
                            class="vs__search"
                            :required="!json.oorsprong.type"
                            v-bind="attributes"
                            v-on="events"
                        />
                      </template>
                      <template #no-options>
                        {{ $t('general.no_options') }}
                      </template>
                    </v-select>
                  </ui-view-card-field>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('matis.detail.data.company_number')"
                                      v-model="json.oorsprong.ondernemingsnummer"/>
                </div>
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('matis.detail.data.branch_number')"
                                      v-model="json.oorsprong.vestigingsnummer"/>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('matis.detail.data.vat_number')" v-model="json.oorsprong.btwNummer"/>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('address.street')" v-model="json.oorsprong.straat"/>
                </div>
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('address.number')" v-model="json.oorsprong.huisnummer"/>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('address.zip')" v-model="json.oorsprong.postcode"/>
                </div>
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('address.city')" v-model="json.oorsprong.gemeente"/>
                </div>
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('address.country')">
                    <v-select :options="countries" v-model="json.oorsprong.land" :reduce="country => country.code">
                      <template #search="{attributes, events}">
                        <input
                          class="vs__search"
                          v-bind="attributes"
                          v-on="events"
                        />
                      </template>
                      <template #no-options>
                        {{ $t('general.no_options') }}
                      </template>
                    </v-select>
                  </ui-view-card-field>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('matis.detail.data.ship_number')"
                                      v-model="json.oorsprong.scheepsnummer"/>
                </div>
              </div>
            </ui-view-card>
            <ui-view-card :title="$t('matis.detail.data.destination')">
              <div class="row">
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('matis.detail.data.name')" v-model="json.bestemming.naam"/>
                </div>
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('matis.detail.data.type')">
                    <v-select v-if="matisLocationTypesLoaded" :options="matisLocationTypes"
                              v-model="json.bestemming.type" :reduce="type => type.value">
                      <template #search="{attributes, events}">
                        <input
                          class="vs__search"
                          :required="!json.bestemming.type"
                          v-bind="attributes"
                          v-on="events"
                        />
                      </template>
                      <template #no-options>
                        {{ $t('general.no_options') }}
                      </template>
                    </v-select>
                  </ui-view-card-field>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('matis.detail.data.company_number')"
                                      v-model="json.bestemming.ondernemingsnummer"/>
                </div>
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('matis.detail.data.branch_number')"
                                      v-model="json.bestemming.vestigingsnummer"/>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('matis.detail.data.vat_number')" v-model="json.bestemming.btwNummer"/>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('address.street')" v-model="json.bestemming.straat"/>
                </div>
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('address.number')" v-model="json.bestemming.huisnummer"/>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('address.zip')" v-model="json.bestemming.postcode"/>
                </div>
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('address.city')" v-model="json.bestemming.gemeente"/>
                </div>
                <div class="col-lg-6">
                  <ui-view-card-field v-model="json.bestemming.land" :label="$t('address.country')">
                    <v-select :options="countries" v-model="json.bestemming.land" :reduce="country => country.code">
                      <template #search="{attributes, events}">
                        <input
                            class="vs__search"
                            v-bind="attributes"
                            v-on="events"
                        />
                      </template>
                      <template #no-options>
                        {{ $t('general.no_options') }}
                      </template>
                    </v-select>
                  </ui-view-card-field>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('matis.detail.data.ship_number')"
                                      v-model="json.bestemming.scheepsnummer"/>
                </div>
              </div>
            </ui-view-card>
            <ui-view-card :title="$t('matis.detail.data.material')">
              <div class="row">
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('matis.detail.data.description')"
                                      v-model="json.materiaal.omschrijving"/>
                </div>
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('matis.detail.data.EURAL')" v-if="euralCodesLoaded">
                    <v-select :options="euralCodes" v-model="json.materiaal.euralcode" :reduce="code => code.value" :filter="EuralCodes.ignoreSpaceSearch">
                      <template #search="{attributes, events}">
                        <input
                            class="vs__search"
                            v-bind="attributes"
                            v-on="events"
                        />
                      </template>
                      <template #no-options>
                        {{ $t('general.no_options') }}
                      </template>
                    </v-select>
                  </ui-view-card-field>
                </div>
              </div>
            </ui-view-card>
            <ui-view-card :title="$t('matis.detail.data.processing')">
              <div class="row">
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('waste_identification_template.form.processing_technique')" >
                    <v-select :key="processingTechniquesLoaded" :options="processingTechniques" v-model="json.verwerking.wijze" :reduce="operation => operation.value">
                      <template #search="{attributes, events}">
                        <input
                            class="vs__search"
                            v-bind="attributes"
                            v-on="events"
                        />
                      </template>
                      <template #no-options>
                        {{ $t('general.no_options') }}
                      </template>
                    </v-select>
                  </ui-view-card-field>
                </div>
                <div class="col-lg-6">
                  <ui-view-card-field :label="$t('waste_identification_template.form.removal_operation')">
                    <v-select :key="removalOperationsLoaded" :options="removalOperations" v-model="json.verwerking.rdCode" :reduce="operation => operation.value">
                      <template #search="{attributes, events}">
                        <input
                            class="vs__search"
                            v-bind="attributes"
                            v-on="events"
                        />
                      </template>
                      <template #no-options>
                        {{ $t('general.no_options') }}
                      </template>
                    </v-select>
                  </ui-view-card-field>
                </div>
              </div>
            </ui-view-card>
          </div>
        </div>
      </template>
    </ui-content>
  </layout>
</template>

<script>

import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import UiHeader from '@/components/ui-header'
import Layout from '@/components/layout'
import UiContent from '@/components/ui-content'
import Spinner from '@/components/spinner'
import BREADCRUMBS from '@/util/breadcrumbs'
import {ref} from 'vue'
import backend from '@/util/backend'
import Notifier from '@/util/notifier'
import UiViewCard from '@/components/ui-view-card'
import UiViewCardField from '@/components/ui-view-card-field'
import MatisLocationTypes from '@/models/MatisLocationTypes'
import CountryCodes from '@/models/CountryCodes'
import meansOfTransport from '@/types/meansOfTransport'
import DocumentType from '@/types/documentType'
import RemovalOperations from '@/models/RemovalOperations'
import ProcessingTechniques from '@/models/ProcessingTechniques'
import EuralCodes from '@/models/EuralCodes'

export default {
  name: 'MatisDetail',
  computed: {
    EuralCodes() {
      return EuralCodes
    },
    DocumentType() {
      return DocumentType
    },
    meansOfTransport() {
      return meansOfTransport
    }
  },
  components: {
    UiViewCardField,
    UiViewCard,
    Spinner,
    UiContent,
    Layout,
    UiHeader,
    UiBreadcrumbs,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup: (props) => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.MATIS, BREADCRUMBS.VIEW]
    const loading = ref(true)
    const notifier = Notifier()
    const upload = ref({})
    const json = ref({})
    const errors = ref([])

    const loadItem = () => {
      loading.value = true
      const uploadUrl = `api/matisuploads/${props.id}`
      const jsonUrl = `api/matisuploads/${props.id}/json`

      const promises = []
      promises.push(backend.get(uploadUrl))
      promises.push(backend.get(jsonUrl))

      Promise.all(promises).then((res) => {
        upload.value = res[0].data
        errors.value = upload.value.matisErrors.split(';;;')

        json.value = res[1].data[0]
        loading.value = false
      })
    }
    loadItem()

    const send = () => {
      backend.post(`api/matisuploads/${props.id}/retry`, [json.value]).then((res) => {
        if (res.data[0].matisReferenceId) {
          notifier.success('toast.matis_upload_success')
        } else {
          notifier.error('toast.matis_upload_failed')
        }
      }).catch(() => {
        notifier.error('toast.matis_upload_failed')
      }).finally(() => {
        loadItem()
      })
    }

    const matisLocationTypes = ref([])
    const matisLocationTypesLoaded = ref(false)
    const getMatisLocationTypes = async () => {
      matisLocationTypes.value = await MatisLocationTypes.get()
      matisLocationTypesLoaded.value = true
    }
    getMatisLocationTypes()

    const removalOperations = ref([])
    const removalOperationsLoaded = ref(false)
    const getRemovalOperations = async () => {
      removalOperations.value = await RemovalOperations.get()
      removalOperationsLoaded.value = true
    }
    getRemovalOperations()

    const processingTechniques = ref([])
    const processingTechniquesLoaded = ref(false)
    const getProcessingTechniques = async () => {
      processingTechniques.value = await ProcessingTechniques.get()
      processingTechniquesLoaded.value = true
    }
    getProcessingTechniques()

    const euralCodes = ref([])
    const euralCodesLoaded = ref(false)
    const getEuralCodes = async () => {
      euralCodes.value = await EuralCodes.get()
      euralCodesLoaded.value = true
    }
    getEuralCodes()

    const countries = []
    countries.push(...CountryCodes.top)
    countries.push(...CountryCodes.rest)

    return {
      breadcrumbs,
      loading,
      upload,
      errors,
      json,
      countries,
      matisLocationTypes,
      matisLocationTypesLoaded,
      removalOperations,
      removalOperationsLoaded,
      processingTechniques,
      processingTechniquesLoaded,
      euralCodes,
      euralCodesLoaded,
      send,
    }
  }
}
</script>

<template>
  <div class="toast-container" :style="style">
    <div
        class="toast border-1"
        :class="{
          'show': message.shown,
          'bg-secondary': message.type === 'message',
          'bg-success': message.type === 'success',
          'bg-warning': message.type === 'warning',
          'bg-danger': message.type === 'error',
        }"
        v-for="(message, index) in Array.from(messages.values()).reverse()"
        :key="index"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
    >
      <div class="toast-header">
        <strong class="me-auto" />
        <small class="text-muted">{{ $t('toast.just_now') }}</small>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div data-cy="toast-message" class="toast-body h4" v-html="$t(message.msg, message.options)" />
    </div>
  </div>
</template>

<script>
import {onMounted, onUpdated, reactive, ref, watch} from 'vue'
import Notifier from '@/util/notifier'
import { Toast } from 'bootstrap'

export default {
  name: 'Toast',
  props: {
  },
  setup: () => {
    const notifier = Notifier()
    let messages = reactive(new Map())

    Array.from(document.querySelectorAll('.toast'))
        .forEach(toastNode => new Toast(toastNode))

    const showMessage = (msg, options, type = 'message') => {
      if (msg) {
        const key = Date.now()
        const multiplier = msg.indexOf('toast.error') > -1 ? 3 : 1
        messages.set(key, {msg, shown: false, options, type})
        setTimeout(() => {
          messages.set(key, {msg, shown: true, options, type})
        }, 300)
        setTimeout(() => {
          messages.set(key, {msg, shown: false, options, type})
        }, 3300 * multiplier)
        setTimeout(() => {
          messages.delete(key)
        }, 3600 * multiplier)
      }
    }

    onMounted(() => {
      if (notifier && notifier.message.msg) {
        showMessage(notifier.message.msg, notifier.message.options, notifier.message.type)
        notifier.reset()
      }
    })

    onUpdated(() => {
      if (notifier && notifier.message.msg) {
        showMessage(notifier.message.msg, notifier.message.options, notifier.message.type)
        notifier.reset()
      }
    })

    if (notifier) {
      watch(() => notifier.message.msg, () => {
        showMessage(notifier.message.msg, notifier.message.options, notifier.message.type)
        notifier.reset()
      })
    }

    const bigStyle = "position: absolute; z-index:9999;"
    const smallStyle = "z-index: 9999; position: fixed; max-width: 85%; bottom: 25px;"
    const style = ref(bigStyle)

    function setStyle() {
      if (window.matchMedia("(max-width: 768px)").matches) {
        style.value = smallStyle
      } else {
        style.value = bigStyle
      }
    }
    window.addEventListener("resize", setStyle);

    return {
      style,
      messages
    }
  }
}
</script>

<template>
  <layout>
    <template #header>
      <ui-header :title="$t('sys_admin.org_list.title')">
      </ui-header>
    </template>
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
      </div>
      <div>
        <button class="btn btn-sm btn-outline-secondary" @click="resetGrid()">{{ $t('general.reset_filters') }}</button>
      </div>
    </div>
    <ui-content>
      <ag-grid-vue
          :serverSideDatasource="grid.datasource('api/admin/organisations/list')"
          :grid-options="grid.defaultOptions"
          :columnDefs="columnDefs"
          class="ag-theme-quartz"
          style="min-height:100%"
          @row-clicked="rowClick"
          @selection-changed="refreshSelection"
          @grid-ready="onGridReady"
          @grid-pre-destroyed="onGridPreDestroyed"
          @filter-changed="onFilterChanged"
          :initialState="initialState"
      />
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import UiContent from '@/components/ui-content'
import {inject, onBeforeUnmount, ref} from 'vue'
import {useRouter} from 'vue-router'
import {AgGridVue} from 'ag-grid-vue3'
import ContractState from '@/types/contractState'
import grid from '@/util/grid'
import checkboxRenderer from '@/util/checkboxRenderer'
import session from '@/util/session'
import OrganisationState from '@/types/organisationState'
import OrganisationType from '@/types/organisationType'

export default {
  name:       'Organisation-List',
  computed:   {
    grid() {
      return grid
    },
  },
  components: {
    AgGridVue,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout,
    // eslint-disable-next-line
    checkboxRenderer,
  },
  setup:      () => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.SYSORGANISATIONS]
    const $t          = inject('$t')

    const gridKey            = 'sys-admin-org-list'
    const initialState       = ref(session.loadFormState(gridKey))
    const onGridPreDestroyed = (params) => {
      session.saveFormState(gridKey, params.state)
    }
    const onFilterChanged    = (event) => {
      session.saveFormState(gridKey, event.api.getState())
    }

    let api           = null
    const onGridReady = (params) => {
      api = params.api
      api.deselectAll() // if we can correctly get selection from saved state we can re-evaluate this
      api.sizeColumnsToFit({'defaultMinWidth': 200})
    }
    onBeforeUnmount(() => {
      api = null
    })

    const resetGrid = () => {
      api?.setFilterModel(null)
      api?.onFilterChanged()
      session.saveFormState(gridKey, null)
    }

    const columnDefs = [
      {headerName: $t('sys_admin.org_list.table.name'), field: 'name', checkboxSelection: false},
      {headerName: $t('sys_admin.org_list.table.email'), field: 'contactEmail', checkboxSelection: false},
      {
        headerName:        $t('sys_admin.org_list.table.state'),
        field:             'organisationState',
        filter:            'agSetColumnFilter',
        filterParams:      {
          values:         OrganisationState.getAll(),
          keyCreator:     (val) => {
            return val.value
          },
          valueFormatter: (val) => {
            return val.value
          },
        },
        checkboxSelection: false,
      },
      {
        headerName:        $t('sys_admin.org_list.table.type'),
        field:             'organisationType',
        filter:            'agSetColumnFilter',
        filterParams:      {
          values:         OrganisationType.getAll(),
          keyCreator:     (val) => {
            return val.value
          },
          valueFormatter: (val) => {
            return val.value
          },
        },
        checkboxSelection: false,
      },
      {headerName: $t('sys_admin.org_list.table.support_credits'), field: 'supportCredits'},
      {
        headerName:        $t('sys_admin.org_list.table.credits'),
        field:             'credits',
        valueGetter:       (params) => {
          const contract = getActiveContract(params.data.contracts)
          if (contract) {
            return contract.currentCredits + '/' + contract.initialCredits
          }
          return ''
        },
        floatingFilter:    false,
        checkboxSelection: false,
      },
      {
        headerName:        $t('sys_admin.org_list.table.overdraft'),
        field:             'overdraft',
        valueGetter:       (params) => {
          const contract = getActiveContract(params.data.contracts)
          if (contract) {
            return contract.overdraft
          }
          return ''
        },
        floatingFilter:    false,
        checkboxSelection: false,
      },
      {
        headerName:         $t('sys_admin.org_list.table.auto_renew'),
        field:              'autoRenew',
        cellRenderer:       'checkboxRenderer',
        cellRendererParams: {
          callback: (data) => {
            return data.autoRenewContract
          },
        },
        filter:             'agSetColumnFilter',
        filterParams:       {
          values:         [{label: 'YES', value: true}, {label: 'NO', value: false}],
          keyCreator:     (val) => {
            return val.value.value
          },
          valueFormatter: (val) => {
            return val.value.label
          },
        },
        checkboxSelection:  false,
      },
    ]

    const getActiveContract = (contracts) => {
      return contracts.reverse().filter((c) => {
        return [
          ContractState.ACTIVE,
          ContractState.LOW_CREDITS,
          ContractState.IN_OVERDRAFT,
        ].indexOf(c.contractState) > -1
      })[0]
    }

    const router           = useRouter()
    const rowClick         = (event) => {
      if (event.data) {
        router.push(`/admin/organisations/${event.data.id}`)
      }
    }
    const selection        = ref([])
    const refreshSelection = (params) => {
      selection.value = params.api.getSelectedRows()
    }

    const rowClassRules = {
      'row-orange': function (params) {
        return getActiveContract(params.data.contracts)?.contractState === ContractState.LOW_CREDITS
      },
      'row-red':    function (params) {
        return getActiveContract(params.data.contracts)?.contractState === ContractState.IN_OVERDRAFT
      },
    }

    return {
      breadcrumbs,
      onGridReady,
      onGridPreDestroyed,
      onFilterChanged,
      initialState,
      columnDefs,
      rowClick,
      refreshSelection,
      rowClassRules,
      resetGrid,
    }
  },
}
</script>

<template>
  <annex7-view-user :id="id" />
</template>

<script>

import Annex7ViewUser from '@/views/forms/annex-7/View-User.vue'

export default {
  name: 'Annex7View',
  components: {
    Annex7ViewUser,
  },
  props: {
    id: {
      type: String,
      required: true,
    }
  },
  setup: () => {
    return {}
  }
}
</script>

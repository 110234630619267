<template>
  <v-select data-cy="language" :options="ownLanguage ? languagesSelf : languages" v-model="itemRef" :reduce="language => language.value">
    <template #search="{attributes, events}">
      <input
        class="vs__search"
        :required="requiresInput"
        v-bind="attributes"
        v-on="events"
      />
    </template>
    <template #no-options>
      {{ $t('general.no_options') }}
    </template>
  </v-select>
</template>

<script>

import {useModelWrapper} from '@/util/modelWrapper'
import {computed, inject, ref} from 'vue'

export default {
  name: 'language-selector',
  props: {
    modelValue: {
      default: '',
    },
    required: {
      type:    Boolean,
      default: false,
    },
    ownLanguage: {
      type:    Boolean,
      default: false,
    }
  },
  setup: (props, {emit}) => {
    const $t = inject('$t')
    const $tget = inject('$tget')
    const itemRef = useModelWrapper(props, emit)

    // normalize to upper case
    if (itemRef.value) {
      itemRef.value = itemRef.value.toUpperCase()
    }

    const requiresInput = computed(() => {
      return props.required && !itemRef.value;
    })

    const languages = ref([
      { label: $t('languages.dutch'), value: 'NL' },
      { label: $t('languages.english'), value: 'EN' },
      { label: $t('languages.french'), value: 'FR' },
      { label: $t('languages.german'), value: 'DE' },
    ])

    const languagesSelf = ref([
      { label: $tget('NL')('languages.dutch'), value: 'NL' },
      { label: $tget('EN')('languages.english'), value: 'EN' },
      { label: $tget('FR')('languages.french'), value: 'FR' },
      { label: $tget('DE')('languages.german'), value: 'DE' },
    ])

    return {
      itemRef,
      requiresInput,
      languages,
      languagesSelf,
    }
  }
}
</script>
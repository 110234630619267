<template>
  <div class="pb-1">
    <label class="pe-2">
      {{ $t('user.labels.language') }}<span v-if="required" :title="$t('general.required')" class="text-danger">*</span><i v-if="icon" style="font-size: 0.75rem; position: absolute" :class="icon" :title="iconText" />
    </label>
    <language-selector v-model="itemRef" :own-language="ownLanguage" :required="required" />
  </div>
</template>

<script>

import LanguageSelector from '@/components/LanguageSelector'
import {useModelWrapper} from '@/util/modelWrapper'

export default {
  name: 'ui-view-card-language',
  components: {LanguageSelector},
  props: {
    modelValue: {
      default: '',
    },
    required: {
      type:    Boolean,
      default: false,
    },
    icon: {
      type:    String,
      default: ''
    },
    iconText: {
      type:    String,
      default: ''
    },
    ownLanguage: {
      type:    Boolean,
      default: false,
    }
  },
  setup: (props, {emit}) => {
    const itemRef = useModelWrapper(props, emit)

    return {
      itemRef,
    }
  }
}
</script>

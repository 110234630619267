<template>
  <layout>
    <template #header>
      <ui-header :title="$t('organisations.title')">
        <template #actions>
          <div class="btn-group">
            <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="actionsDropdown" data-bs-toggle="dropdown" aria-expanded="false" :disabled="selectedIDs.length === 0">
              {{$t('general.actions')}}
            </button>
            <ul data-cy="organisations-actions" class="dropdown-menu" aria-labelledby="actionsDropdown">
              <li>
                <button :disabled="selectedIDs.length === 0" class="dropdown-item" @click="showRemove()">
                  {{ $t('organisations.list.actions.delete') }}
                </button>
              </li>
            </ul>
          </div>
          <button type="button" class="btn btn-outline-primary" @click="addOrganisation()" id="addOrganisationBtn"><i class="bi-plus" />&nbsp;{{$t('organisations.add_organisation')}}</button>
        </template>
      </ui-header>
    </template>
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
      </div>
      <div>
        <button class="btn btn-sm btn-outline-secondary" @click="resetGrid()">{{ $t('general.reset_filters') }}</button>
      </div>
    </div>
    <ui-content>
      <ag-grid-vue
        :serverSideDatasource="grid.datasource('api/contacts/list')"
        :grid-options="grid.defaultOptions"
        :columnDefs="xsWindow ? xsColumnDefs : columnDefs"
        class="ag-theme-quartz"
        style="min-height:100%"
        @row-clicked="rowClick"
        @selection-changed="refreshSelection"
        @grid-ready="onGridReady"
        @grid-pre-destroyed="onGridPreDestroyed"
        @filter-changed="onFilterChanged"
        :initialState="initialState"
      />
    </ui-content>
  </layout>
  <organisation-delete-modal ref="removeModal" :amount="selectedIDs.length" :callback="remove" />
</template>

<script>

import {inject, onBeforeUnmount, ref} from 'vue'
import backend from '@/util/backend'
import Layout from '@/components/layout'
import {AgGridVue} from 'ag-grid-vue3'
import {useRouter} from 'vue-router'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import UiContent from '@/components/ui-content'
import OrganisationDeleteModal from '@/views/organisations/Delete-Modal'
import grid from '@/util/grid'
import session from '@/util/session'

export default {
  name: 'OrganisationsList',
  computed: {
    grid() {
      return grid
    }
  },
  components: {
    OrganisationDeleteModal,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    AgGridVue,
    Layout,
  },
  setup: () => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.ORGANISATIONS]
    const $t = inject('$t')

    const gridKey = 'organisations-list'
    const initialState = ref(session.loadFormState(gridKey))
    const onGridPreDestroyed = (params) => {
      session.saveFormState(gridKey, params.state)
    }
    const onFilterChanged = (event) => {
      session.saveFormState(gridKey, event.api.getState())
    }

    let api = null
    const onGridReady = (params) => {
      api = params.api
      api.deselectAll() // if we can correctly get selection from saved state we can re-evaluate this
      api.sizeColumnsToFit({'defaultMinWidth': 200})
    }
    onBeforeUnmount(() => {
      api = null
    })
    const reload = () => {
      api?.refreshServerSide()
      api?.deselectAll()
    }

    const resetGrid = () => {
      api?.setFilterModel(null)
      api?.onFilterChanged()
      session.saveFormState(gridKey, null)
    }

    const xsWindow = ref(window.innerWidth < 576)
    const resize = () => {
      xsWindow.value = window.innerWidth < 576
      setTimeout(() => {
        api.sizeColumnsToFit({'defaultMinWidth': 200})
      }, 1)
    }
    window.addEventListener("resize", resize)
    onBeforeUnmount(() => {
      window.removeEventListener("resize", resize)
    })

    const xsColumnDefs = [
      {
        headerName: $t('organisations.name'),
        field: 'name',
      },
    ]

    const columnDefs = [
      {
        headerName: $t('organisations.name'),
        field: 'name',
      },
      { headerName: $t('organisations.email'), field: 'email' },
      { headerName: $t('organisations.external_id'), field: 'externalId' },
      { headerName: $t('address.street'), field: 'address.street' },
      { headerName: $t('address.number'), field: 'address.number' },
      { headerName: $t('address.zip'), field: 'address.zip' },
      { headerName: $t('address.city'), field: 'address.city' },
      { headerName: $t('address.country'), field: 'address.country' },
    ]

    const router = useRouter()
    const rowClick = (event) => {
      if (event.data) {
        router.push(`/organisations/view/${event.data.id}`)
      }
    }

    const addOrganisation = () => {
      router.push(`/organisations/new`)
    }

    const removeModal = ref('removeModal')
    const showRemove = () => {
      removeModal.value.modal.open()
    }
    const remove = () => {
      const ids = api.getSelectedRows().map((row) => { return row.id })
      if (ids.length > 0) {
        backend.post('api/contacts/batch', {
          'action': 'DELETE',
          'ids':    ids,
        }).finally(() => {
          removeModal.value.modal.close()
          reload()
        })
      }
    }

    const selectedIDs = ref([])
    const refreshSelection = (params) => {
      selectedIDs.value = params.api.getSelectedRows().map((row) => { return row.id })
    }

    return {
      breadcrumbs,
      onGridReady,
      onGridPreDestroyed,
      onFilterChanged,
      initialState,
      xsWindow,
      xsColumnDefs,
      columnDefs,
      rowClick,
      addOrganisation,
      selectedIDs,
      refreshSelection,
      removeModal,
      showRemove,
      remove,
      resetGrid,
    }
  }
}
</script>

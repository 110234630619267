<template>
  <ui-modal ref="modal" :callback="cb" :can-confirm="() => { return mailInput !== ''} ">
    <template #title>
      {{ $t('share_link.control_qr.title')}}
    </template>
    <template #default>
      {{ $t('share_link.control_qr.text') }}
      <div class="d-flex justify-content-center">
        <img id="qr-canvas" :src="qrSrc" alt="qrSrc" />
      </div>
      {{ $t('share_link.control_qr.text2') }}
      <div class="d-flex justify-content-center">
        <ui-view-card-field :label="$t('form.labels.email')" v-model="mailInput" />
      </div>
    </template>
  </ui-modal>
</template>

<script>

import {ref, watch} from 'vue'
import UiModal from '@/components/ui-modal'
import UiViewCardField from '@/components/ui-view-card-field'

export default {
  name: 'FormControlQRModal',
  components: {UiViewCardField, UiModal},
  props: {
    callback: {
      type:     Function,
      required: true,
    },
    url: {
      type:     String,
      required: true,
    },
  },
  setup: (props) => {
    const modal = ref('modal')
    const qrSrc = ref(null)

    const cb = async () => {
      await props.callback(mailInput.value)
      mailInput.value
    }

    watch(() => props.url, (url) => {
      if (url) {
        // get control sharelink qr code
        setTimeout(() => {
          var QRCode = require('qrcode')
          QRCode.toDataURL(props.url)
              .then(url => {
                qrSrc.value = url
              })
              .catch(err => {
                console.error(err)
              })
        }, 100)
      }
    })

    const mailInput = ref('')

    return {
      modal,
      qrSrc,
      mailInput,
      cb,
    }
  }
}
</script>

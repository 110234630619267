<template>
  <layout>
    <template #header>
      <ui-header :title="$t('my_organisation.title')">
        <template #actions>
          <div class="btn-group">
            <button type="button" class="btn btn-outline-secondary" v-if="$roles([roles.ORGANISATION_ADMIN])"
                    @click="edit()" form="user-form"><i class="bi-pencil"/>&nbsp;{{ $t('general.edit') }}
            </button>
          </div>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    <ui-content class="pb-1">
      <spinner id="loadingSpinner" v-if="loading"/>
      <template v-if="!loading">
        <div class="container-fluid">
          <div class="container-fluid">
            <div class="row justify-content-center">
              <ui-view-card :title="$t('my_organisation.basic_information')">
                <div class="row">
                  <div>
                    {{ $t('my_organisation.name') }}: {{ organisation.name }}
                  </div>
                  <div>
                    {{ $t('my_organisation.contact_email') }}: {{ organisation.contactEmail }}
                  </div>
                  <div class="d-flex">
                    {{ $t('my_organisation.language') }}:
                    <i style="font-size: 0.75rem; padding-right: 0.5rem;" class="bi-question-circle-fill"
                       :title="$t('my_organisation.language_title')"/>
                    {{ languages.getLanguage(organisation.language) }}
                  </div>
                  <div>
                    {{ $t('my_organisation.support_credits') }}: {{ organisation.supportCredits }}
                  </div>
                  <template v-for="identification in organisation.identifications" :key="identification.id">
                    <div class="d-flex">
                      {{ $t('form.labels.identification_number') }}:&nbsp;
                      <span v-if="identification.identificationType">
                        {{ $t('identification_types.' + identification.identificationType) }}:
                        {{ identification.value }}
                        </span>
                    </div>
                  </template>
                  <template v-if="$orgType(OrganisationType.REGULAR)">
                    <hr/>
                    <div class="d-flex">
                      {{ $t('my_organisation.config.empty_container_status') }}:
                      <i style="font-size: 0.75rem; padding-right: 0.5rem;" class="bi-question-circle-fill"
                         :title="$t('my_organisation.empty_container_info')"/>
                      {{ organisation.config?.empty_container ? $t('general.enabled') : $t('general.disabled') }}
                    </div>
                    <div class="d-flex">
                      {{ $t('my_organisation.config.include_work_slip') }}:
                      <i style="font-size: 0.75rem; padding-right: 0.5rem;" class="bi-question-circle-fill"
                         :title="$t('my_organisation.include_work_slip_info')"/>
                      {{ organisation.config?.include_work_slip ? $t('general.enabled') : $t('general.disabled') }}
                    </div>
                    <div class="d-flex">
                      {{ $t('my_organisation.config.annex_7') }}:
                      <i style="font-size: 0.75rem; padding-right: 0.5rem;" class="bi-question-circle-fill"
                         :title="$t('my_organisation.annex_7_info')"/>
                      {{ organisation.config?.ANNEX_7 ? $t('general.enabled') : $t('general.disabled') }}
                    </div>
                  </template>
                </div>
              </ui-view-card>
            </div>
            <div class="row justify-content-center"
                 v-if="organisation && organisation.organisationType !== OrganisationType.OVERVIEWER">
              <ui-view-card :title="$t('my_organisation.contract.active_contract')">
                <div v-if="!active" style="color: red">
                  {{ $t('my_organisation.contract.contact_us') }}
                </div>
                <div v-if="contract" class="row">
                  <div>
                    {{ $t('my_organisation.contract.status.status') }}:
                    {{ $t('my_organisation.contract.status.' + contract.contractState) }}
                  </div>
                  <div>
                    {{ $t('my_organisation.contract.remaining_credits') }}: {{ contract.currentCredits }} /
                    {{ contract.initialCredits }}
                  </div>
                  <div v-if="contract.contractState === ContractState.IN_OVERDRAFT" style="color: rgb(256, 0, 0)">
                    {{ $t('my_organisation.contract.overdraft') }}: {{ contract.overdraft }}
                  </div>
                  <div>
                    {{ $t('my_organisation.contract.start') }}: {{ DateFormatter.formatDate(contract.start) }}
                  </div>
                  <div>
                    {{ $t('my_organisation.contract.end') }}: {{ DateFormatter.formatDate(contract.stop) }}
                  </div>
                  <div>
                    {{ $t('my_organisation.contract.auto_renew') }}: {{
                      organisation.autoRenewContract
                      ? $t('my_organisation.contract.auto_renew_enabled')
                      : $t('my_organisation.contract.auto_renew_disabled')
                    }}
                  </div>
                </div>
              </ui-view-card>
            </div>
            <div class="row justify-content-center">
              <p-credentials/>
            </div>
          </div>
        </div>
      </template>
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import backend from '@/util/backend'
import {ref} from 'vue'
import Spinner from '@/components/spinner'
import UiViewCard from '@/components/ui-view-card'
import {DateFormatter} from '@/services/dateFormatter'
import {useRouter} from 'vue-router'
import OrganisationState from '@/types/organisationState'
import OrganisationType from '@/types/organisationType'
import languages from '@/types/languages'
import ContractState from '@/types/contractState'
import PCredentials from '@/partials/organisation/Credentials'

export default {
  name:       'MyOrganisationView',
  computed:   {
    ContractState() {
      return ContractState
    },
  },
  components: {
    PCredentials,
    UiViewCard,
    Spinner,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout,
  },
  setup:      () => {
    const breadcrumbs = [BREADCRUMBS.MY_ORGANISATION]
    const router      = useRouter()

    const loading      = ref(true)
    const organisation = ref({})
    const active       = ref(false)
    const contract     = ref(null)
    backend.get(`api/organisations/me`).then((r) => {
      organisation.value        = r.data
      organisation.value.config = JSON.parse(organisation.value.config ?? '{}') ?? {}
      const contracts = r.data.contracts.reverse()
      contract.value = contracts[0]
      for (let i = 0; i < contracts.length; i++ ) {
        if ([
          ContractState.ACTIVE,
          ContractState.LOW_CREDITS,
          ContractState.IN_OVERDRAFT,
        ].indexOf(contracts[i].contractState) > -1) {
          active.value = true
          contract.value = contracts[i]
          break
        }
      }
      loading.value             = false
    })

    const edit = () => {
      router.push({name: 'MyOrganisationEdit'})
    }

    return {
      breadcrumbs,
      loading,
      organisation,
      OrganisationState,
      OrganisationType,
      contract,
      edit,
      DateFormatter,
      languages,
      active,
    }
  },
}
</script>

import session from '@/util/session'
import i18n, {languages} from '@/util/i18n'
import DocumentType from '@/types/documentType'

const $t = () => {
  const user = session.getUser()
  return i18n(user?.language ?? languages.NL)
}

const MeansOfTransport = {
  ROAD: 'ROAD',
  TRAIN: 'TRAIN',
  AIR: 'AIR',
  SEA: 'SEA',
  INLAND_WATER: 'INLAND_WATER',
  PIPE: 'PIPE',
  NON_PUBLIC_ROAD: 'NON_PUBLIC_ROAD',

  getForDocumentType: (documentType) => {
    switch (documentType) {
      case DocumentType.ANNEX_7:
        return [
          {
            label: $t()('means_of_transport.ROAD'),
            value: MeansOfTransport.ROAD,
          },
          {
            label: $t()('means_of_transport.TRAIN'),
            value: MeansOfTransport.TRAIN,
          },
          {
            label: $t()('means_of_transport.AIR'),
            value: MeansOfTransport.AIR,
          },
          {
            label: $t()('means_of_transport.SEA'),
            value: MeansOfTransport.SEA,
          },
          {
            label: $t()('means_of_transport.INLAND_WATER'),
            value: MeansOfTransport.INLAND_WATER,
          },
        ]
    }
  },

  getForMatis: () => {
    return [
      {
        label: $t()('means_of_transport.ROAD'),
        value: MeansOfTransport.ROAD,
      },
      {
        label: $t()('means_of_transport.TRAIN'),
        value: MeansOfTransport.TRAIN,
      },
      {
        label: $t()('means_of_transport.AIR'),
        value: MeansOfTransport.AIR,
      },
      {
        label: $t()('means_of_transport.INLAND_WATER'),
        value: MeansOfTransport.INLAND_WATER,
      },
      {
        label: $t()('means_of_transport.PIPE'),
        value: MeansOfTransport.PIPE,
      },
      {
        label: $t()('means_of_transport.NON_PUBLIC_ROAD'),
        value: MeansOfTransport.NON_PUBLIC_ROAD,
      },
    ]
  },

  getAll: () => {
    return [
      {
        label: $t()('means_of_transport.ROAD'),
        value: MeansOfTransport.ROAD,
      },
      {
        label: $t()('means_of_transport.TRAIN'),
        value: MeansOfTransport.TRAIN,
      },
      {
        label: $t()('means_of_transport.AIR'),
        value: MeansOfTransport.AIR,
      },
      {
        label: $t()('means_of_transport.SEA'),
        value: MeansOfTransport.SEA,
      },
      {
        label: $t()('means_of_transport.INLAND_WATER'),
        value: MeansOfTransport.INLAND_WATER,
      },
      {
        label: $t()('means_of_transport.PIPE'),
        value: MeansOfTransport.PIPE,
      },
      {
        label: $t()('means_of_transport.NON_PUBLIC_ROAD'),
        value: MeansOfTransport.NON_PUBLIC_ROAD,
      },
    ]
  },
}

export default MeansOfTransport
